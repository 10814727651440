import React, {Fragment, useState, useEffect, createContext } from 'react';
import en from './translations/en';
import de from './translations/de';

export const LocaleContext = createContext();



const LocaleProvider = ({children}) => {

    const [updated, setUpdated] = useState(false);
    const [t, setTranslations] = useState(de);
    const [locale, setLocale] = useState('de');
    const [localeAvailable, setLocaleAvailable] = useState(
        {

            en: {
                flag:'us',
                label: 'English'
            },

            de: {
                flag:'de',
                label: 'Deutsch'
            },

        }
    );

    useEffect( () => {
        if(!updated) {
            setTranslationObject();
        }
    }, [locale, localeAvailable, t])


    const setTranslationObject =  async () => {
        let translationObject = await import(`./translations/${locale}`)
        setTranslations(translationObject)
        setUpdated(true)
    }

    const setLang = async(lang) => {
        if(Object.keys(localeAvailable).includes(lang)){
            setLocale(lang)
            let translationObject = await import(`./translations/${lang}`)
            setTranslations(translationObject)
        }
    }

    const params = {
        locale,
        localeAvailable,
        t,
        setLang
    }

    return (
        <LocaleContext.Provider value={params}>
            {children}
        </LocaleContext.Provider>
    )
}

export default LocaleProvider;
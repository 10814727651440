import React, {Fragment, useState, useEffect, useContext, useRef} from 'react';
import { OrganizationContext } from '../../context/Organization/Organization';

const OrgCookieCrumb = (props) => {

    const { organization } = useContext(OrganizationContext);
    const organizationChanged = useCompare(organization)

    useEffect(() => {  
        
        
    }, [organization, organizationChanged]);

    function useCompare (val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const UI = () => {
        return (
            <Fragment>
                <div className="col-md-3 col-sm-12 text-end" id="userCurrentOrg">
                    <p>{ (organization.name)?organization.name:'' }</p>
                </div>
            </Fragment>
        )  
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}

export default OrgCookieCrumb;
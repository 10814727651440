import React, {Fragment, useEffect, useContext } from 'react';
import {GatewaysContext} from "../../../../context/Devices/Gateways";


const CountSensorsWidget = (props) => {

    const { gateway, gatewayChanged } = useContext(GatewaysContext);


    useEffect(  () => {
            
    }, [gateway, gatewayChanged]);

    const UI = () => {
        let insights = []
        if(gateway?.serials_insight) {
            for (const ins of gateway.serials_insight) {
                for (const serial of ins.serials_insight) {
                    if(!insights.includes(serial)) insights.push(serial)
                }
            }
        }
        return (
            <div className="col-xl-2 col-md-4" id="widgetCountSensorsWidget">
                <div className="card text-center " title="The total number of sensors seen by this gateway (registered & unregistered)">
                    <div className="card-body align-self-center">
                        <div className="mx-auto my-1" style={{height: "75px", display: "inline-flex", alignItems: "center"}}>
                            <i className="fa-solid fa-microchip fa-4x text-secondary"></i>
                        </div>
                        <p className="text-muted mb-0 line-ellipsis">Zähler Insight</p>
                        <h2>{insights.length}</h2>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )

}

export default CountSensorsWidget;
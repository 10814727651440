import React, {Fragment, useEffect, useContext } from 'react';
import {SensorsContext} from "../../../../context/Devices/Sensors";
import { CSVLink, CSVDownload } from "react-csv";
import CountMbusSensorsWidget from "./countMbusSensors";

const CountDefectSensors = (props) => {

    const { sensors, sensorsChanged, sanitizedSensor } = useContext(SensorsContext);

    useEffect(() => {

    }, [sensors, sensorsChanged])


    const UI = () => {

        let noCommSensors = [];
        if(!!sensors && sensors.length > 0) noCommSensors = sensors.filter(sensor => {
            if(!sensor.state.date) return sanitizedSensor(sensor)
        })

        if(noCommSensors.length >0) {
            return (
                <Fragment>

                    <div className="col-xl-2 col-md-4" id="widgetSensorshomelessCount">

                        <div className="card text-center " title="Sensors not Received Counter">
                            <div className="card-body align-self-center">
                                <div className="mx-auto my-1" style={{display: "inline-flex", alignItems: "center"}}>
                                    <i className={`fa-solid fa-heart-pulse fa-4x text-danger`}></i>
                                </div>
                                <p className="mb-0 text-muted">Noch nie empfangen</p>
                                <h2 className="mb-0 line-ellipsis ">{noCommSensors.length}</h2>
                            </div>
                        </div>
                    </div>

                </Fragment>
            )
        } else {
            return (
                <Fragment>
                </Fragment>
            )
        }


    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )

}

export default CountDefectSensors;
import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';
import { useRouter } from "../../../Shared/Router/Router";
import {PermissionsWrapper, hasPermission} from "../../../../components/Dashboard/reactPermissionsWrapper";
import { AuthContext } from '../../../../context/Auth/AuthProvider';
import {TenantContext} from '../../../../context/Tenant/Tenant';
import {LocationsContext} from '../../../../context/Locations/Locations';
import { Link } from "react-router-dom";

import de from '../datatable.de.json'

import capitalizeUtil from '../../../../utils/capitalizeUtils'
import {OrganizationContext} from "../../../../context/Organization/Organization";

const $ = require('jquery')
$.DataTable = require('datatables.net');

/**
 * This table is used multiple times. A props.id is REQUIRED
 * when calling this component
 */
const TblSearchLocations = (props) => {

    const router = useRouter()
    const { user } = useContext(AuthContext);
    const { setSearchLocations, locationIcon, searchLocations, getLocsByAddress, searchLocationsChanged} = useContext(LocationsContext);
    const {organizations, organization} = useContext(OrganizationContext);

    const locationsChanged = useCompare(searchLocations);

    useEffect(() => {
        if (locationsChanged === true) {
            initializeDT()
        }

    }, [searchLocations, locationsChanged]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const dd = (str) => {
        //  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`
        //  if (!str) return str;

        // Handle "ß" (eszett) special case
        const firstChar = str.charAt(0).toUpperCase();
        const restOfString = str.slice(1).replace(/ß/g, 'SS');

        // Combine the first character and the rest
        return `${firstChar}${restOfString}`;
    }

    const initializeDT = () => {
        if (!!searchLocations && searchLocations.length > 0) {
            if ($.fn.dataTable.isDataTable(`#${props.id}`)) {
                $(`#${props.id}`).DataTable().destroy();
                // remove the eventhandler to prevent multiple events
                $(`#${props.id} tbody`).off('click')
                $(`#${props.id} tbody`).off('mouseenter')
                $(`#${props.id} tbody`).off('mouseleave')
                initializeDT()
            } else {
                $(`#${props.id}`).DataTable({
                    language: de,
                    paging: true,
                    pagingType: "full",
                    processing: true,
                    searching: true,
                    data: (!!props.locations)? props.locations:searchLocations,
                    orderCellsTop: true,
                    fixedHeader: true,
                    rowId: "_id",
                    pageLength: 50,
                    columns: [
                        {
                            "data": null,
                            "searchable": false,
                            "ordering": true,
                            render: function (data, type, row) {
                                return locationIcon({location:data})
                            }
                        },

                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                const organisation =  organizations.filter(org => org._id === data.organization_id);
                                return organisation[0]?.name;
                            }
                        },
                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if (data.address.street !== undefined) {
                                    return capitalizeUtil.capitalize(data.address.street)
                                } else {
                                    return '';
                                }
                            }
                        },
                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if (data.address.house_number !== undefined) {
                                    return data.address.house_number
                                } else {
                                    return '';
                                }
                            }
                        },
                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if (data.address.street1 !== undefined) {
                                    return data.address.street1
                                } else {
                                    return '';
                                }
                            }
                        },
                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if (data.address.city !== undefined) {
                                    return capitalizeUtil.capitalize(data.address.city)
                                } else {
                                    return '';
                                }
                            }
                        },
                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if (data.address.post_code !== undefined) {
                                    return data.address.post_code
                                } else {
                                    return '';
                                }
                            }
                        },

                    ],
                    createdRow: function (row, data, dataIndex) {
                        $(row).attr('data-location_id', data._id || 'na');
                        //       $(row).addClass('alert-warning');
                    },
                    "fnDrawCallback": function( oSettings ) {}
                });

                $('.dataTables_length select').addClass('form-select');
                $('.dataTables_filter input').addClass('form-control');

                $(`#${props.id} thead`).on('mouseenter', 'tr', function () {
                    $(this).css('cursor', 'pointer');
                });

                $(`#${props.id} tbody`).on('mouseenter', 'tr', function () {
                    $(this).css('cursor', 'pointer');
                    $(this).css('background-color', '#f3f2f7');
                });

                $(`#${props.id} tbody`).on('mouseleave', 'tr', function () {
                    $(this).css('background-color', 'transparent');
                });

                $(`#${props.id} tbody`).on('click', 'tr', function () {
                    //$(this).toggleClass('selected');
                });

                $(`#${props.id} tbody`).on('click', 'a.connectLink', function (e) {
                    e.preventDefault()
                    let link = e.currentTarget.getAttribute("data-href");
                    router.push(link);
                });

            }
        }
    }

    const UI = () => {
        const isProcessing = () => {
            if (searchLocations.length === 0) {
                return (
                    <div className="mt-4">
                        <h4 className="text-center"><i class="fa-solid fa-cog fa-spin"></i> WIRD AKTUALISIERT ...</h4>
                    </div>
                )
            }
        }
        return (
            <Fragment>
                <div className='table-responsive'>
                    <table id={props.id} className="table mb-0 row-border hover order-column w-100" role="grid">
                        <thead>
                        <tr>
                            <th></th>
                            <th>Kunde</th>
                            <th>Straße</th>
                            <th>Hausnr.</th>
                            <th>ME</th>
                            <th>Stadt</th>
                            <th>PLZ</th>
                        </tr>
                        </thead>
                    </table>
                </div>
                {isProcessing()}
            </Fragment>
        )
    }
    return (
        <Fragment>{UI()}</Fragment>
    )
}

export default TblSearchLocations;

import React, {Fragment, useState, useEffect, useContext} from 'react';
import { AuthContext } from '../../context/Auth/AuthProvider';
import { LocaleContext } from '../../context/Locale/Locale';
import ContactForm from "./ContactForm";
import { useRouter } from '../Shared/Router/Router'


/**
 * FontAwesome
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "@fortawesome/fontawesome-svg-core/styles.css";
import { config } from "@fortawesome/fontawesome-svg-core";
import { faFacebookSquare, faLinkedin, faXingSquare, faYoutubeSquare, faInstagramSquare} from '@fortawesome/free-brands-svg-icons'

config.autoAddCss = false;



const Footer = ({children}) => {

    const router = useRouter()
    const [updated, setUpdated] = useState(false);
    const {t, locale, localeAvailable, setLang} = useContext(LocaleContext);
//    const {config} = useContext(WhiteLabelContext);



    useEffect(  () => {
        if(!updated){
            setUpdated(true)
        }

    }, [locale]);


    const UI = () => {
        return (
            <Fragment>

                <footer className="footer footer-static footer-light">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col">
                                <span className="w-100 d-flex justify-content-start ">Alle Rechte bei<a href="https://www.molline.de" target="_blank">WDV-MOLLINÉ GmbH</a> &copy; {new Date().getFullYear()}</span>
                            </div>
                            <div className="col">
                                <p>
                                     <span className="w-100 d-flex justify-content-center ">
                                         <a id="contactLink" className="cursor-pointer" onClick={() => {ContactForm.showForm()}}>Kontakt</a>
                                         <a href="https://www.molline.de/datenschutz/" target="_blank">Datenschutz</a>
                                         <a href="https://www.molline.de/impressum/" target="_blank">Impressum</a>
                                         <a href="https://www.molline.de/impressum/" target="_blank">AGB</a>
                                     </span>
                                </p>
                            </div>

                            <div className="col">
                                <p>
                                   <span className="w-100 d-flex justify-content-end ">
                                       <a href="https://www.facebook.com/wdvmolline" className="facebook" target="_blank"><FontAwesomeIcon size="2x" icon={faFacebookSquare} /></a>
                                       <a href="https://www.instagram.com/wdvmolline/" className="instagram" target="_blank"><FontAwesomeIcon size="2x" icon={faInstagramSquare} /></a>

                                       <a href="https://www.youtube.com/channel/UCowvIyUncw6EQaX_CwvsB9Q" className="youtube" target="_blank"><FontAwesomeIcon size="2x" icon={faYoutubeSquare} /></a>
                                       <a href="https://www.xing.com/companies/wdv%2Fmollin%C3%A9" className="xing" target="_blank"><FontAwesomeIcon size="2x" icon={faXingSquare} /></a>
                                       <a href="https://de.linkedin.com/company/wdv-mollin%C3%A9" className="linkedin" target="_blank"><FontAwesomeIcon size="2x" icon={faLinkedin} /></a>
                                   </span>
                                </p>
                            </div>
                        </div>
                        <div className="pixels"></div>
                    </div>
                </footer>

            </Fragment>
        )
    }



    return (
        <Fragment>

            {UI()}

        </Fragment>
    )
}

export default Footer;

import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import {CmsContext} from '../../context/Cms/Cms';
import uniqid from 'uniqid';

import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from "swiper";

import 'swiper/css';
import "swiper/css/navigation";

const Faq = (props) => {


    const [updated, setUpdated] = useState(false);
    const [relFaqs, setRelevantFaqs] = useState([])
    const [relCategories, setRelevantCategories] = useState([])
    const [categorySelected, setCategorySelected] = useState({});

    const {
        faqs,
        categories,
        getFaqs,
        getCategories
    } = useContext(CmsContext);

    const faqsChanged = useCompare(faqs)
    const categoriesChanged = useCompare(categories)


    useEffect(() => {

        const getFaqsData = async () => {
            await getFaqs()
        }
        const getCateforiesData = async () => {
            await getCategories()
        }

        sortCategories();
        if (faqsChanged === true && !updated) {
            getFaqsData().catch(console.error);
        }
        if (categoriesChanged === true && !updated) {
            getCateforiesData().catch(console.error);
        }
        setUpdated(true)

    }, [faqs, categories, faqsChanged, categoriesChanged, categorySelected, relFaqs, relCategories]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    /**
     * THere may be many categories. These can be for more than FAQs though.
     * Sort only the categories where there are FAQs in that category
     */
    const sortCategories = () => {
        if (relCategories.length === 0) {
            let categoriesToShow = [];
            for (let i = 0; i < categories.length; i++) {
                if (categories.length > 0) {
                    let candidates = faqs.filter(faq => faq.tags.includes(categories[i].tag))
                    if (candidates.length > 0) categoriesToShow.push(categories[i]);
                    if (i === (categories.length - 1) && categoriesToShow.length > 0) {
                        setCategorySelected(categoriesToShow[0])
                        setRelevantCategories(categoriesToShow)
                    }
                }
            }
        }
    }

    const selectCategory = (event) => {
        let id = event.currentTarget.id;
        categories.map(cat => {
            if (cat._id === id) {
                console.log('SETTING CAT', cat)
                setCategorySelected(cat)

            }
        })
    }
    /**
     * Filter FAQs where any word from the search string is included
     */
    const search = () => {
        let value = document.getElementById('searchbar').value
        value = value.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]<>/g, "") //Strip punctuation
        if (value.length < 1) {
            setRelevantFaqs([])
            return;
        }
        let valuesArray = value.split(' ');
        let relevantFaqs = [];
        for (let i = 0; i < faqs.length; i++) {
            valuesArray.map(v => {
                if (faqs[i].title.indexOf(v) !== -1 || faqs[i].tags.indexOf(v) !== -1 || faqs[i].content.indexOf(v) !== -1) {
                    relevantFaqs.push(faqs[i])
                }
            })
            if (i === faqs.length - 1) {
                setRelevantFaqs(relevantFaqs)
                const collection = document.getElementsByClassName("swiper-slide-active");
                setCategorySelected({})

                return;
            }
        }

    }

    const UI = () => {

        const renderFaqs = () => {
            if (relFaqs.length > 0) {
                return (
                    relFaqs.map((faq, index) => {
                        return (
                            <div key={uniqid()} className="card collapse-header ">
                                <div id={`heading${index}`} className="card-header" data-toggle="collapse" role="button"
                                     data-target={`#accordion${index}`} aria-expanded="true"
                                     aria-controls={`#accordion${index}`}>
                    <span className="collapse-title d-flex align-items-center">
                      <i className="bx bxs-circle font-small-1"></i>
                        {faq.title}
                    </span>
                                </div>
                                <div id={`accordion${index}`} role="tabpanel"
                                     data-parent={`#accordionWrapper-${categorySelected._id}`}
                                     aria-labelledby={`heading${index}`} className="collapse ">
                                    <div className="card-body">
                                        {faq.content}
                                    </div>
                                </div>
                            </div>
                        )

                    })
                )
            } else {
                return (
                    faqs.map((faq, index) => {
                        if (faq.tags.includes(categorySelected.tag)) {
                            return (
                                <div key={uniqid()} className="card collapse-header ">
                                    <div id={`heading${index}`} className="card-header" data-toggle="collapse"
                                         role="button" data-target={`#accordion${index}`} aria-expanded="true"
                                         aria-controls={`#accordion${index}`}>
                    <span className="collapse-title d-flex align-items-center">
                      <i className="bx bxs-circle font-small-1"></i>
                        {faq.title}
                    </span>
                                    </div>
                                    <div id={`accordion${index}`} role="tabpanel"
                                         data-parent={`#accordionWrapper-${categorySelected._id}`}
                                         aria-labelledby={`heading${index}`} className="collapse ">
                                        <div className="card-body">
                                            {faq.content}
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })

                )
            }
        }


        return (
            <Fragment>

                <head>
                    <title>MOLLINÉ CONNECT FAQ</title>
                </head>
                <div className="mt-4">
                    <section className="faq-search">
                        <div className="row">
                            <div className="col-12">
                                <div className="card faq-bg bg-transparent box-shadow-0 p-1 p-md-5">
                                    <div className="card-body p-0">
                                        <h1 className="faq-title text-center mb-3">wie können wir helfen?</h1>
                                        <form>
                                            <fieldset
                                                className="faq-search-width form-group position-relative w-50 mx-auto">
                                                <input type="text"
                                                       className="form-control round form-control-lg shadow pl-2"
                                                       id="searchbar" placeholder="Ask a question..." onChange={() => {
                                                    search()
                                                }}/>
                                            </fieldset>
                                        </form>
                                        <p className="card-text text-center mt-1 font-medium-1 text-muted">
                                            oder wählen Sie eine Kategorie aus, um schnell die benötigte Hilfe zu
                                            finden</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    <section className="faq">
                        <div className="row">
                            <div className="col-12">
                                <div className="card bg-transparent shadow-none">
                                    <div className="card-body">
                                        <div
                                            className="swiper-centered-slides swiper-container p-1 swiper-container-initialized swiper-container-horizontal">
                                            <div className="swiper-wrapper" id="swiper-wrapper-553cbd510103e4cf6"
                                                 aria-live="polite">
                                                {relCategories ?
                                                    <Swiper
                                                        spaceBetween={30}
                                                        slidesPerView={'auto'}
                                                        initialSlide={0}
                                                        centeredSlides={true}
                                                        slideToClickedSlide={true}
                                                        className={"faq-swiper"}
                                                        onSlideChange={() => console.log('slide change')}
                                                        onSwiper={(swiper) => console.log(swiper)}
                                                        navigation={false}
                                                        modules={[Navigation]}
                                                    >
                                                        {
                                                            relCategories.map((cat, index) => {
                                                                return (
                                                                    <SwiperSlide key={uniqid()}
                                                                                 className={`swiper-slide rounded swiper-shadow ${(categorySelected._id === cat._id) ? "swiper-slide-active" : ""}`}
                                                                                 id={cat._id} role="group"
                                                                                 aria-label={`${index} / ${relCategories.length}`}
                                                                                 onClick={(event) => {
                                                                                     selectCategory(event)
                                                                                 }}>
                                                                        <i className={`bx bx-${cat.icon} mb-1 font-large-1`}></i>
                                                                        <div className="cent-text1">{cat.title}</div>
                                                                    </SwiperSlide>
                                                                )
                                                            })
                                                        }

                                                    </Swiper>
                                                    :
                                                    <h1>No data</h1>

                                                }

                                            </div>
                                        </div>

                                        <div className="main-wrapper-content">
                                            <div className="wrapper-content active" data-faq="faq-text">

                                                <div className="text-center p-md-4 p-sm-1 py-1 p-0">
                                                    <h1 className="faq-title">{categorySelected.title}</h1>
                                                    <p>{categorySelected.content}</p>
                                                </div>

                                                <div id="accordion-icon-wrapper"
                                                     className="collapse-icon accordion-icon-rotate mb-4">
                                                    <div className="accordion"
                                                         id={`accordionWrapper-${categorySelected._id}`}
                                                         data-toggle-hover="true">

                                                        {renderFaqs()}

                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                </div>


            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>

    )


}


Faq.requireAuth = false;  // true -> force login via AuthContext, false -> no login required so no user available either
export default Faq;

import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { MgmtUsersContext } from '../../../../context/Users/UsersContext';
import { AuthContext } from '../../../../context/Auth/AuthProvider';
import OrgCookieCrumb from "../../../../components/Dashboard/orgCookieCrumb";
import {OrganizationContext} from '../../../../context/Organization/Organization';

import TblUsers from "../../../../components/Dashboard/tables/dataTables/tblUsers";
import TourSteps from "../../../../TourSteps.json";
import PageTour from "../../../../components/Dashboard/tour/tour";
const $ = require('jquery');
$.DataTable = require('datatables.net');

const Users = ({children}) => {

    const {mgmtUsers, setMgmtUsers, getAllUsers} = useContext(MgmtUsersContext)
    const { user } = useContext(AuthContext);
    const { organization } = useContext(OrganizationContext);

    const organizationChanged = useCompare(organization)
    const mgmtUsersChanged = useCompare(mgmtUsers)


    useEffect(() => {

       
    }, [mgmtUsers, user, organizationChanged, mgmtUsersChanged]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const UI = () => {
       if(!!user){
        return (
            <Fragment>

                <div className="content-header row">
                    <div className="content-header-left col-md-9 col-12 mb-2">
                        <div className="row breadcrumbs-top">
                            <div className="col-9">
                                <h2 className="content-header-title float-start mb-0">Verwalter Management</h2>
                                <div className="breadcrumb-wrapper">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a>Verwaltung</a></li>
                                        <li className="breadcrumb-item active">Verwalter</li>
                                        <li>
                                            <span className="tourBorderRight" />
                                            <PageTour steps={TourSteps.users.overview}  isButton={false} />
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <OrgCookieCrumb />
                </div>

                <div className="content-body">   
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            
                                            <TblUsers 
                                                id="tblUsers"
                                                users={mgmtUsers}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                  
            </Fragment>
        )
       }else{
        return (<Fragment></Fragment>)
       }
    }

    return (
        <Fragment>
           {UI()}
        </Fragment>
    )
}

Users.requireAuth = true;
Users.requiredScope = ['user.view.any'];
export default Users;
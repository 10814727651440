import React, {Fragment, useState, useEffect, useContext } from 'react';

import { useRouter } from '../Router/Router'
import Navbar from '../Navbar/Navbar'
import LPFooter from '../../LandingPages/Footer'
import AppHeader from "../../Dashboard/header/header";
import MainMenu from "../../Dashboard/header/MainMenu";
import Footer from "../../Dashboard/Footer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faBuildingCircleXmark, faDownload, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import {Helmet} from "react-helmet";
import ReportsProvider from '../../Dashboard/reportsProvider/reportsProvider';
import LiveChatSupport from "../../Dashboard/liveChatSupport/liveChatSupport";
import { AuthContext } from '../../../context/Auth/AuthProvider';

import ShortcutHandler from '../../../components/Dashboard/shortcutKeys';


const LayoutHandler = ({children}) => {
    const [updated, setUpdated] = useState(false);
    const [scrollVisible, setScrollVisible] = useState(false)
    const router = useRouter();
    const { user } = useContext(AuthContext);

    useEffect(() => {
        /**
         * We can not alter the body tag directly, so we do it in the
         * Layout via javascript.
         */
        if(!updated){
            setUpdated(true)
        }
    }, [updated]);

    //scroll-to-top toggle btn
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300){
            setScrollVisible(true)
        }
        else if (scrolled <= 300){
            setScrollVisible(false)
        }
    };
    const scrollToTop = () =>{
        window.scrollTo({top: 0, behavior: 'smooth'});
    };
    window.addEventListener('scroll', toggleVisible);
    /**
     * Some pages have a slightly different layout. For example an error page should not
     * have a full menu bar, or header. This UI switch case will render different layouts according
     * the the URI.
     */
    const UI = () => {
        switch(router.pathname){
            case '/login':
            case '/404':
            case '/500':
                return (
                    <Fragment>
                        <Helmet>
                            <link rel="stylesheet" type="text/css" href="/homepage/app-assets/css/bootstrap.css" />
                            <link rel="stylesheet" type="text/css" href="/homepage/app-assets/css/bootstrap-extended.css" />
                        </Helmet>
                        <div className="bg-full-screen-image  blank-page" id="notFoundPage" data-open="hover" data-menu="horizontal-menu" data-col="1-column" >
                            {children}
                        </div>
                    </Fragment>
                )
            case '/':
            case '/home':
            case '/faq':
            case '/kb/article':
            case '/kb/categories':
            case '/kb/article/[id]':
                return (
                    <Fragment>

                        <Helmet>
                            <title>Mit MOLLINÉ CONNECT in die Zukunft starten</title>
                            <link rel="icon" type="image/x-icon" href="/favicons/molline_favicon.png" />
                            <link rel="stylesheet" type="text/css" href="/app-assets/fontawesome/css/all.css" />

                            {/*  BEGIN: Vendor CSS */}
                            <link rel="stylesheet" type="text/css" href="/homepage/app-assets/vendors/css/vendors.min.css" />
                            <link rel="stylesheet" type="text/css" href="/homepage/app-assets/vendors/css/ui/prism.min.css" />
                            {/*  END: Vendor CSS */}

                            {/*  BEGIN: Theme CSS */}
                            <link rel="stylesheet" type="text/css" href="/homepage/app-assets/css/bootstrap.css" />
                            <link rel="stylesheet" type="text/css" href="/homepage/app-assets/css/bootstrap-extended.css" />
                            <link rel="stylesheet" type="text/css" href="/homepage/app-assets/css/colors.css" />
                            <link rel="stylesheet" type="text/css" href="/homepage/app-assets/css/components.css" />

                            {/*  BEGIN: Page CSS */}
                            <link rel="stylesheet" type="text/css" href="/homepage/app-assets/css/core/menu/menu-types/horizontal-menu.css" />
                            <link rel="stylesheet" type="text/css" href="/homepage/app-assets/css/plugins/extensions/swiper.min.css" />
                            {/*  END: Page CSS */}

                            {/*  BEGIN: Custom CSS */}
                            <link rel="stylesheet" type="text/css" href="/homepage/assets/css/molline.css" />
                            {/*  END: Custom CSS */}

                            {/*  BEGIN: Vendor S */}
                            <script src="/homepage/app-assets/vendors/js/vendors.min.js" strategy="beforeInteractive" />
                            <script src="/homepage/app-assets/vendors/js/extensions/swiper.min.js" strategy="beforeInteractive" />
                            {/*  BEGIN Vendor JS */}

                            {/*  BEGIN: Page Vendor JS */}
                            <script src="/homepage/app-assets/vendors/js/ui/prism.min.js" strategy="beforeInteractive" />
                            {/*  END: Page Vendor JS */}

                            {/*  BEGIN: Theme JS */}
                            <script src="/homepage/app-assets/js/scripts/configs/horizontal-menu.js" strategy="beforeInteractive" />
     
                            {/*  END: Theme JS */}


                        </Helmet>
                        { updated ?
                            <div  id="lp-mc" className="horizontal-layout horizontal-menu 1-columns navbar-sticky footer-static" data-open="hover" data-menu="horizontal-menu" data-col="1-columns" >
                                <Navbar />
                                <div className="" >
                                    <div className="content-body" >
                                        {children}
                                    </div>
                                </div>
                                <LPFooter />
                            </div>
                            :
                            <Fragment></Fragment>
                        }

                    </Fragment>
                )

            default:
                return (
                    <Fragment>
                        <Helmet>

                            <link rel="stylesheet" type="text/css" href="/app-assets/fontawesome/css/all.css" />

                            {/* BEGIN: Vendor CSS */}
                            <link  rel="stylesheet" type="text/css" href="/theme2/app-assets/vendors/css/vendors.min.css" />
                            <link rel="stylesheet" type="text/css" href="/theme2/app-assets/vendors/css/charts/apexcharts.css" />
                            <link rel="stylesheet" type="text/css" href="/theme2/app-assets/vendors/css/extensions/toastr.min.css" />
                            <link rel="stylesheet" type="text/css" href="/theme2/app-assets/vendors/css/forms/wizard/bs-stepper.min.css" />
                            {/* END: Vendor CSS */}

                            {/* BEGIN: Theme CSS */}
                            <link rel="stylesheet" type="text/css" href="/theme2/app-assets/css/bootstrap.css" />
                            <link rel="stylesheet" type="text/css" href="/theme2/app-assets/css/bootstrap-extended.css" />
                            <link rel="stylesheet" type="text/css" href="/theme2/app-assets/css/colors.css" />
                            <link rel="stylesheet" type="text/css" href="/theme2/app-assets/css/components.css" />
                            <link rel="stylesheet" type="text/css" href="/theme2/app-assets/css/themes/dark-layout.css" />
                            <link rel="stylesheet" type="text/css" href="/theme2/app-assets/css/themes/bordered-layout.css" />
                            <link rel="stylesheet" type="text/css" href="/theme2/app-assets/css/themes/semi-dark-layout.css" />

                            {/* BEGIN: Page CSS */}
                            <link rel="stylesheet" type="text/css" href="/theme2/app-assets/css/core/menu/menu-types/horizontal-menu.css" />
                            <link rel="stylesheet" type="text/css" href="/theme2/app-assets/css/pages/dashboard-ecommerce.css" />
                            <link rel="stylesheet" type="text/css" href="/theme2/app-assets/css/plugins/charts/chart-apex.css" />
                            <link rel="stylesheet" type="text/css" href="/theme2/app-assets/css/plugins/extensions/ext-component-toastr.css" />

                            <link rel="stylesheet" type="text/css" href="/theme2/app-assets/css/plugins/forms/form-validation.css" />
                            <link rel="stylesheet" type="text/css" href="/theme2/app-assets/css/plugins/forms/form-wizard.css" />

                            <link rel="stylesheet" type="text/css" href="/theme2/app-assets/css/plugins/forms/form-file-uploader.css" />

                            {/* END: Page CSS */}

                            <link rel="stylesheet" href="/theme2/app-assets/vendors/css/extensions/shepherd.min.css"/>

                            {/* BEGIN: Custom CSS */}
                            <link rel="stylesheet" type="text/css" href="/theme2/molline.css" />
                            {/* END: Custom CSS */}

                            <script type="text/javascript" src="/theme2/app-assets/vendors/js/vendors.min.js" strategy="beforeInteractive" />
                            <script type="text/javascript" src="/theme2/app-assets/vendors/js/jquery/jquery.min.js" strategy="beforeInteractive" />
                            <script type="text/javascript" src="/theme2/app-assets/vendors/js/forms/wizard/bs-stepper.min.js" strategy="beforeInteractive" />

                            <script type="text/javascript" src="/theme2/app-assets/vendors/js/extensions/shepherd.min.js"></script>
                            {/**  META **/}
                            <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />

                            <meta name="viewport" content="width=device-width,initial-scale=1.0,user-scalable=0,minimal-ui" />
                            <meta name="title" content="Molline Connect - Messtechnik der Zukunft" />
                            <meta name="description" content="Molline Connect ist Ihr innovative Lösung für Ihr Energiedatenmanagement und die perfekte Kombination aus intelligenter Softwarelösung und Messtechnik der Zukunft. Von Deutschland für Deutschland" />
                            <meta name="keywords" content={`energiedatenmanagement, hkvo, messtechnik, uvi, eed, gateway, heizkostenverteiler, rauchwarnmelder, smarter building, diehl, techem, kamstrup, lorenz, engelmann, innotas, qundis, maddalena, sonntex, zenner`} />
                            <meta name="author" content={`https://molline.de | @DevOps`} />
                            <meta name="robots" content="index,follow"></meta>
                            {/** Google **/}
                            <meta name="google-site-verification" content="" />
                            {/** Open Graph **/}
                            <meta property="og:title" content="Molline Connect - Messtechnik der Zukunft" />
                            <meta property="og:site_name" content="Molline Connect - Messtechnik der Zukunft" />
                            <meta property="og:type" content="article" />
                            <meta property="og:url" content="https://molline-connect.de/" />
                            <meta property="og:description" content="Molline Connect ist Ihr innovative Lösung für Ihr Energiedatenmanagement und die perfekte Kombination aus intelligenter Softwarelösung und Messtechnik der Zukunft. Von Deutschland für Deutschland" />
                            <meta property="og:image" content="" />
                            {/** Twitter Card data **/}
                            <meta property="twitter:card" content="summary" />
                            <meta property="twitter:title" content="Molline Connect - Messtechnik der Zukunft" />
                            <meta property="twitter:description" content="Molline Connect ist Ihr innovative Lösung für Ihr Energiedatenmanagement und die perfekte Kombination aus intelligenter Softwarelösung und Messtechnik der Zukunft. Von Deutschland für Deutschland" />
                            <meta property="twitter:image" content="" />

                        </Helmet>
                        <div>
                            <AppHeader />
                            <div className="app-content content " id="appContent" >
                                <div id="top" ></div>
                                <div className="content-overlay"></div>
                                <div className="header-navbar-shadow"></div>
                                <div className="content-wrapper container-fluid p-0">
                                    {children}
                                </div>
                            </div>
                            <button className="btn btn-primary btn-icon scroll-top" type="button" style={{display: scrollVisible ? 'inline' : 'none'}} onClick={scrollToTop}><i className="fa-light fa-arrow-up"></i></button>
                            <div className="drag-target"></div>
                            <LiveChatSupport />
                            <Footer />
                            <ReportsProvider />

                            <ShortcutHandler />
                        </div>
                    </Fragment>
                )
        }
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )



}

export default LayoutHandler;

import React, { useState, Fragment, useEffect, useContext } from 'react';
import { AuthContext } from "../../../../context/Auth/AuthProvider";
import { LocationsContext } from "../../../../context/Locations/Locations";
import ConsumptionChart from "../../charts/consumptionChart";
import {useRouter} from "../../../Shared/Router/Router";
import { PermissionsWrapper, hasPermission, MembershipWrapper, hasMembership } from "../../reactPermissionsWrapper";

const ConsumptionChartsWidget = (props) => {

    const router = useRouter()
    const { user } = useContext(AuthContext);
    const { locationsChanged, location, setLocation, locations, locationChanged, capitalize, getLocationAddress, locationIcon } = useContext(LocationsContext);

    useEffect( () => {
            
    }, [ locationChanged, location ]);

    const UI = () => {
        if(Object.keys(location).length === 0  || !user || locations.length === 0) return <Fragment></Fragment>
        return(
            <PermissionsWrapper user={user} scopes={['location.view.any', 'location.view.own']} >
                <MembershipWrapper user={user} location={location} memberships={['basic','advanced', 'premium']} >
                    <Fragment>
                        <div className="mt-2" style={{ display: "flex", flexDirection: "row", flex: 1 }}>
                            <div className="d-flex flex-fill flex-shrink flex-grow row consumption_charts" id="widgetConsumptionCharts"  style={{ justifyContent: "stretch", alignItems: "flex-start", flexWrap: "wrap" }}>
                                <ConsumptionChart
                                    location={location}
                                    showPlaceholder={true}
                                    showLocationAddress={false}
                                    height={240}
                                />
                            </div>
                        </div>
                    </Fragment>
                </MembershipWrapper>
            </PermissionsWrapper>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}

export default ConsumptionChartsWidget;
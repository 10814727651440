import React, {useState, useEffect, useContext, Fragment } from 'react';
import { OrganizationContext } from '../../context/Organization/Organization';


const ShortcutHandler = ({children}) => {

    const [updated, setUpdated] = useState(false);
    const { switchOrganization } = useContext(OrganizationContext)


    useEffect(() => {
        
        if(!updated){
            listen()
            setUpdated(true)
        }
    }, [])

    const listen = () => {
        document.addEventListener("keydown", (event) => {
            if(event.ctrlKey && event.altKey) {
                switch(event.key){
                    case 'o':
                        switchOrganization();
                    default:
                        break;
               }
            }
        });
    }

    return (<Fragment></Fragment>)

}

export default ShortcutHandler;
import React, {Fragment, useEffect, useContext } from 'react';
import {GatewaysContext} from "../../../../context/Devices/Gateways";
import _ from "underscore";


const GatewayStatusWidget = (props) => {

    const { gateway, gatewayChanged } = useContext(GatewaysContext);


    useEffect(  () => {
            
    }, [gateway, gatewayChanged]);

    const UI = () => {
        let signalStrength = _.has(gateway, ["supervision", "msg", "modem", "rssi"]) ? Math.abs(gateway?.supervision?.modem?.rssi) : null;
        let signalClass = "text-muted";
        if (signalStrength >= 90) {
            signalClass = "text-danger";
        } else if (signalStrength < 90) {
            signalClass = "text-warning";
        } else if(signalStrength < 70 ) {
            signalClass = "text-success";
        }
        let icon = <i className={`${signalClass} fa-solid fa-${gateway?.active ? 'wifi':'wifi-slash'} fa-4x`}></i>;

        return (
            <div className="col-xl-2 col-md-4" id="widgetGatewayStatus">
                <div className="card text-center " title="Out of all sensors this gateway can see, this many are registered.">
                    <div className="card-body align-self-center">
                        <div className="mx-auto my-1" style={{height: "75px", display: "inline-flex", alignItems: "center"}}>
                            {icon}
                        </div>
                        <h2 className="mb-0">{(gateway?.active !== undefined) ? (gateway?.active ? 'Aktiv' : 'Inaktiv') : '-'}</h2>
                        {_.has(gateway, ["supervision", "modem"]) &&
                            <h5 style={{ textTransform: "uppercase" }} className={signalClass}>Signal {gateway?.supervision?.modem?.rssi} ({gateway?.supervision?.modem?.mode}) </h5>
                        }
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )

}

export default GatewayStatusWidget;
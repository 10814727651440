import React, {useState, useEffect, createContext, Fragment, useContext, useRef } from 'react';
import Axios_client from "../../utils/axios_client";
import { hasPermission,  } from '../../components/Dashboard/reactPermissionsWrapper';
import { AuthContext } from '../Auth/AuthProvider';
import { GatewaysContext } from '../Devices/Gateways';

import Swal from "@molline/sweetalert2";

export const TelegramContext = createContext();

const TelegramProvider = ({children, context}) => {

    const { user } = useContext(AuthContext)
    const { gateway, gatewayChanged } = useContext(GatewaysContext)
    const [telegrams, setTelegrams] = useState([])
    const telegramsChanged = useCompare(telegrams)

    useEffect( () => {
        if(gatewayChanged === true) getGatewayTelegrams()

    }, [gateway, gatewayChanged] )

    function useCompare (val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const errorToast = (err) => {
        if(!err) return
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: err,
            showConfirmButton: false,
            customClass: {
                popup: "bg-danger text-white"
            }
        })
    }

    const getTelegram = async (telegram_id) => {
        try {
            if(!user || !hasPermission({user, scopes:['telegram.view.any','telegram.view.own' ]})) {
                return {};
            }
            const response = await Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/sensor/telegram?telegram_id=${telegram_id}`)
            return response.data
        }catch(e) {
            console.log('error at getting telegram', e);
            errorToast('Telegramm ist nicht vefügbar');
        }
    }

    const getGatewayTelegrams = () => {
        return new Promise( (resolve, reject) => {
            if(!user || !hasPermission({user, scopes:['telegram.view.any','telegram.view.own' ]})) {
                setTelegrams([])
                return resolve();
            }

            Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/gateway/telegrams?uid=${gateway.uid}`)
            .then( response => {
                setTelegrams(response.data)
                return resolve();
            })
            .catch( err => {
                console.log('TELEGRAMS FETCHING ERROR 41', err)
            })
        })
    }

    const actions = {
        getTelegram,
        telegramsChanged,
        telegrams
    }

    return (
        <Fragment>
            <TelegramContext.Provider value={actions}>
                {children}
            </TelegramContext.Provider>
        </Fragment>
    )

}

export default TelegramProvider;
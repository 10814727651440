import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {LocationsContext} from "../../../context/Locations/Locations";
import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';
import {useRouter} from "../../Shared/Router/Router";

import uniqId from 'uniqid';
import de from  '../tables/datatable.de.json';
import capitalizeUtil from "../../../utils/capitalizeUtils";
import { hasPermission} from "../reactPermissionsWrapper";
import {OrganizationContext} from "../../../context/Organization/Organization";
import {AuthContext} from "../../../context/Auth/AuthProvider";

const $ = require('jquery');
$.DataTable = require('datatables.net');



const LocationGroupDetails = (props) => {
    const router = useRouter()

    const { user } = useContext(AuthContext);
    const { locations, locationIcon } = useContext(LocationsContext)
    const {mollineOrganization} = useContext(OrganizationContext)
    const [groupLocations, setGroupLocations] = useState([])

    const propsChanged = useCompare(props)
    const locationsChanged = useCompare(locations)
    const groupLocationsChanged = useCompare(groupLocations)


    useEffect(() => {

        if (propsChanged || locationsChanged) {
            parseGroupLocations()
        }

        if (groupLocationsChanged) initializeGroupLocationDt()

    }, [propsChanged, locationsChanged, mollineOrganization, groupLocationsChanged])

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const parseGroupLocations = () => {
        if (locations.length !== 0 && props.group !== undefined && props.group !== null) {
            if (props.group.members.length === 0) return setGroupLocations([])
            const myGroupLocations = []
            locations.forEach(location => {
                if (props.group.members.includes(location._id)) myGroupLocations.push(location)
            });
            setGroupLocations(myGroupLocations)
        }
    }

    const refreshDataTables = () => {
        $('#tblGroupLocations').DataTable().destroy();
        $('#tblGroupLocations').off('click', 'button.btnOverflowMenu')
        $('#tblGroupLocations').off('click', 'li.destroyTarget')
        initializeGroupLocationDt();
    }



    const initializeGroupLocationDt = () => {
        if ($.fn.dataTable.isDataTable('#tblGroupLocations')) {
            refreshDataTables()
        } else {
            $('#tblGroupLocations').DataTable({
                language: de,
                paging: true,
                pagingType: "full",
                processing: true,
                searching: true,
                data: groupLocations,
                orderCellsTop: true,
                fixedHeader: true,
                rowId: "_id",
                pageLength: 50,
                columns: [
                    {
                        "data": null,
                        "searchable": false,
                        "ordering": true,
                        render: function (data, type, row) {
                            return locationIcon({location:data})
                        }
                    },

                    {
                        "data": null,
                        "searchable": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if (data.token !== undefined && data.token.length > 0) {
                                return data.token; // If the user added a foreign key ref, show that key
                            } else {
                                return data._id;
                            }
                        },
                        visible: hasPermission({ roles: ["realm_admin", "realm_manager"], user: user}) ? true : false
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if (data.nickname !== undefined) {
                                return capitalizeUtil.capitalize(data.nickname)
                            } else {
                                return '';
                            }
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if (data.address.street !== undefined) {
                                return capitalizeUtil.capitalize(data.address.street)
                            } else {
                                return '';
                            }
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if (data.address.house_number !== undefined) {
                                return data.address.house_number
                            } else {
                                return '';
                            }
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if (data.address.street1 !== undefined) {
                                return data.address.street1
                            } else {
                                return '';
                            }
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if (data.address.city !== undefined) {
                                return capitalizeUtil.capitalize(data.address.city)
                            } else {
                                return '';
                            }
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if (data.address.post_code !== undefined) {
                                return data.address.post_code
                            } else {
                                return '';
                            }
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            return 'DE'
                        }
                    },
                    {
                        "data": null,
                        "searchable": false,
                        "ordering": false,
                        "className": "text-end",
                        render: function (data, type, row) {
                            return `<div class="dropdown b-dropdown btn-group" id="${data._id}">
                                <button aria-haspopup="true" type="button" data-bs-toggle="dropdown" class="btnOverflowMenu btn btn-sm dropdown-toggle btn-secondary dropdown-toggle-split" >
                                    <i class="fa-solid fa-bars"></i>
                                </button>
                                <ul role="menu" tabindex="-1" class="dropdown-menu dropdown-menu-right" >
                                    <li role="presentation" class="viewTarget">
                                        <a role="menuitem" href="#" target="_self" class="dropdown-item"><i class="text-secondary fa-solid fa-magnifying-glass me-50"></i> Anzeigen</a>
                                    </li>
                                    <li role="presentation">
                                        <hr role="separator" aria-orientation="horizontal" class="dropdown-divider">
                                    </li>
                                    <li role="presentation" class="destroyTarget">
                                        <a role="menuitem" id="${data._id}" href="#" target="_self" class="dropdown-item"><i class="text-secondary fa-solid fa-trash-can me-50"></i> Entfernen</a>
                                    </li>
                                </ul>
                            </div>`
                        }
                    }
                ],
                createdRow: function (row, data, dataIndex) {},
                "fnDrawCallback": function( oSettings ) {
                    $('.dataTables_length select').addClass('form-select');
                    $('.dataTables_filter input').addClass('form-control');
        
                    $('#tblGroupLocations tbody').on('click', 'li.viewTarget', function (e) {
                        e.preventDefault()
                        let link = e.currentTarget;
                        let parent = link.closest('div');
                        router.push(`/details/location/${parent.id}`);
                    });
        
                    $('#tblGroupLocations').on('click', 'li.destroyTarget', async function (e) {
                        e.preventDefault()
                        let button = e.target
                        let subject_id = button.id;
                        await props.removeMember(subject_id)
        
                    });
                }
            });
        }
    }

    return (
        <Fragment>
            <div className='table-responsive'>
                <table id="tblGroupLocations" className="table mb-0 row-border hover order-column w-100" role="grid">
                    <thead>
                        <tr>
                            <th></th>
                            <th>ID</th>
                            <th >Name</th>
                            <th >Straße</th>
                            <th >Hausnr.</th>
                            <th >Wohnung</th>
                            <th >Stadt</th>
                            <th >PLZ</th>
                            <th >Land</th>
                            <th></th>
                        </tr>
                    </thead>
                </table>
            </div>
        </Fragment>
    )
}

export default LocationGroupDetails;
import Swal from "@molline/sweetalert2";
import Axios_client from "../axios_client";
import { Buffer } from "buffer";

const capitalize = (str) => {
    if (!str) return str;
    if (str.indexOf("-") > -1)
        return str.split("-").map((w) => `${w.charAt(0).toUpperCase()}${w.slice(1)}`).join("-")
    return `${str.charAt(0).toUpperCase()}${str.slice(1)}`
}

const getLocationAddress = (loc) => {
    if (!loc) return;
    return `${capitalize(loc?.address?.street)} ${loc?.address?.house_number}, ${(loc?.address?.street1 !== '') ? `Mieteinheit ${loc?.address?.street1}, ` : ''}${loc?.address?.post_code} ${capitalize(loc?.address?.city)}, ${loc?.address?.country.toLocaleUpperCase()}`
}

const errorToast = (err) => {
    Swal.fire({
        toast: true,
        position: 'bottom-end',
        timer: 3000,
        timerProgressBar: true,
        allowOutsideClick: true,
        text: err,
        showConfirmButton: false,
        customClass: {
            popup: "bg-danger text-white"
        }
    })
}

const successToast = (msg) => {
    Swal.fire({
        toast: true,
        position: 'bottom-end',
        timer: 3000,
        timerProgressBar: true,
        allowOutsideClick: true,
        text: msg,
        showConfirmButton: false,
        customClass: {
            popup: "bg-success text-white"
        }
    })
}

const downloadReplacedSensorsReport = async (organization_id) => {
    console.log('downloadReplacedSensorsReport org', organization_id)
    try {
        const response = await   Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/report/sensors/closed?organization_id=${organization_id}`)
        return response.data
    } catch (e) {
        console.log('error at getting report for replaced sensors', e);
        throw new Error(e)
    }
}

const ReportService = {

    endYearReport: (organization) => {
        Swal.fire({
            icon: "question",
            title: `Jahres-End-Bericht herunterladen? `,
            allowOutsideClick: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Herunterladen',
            background: localStorage.getItem('theme-preference') === 'dark-layout' ? '#161D31' : '#fff',
            cancelButtonText: 'Abbrechen',
            text: `Wenn Sie auf "Erstellen" klicken, wird für Sie ein CSV-Bericht mit den Werten von allen Zählern je Monat für das Jahr ${new Date().getFullYear() - 1} erstellt und heruntergeladen.`,
            preConfirm: () => { }
        })
            .then(async (results) => {
                if (!results.isConfirmed || results.isDenied || results.isDismissed) return;
                successToast('Fantastisch! Ihr Bericht wird gerade erstellt. Dies kann einen Moment dauern');
                Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/report/report/endyear/sensor?organization_id=${organization._id.toString()}&year=${new Date().getFullYear()}&lang=de`, {})
                    .then(response => {
                        Swal.fire({
                            icon: "success",
                            title: `Zähler Jahres-End-Bericht `,
                            html: `<p>Bitte klicken Sie den untenstehenden Link, um Ihren Bericht und die Dokumente herunterzuladen. Die Dokumente erhalten Sie einmalig, so dass Sie in Zukunft nur noch einen Link klicken müssen:</p>` +
                            `<ul> Bericht-Daten: </br>` + 
                                `<li><a href="${response.data}"><b>${response.data}</b></a></li>` + 
                            `</ul>`,
                            allowOutsideClick: true,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                            showConfirmButton: false,
                            showCancelButton: true,
                            confirmButtonText: 'Erstellen',
                        })
                    })
                    .catch(err => {
                        errorToast(err)
                        //console.log(err)
                    })
            })
    },

    buildConsumptionReportsPackage: async(organization, user) => {

        console.log('org', organization)
        console.log(' user', user)
        const answer = await Swal.fire({
            title: 'Verbrauchsberichte für Organisation erstellen',
            icon: 'question',
            confirmButtonText: "Ja",
            cancelButtonText:"Nein",
            background: localStorage.getItem('theme-preference') === 'dark-layout' ? '#161D31' : '#fff',
            showCancelButton: true,
            text: 'Sind Sie sicher? Dies kann einige Zeit dauern. Damit werden die Verbrauchsberichte für die gesamte Organisation erstellt und im Anschluss per E-Mail an die hinterlegte Kontakt-Email versendet.'
        })

        if(!answer || !answer.isConfirmed)
            return;

         Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/report/report/consumption/package?organization_id=${organization._id}&email=true&email_address=${user.emails[0].email}`)
            .then(response => {
                console.log(response.data)
                Swal.fire({
                    title: 'Verbrauchsbericht-Archiv',
                    icon: 'success',
                    text: `Die Verbrauchsberichte für ihre Organisation wurden erfolgreich erstellt und werden per E-Mail an Sie zugestellt. ${response.data.job_id}`,
                    showCloseButton: false,
                    showCancelButton: false,
                    focusConfirm: true,
                    confirmButtonText:'<i class="fa-solid fa-thumbs-up"></i>',
                    allowOutsideClick: true,
                })
            })
            .catch(err => {
                console.log(err)
                Swal.fire({
                    toast: true,
                    position: 'bottom-end',
                    timer: 3000,
                    title: 'Fehler beim erstellen der Verbraucherberichte',
                    timerProgressBar: true,
                    allowOutsideClick: true,
                    text: `Leider ist bei der Erstellung etwas schiefgegangen. Bitte kontaktieren Sie uns unter Referenz der Job-ID: oder versuchen Sie es später noch einmal.`,
                    showConfirmButton: false,
                    customClass: {
                        popup: "bg-danger"
                    }
                })
            })
    },

    downloadSensorMeasurementsReport: (location) => {
        alert()
        let html = `
            <p style="text-align:left">Laden Sie hier einen Bericht zu den Messwerten herunter. Sie können einzelne Zähler und/oder einen Zeitraum wählen.</p>
            <p>Nutzer-/Mieteinheit: ${getLocationAddress(location)}</p>
            <input id="start_date" type="date" class="form-control form-control-lg mb-1"   /> 
            <input id="end_date" type="date" class="form-control form-control-lg mb-1"   /> 
            <input id="sensors" type="text" class="form-control form-control-lg mb-1" placeholder="z.B. 123,456,789"  />
            `
        Swal.fire({
            icon: "question",
            width: "60%",
            title: `Bericht herunterladen `,
            allowOutsideClick: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            background: localStorage.getItem('theme-preference') === 'dark-layout' ? '#161D31' : '#fff',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Herunterladen',
            cancelButtonText: 'Abbrechen',
            html: html,

            preConfirm: () => {
                const start_date = Swal.getPopup().querySelector('#start_date').value
                const end_date = Swal.getPopup().querySelector('#end_date').value
                const sensors = Swal.getPopup().querySelector('#sensors').value
                return {
                    start_date,
                    end_date,
                    sensors
                }
            }
        })
        .then(async (results) => {
            if (!results.isConfirmed || results.isDenied || results.isDismissed) return;

            let params = {
                returnbuffer: true,
                location_id: location._id
            }

            if(results.value.start_date.length > 0) params.start_date = results.value.start_date;
            if(results.value.end_date.length > 0) params.end_date = results.value.end_date;
            if(results.value.sensors.length > 0) params.sensors = results.value.sensors;

            Swal.fire({
                toast: true,
                position: 'bottom-end',
                timer: 7000,
                title: 'Lädt',
                timerProgressBar: true,
                allowOutsideClick: true,
                text: `Ihr Bericht wird nun vorbereitet und heruntergeladen`,
                showConfirmButton: false,
                customClass: {
                    popup: "bg-success"
                }
            })

            Axios_client.backendClient().post(`${process.env.REACT_APP_API_OPENMETER}/report/report/sensor/measurements`, params)
                .then(response => {

                    let fileName, buffer, blob, fileURL, link;
                    fileName = `Sensor_Measurements_Report_${new Date().getTime()}.csv`
                    buffer = Buffer.from(response.data, "utf-8")
                    blob = new Blob([buffer], { type: 'text/csv' })
                    fileURL = URL.createObjectURL(blob);
                    link = document.createElement('a');
                    link.href = fileURL;
                    link.setAttribute('download', fileName);
                    link.setAttribute('target', '_blank');
                    document.body.appendChild(link);
                    link.dispatchEvent(
                        new MouseEvent('click', {
                            bubbles: true,
                            cancelable: true,
                            view: window
                        })
                    );
                    link.remove();
                
                })
                .catch(err => {
                    console.log(err)
                })
        })

    },

    downloadSensorReport : (location_id) => {
        console.log('location_id 228', location_id)
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: 'Sensorbericht wird erstellt...',
            background: localStorage.getItem('theme-preference') === 'dark-layout' ? '#161D31' : '#fff',
            showConfirmButton: false,
            customClass: {
                popup: "bg-info"
            }
        })

        Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/report/report/monthly/sensor?location_id=${location_id}`,
            {})
            .then(response => {
                Swal.fire({
                    toast: true,
                    position: "bottom-end",
                    icon: "success",
                    timer: 5000,
                    timerProgressBar: true,
                    text: "Your Sensor report has been requested and is under construction. You will receive it by email shortly.",
                    customClass: {
                        popup: "bg-success"
                    }
                })
            })
            .catch(err => {
                errorToast(err)
            })
    },
    /**
     * Receive the PDF as a base64 to do what we want with it.
     */
    downloadConsumptionReport: ({location}) => {
        let year= new Date().getFullYear();
        let month = new Date().getMonth()
        /**
         * uVi for the new year are not available until February, so set the request for December of last year
         */
        if(month === 0){
            month = 11;
            year = year --;
        }
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: `Verbrauchsbericht wird erstellt ...`,
            showConfirmButton: false,
            customClass: {
                popup: "bg-info"
            }
        })
        Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/report/report/consumption?location_id=${location._id}&month=${month}&lang=de&year=${year}&returnbuffer=true`, {})
        .then(response => {
            if (response.status === 200) {
                let buffer = Buffer.from(response.data, "base64")
                const blob = new Blob([buffer], { type: 'application/pdf' })
                const fileURL = URL.createObjectURL(blob);
                const previewWindow = window.open();
                previewWindow.location.href = fileURL
            }
        })
        .catch(err => {
            console.log(err)
        })
    },

    downloadMyReplacedSensorsReport: async (user) => {
        Swal.fire({
            icon: "question",
            title: `Bericht zu ausgetauschten Zählern generieren`,
            allowOutsideClick: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            background: localStorage.getItem('theme-preference') === 'dark-layout' ? '#161D31' : '#fff',
            showCancelButton: true,
            confirmButtonText: 'Erstellen',
            cancelButtonText: 'Abbrechen',
            text: `Wenn Sie auf "Erstellen" klicken, wird ein CSV-Bericht mit allen ausgetauschten Zählern heruntergeladen.`,
            preConfirm: () => { }
        })
            .then(async (results) => {
                if (!results.isConfirmed || results.isDenied || results.isDismissed) return;
                try {
                    const result = await downloadReplacedSensorsReport(user.organization_id)
                    Swal.fire({
                        toast: true,
                        position: "bottom-end",
                        icon: "success",
                        timer: 5000,
                        timerProgressBar: true,
                        text: "Ihr Bericht zu augetauschten Zählern wurde gerade erstellt und wird nun heruntergeladen.",
                        customClass: {
                            popup: "bg-success"
                        }
                    })
                } catch (e) {
                    console.log('error at downloading replaced sensors report', e);
                    errorToast('Beim Erstellen des Berichts ist ein Fehler aufgetreten')
                }
            })
    },

}

export default ReportService;
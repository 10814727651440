import React, {Fragment, useState, useEffect, useContext, useRef} from 'react';
import {ShepherdTour, ShepherdTourContext} from 'react-shepherd'
import TourSteps from "../../../TourSteps.json"

const PageTour = ({steps, isButton}) => {

    const [updated, setUpdated] = useState(false);
    const [tourSteps, setTourSteps] = useState([]);
    /* * Combine currentPageTourSteps(called steps) & widgetsTourStepsList (TourSteps.widgets.widgetList),
       * in addTourSteps Func it will check if widget is available in current Page then add it to Current Page tour Otherwise skip it....
     */
    const [completeSteps, setCompleteSteps] = useState([...steps]);


    useEffect(() => {
        if (!updated) {
            // added 1 second wait for complete DOM load before Tour, because Tour decides based on element present
            document.onload = setTimeout(function () {
                setUpdated(true)
            }, 1000);
        }
        if (updated) {
            addTourSteps();
        }
    }, [updated]);

    const addTourSteps = () => {
        let stepsArr = [];
        if(completeSteps  !== undefined) {
            Object.values(completeSteps ).forEach((eachStep, index) => {
                let eleExists = document.getElementById(`${eachStep.id}`)
                if (eleExists !== null) {
                    eachStep.attachTo = {element: '#' + eachStep.id, on: 'bottom'}
                    eachStep.buttons = [];
                    if (index === 0) {
                        eachStep.buttons.push(
                            {classes: 'btn btn-secondary', text: 'Beenden', type: 'cancel'}, {classes: 'btn btn-primary', text: 'Nächste', type: 'next'}
                        )
                    } else {
                        eachStep.buttons.push(
                            {classes: 'btn btn-secondary', text: 'Beenden', type: 'cancel'},
                            {classes: 'btn btn-primary', text: 'Zurück', type: 'back'},
                            {classes: 'btn btn-primary', text: 'Nächste', type: 'next'}
                        )
                    }
                    stepsArr.push(eachStep);
                }
            })
        }

        // set step btns when only one element (only finish btn )
        if (stepsArr.length === 1) {
            stepsArr[0].buttons = [];
            stepsArr[0].buttons.push({classes: 'btn btn-secondary', text: 'Beenden', type: 'cancel'},)
        }

        // set last step btns (back & finish btn )
        if (stepsArr.length > 1) {
            const lastIndexObj = stepsArr[stepsArr.length - 1];
            lastIndexObj.buttons = [];
            lastIndexObj.buttons.push({
                classes: 'btn btn-primary',
                text: 'Zurück',
                type: 'back'
            }, {classes: 'btn btn-secondary', text: 'Beenden', type: 'cancel'},)
        }

        setTourSteps(stepsArr)
    }

    const tourOptions = {
        defaultStepOptions: {
            cancelIcon: {
                enabled: true
            },
            scrollTo: { behavior: 'smooth', block: 'center' }
        },
        useModalOverlay: true,
        exitOnEsc: true,
        canClickTarget: false,
        confirmCancel: false,
    };

    const completeTour = () => {

    }


   const startTour = (tour) => {
       tour.start();

       // complete the currently running Tour upon browser back-btn click
       window.addEventListener("popstate", e => {
           tour.complete();
       });


   }

    function TourStartButton() {
        const tour = useContext(ShepherdTourContext);
        return (
            isButton === false ?
                <i className="fa-regular  fa-vr-cardboard cursor-pointer tour_info_icon"  title="Seiten-Tour Entdecken Sie alle Nützlichen & Coolen Features" onClick={()=> startTour(tour)}></i>
                :
                <button className="btn btn-info darkm m-lg-1" title="Seiten-Tour Entdecken Sie alle  Nützlichen & Coolen Features" onClick={()=> startTour(tour)}>
                    Tour Starten
                </button>

        );
    }

    const UI = () => {
        if (updated &&  completeSteps  !== undefined && completeSteps .length > 0) {
            return (
                <Fragment>
                    <ShepherdTour steps={tourSteps} tourOptions={tourOptions}>
                        <TourStartButton />
                    </ShepherdTour>
                </Fragment>
            )
        } else {
            return (<Fragment></Fragment>)
        }

    }

    return (
        <Fragment>
            {UI()}
        </Fragment>

    )

}

export default PageTour;

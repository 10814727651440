import React, { useState, Fragment, useEffect, useContext } from 'react';
import { AuthContext } from "../../../../context/Auth/AuthProvider";
import { LocationsContext } from "../../../../context/Locations/Locations";
import {TenantContext} from '../../../../context/Tenant/Tenant';
import {useRouter} from "../../../Shared/Router/Router";
import { PermissionsWrapper, hasPermission, MembershipWrapper, hasMembership } from "../../reactPermissionsWrapper";

const CountEmptyLocationWidget = (props) => {

    const router = useRouter()
    const { user } = useContext(AuthContext);

    const { locationsChanged, location, setLocation, locations, locationChanged, capitalize, getLocationAddress, locationIcon } = useContext(LocationsContext);
    const { tenants, tenantsChanged, isTenantsDone } = useContext(TenantContext);


    useEffect( () => {

            
    }, [ locationChanged, location, tenants, tenantsChanged, ]);

    const countEmptyLocation = () => {

            let count = 0
            for(let loc of locations){
                if(loc.type !== 'single_premise') continue;
                let tenantsArray = tenants.filter(tenant => tenant.location_id === loc._id.toString() )
                if(tenantsArray.length === 0) count++;
            }
        if(isTenantsDone) {
            return count;
        }

    }

    const UI = () => {
        if(Object.keys(location).length === 0  || !user || locations.length === 0) return <Fragment></Fragment>
        return(
            <PermissionsWrapper user={user} scopes={['location.view.any', 'location.view.own']} >
                <MembershipWrapper user={user} location={location} memberships={['basic','advanced', 'premium']} >
                    <Fragment>
                        <div className="col-xl-2 col-md-4" id="widgetCountEmptyLocation">
                            <div className="card text-center" id="countEmptyLoc" title="Anzahl an Mieteinheiten, denen kein Nutzer zugewiesen ist">
                                <div className="card-body align-self-center">
                                    <div className="mx-auto my-1" style={{display: "inline-flex", alignItems: "center"}}>
                                        <i class="fa-solid fa-building-circle-xmark fa-4x text-warning"></i>
                                    </div>
                                    <p className="mb-0 text-muted">Leerstand</p>
                                    <h4 className="mb-0 line-ellipsis ">{ !isTenantsDone ?

                                        <div className="text-center">
                                            <div class="spinner-border text-warning" role="status">
                                                <span class="visually-hidden">Lädt...</span>
                                            </div>
                                        </div>
                                        : countEmptyLocation()}</h4>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                </MembershipWrapper>
            </PermissionsWrapper>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}

export default CountEmptyLocationWidget;
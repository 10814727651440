
const deCapitalize = (str) => {
    if (!str) return str;
    const firstChar = str.charAt(0).toUpperCase();
    const restOfString = str.slice(1);
    return `${firstChar}${restOfString}`;
}

const CapitalizeUtils = {


    capitalize : (str) => {
        if (!str) return str;

        return str
            .split(/(\s|-)+/)
            .map(word => deCapitalize(word))
            .join('');
    },


    enCapitalize: (str) => {

    },

    esCapitalize: (str) => {

    }


}

export default CapitalizeUtils;
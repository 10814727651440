import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { AuthContext } from '../../../../context/Auth/AuthProvider';
import {GatewaysContext} from "../../../../context/Devices/Gateways";
import {LocationsContext} from "../../../../context/Locations/Locations";
import { useRouter } from "../../../../components/Shared/Router/Router"
import OrgCookieCrumb from "../../../../components/Dashboard/orgCookieCrumb";
import GatewaysTable from "../../../../components/Dashboard/tables/dataTables/tblGateways";
import {PermissionsWrapper, hasPermission} from "../../../../components/Dashboard/reactPermissionsWrapper";
import PageTour from "../../../../components/Dashboard/tour/tour";
import TourSteps from "../../../../TourSteps.json"
import {GroupsContext} from "../../../../context/Groups/Groups";
import CountGatewaysWidget from '../../../../components/Dashboard/widgets/gateways/countGateways';

const AppsGateways = (props) => {

    const {user} = useContext(AuthContext);
    const router = useRouter()
    const { gateways } = useContext(GatewaysContext);
    const { locations } = useContext(LocationsContext);
    const { groupSelect } = useContext(GroupsContext);

    const gatewaysChanged = useCompare(gateways)
    const [countGateways, setCountGateways] = useState(0);
    const [countGatewaysDelinquent, setCountGatewaysDelinquent] = useState(0);
    const [tabActive, setTabActive] = useState('tpgateways')
    const locationsChanged = useCompare(locations)

    useEffect(  () => {

    }, [gateways, gatewaysChanged, locationsChanged, locations, countGateways, countGatewaysDelinquent]);


    function useCompare (val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const UI = () => {

        return (
            <Fragment>

                <div className="content-header row">
                    <div className="content-header-left col-md-9 col-12 mb-2" id="gatewaysPage">
                        <div className="row breadcrumbs-top">
                            <div className="col-12">
                                <h2 className="content-header-title float-start mb-0">Gateways</h2>
                                <div className="breadcrumb-wrapper">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a >Apps</a></li>
                                        <li className="breadcrumb-item active">Gateways
                                        </li>
                                        <li>
                                            <span className="tourBorderRight"></span>
                                            <PageTour steps={TourSteps.gateways.overview}  isButton={false} />
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <OrgCookieCrumb />
                </div>

                <div className="content-body">
                    <section id="device-details">
                        <div className="row">
                            <CountGatewaysWidget />
                        </div>
                    </section>

                    <section id="gateway-tabs">
                        <div className="row">
                            <div className="col-12">
                                <ul className="nav nav-pills mb-2">

                                    <li className="nav-item" id="gatewaysTb">
                                        <a className={`nav-link ${(tabActive === 'tpgateways') ? 'active' : ''}`} onClick={() => { setTabActive('tpgateways') }}>
                                            <i className="fa-solid fa-building"></i>
                                            <span className="fw-bold">Gateways</span>
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </section>

                    <section id="gateway-tabpanels">
                        <div className="row">
                            <div className="col-12">
                                <div className="tab-content pt-1">

                                    <div className={`tab-pane ${(tabActive === 'tpgateways') ? 'active' : ''}`} id="tpgateways" role="tabpanel" aria-labelledby="tpgateways-tab-fill">
                                        <div className="card">
                                            <div className="card-header border-bottom">
                                                <h4 className="card-title">Gateways </h4>
                                                <div className="heading-elements">
                                                    <div className="heading-elements">
                                                        <div className="row">

                                                            <div className="col-6">
                                                                {groupSelect()}
                                                            </div>
                                                            <div className="col-2">
                                                                <PermissionsWrapper
                                                                    user={user}
                                                                    scopes={['gateway.create.any', 'gateway.create.own']}
                                                                >
                                                                    <button className="btn btn-primary me-1" id="gatewaysAddBtn" onClick={() => { router.push('/apps/gateways/create') }} ><i class="fa-solid fa-plus"></i></button>
                                                                </PermissionsWrapper>
                                                            </div>
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body py-2 my-25">
                                                <GatewaysTable  />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>

                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}

AppsGateways.requiredScope = ['gateway.view.own', 'gateway.view.any'];
AppsGateways.requireAuth = true;
export default AppsGateways;
import React, { useState, Fragment, useEffect, useContext } from 'react';
import { AuthContext } from "../../../../context/Auth/AuthProvider";
import { LocationsContext } from "../../../../context/Locations/Locations";
import { OrganizationContext } from "../../../../context/Organization/Organization";
import { Buffer } from "buffer";

import { PermissionsWrapper, hasPermission, MembershipWrapper, hasMembership } from "../../reactPermissionsWrapper";

import Swal from "@molline/sweetalert2";
import Axios_client from "../../../../utils/axios_client";
import HelpProvider from "../../helpProvider/helpProvider";

const ReportUviReportWidget = (props) => {

    const { user } = useContext(AuthContext);
    const { locationsChanged, location, locations, locationChanged } = useContext(LocationsContext);
    const { organization, organizationChanged } = useContext(OrganizationContext);


    useEffect( () => {
            
    }, [ locationChanged, location ]);

    const errorToast = (err) => {
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: err,
            showConfirmButton: false,
            customClass: {
                popup: "bg-danger text-white"
            }
        })
    }
    
    const downloadConsumptionReport = async () => {
        const today = new Date()
        let year = today.getFullYear();
        let month = today.getMonth()
        /**
         * uVi for the new year are not available until February, so set the request for December of last year
         */
        if (month === 0) {
            month = 11;
            year = year - 1;
        } else {
            month = month - 1
        }

        let years = [];
        years.push(new Date().getFullYear())
        for (let x = 1; x < 2; x++) {
            years.push(new Date().getFullYear() - x)
        }

        let yearHtml = ''
        years.map(y => {
            yearHtml += `<option key=${y} ${(y === year) ? "selected" : ""} value=${y} >${y}</option>`
        })
        const months = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"]
        let monthHtml = ''
        for (const [index, m] of months.entries())
            monthHtml += `<option key=${m} ${(index === month) ? "selected" : ""} value=${index} >${m}</option>`

        let html = `
            <div class="container">
                <p>Für welchen Monat in welchem Jahr möchten sie den Verbrauchsbericht der aktuell ausgewählten Mieteinheit herunterladen?.</p>
                <div class="row">
                    <div class="col-6">
                        <select class="form-select" aria-label="Select Year" id="selectInputMonth">
                            ${monthHtml}
                        </select>
                    </div>
                    <div class="col-6">
                        <select class="form-select" aria-label="Select Year" id="selectInputYear">
                            ${yearHtml}
                        </select>
                    </div>
                </div>
            </div>
        `;


        Swal.fire({
            icon: "question",
            title: `Monatlicher Verbrauchsbericht `,
            allowOutsideClick: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Herunterladen',
            background: localStorage.getItem('theme-preference') === 'dark-layout' ? '#161D31' : '#fff',
            cancelButtonText: 'Abbrechen',
            html,
            preConfirm: () => {
                const inputYear = Swal.getPopup().querySelector('#selectInputYear').value
                const inputMonth = Swal.getPopup().querySelector('#selectInputMonth').value
                if (!inputYear|| !inputMonth) {
                    Swal.showValidationMessage(`Bitte Monat und Jahr auswählen`)
                }
                return { inputYear, inputMonth }
            }
        })
            .then(async (results) => {
                if (results.isConfirmed) {
                    Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/report/report/consumption?location_id=${location._id}&month=${results.value.inputMonth}&lang=de&year=${results.value.inputYear}&returnbuffer=true`, {})
                        .then(response => {
                            if (response.status === 200) {
                                let buffer = Buffer.from(response.data, "base64")
                                const blob = new Blob([buffer], { type: 'application/pdf' })
                                const fileURL = URL.createObjectURL(blob);
                                const previewWindow = window.open();
                                previewWindow.location.href = fileURL
                            }
                        })
                        .catch(err => {
                            console.log('uvi donwload error', err)
                            if(err?.response?.data === 'uVi-Download is not allowed') errorToast('uVi-Download muss erst noch freigeschaltet werden')
                            else errorToast('Fehler beim Download')
                        })
                }

            })

    }

    const UI = () => {
        return(
        <PermissionsWrapper user={user} scopes={['sensor.view.any', 'sensor.view.own']} >
            {hasMembership({memberships: ['advanced', 'premium'], location: location, user: user}) ?
                <MembershipWrapper user={user} location={location} memberships={['advanced', 'premium']}>
                    <div className="col-xl-4 col-md-6"  id="widgetUviReport">
                        <div className="card text-center clickable">
                            <HelpProvider reportSampleImgPath="/theme2/app-assets/images/reports/consumption_report.jpg" title="Verbrauchsbericht" descriptionText="Verbrauchsbericht für die aktuell ausgewählte Nutzer-/Mieteinheit herunterladen"/>
                            <div className="card-body" onClick={() => {downloadConsumptionReport()}}>
                                <div className="mx-auto my-1">
                                    <i className="fa-solid fa-file-chart-column fa-4x text-success"></i>
                                </div>
                                <p className="text-muted mb-0 line-ellipsis">Verbrauchsbericht für Nutzereinheiten</p>
                            </div>
                        </div>
                    </div>
                </MembershipWrapper>
                :
                <div className="col-xl-4 col-md-6">
                    <div className="card text-center clickable">
                        <div className="premium_ribbon premium_ribbon_top_left"><span>Premium</span></div>
                        <HelpProvider reportSampleImgPath="/theme2/app-assets/images/reports/consumption_report.jpg" title="Verbrauchsbericht" descriptionText="Verbrauchsbericht für die aktuell ausgewählte Nutzer-/Mieteinheit herunterladen"/>
                        <div className="card-body">
                            <div className="mx-auto my-1">
                                <i className="fa-solid fa-file-chart-column fa-4x text-success"></i>
                            </div>
                            <p className="text-muted mb-0 line-ellipsis">Verbrauchsbericht für Nutzereinheiten</p>
                        </div>
                    </div>
                </div>
            }
        </PermissionsWrapper>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )

}

export default ReportUviReportWidget;
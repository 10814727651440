import React, {useState, useEffect, useContext,} from 'react';
import {useCallback} from "react";
import {Routes, Route, useNavigate, Navigate} from 'react-router-dom';
import axios from 'axios';
/* Contexts & components  */
import CookieProvider from "./context/CookieMaster/CookieMaster";
import AuthProvider from './context/Auth/AuthProvider';
import LocaleProvider from './context/Locale/Locale';
import Organization from "./context/Organization/Organization";

import MgmtUser from "./context/Users/UsersContext";
import Cms from './context/Cms/Cms';
import AuthGuard from './context/Auth/AuthGuard';
import PreferenceProvider from "./context/Preferences/UserPreferences";
import WhiteLabel from "./context/WhiteLabels/WhiteLabels"
import Locations from "./context/Locations/Locations";
import Groups from './context/Groups/Groups';
import Gateways from "./context/Devices/Gateways";
import Sensors from "./context/Devices/Sensors";
import Telegrams from "./context/Telegrams/Telegrams";
import SocketProvider from "./context/Socket/Sockets";
import ProgressProvider from "./context/Progress/ProgressIndicator";
import Alerts from "./context/Alerts/Alerts";

import Tenants from './context/Tenant/Tenant';
import {Navbar} from './components/Shared/Navbar/Navbar';
import {useRouter} from "./components/Shared/Router/Router";

/*Landing pages & shared components/pages */
import Home from './pages/LandingPages/home';
import Faq from './pages/LandingPages/faq'
import Error404 from "./pages/404";
import LayoutHandler from "./components/Shared/Layout/LayoutHandler";

/** Dashboard, admin, apps, details Pages */
import DashboardAnalytics from "./pages/Dashboard/dashboards/analytics";

import AppsGateways from "./pages/Dashboard/apps/gateways/gateways";
import GatewayCreator from "./pages/Dashboard/apps/gateways/create";

import AppsLocations from "./pages/Dashboard/apps/locations/locations";
import AppLocationDetails from "./pages/Dashboard/details/location/locationDetail";

import AppsSensors from "./pages/Dashboard/apps/sensors/sensors";
import SensorDetail from "./pages/Dashboard/details/sensor/SensorDetail";

import AppsOrganizations from "./pages/Dashboard/admin/organizations/organizations";


import AdminUsers from "./pages/Dashboard/admin/users/users";
import AdminClients from "./pages/Dashboard/admin/clients/clients";
import AdminPermissions from "./pages/Dashboard/admin/permissions/permissions";
//import AdminGroups from "./pages/Dashboard/admin/groups/groups"
import AdminGroups from "./pages/Dashboard/admin/groups/groups";
import UserDetails from "./pages/Dashboard/details/user/user";
import OrganizationDetail from "./pages/Dashboard/details/organization/organizationDetail";
import Cookie from "js-cookie";
import querystring from "query-string";
import GatewayDetails from "./pages/Dashboard/details/gateway/gatewayDetail";
import BsonJsonConverter from "./pages/Dashboard/tools/convert/bsonjsons";
import GatewayAnalzer from "./pages/Dashboard/tools/gateway/analyzer";
import GroupDetailsPage from "./pages/Dashboard/details/group/groupDetails";
import LocationCreator from "./pages/Dashboard/apps/locations/locationCreator";
import OrganizatoinCreator from "./pages/Dashboard/admin/organizations/OrganizationCreator";
import SensorCreator from "./pages/Dashboard/apps/sensors/sensorCreator";
import EmailLogs from "./pages/Dashboard/tools/emailLogs/emailLogs";
import DataManager from "./pages/Dashboard/tools/datamanager/datamanager";
import SensorChange from "./pages/Dashboard/tools/sensor/change"; //Sensor Austausch tool

import TenantsOverview from "./pages/Dashboard/apps/tenants/tenantsOverview";
import TenantDetails from "./pages/Dashboard/details/tenant/tenantDetails";
import HelpVideos from "./pages/Dashboard/help/helpVideos";
import Cookies from 'js-cookie';
import SearchPage from "./pages/Dashboard/tools/search/SearchPage";
import Billing from "./pages/Dashboard/tools/groups/Billing"

const oauth = (process.env.REACT_APP_ENV === 'production' || process.env.NODE_ENV === 'production' ) ? 'https://auth.molline.digital' : 'http://localhost:7002'

const App = ({children, props}) => {

    const [updated, setUpdated] = useState(false);
    const router = useRouter();

    useEffect(() => {

        if (!updated) {
            setUpdated(true)
        }
    }, []);
    /**
     * Handles the final login response from the Auth system.
     */
    const handleCallback = useCallback(async () => {
        if (router.location.pathname === '/api/auth/callback') {
            let parsed = querystring.parse(window.location.search);
            let code_verifier = Cookie.get('code_verifier')
            let data = {
                code_verifier: code_verifier
            };
            let headers = {
                'Authorization': 'Bearer ' + parsed.code,
                'x-code-verifier': code_verifier
            }
            axios.post(`${oauth}/api/payload`, data, {headers})
            .then( () => {
                const cookieRedirectUrl = Cookies.get('redirect');
                //curr only redirect to erklaervideos if needed later switch to general also need to change in AuthContext redirectUser
                if(cookieRedirectUrl === "/erklaervideos") {

                    window.location.href = cookieRedirectUrl;      //redirect to cookie redirect url & it MUST BE a full refresh to trigger AuthContext -> redirectUser()
                }else {
                    window.location.href = '/dashboards/analytics' //MUST be a full refresh to trigger AuthContext -> redirectUser()
                }

            })
            .catch(err => console.log('ERROR 107', err))
        }
    }, [])

    return (
        <CookieProvider>
            <LocaleProvider>
                <WhiteLabel>
                    <AuthProvider>
                    <SocketProvider>
                        <PreferenceProvider>
                            <Alerts>
                                <Cms>
                                    <Organization>
                                        <Groups>
                                            <MgmtUser>
                                                <Locations>
                                                    <Tenants>
                                                        <Sensors>
                                                            <Gateways>
                                                                <Telegrams>
                                                                <ProgressProvider>

                                                                    <LayoutHandler>
                                                                        <Routes>
                                                                            <Route path="/api/auth/callback" element={handleCallback()} />

                                                                            {/* HOME/UnProtected Router */}
                                                                            <Route exact path='/home' element={ <Home /> }/>
                                                                            <Route exact path='/faq' element={<Faq/>}/>
                                                                            <Route exact path="/" element={<Navigate to="/home" replace/>}/>

                                                                            {/* Dashboards Pages   */}
                                                                            <Route exact path='/dashboards/analytics' element={
                                                                                <AuthGuard children={children}>
                                                                                    <DashboardAnalytics/>
                                                                                </AuthGuard>
                                                                                }>
                                                                            </Route>

                                                                            {/* Apps Pages   */}
                                                                            <Route exact path='/apps/locations' element={<AuthGuard children={children}><AppsLocations/></AuthGuard>}> </Route>
                                                                            <Route exact path='/apps/locations/location-create' element={<AuthGuard children={children}>  <LocationCreator/>  </AuthGuard>}> </Route>

                                                                            <Route exact path='/apps/gateways' element={<AuthGuard children={children}><AppsGateways/></AuthGuard>}> </Route>
                                                                            <Route exact path='/apps/gateways/create' element={<AuthGuard children={children}><GatewayCreator/></AuthGuard>}> </Route>

                                                                            <Route exact path='/apps/sensors' element={ <AuthGuard children={children}> <AppsSensors/> </AuthGuard>}> </Route>
                                                                            <Route exact path='/apps/sensors/create' element={ <AuthGuard children={children}> <SensorCreator/> </AuthGuard>}> </Route>

                                                                            <Route exact path='/apps/tenants' element={<AuthGuard children={children}><TenantsOverview/></AuthGuard>}> </Route>

                                                                            {/* Detail Pages   */}
                                                                            <Route exact path='/details/location/:location_id' element={ <AuthGuard children={children}> <AppLocationDetails/> </AuthGuard>}> </Route>
                                                                            <Route exact path='/details/gateway/:serial_number' element={ <AuthGuard children={children}> <GatewayDetails/> </AuthGuard>}> </Route>
                                                                            <Route exact path='/details/user/:user_id' element={ <AuthGuard children={children}> <UserDetails/> </AuthGuard>}> </Route>
                                                                            <Route exact path='/details/organization/:organization_id' element={ <AuthGuard children={children}> <OrganizationDetail/> </AuthGuard>}> </Route>
                                                                            <Route exact path='/details/sensor/:serial_number' element={ <AuthGuard children={children}> <SensorDetail/> </AuthGuard>}> </Route>
                                                                            <Route exact path='/details/group/:group_id' element={ <AuthGuard children={children}> <GroupDetailsPage/> </AuthGuard>}> </Route>
                                                                            <Route exact path='/details/tenant/:tenant_id' element={<AuthGuard children={children}><TenantDetails/></AuthGuard>}> </Route>
                                                                            {/*  Admin Pages */}
                                                                            <Route exact path='/admin/users' element={<AuthGuard children={children}><AdminUsers/></AuthGuard>}> </Route>
                                                                            <Route exact path='/admin/organizations' element={ <AuthGuard children={children}> <AppsOrganizations/> </AuthGuard>}> </Route>
                                                                            <Route exact path='/admin/organizations/create' element={<AuthGuard children={children}><OrganizatoinCreator/></AuthGuard>}> </Route>
                                                                            <Route exact path='/admin/groups' element={ <AuthGuard children={children}> <AdminGroups /> </AuthGuard>}> </Route>
                                                                            <Route exact path='/admin/permissions' element={<AuthGuard children={children}><AdminPermissions/></AuthGuard>}> </Route>
                                                                            <Route exact path='/admin/clients' element={<AuthGuard children={children}><AdminClients/></AuthGuard>}> </Route>
                                                                            {/* TOOL Pages   */}
                                                                            <Route exact path='/tools/convert/bsonjson' element={<AuthGuard children={children}><BsonJsonConverter/></AuthGuard>}> </Route>
                                                                            <Route exact path='/tools/gateway/analyzer' element={<AuthGuard children={children}><GatewayAnalzer/></AuthGuard>}> </Route>
                                                                            <Route exact path='/tools/emaillogs' element={<AuthGuard children={children}><EmailLogs/></AuthGuard>}> </Route>
                                                                            <Route exact path='/tools/datamanager' element={<AuthGuard children={children}><DataManager/></AuthGuard>}> </Route>
                                                                            <Route exact path='/tools/sensor/change' element={<AuthGuard children={children}><SensorChange/></AuthGuard>}> </Route>

                                                                            <Route exact path='/tools/search' element={<AuthGuard children={children}><SearchPage/></AuthGuard>}> </Route>
                                                                            <Route exact path='/tools/billing' element={<AuthGuard children={children}><Billing/></AuthGuard>}> </Route>

                                                                            {/* help video: plateform Explain Videos for customer  */}
                                                                            <Route exact path='/erklaervideos' element={<AuthGuard children={children}> <HelpVideos /> </AuthGuard>}> </Route>

                                                                            <Route path='/404' element={<Error404/>}/>
                                                                            <Route exact path="*" element={<Navigate to="/404" replace/>}/>

                                                                        </Routes>
                                                                    </LayoutHandler>
                                                                    </ProgressProvider>
                                                                </Telegrams>
                                                            </Gateways>
                                                        </Sensors>
                                                    </Tenants>
                                                </Locations>
                                            </MgmtUser>
                                        </Groups>
                                    </Organization>
                                 </Cms>
                            </Alerts>
                        </PreferenceProvider>
                        </SocketProvider>
                    </AuthProvider>
                </WhiteLabel>
            </LocaleProvider>
        </CookieProvider>
    )
}

export default App

import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import AxiosClient from "../../../utils/axios_client";
import Swal from '@molline/sweetalert2'
import Dropzone from 'react-dropzone';


const TenantUpload = (props) => {

    const propsChanged = useCompare(props);
    const ACCEPTED_FILE_TYPES = [
        { name: "CSV", mimeTypes: [".csv"] },
        { name: "XLS", mimeTypes: ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"] },
        //{name:"XML", mime:"text/xml"},
    ];
    const ACCEPTED_MIME_TYPES = ACCEPTED_FILE_TYPES.map((v) => v.mimeTypes).flat();
    const [fileName, setFileName] = useState(null);
    const [taskList, setTaskList] = useState(
        {
            tenants: -1
        }
    )
    const [completed, setCompleted] = useState(
        {
            tenants: -1
        }
    )
    const [errors, setErrors] = useState([])
    const [warnings, setWarnings] = useState([])
    const [working, setWorking] = useState(false)

    
    useEffect(() => {

        if (propsChanged === true) {
            
        }

    }, [props, propsChanged]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }
    
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const upload = async (acceptedFiles) => {
        if(!props.organization_id){
            Swal.fire({
                title: "ERROR",
                text: 'You MUST select the organization for which you want to import data to.',
                icon: "error",
                showConfirmButton: true,
            })
            return
        }
        const accepted = ['text/csv']
        if(acceptedFiles.length > 1){
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'ONLY 1 file at a time please',
            })
            return;
        }
        if(!accepted.includes(acceptedFiles[0].type)){
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'ONLY csv files are allowed',
            })
            return;
        }

        let formData = new FormData();
        formData.append("file", acceptedFiles[0]);
        formData.append("organization_id", props.organization_id);        
        await AxiosClient.backendClient().post(`${process.env.REACT_APP_API_TENANT}/validate`, formData, {
            maxBodyLength: Infinity,
            maxContentLength: Infinity,
            timeout: 10000,
        })
        .then( results => {
            console.log('RESULTS',results.data)
            setFileName(results.data.file_name)
            let taskListU = {...taskList}
            taskListU.tenants = results.data.tasklist.tenants
            setTaskList(taskListU);
            setErrors(results.data.tasklist.errors);
            setWarnings(results.data.tasklist.warnings)
        })
        .catch(err => {
            Swal.fire({
                title: "ERROR",
                text: err.message,
                icon: "error",
                showConfirmButton: true,
            })
        });
    }

    const ImportData = () => {
        setWorking(true)
        setErrors([])
        setWarnings([])
        AxiosClient.backendClient().post(`${process.env.REACT_APP_API_OPENMETER}/tenant/import`, {
            "organization_id": props.organization_id,
            "file_name": fileName
        },{timeout: 60000})
        .then((results) => {

            console.log('RESULTS', results)
            setWorking(false)
            let taskListU = {...taskList}
            taskListU.locations = results.data.tasklist.tenants
            setTaskList(taskListU);

            let updated = {...completed}
            updated.tenants = results.data.completed.tenants
            setCompleted(updated);
            setErrors(results.data.completed.errors);
            setWarnings(results.data.completed.warnings);
            setFileName(null);

            Swal.fire({
                title: "Success",
                text: "Good work and attention to detail has finally paid off :)",
                icon: "success",
                showConfirmButton: true
            })
        })
        .catch( err => {
            console.log(err)
            setWorking(false)
            Swal.fire({
                title: "ERROR",
                text: err,
                icon: "error",
                showConfirmButton: true
            })
        })
    }

    const UI = () => {
        
        return (
            <Fragment>
                <div className="card">

                    <div className={`card-body ${(taskList.tenants >= 0)? "":"hidden" }`}>
                        <div className="content-header mt-2">
                            <h5 className="mb-1">Task List</h5>

                            <p className="">
                                {`
                                    ${(errors.length === 0)? "EXCELLENT. Everything looks good. If all is GREEN then time to upload. Good Luck !":"Oh man. Not quite right yet. Below you will find a list of the problems found by Row and then Col. Please make these corrections to your CSV and then try again."}
                                `}
                            </p>
                            <button className={`btn btn-${(errors.length > 0 || !fileName)?"danger":"success"} mb-2`} disabled={(errors.length === 0 && !!fileName)?"":"true"} onClick={() =>{ImportData()}}>
                                IMPORT: {fileName } <span className={`ms-1 spinner-border spinner-border-sm ${(working)? '':'hidden'}`} role="status" aria-hidden="true"></span> 
                            </button>

                            <section className={`mb-2 ${ (errors.length > 0)? "":"hidden" }`} id="upload-errors">
                                <h4 className="text-danger"><b>ERRORS</b></h4>
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Row</th>
                                            <th>Column</th>
                                            <th>Errors</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            errors.map(err => {

                                                console.log(err)

                                                let cols = []
                                                let descrips = []
                                                
                                                Object.keys(err.error).map(col => {
                                                    cols.push(<div className="mb-1"><span className="badge badge-danger badge-pill"> {col} </span></div>)
                                                    err.error[col].map(descrip => {
                                                        descrips.push(<div className="mb-1">{descrip}</div>)
                                                    })
                                                })

                                                return (
                                                    <tr>
                                                        <td className="table-danger">{err.row}</td>
                                                        <td>
                                                            {cols}
                                                        </td>
                                                        <td>{descrips}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                    <tfoot></tfoot>
                                </table>
                            </section>

                            <section className={`mb-2 ${ (warnings.length > 0)? "":"hidden" }`} id="upload-warnings">
                                <h4 className="text-warning"><b>WARNINGS</b></h4>
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Row</th>
                                            <th>Column</th>
                                            <th>Warnings</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            warnings.map(warn => {

                                                let cols = []
                                                let descrips = []
                                                
                                                Object.keys(warn.warning).map(col => {
                                                    cols.push(<div className="mb-1"><span className="badge badge-warning badge-pill"> {col} </span></div>)
                                                    warn.warning[col].map(descrip => {
                                                        descrips.push(<div className="mb-1">{descrip}</div>)
                                                    })
                                                })

                                                return (
                                                    <tr>
                                                        <td className="table-warning">{warn.row}</td>
                                                        <td>
                                                            {cols}
                                                        </td>
                                                        <td>{descrips}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                    <tfoot></tfoot>
                                </table>
                            </section>

                            <section id="upload-status">
                                <h4 className="text-primary"><b>PROGRESS</b></h4>
                                <table className="table b-table table-hover b-table-caption-top">
                                    <thead>
                                        <tr>
                                            <th>Subject</th>
                                            <th>ToDo</th>
                                            <th>Updated</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>Tenants</th>
                                            <td className={`${ (taskList.tenants > 0)? "table-success" : "table-secondary" }`}>{taskList.tenants}</td>
                                            <td className={`${ (completed.tenants.length === taskList.tenants) ? "table-success" : "table-secondary" }`}>{completed.tenants.length}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot></tfoot>
                                </table>
                            </section>
                            
                        </div>
                    </div>

                    <div className={`card-body ${(taskList.tenants > 0)? "hidden":"" }`}>
                        <div className="content-header mt-2">
                            <h5 className="mb-1">CSV Validator</h5>
                            <p className="">Upload your CSV. The validator will check the CSV and test all fields for quality. Validator will return an import report to you. </p>
                            <p>
                                <ul>
                                    <li><b className="text-danger">ERRORS:</b> If there are errors, then Validator will list the row and data that is incorrect. 
                                    In some cases it may tell you how to correct the data so it will work when you validate again. If there are no errors, 
                                    then Validator will complete the import process and return a task report to you.
                                    </li>
                                    <li>
                                        <b className="text-warning">WARNINGS:</b> If there are warnings, these can be ignored, but they are there for a reason. Double check that you are good with them and then 
                                        continue at your own risk.
                                    </li>
                                </ul>
                            </p>
                        </div>
                        <div className="row mt-2">
                            <div className="mb-1 col-md-12">
                                <div className="dropzone dropzone-area ">
                                    <Dropzone onDrop={acceptedFiles => upload(acceptedFiles)}>
                                        {({getRootProps, getInputProps}) => (
                                            <section className="bg-light-error">
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <p className="dz-message">CSV hier ablegen oder klicken um eine Datei auszuwählen</p>
                                            </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>{UI()}</Fragment>
    )

}

export default TenantUpload;
import React, {useState, useEffect, createContext, Fragment } from 'react';
import Cookie from 'js-cookie';
import randomString from 'randomstring';
import CryptoJS from "crypto-js";
import axios from 'axios';

import { useRouter } from "../../components/Shared/Router/Router"

export const WhiteLabelContext = createContext();

const WhiteLabelProvider = ({children, context}) => {

    const [updated, setUpdated] = useState(false);
    const router = useRouter()
    const [config, setConfig] = useState({
        "client_token":"",
        "host":"https://molline-connect.de",
        "css":"/style.css",
        "firm_name":"Molline Connect",
        "meta":{
            "description":"Molline-Connect Messtechnik der Zukunft ",
            "favicon":"favicon.png",
            "keywords":"Molline-Connect, messtechnik, diehl, engelmann, zenner, kamstrup, qundis",
            "title":"Molline-Connect",
            "author": "@DevOps Molline.Digital"
        },
        "og":{
            "description":"Molline-Connect Messtechnik der Zukunft",
            "image":"/Molline_Logo mit Slogan_Rot_300ppi_CMYK.png",
            "site_name":"Molline-Connect",
            "url":"https://molline-connect.de/"
        },
        "realm":"molline",
        "twitter":{
            "description":"Molline-Connect Messtechnik der Zukunft",
            "image":"/Molline_Logo mit Slogan_Rot_300ppi_CMYK.png",
            "title":"Molline-Connect",
            "url":"https://molline-connect.de/"
        },
        "white_label":true,
        "logo":"Molline_Logo mit Slogan_Rot_300ppi_CMYK.png"
    })

    useEffect( () => {

        if(!updated){
            //          getConfig()
            setUpdated(true);
        }

    }, [config])

    const getConfig = () => {
        console.log('GETTING CONFIG')
        axios.get('/api/admin/config')
            .then( response => {
                console.log('CONFIG RESPONSE', response.data)
                if(Object.keys(response.data).length > 1){
                    setConfig(response.data)
                }
            })
            .catch( err => {
                console.log('ERROR 41', err)
                return {}
            })
    }

    const configActions = {
        config,
        getConfig,
    }

    return (
        <Fragment>
            <WhiteLabelContext.Provider value={configActions}>
                {children}
            </WhiteLabelContext.Provider>
        </Fragment>
    )

}

export default WhiteLabelProvider;
import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import {useRouter} from "../../../../components/Shared/Router/Router";
import OrgCookieCrumb from "../../../../components/Dashboard/orgCookieCrumb";
import {AuthContext} from "../../../../context/Auth/AuthProvider";
import Dropzone from "react-dropzone";
import BSON from 'bson';
import {NavLink} from "react-router-dom";
import TourSteps from "../../../../TourSteps.json";
import PageTour from "../../../../components/Dashboard/tour/tour";


const BsonJsonConverter = (props) => {

    const [updated, setUpdated] = useState(false);
    const {user} = useContext(AuthContext);
    const router = useRouter()


    const [tabActive, setTabActive] = useState('convert_bson')

    useEffect(  () => {

        if(!updated){
            setUpdated(true)
        }

    }, [user, tabActive ]);

    const handleDrop = ([file]) => {
        const reader = new FileReader()
        if(file.name.search('bson') !== -1){
            reader.onload = function(e) {
                let contents = e.target.result
                let json = BSON.deserialize(contents)
                let pretty = JSON.stringify(json, undefined, 4)
                document.getElementById('txt_conversion_results').innerHTML = pretty
                setTabActive('convertion_results')
            }
            reader.readAsArrayBuffer(file);
        }

        if(file.name.search('json') !== -1){
            reader.onload = function(e) {
                let contents = e.target.result
                let bson = BSON.serialize(contents)
                let buffer = Buffer.from(bson)
                document.getElementById('txt_conversion_results').innerHTML = 'JSON to BSON not available yet :('
                setTabActive('convertion_results')
            }
            reader.readAsArrayBuffer(file);
        }

    }




    const UI  = () => {
        return (
            <Fragment>

                <div className="content-header row">
                    <div className="content-header-left col-md-9 col-12 mb-2">
                        <div className="row breadcrumbs-top">
                            <div className="col-12">
                                <h2 className="content-header-title float-start mb-0">Convert</h2>
                                <div className="breadcrumb-wrapper">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a >Tools</a>
                                        </li>
                                        <li className="breadcrumb-item active"> BSON Converter
                                        </li>
                                        <li>
                                            <span className="tourBorderRight" />
                                            <PageTour steps={TourSteps.bson_converter.overview}  isButton={false} />
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <OrgCookieCrumb />
                </div>
                <div className="content-body">
                    <section className="vertical-wizard">
                        <div className="bs-stepper vertical vertical-wizard-example">
                            <div className="bs-stepper-header">
                                <div className={`step ${(tabActive === 'convert_bson')? 'active':''}`} data-target="#convert_bson" role="tab" id="convert_bson-trigger" onClick={() => setTabActive('convert_bson')}>
                                    <button type="button" className="step-trigger">
                                        <span className="bs-stepper-box">1</span>
                                        <span className="bs-stepper-label">
                                            <span className="bs-stepper-title">BSON -> JSON</span>
                                            <span className="bs-stepper-subtitle">Datei hochladen</span>
                                        </span>
                                    </button>
                                </div>
                                <div className={`step ${(tabActive === 'convertion_results')? 'active':''}`} data-target="#convertion_results" role="tab" id="convertion_results-trigger" onClick={() => setTabActive('convert_json')}>
                                    <button type="button" className="step-trigger">
                                        <span className="bs-stepper-box">2</span>
                                        <span className="bs-stepper-label">
                                            <span className="bs-stepper-title">Ergebnis</span>
                                            <span className="bs-stepper-subtitle">Hier das Ergebnis anschauen</span>
                                        </span>
                                    </button>
                                </div>

                            </div>
                            <div className="bs-stepper-content">
                                <form id="gateway-registration">
                                    <div id="convert_bson" className={`content ${(tabActive === 'convert_bson')? 'active':''}`} role="tabpanel" aria-labelledby="convert_bson-trigger">
                                        <div className="content-header">
                                            <h5 className="mb-0">BSON -> JSON   </h5>
                                            <small className="text-muted">Hier eine valide BSON-Datei hochladen</small>
                                        </div>

                                        <div className="row">
                                            <div className="col-10 " id={"dzconverter"}>
                                                <Dropzone
                                                    maxFiles={1}
                                                    onDrop={acceptedFiles => handleDrop(acceptedFiles)}
                                                >
                                                    {({ getRootProps, getInputProps }) => (
                                                        <section>
                                                            <div {...getRootProps()}>
                                                                <input {...getInputProps()} />
                                                                <p className="dz-message">Hier BSON-Datei ablegen</p>
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                            </div>
                                        </div>

                                    </div>
                                </form>


                                <form id="gateway-registration">
                                    <div id="convertion_results" className={`content ${(tabActive === 'convertion_results')? 'active':''}`} role="tabpanel" aria-labelledby="convertion_results-trigger">
                                        <div className="content-header">
                                            <h5 className="mb-0">Ergebnis</h5>
                                            <small className="text-muted">Hier das Ergebnis anschauen</small>
                                        </div>
                                        <div className="row">
                                            <div className="mb-1 col-10">
                                                <textarea className="form-control" id="txt_conversion_results" rows={20}/>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                </div>
            </Fragment>
        )



    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )



}


BsonJsonConverter.requiredScope = ['tool.generic.any']
BsonJsonConverter.requireAuth = true;
export default BsonJsonConverter;
import React, {Fragment, useState, useEffect, useContext, useRef} from 'react';
import {useSearchParams} from "react-router-dom";
import {AuthContext} from "../../../../context/Auth/AuthProvider";
import {OrganizationContext} from '../../../../context/Organization/Organization';
import {SensorsContext} from '../../../../context/Devices/Sensors';
import {LocationsContext} from '../../../../context/Locations/Locations';
import Swal from '@molline/sweetalert2';

import {PermissionsWrapper, hasPermission} from "../../../../components/Dashboard/reactPermissionsWrapper";
import OrgCookieCrumb from "../../../../components/Dashboard/orgCookieCrumb";
import { SocketContext } from '../../../../context/Socket/Sockets';

import LinearProgressBar from '../../../../components/Dashboard/progress/bar_linear';
import useSound from 'use-sound';
import TourSteps from "../../../../TourSteps.json";
import PageTour from "../../../../components/Dashboard/tour/tour";



const SensorChangeTool = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const [updated, setUpdated] = useState(false);
  const [processing, setProcessing] = useState(false);
  const {user} = useContext(AuthContext);
  const { socket, socketId } = useContext(SocketContext);
  const { sensors, replaceSensor, getAllSensors, errorToast, successToast } = useContext(SensorsContext);
  const { organization } = useContext(OrganizationContext);

  const [oldSensorsFilter, setOldSensorsFilter] = useState(sensors)
  const [newSensorsFilter, setNewSensorsFilter] = useState(sensors)

  const [oldSensor, setOldSensor] = useState(null)
  const [newSensor, setNewSensor] = useState(null)

  const [jobsList, setJobsList] = useState([])
  const [errors, setErrors] = useState([])
  const [jobReady, setJobReady] = useState(false)

  const sensorsChanged = useCompare(sensors)
  const organizationChanged = useCompare(organization)
  const oldSensorChanged = useCompare(oldSensor)
  const newSensorChanged = useCompare(newSensor)

  const [soundOn, setSoundOn] = useState(true);

  const btnRef = useRef(null);
  const inputRef = useRef(null);

  const [playAlert] = useSound(
    '/app-assets/audio/chime.mp3',
    { volume: 0.10 }
  );
  /**
   * List of medium_codes which may be switched for on another
   * for example a 0x4 can be switched with another 0x4 or 0xd (combi sensor)
   */
  const compatible_medium_codes = {
    '0x0': ['0x0'],
    '0x1': ['0x1'],
    '0x1a': ['0x1a'],
    '0x2': ['0x2'],
    '0x3': ['0x3'],
    '0x4': ['0x4','0xd'],
    '0x5': ['0x5'],
    '0x6': ['0x6'],
    '0x7': ['0x7','0x16'],
    '0x8': ['0x8'],
    '0x9': ['0x9'],
    '0x16': ['0x16','0x7'],
    '0xa': ['0xa'],
    '0xb': ['0xb'],
    '0xc': ['0xc'],
    '0xd': ['0xd','0x4'],
  }

  useEffect(() => {


    if(!updated){
        let jobs = localStorage.getItem('austauschJobList');
        if(!!jobs && !processing){
            jobs = JSON.parse(jobs);
            setJobsList(jobs)
        }
        setUpdated(true)
    }

    if(sensorsChanged === true || organizationChanged === true){
        reset();
        //jobsReset()
        inputRef.current.focus();
        if(!!searchParams.get("serial_number") && sensors.length > 0){
            let serial_number = searchParams.get("serial_number");
            let oldSen = sensors.filter(s => s.serial_number === serial_number)[0]
            if(!!oldSen.serial_number){
                setOldSensor( oldSen )
                onChangeOldSerialHandler({target: {value:oldSen.serial_number}})
            }
        }
    }

    if(oldSensorChanged === true && oldSensor?.serial_number) filterNewSensors()
    if(newSensorChanged === true) evalJob()
    

  },[user, sensors, sensorsChanged, oldSensorChanged, oldSensor, jobsList, organization, organizationChanged, newSensor, newSensorChanged ])

  
  function useCompare(val) {
    const prevVal = usePrevious(val)
    return prevVal !== val
  }

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
  }

  const reset = () => {
    setOldSensor(null);
    setNewSensor(null);
    setJobReady(false);
    setOldSensorsFilter(sensors);
    setNewSensorsFilter(sensors);
    setErrors([])
  }
    /**
     * Handles input from oldSensor Search filter
     */
  const onChangeOldSerialHandler = (e) => {
    reset()
    let sensorArray = sensors.filter(s => s.serial_number.search(e.target.value) !== -1)
    setOldSensorsFilter(sensorArray)
    if(sensorArray.length === 1) setOldSensor(sensorArray[0])
  }

  const onChangeNewSerialHandler = (e) => {
    let sensorArray = newSensorsFilter.filter(s => s.serial_number.search(e.target.value) !== -1)
    setNewSensorsFilter(sensorArray)
    if(sensorArray.length === 1) setNewSensor(sensorArray[0])
  }

  const filterNewSensors = () => {
    if(!!oldSensor && oldSensor?.serial_number && oldSensor?.medium_code){
        let sensorArray = sensors.filter(s => {
            if(
                s.serial_number !== oldSensor.serial_number &&
                s.location_id === oldSensor.location_id && 
                compatible_medium_codes[oldSensor.medium_code].includes(s.medium_code)
            ) return s
        })
        setNewSensorsFilter(sensorArray)
        if(sensorArray.length === 1) setNewSensor(sensorArray[0])
    }
  }
  /**
   * Clicking the old sensors table can set the
   * Sensor variable 
   */
  const setOldSensorTarget = (e) => {
    let oldSen = sensors.filter(s => s._id === e.currentTarget.id)[0]
    setOldSensor( oldSen )
    onChangeOldSerialHandler({target: {value:oldSen.serial_number}})
  }
    /**
   * Clicking the old sensors table can set the
   * Sensor variable 
   */
  const setNewSensorTarget = (e) => {
    let newSen = newSensorsFilter.filter(s => s._id === e.currentTarget.id)[0]
    setNewSensor( newSen )
  }
  /**
   * valid/invalid status checker of the input fields
   */
  const evalOldSensorCandidate = () => {
    if(!!oldSensor && oldSensor?.serial_number) return 'valid';
    return 'invalid';
  }

  const evalNewSensorCandidate = () => {
    if(!!newSensor && newSensor?.serial_number) return 'valid';
    return 'invalid';
  }

  const evalJob = () => { 
    let newErrors = [...errors]
    if(oldSensor?.serial_number && newSensor?.serial_number){

        let curJob = jobsList.filter(job => job.sensorOld?.serial_number === oldSensor.serial_number)
        if(curJob.length > 0) newErrors.push(`Alter Zähler ${oldSensor.serial_number} ist bereits zum Tausch vorgemerkt.`);
        curJob = jobsList.filter(job => job.sensorNew.serial_number === newSensor?.serial_number)
        if(curJob.length > 0) newErrors.push(`Neuer Zähler ${newSensor?.serial_number} ist bereits zum Tausch vorgemerkt.`);

        if(!!oldSensor.medium_code && !compatible_medium_codes[oldSensor.medium_code].includes(newSensor?.medium_code)) newErrors.push('Diese Medium-Codes sind nicht kompatibel.');
        if(oldSensor.location_id !== newSensor?.location_id) newErrors.push('Zähler müssen derselben Mieteinheit zugewiesen sein');
        if(oldSensor.organization_id !== newSensor?.organization_id) newErrors.push('Zähler müssen demselben Kunden zugewiesen sein');
        console.log('ERRORS', newErrors, newErrors.length === 0)
        setErrors(newErrors)
        if(newErrors.length === 0){
            setJobReady(true)
            if(soundOn) playAlert()
            btnRef.current.focus()
        }
        return newErrors.length === 0;
    }
    return false 
  }

  const toggleSound = () => {
    if(soundOn === true) setSoundOn(false)
    else setSoundOn(true)
  }

  const alterOldSensorState = () => {
    Swal.fire({
        icon: 'question',
        title: `Finaler Sensor Wert`,
        input: 'text',
        inputLabel: 'Wert',
        inputValue: (oldSensor?.state?.value)? oldSensor.state.value : '',
        allowOutsideClick: true,
        backdrop: true,
        allowEscapeKey: true,
        allowEnterKey: true,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Speichern',
        cancelButtonText: 'Abbrechen',
        onClose: btnRef.current.focus(),
        inputValidator: (value) => {
            if (!value || isNaN(+value)) {
              return 'Ein valider Sensor-Wert ist benötigt.'
            }
        }
    })
    .then(async results => {
        if (results.isConfirmed) {
            let updatedSensor = JSON.parse(JSON.stringify(oldSensor))
            if(!updatedSensor.state) updatedSensor.state = {};
            updatedSensor.state.value = results.value;
            setOldSensor(updatedSensor);
        }
    })
    
  }

  const acceptJob = () => {
    if(errors.length > 0) {
        errorToast('Sie können diesen Job aufgrund von Fehlern nicht erstellen')
    }
    let updatedJobsList = [...jobsList]
    let newJob = {
        sensorOld: oldSensor,
        sensorNew: newSensor,
        results: null,
        error:''
    }
    updatedJobsList.unshift(newJob)
    setJobsList(updatedJobsList)
    localStorage.setItem('austauschJobList', JSON.stringify(updatedJobsList));
    document.getElementById('txtOldSensorSerialFilterControl').value = '';
    document.getElementById('txtNewSensorSerialFilterControl').value = '';

    reset()
    inputRef.current.focus();
  }

  const jobsReset = () => {
    localStorage.removeItem('austauschJobList');
    setJobsList([])
    reset()
    inputRef.current.focus();
  }

  const removeJob = (e) => {
    let id = e.currentTarget.id;
    let updatedJobsList = jobsList.filter(job => job.sensorOld?.serial_number !== id)
    setJobsList(updatedJobsList)
  }

  const executeJobList = async () => {
    let updatedList = JSON.parse(JSON.stringify(jobsList))
    if(jobsList.length > 0){
        Swal.fire({
            icon: 'question',
            title: `Sind Sie wirklich sicher, dass Sie diese Jobs ausführen möchten?`,
            allowOutsideClick: true,
            backdrop: true,
            allowEscapeKey: true,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Ausführen',
            cancelButtonText: 'Abbrechen',
            
        })
        .then(async results => {
            if (results.isConfirmed) {
                setProcessing(true)
                for(const [index, job] of jobsList.entries()){
                    let results;
                    console.log(job)
                    
                    if(!!job.results) continue;
                    try{ 
                        results = await replaceSensor(job)
                        updatedList[index].results = 'success';
                        setJobsList(updatedList)
                        localStorage.setItem('austauschJobList', JSON.stringify(updatedList));
                    }catch(err){
                        console.log(err)
                        updatedList[index].results = 'fail';
                        updatedList[index].error = err;
                        setJobsList(updatedList)
                        localStorage.setItem('austauschJobList', JSON.stringify(updatedList));
                    }
                    console.log('RESULTS', results)
                }
            }
        })   
    }
  }

  const UI = () => {
      
      return (
          <Fragment>
              <div className="content-header row">
                  <div className="content-header-left col-md-9 col-12 mb-2">
                      <div className="row breadcrumbs-top">
                          <div className="col-12">
                              <h2 className="content-header-title float-start mb-0">Sensor Austausch v1.0.0</h2>
                              <div className="breadcrumb-wrapper">
                                  <ol className="breadcrumb">
                                      <li className="breadcrumb-item"><a>Tools</a></li>
                                      <li className="breadcrumb-item active">Sensor Austausch Tool
                                      </li>
                                      <li>
                                          <span className="tourBorderRight" />
                                          <PageTour steps={TourSteps.sensorExchange.overview}  isButton={false} />
                                      </li>
                                  </ol>
                              </div>
                          </div>
                      </div>
                  </div>
                  <OrgCookieCrumb />
              </div>

              <div className="content-body" >

                  <section id="changeTool">
                      <div className="row">



                          <div className="col-xl-8 col-md-12 col-sm-12" id="sensorsExchangeJobBuilder">
                                <div className="card">
                                    <div className="card-header">
                                        <h4>Austausch-Jobs erstellen </h4>
                                        <i class={`text-${(soundOn)? 'success':'danger'} fa-solid fa-music fa-2x waves-effect waves-light`} onClick={() => {toggleSound()}}></i>
                                        
                                    </div>
                                    <div className="card-body">
                                        
                                        <div className="row mb-4">
                                            <div className="col-5" id="oldSensorsSerialNumber">
                                                <div className='input-group'>
                                                    <input className={`form-control is-${evalOldSensorCandidate()}`} type="text" id="txtOldSensorSerial" placeholder="Alte Seriennummer" value={(!!oldSensor && oldSensor?.serial_number)? oldSensor.serial_number:'Alte Seriennummer'} readOnly></input>
                                                    <span className={`input-group-text cursor-pointer btn-outline-${(!!oldSensor && oldSensor?.state?.value && !isNaN(+oldSensor.state.value))?'success':'danger'} waves-effect`} id="txtOldSensorState" onClick={() => {alterOldSensorState()}}> {(!!oldSensor && oldSensor?.state?.value)? oldSensor?.state?.value:'Wert'} </span>
                                                    <span className={`input-group-text cursor-pointer btn-outline-${( (!!oldSensor && !!newSensor && !!oldSensor.medium_code) && (compatible_medium_codes[oldSensor.medium_code].includes(newSensor?.medium_code)) )?'success':'danger'} waves-effect`} id="txtOldSensorMC"> {(!!oldSensor && oldSensor?.medium_code)? oldSensor?.medium_code:'MC'} </span>
                                                </div>
                                                <button className='mt-1 w-100 btn btn-danger waves-effect waves-light' type='button' onClick={() => reset()}>Zurücksetzen</button>
                                            </div>
                                            <div className="col-2 text-center">
                                                <div className=''><i class={`text-${( !!errors && errors.length === 0 && jobReady )?'success':'muted'} fa-solid fa-repeat fa-2x ${( errors.length === 0 && jobReady )?'fa-spin':''} `}></i></div>
                                            </div>
                                            <div className="col-5" id="newSensorsSerialNumber">
                                                <div className='input-group'>
                                                    <span className={`input-group-text cursor-pointer btn-outline-${( (!!oldSensor && !!newSensor && !!oldSensor.medium_code) && (compatible_medium_codes[oldSensor.medium_code].includes(newSensor?.medium_code)) )?'success':'danger'} waves-effect`} id="txtNewSensorMC"> {(!!newSensor && newSensor?.medium_code)? newSensor?.medium_code:'MC'} </span>
                                                    <span className={`input-group-text cursor-pointer btn-outline-${(!!newSensor && newSensor?.state?.value && !isNaN(+newSensor?.state?.value))?'success':'danger'} waves-effect`} id="txtNewSensorState"> {(!!newSensor && newSensor?.state?.value)? newSensor?.state?.value:'Wert'} </span>
                                                    <input className={`form-control is-${evalNewSensorCandidate()}`} type="text" id="txtNewSensorSerial" placeholder="Alte Seriennummerr" value={(!!newSensor && newSensor?.serial_number)? newSensor?.serial_number:'Neue Seriennummer'} readOnly></input>
                                                </div>
                                                <button ref={btnRef} tabIndex={3} id="btnAcceptJob" className='mt-1 w-100 btn btn-success waves-effect waves-light' type='button'  onClick={() => {acceptJob()}} >Akzeptieren</button>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className='col-12'>
                                                {
                                                    errors.map(err => {
                                                        return <div className='alert alert-danger p-1' role="alert">{err}</div>
                                                    })
                                                }
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-6 table-responsive">
                                                <div className='input-group'>
                                                    <input ref={inputRef} className="form-control" type="text" id="txtOldSensorSerialFilterControl" placeholder="Alte Seriennummer" tabIndex={1} autoFocus onChange={(e) => onChangeOldSerialHandler(e)} ></input>
                                                </div>
                                                <div className='mt-2 '>
                                                    <table className="table " id="tblOldSensorCandidates">
                                                        <thead>
                                                            <tr>
                                                                <th>Seriennummer</th>
                                                                <th>MediumCode</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                oldSensorsFilter.map(os => {
                                                                    let date = new Date()
                                                                    let sDate = new Date(os.state.date)
                                                                    let hours = Math.abs(date - sDate) / 36e5;
                                                                    let bg = 'alert-success';

                                                                    if (hours > 360) bg = 'alert-danger';
                                                                    if (os.state.date === undefined) bg = 'alert-danger';
                                                                    if (os.state?.status === 'warning') bg = 'alert-warning';
                                                                    if (os.state?.status === 'defective' || os.state?.flags.includes('handicap')) bg = 'alert-dark';
                                                                    return <tr className={bg} id={os._id} key={os._id} onClick={(e) => setOldSensorTarget(e)}><td>{os.serial_number}</td><td>{os.medium_code}</td></tr>
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-6 table-responsive">
                                                <div className='input-group'>
                                                    <input className="form-control" type="text" id="txtNewSensorSerialFilterControl" placeholder="Neue Seriennummer" tabIndex={2} onChange={(e) => onChangeNewSerialHandler(e)} ></input>
                                                </div>
                                                <div className='mt-2 '>
                                                    <table className="table table-striped" id="tblNewSensorCandidates">
                                                        <thead>
                                                            <tr>
                                                                <th>MediumCode</th>
                                                                <th>Seriennummer</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                newSensorsFilter.map(ns => {
                                                                    return <tr id={ns._id} key={ns._id} onClick={(e) => setNewSensorTarget(e)}><td>{ns.medium_code}</td><td>{ns.serial_number}</td></tr>
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                          </div>

                          <div className="col-xl-4 col-md-12 col-sm-12">
                                <div className="card " id="sensorsExchangeDetails">
                                    <div className="card-header">
                                        <h4>Zähler</h4>
                                    </div>
                                    <div className="card-body">
                                        <table className="table table-striped table-hover" id="tbleSensorChangeTaskList">
                                            <thead>
                                                <tr>
                                                    <th>Alt</th>
                                                    <th>Neu</th>
                                                    <th>Wert</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    jobsList.map( job => {
                                                        let icon = 'delete-left';
                                                        let color = 'danger';
                                                        if(!!job.results && job.results === 'success'){icon = 'check'; color = 'success'}
                                                        if(!!job.results && job.results === 'fail') {icon = 'circle-exclamation'; color = 'danger'}
                                                        return (
                                                            <tr>
                                                                <td>{job.sensorOld?.serial_number}</td>
                                                                <td>{job.sensorNew.serial_number}</td>
                                                                <td>{job.sensorOld?.state.value}</td>
                                                                <td id={job.sensorOld?.serial_number} onClick={ (e) => { if(!job.results) removeJob(e) }}><i class={`text-${color} fa-solid fa-${icon}`}></i></td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="card-footer">
                                        <div className="row">
                                            <div className="col-6 text-center">
                                                <button className="btn btn-danger waves-effect waves-light " onClick={() => jobsReset()}>Zurücksetzen</button>
                                            </div>
                                            <div className="col-6 text-center">
                                                <button className="btn btn-success waves-effect waves-light " onClick={() => executeJobList()}>Austauschen</button>
                                            </div>
                                        </div>
                                        
                                        
                                    </div>

                                </div>
                            </div>


                      </div>
                  </section>

              </div>
          </Fragment>
      )
  }

  return (
      <Fragment>
        {UI()}
      </Fragment>
  ) 
}


SensorChangeTool.requiredScope = ['sensor.update.any'];
SensorChangeTool.requireAuth = true;
export default SensorChangeTool;

import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';
import { useRouter } from "../../../Shared/Router/Router";

import de from '../datatable.de.json'
import { AuthContext } from '../../../../context/Auth/AuthProvider';

const $ = require('jquery')
$.DataTable = require('datatables.net')

const capitalize = (str) => {
    return `${str.charAt(0).toUpperCase()}${str.slice(1)}`
}

const TblLocationUsers = (props) => {
    const router = useRouter()
    const { user } = useContext(AuthContext);
    const propsUserChanged = useCompare(props.users)

    useEffect(() => {
        if(propsUserChanged) {
            initializeLocationUsersDT();
        }
    }, [props.users, propsUserChanged]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const refreshDataTables = () => {
        $('#locationBasedUsers').DataTable().destroy();
        // remove the eventhandler to prevent multiple events 
        $(`#locationBasedUsers tbody`).off('click')
        $(`#locationBasedUsers tbody`).off('mouseenter')
        $(`#locationBasedUsers tbody`).off('mouseleave')
        initializeLocationUsersDT();
    }

    const initializeLocationUsersDT = () => {

        const sortedUsers = [...props.users]
        sortedUsers.sort(function (a, b) {
            return new Date(b.created_at) - new Date(a.created_at);
        });

        let columns = [];

        if (true) {
            columns.push({ "title": "ID", "data": "_id" })
        }
        if (true) {
            columns.push({
                "title": "Vorname", "data": "first_name", render: function (value, type, row) {
                    return capitalize(value);
                }
            })
        }
        if (true) {
            columns.push({
                "title": "Nachname", "data": "last_name", render: function (value, type, row) {
                    return capitalize(value);
                }
            })
        }
        if (true) {
            columns.push({ "title": "E-Mail", "data": "emails[0].email" });
        }
        if (true) {
            columns.push({
                "title": "Rollen", "data": "roles", render: function (roles) {
                    if (roles?.length > 0) {
                        return roles.reduce(
                            (p, c) =>
                                user?.roles?.includes('god') || (user?.scopes?.includes('role.view.any') && c !== 'god') || ((user?.scopes?.includes('role.view.own') && user?.roles?.includes(c)) || c === 'tenant' || c === 'bldg_manager') ?
                                    p + `<span class="badge rounded-pill bg-primary me-25 mb-25">${c}</span>` : '',
                            "")
                    }
                    return "";
                }
            });
        }
        if (true) {
            columns.push({
                "data": null,
                "searchable": false,
                "ordering": false,
                render: function (data, type, row) {
                    if (!user?.roles?.includes('god') && !user?.scopes?.includes('location.update.any') && !user?.scopes?.includes('location.update.own')) return '';
                    return `<button class="btnRemoveUser btn btn-sm btn-danger">X</button>`;
                }
            })
        }


        if ($.fn.dataTable.isDataTable('#locationBasedUsers')) {
            refreshDataTables()
        }else {
            const table = $('#locationBasedUsers').DataTable({
                "language": de,
                "processing": true,
                "data": sortedUsers,
                order: [],
                "rowId": "_id",
                "columns": columns,
            });

            $('#locationBasedUsers tbody').on('click', '.btnRemoveUser', function (e) {
                e.preventDefault()
                let button = e.target;
                let row = button.closest('tr');
                let type = row.parentNode.parentNode.dataset.type;
                let _id = button.closest('tr').id;
                console.log('REMOVING USER', _id)
                props.removeUser(_id)
            });
        }
    }

    const UI = () => {

        return (
            <Fragment>
                <section id="">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <table id="locationBasedUsers" className="table mb-0 row-border hover order-column w-100" role="grid">
                                    <thead>
                                        <tr>
                                            <th >ID</th>
                                            <th >Vorname</th>
                                            <th >Nachname</th>
                                            <th >E-Mail</th>
                                            <th >Rollen</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        )
    }

    return (
        <Fragment>{UI()}</Fragment>
    )



}

export default TblLocationUsers;
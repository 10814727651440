import React, { useState, Fragment, useEffect, useContext } from 'react';
import { AuthContext } from "../../../../context/Auth/AuthProvider";
import { LocationsContext } from "../../../../context/Locations/Locations";
import { PermissionsWrapper, hasPermission, MembershipWrapper, hasMembership } from "../../reactPermissionsWrapper";
import {useRouter} from "../../../Shared/Router/Router";
/**
 * Sets the current location using a dropdown slect input.
 * @param {*} props 
 * @returns 
 */
const SelectLocationWidget = (props) => {

    const router = useRouter()
    const { user } = useContext(AuthContext);
    const { locationsChanged, location, setLocation, locations, locationChanged, capitalize, locationIcon } = useContext(LocationsContext);

    useEffect( () => {
            
    }, [ locationsChanged, locations, locationChanged, location ]);

    const getLocationAddress = (loc) => {
        if (!loc) return;
        return `${capitalize(loc?.address?.street)} ${loc?.address?.house_number}, ${(loc?.address?.street1) ? `ME ${loc.address.street1}, ` : ''} ${loc?.address?.post_code} ${capitalize(loc?.address?.city)}, ${loc?.address?.country.toLocaleUpperCase()}`
    }

    const UI = () => {
        if(Object.keys(location).length === 0  || !user || locations.length === 0) return <Fragment></Fragment>
        let orderedLocs = [...locations]
        orderedLocs.sort(function (a, b) {
            const bLocString = `${b.address.street}${a.address.street.house_number}}${b.address.street1 === "" ? "" : b.address.street1}`
            const aLocString = `${a.address.street}${b.address.street.house_number}}${a.address.street1 === "" ? "" : a.address.street1}`
            return aLocString.localeCompare(bLocString, undefined, {
                numeric: true
            })
        });
        return(
            <PermissionsWrapper user={user} scopes={['location.view.any', 'location.view.own']} >
                <Fragment>
                    <div className="input-group" style={{ border: 0, fontSize: "1.285rem" }} id="widgetSelectLocation">
                        <span className="input-group-text btn btn-primary" style={{ fontSize: "1.285rem", fontWeight: "500", padding: "1.2rem 1rem 1.2rem 1rem" }} onClick={(e) => router.push('/details/location/' + location?._id)} > <i className="fa-solid fa-magnifying-glass"></i> </span>
                        <span className="input-group-text" style={{ border: 0, fontSize: "1.285rem", padding: "0rem 1.35rem" }}> <span dangerouslySetInnerHTML={{__html:locationIcon({location, size:1})}} /> </span>
                        <select className="form-select" name="type" style={{ border: 0, fontSize: "1.285rem", fontWeight: "500", padding: "1.2rem 1rem 1.2rem 0.5rem" }} onChange={(e) => {
                            const id = e?.target?.value;
                            if (!id) return;
                            const loc = locations?.find((l) => l._id === id);
                            if (loc) {
                                setLocation(loc)
                            }
                        }} >
                            {
                                orderedLocs.map(loc => {
                                    return (
                                        <option key={loc._id} selected={location?._id === loc?._id} value={loc._id}> {getLocationAddress(loc)}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </Fragment>
            </PermissionsWrapper>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )

}

export default SelectLocationWidget;
import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';
import { AuthContext } from "../../../../context/Auth/AuthProvider";
import { TelegramContext } from '../../../../context/Telegrams/Telegrams';
import {PermissionsWrapper, hasPermission} from "../../../../components/Dashboard/reactPermissionsWrapper";
import Swal from "@molline/sweetalert2";

import de from '../datatable.de.json';
const $ = require('jquery')
$.DataTable = require('datatables.net');


const TblTelegrams = (props) => {

    const { user } = useContext(AuthContext);
    const { telegrams, getTelegram, telegramsChanged } = useContext(TelegramContext)

    useEffect(() => {
        if (telegramsChanged === true) initializeDT()
        
    }, [telegrams]);

    
    const initializeDT = () => {
    
        if(telegrams && telegrams.length !== 0 ){
            if ($.fn.dataTable.isDataTable(`#tblTelegrams`)) {
                $(`#tblTelegrams`).DataTable().destroy();
                // remove the eventhandler to prevent multiple events
                $(`#tblTelegrams tbody`).off('click')
                $(`#tblTelegrams tbody`).off('mouseenter')
                $(`#tblTelegrams tbody`).off('mouseleave')
                initializeDT()
            }else{
                $('#tblTelegrams').DataTable({
                    "language": de,
                    "processing": true,
                    "data": telegrams,
                    "rowId": "telegram_id",
                    "order": [[ 1, "desc" ]],
                    "pageLength": 100,
                    "columns": [
                        {
                            "data": "_id",
                            "searchable": true,
                            "ordering": true,
                            visible: false
                            
                        },
                        { 
                            "data": "telegram_date",
                            "searchable": true,
                            "ordering": true,
                        },
                        { 
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            visible: false,
                            render: function ( data, type, row ) {
                                if(data.gateway_uid !== undefined) return data.gateway_uid;
                                return `<span class="badge bg-danger">NA</span>`
                                
                            }
                        },
                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: function ( data, type, row ) {
                                if(data.sensor_serial_number !== undefined) return `<span>${data.sensor_serial_number}</span>`
                                return ''
                            }
                        },
                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: function ( data, type, row ) {
                                if(data?.measurement?.value && data?.measurement?.unit_of_measure) return `${data.measurement.value} ${data.measurement.unit_of_measure}`
                                return `<span class="badge bg-danger">NA</span>`
                            }
                        },
                        { 
                            "data": "filename",
                            "searchable": true,
                            "ordering": true,
                        },
                        { 
                            "data": "rssi",
                            "searchable": true,
                            "ordering": true,
                         },

                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: function ( data, type, row ) {
                                return(
                                    `${hasPermission({user, scopes:['telegram.view.any', 'telegram.view.own']}) ? `<button className="view-telegram" id="${data._id}" class="btn btn-sm btn-primary btn-viewtg me-1"><i class="fa-solid fa-magnifying-glass"></i></button>`  : ''}`
                                )
                                
                            }
                        },
                    ],
                    "fnDrawCallback": function( oSettings ) {
                        
                    }
                });
                $('.dataTables_length select').addClass('form-select');
                $('.dataTables_filter input').addClass('form-control');

                $('#tblTelegrams tbody').on('mouseenter', 'tr', function () {
                    $(this).css('cursor', 'pointer');
                    $(this).css('background-color', '#f3f2f7');
                });
                $('#tblTelegrams tbody').on('mouseleave', 'tr', function () {
                    $(this).css('background-color', 'transparent');
                });

                $('#tblTelegrams tbody').on( 'click', 'button.btn-viewtg', function (e) {
                    let button = e.currentTarget;
                    getTelegram(button.id)
                    .then(telegram => {
                        let t = JSON.stringify(telegram, null, 2)
                        Swal.fire({
                            title: 'Telegram',
                            icon: 'info',
                            width: '90%',
                            html:
                                '<div class="text-left" style="text-align:left"><pre>'+t+'</pre></div>',
                            showCloseButton: true,
                            showCancelButton: false,
                        })
                    })
                    .catch(err => {})
                });
            }
        }
    }

    const UI = () => {
        const isProcessing = () => {
            if (telegrams.length === 0 && !telegramsChanged) {
                return (
                    <div className="mt-4">
                        <h4 className="text-center"><i className="fa-solid fa-cog fa-spin"></i> WIRD GELADEN ...</h4>
                    </div>
                )
            }
        }

        return (
            <Fragment>
                <div className='table-responsive'>
                    <table id={`tblTelegrams`} className="table mb-0 row-border hover order-column w-100 table-hover" role="grid">
                        <thead>
                            <tr>
                                <th>Telegramm-ID</th>
                                <th>Datum</th>
                                <th>Gateway-UID</th>
                                <th>Sensor-Seriennummer</th>
                                <th>Messwert</th>
                                <th>Dateiname</th>
                                <th>TELEKOM RSSI</th>
                                <th></th>
                            </tr>
                        </thead>
                    </table>
                </div>
                {isProcessing()}
            </Fragment>
        )
    }
    return (
        <Fragment>{UI()}</Fragment>
    )
}

export default TblTelegrams;
import React, {Fragment, useState, useEffect, useContext } from 'react';
import queryString from 'query-string';
import axios from 'axios';
import { X, CheckSquare, Key, Square, Plus } from 'react-feather';
import uniqid from 'uniqid';
import Swal from '@molline/sweetalert2'
import { AuthContext } from '../../../../context/Auth/AuthProvider';

import de from "../../../../components/Dashboard/tables/datatable.de.json";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AxiosClient from '../../../../utils/axios_client';
import cookies from "js-cookie";
import TourSteps from "../../../../TourSteps.json";
import PageTour from "../../../../components/Dashboard/tour/tour";

const Clients = ({children}) => {

    const [updated, setUpdated] = useState(false);
    const [clients, setClients] = useState([]);
    const [limit, setLimit] = useState(1000);
    const [search, setSearch] = useState(null);
    const {user} = useContext(AuthContext);

    useEffect(() => {

        console.log('USER', user)

        if(!updated){
      //      getData();
            setUpdated(true)
        }
    }, [user, clients, limit, search]);

    const getData = () => {
        AxiosClient.backendClient({
            sessionCookie: cookies.get('session'),
            userCookie: cookies.get('auth'),
            app_key: cookies.get('APP_KEY')
        }).get(`${process.env.REACT_APP_API_OPENMETER}/client`)
            .then(results => {
                setClients(results[0].data)
            })
    }

    const deleteClient = (event) => {
        let token = event.target.dataset.token;
        Swal.fire({
            title: 'Sind Sie sicher?',
            icon: 'question',
            text: `Soll der Zugang für ${token} wirklich gelöscht werden?`,
            allowOutsideClick: true,
            allowEscapeKey: true,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Delete it'
        })
            .then( results => {
                if(results.isConfirmed === true){
                    AxiosClient.backendClient({
                        sessionCookie: cookies.get('session'),
                        userCookie: cookies.get('auth'),
                        app_key: cookies.get('APP_KEY')
                    }).delete(`${process.env.REACT_APP_API_OPENMETER}/client`, {data:{'client_token': token}})
                        .then(results => {
                            getData();
                        }).catch(err => {
                            console.log('CLIENT DELETE ERROR', err)
                    })
                }
            })
    }

    const addNewClient = () => {
        Swal.fire({
            title: 'Neuen Zugang anlegen',
            width: '50rem',
            allowOutsideClick: true,
            allowEscapeKey: true,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            focusCancel: true,
            html:
                '<input class="form-control mb-1" type"text" id="email" placeholder="your-email@email.com"/>' +
                '<input class="form-control mb-1" type"text" id="name" placeholder="Name"/>' +
                '<input class="form-control mb-1" type"text" id="firm" placeholder="Firma"/>' +
                '<input class="form-control mb-1" type"text" id="app_name" placeholder="Name der Anwendung"/>' +
                '<input class="form-control mb-1" type"text" id="callback" placeholder="Callback URL der Anwendung"/>' +
                '<input class="form-control mb-1" type"text" id="host" placeholder="Host der Anwendung"/>' +
                '<input class="form-control mb-1" type"text" id="ip" placeholder="IP-Adresse der Anwendung"/>' +
                '<input class="form-control mb-1" type"text" id="realm" placeholder="Domäne / Realm"/>',
            preConfirm: () => {
                const email = Swal.getPopup().querySelector('#email').value
                const name = Swal.getPopup().querySelector('#name').value
                const firm = Swal.getPopup().querySelector('#firm').value
                const app_name = Swal.getPopup().querySelector('#app_name').value
                const callback = Swal.getPopup().querySelector('#callback').value
                const host = Swal.getPopup().querySelector('#host').value
                const ip = Swal.getPopup().querySelector('#ip').value
                const realm = Swal.getPopup().querySelector('#realm').value
                if (!email || !name || !firm || !app_name || !callback ||  !host || !ip || !realm) {
                    Swal.showValidationMessage(`Please fully complete the firm`)
                }
                return { email, app_name, name , firm, callback, host, ip, realm}
            }
        })
            .then( results => {
                if(results.isConfirmed === true){
                    AxiosClient.backendClient({
                        sessionCookie: cookies.get('session'),
                        userCookie: cookies.get('auth'),
                        app_key: cookies.get('APP_KEY')
                    }).post(`${process.env.REACT_APP_API_OPENMETER}/client`, results.value)
                        .then(results => {
                            getData();
                            confirmNewClient(results.data);
                        }).catch(err => {
                            console.log('CLIENT CREATE ERROR', err)
                        })
                }
            })
    }

    const confirmNewClient = (client) => {
        Swal.fire({
            title: 'Neuen Zugang anlegen',
            icon: 'success',
            width: '50rem',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            html:
                '<div class="alert alert-warning" role="alert"><div class="alert-body">Bitte notieren! Diese Daten werden nicht in der Datenbank gespeichert</div></div>' +
                '<div class="mb-1"><label class="form-label"/>Zugangs ID</label><input class="form-control" type"text" value='+ client.client_id + '/></div>' +
                '<div class="mb-1"><label class="form-label"/>Zugangs Secret</label><input class="form-control" type"text" value='+ client.secret + '/></div>' +
                '<div class="mb-1"><label class="form-label"/>Zugangs Key</label><textarea class="form-control" rows="17">'+ client.client_key.token +'</textarea></div>'
        })
    }

    const searchClientId = (event) => {
        if(event.target.value.length === 0){
            setSearch(null)
        }else{
            setSearch(event.target.value)
        }
    }

    const rowBuilder = (client) => {
        return (
            <tr key={uniqid()}>
                <td>{ (client.active === true)? <CheckSquare /> : <Square />}</td>
                <td>{client.last_use}</td>
                <td>{client.payload.client_token}</td>
                <td>{client.payload.app_name}</td>
                <td>{client.payload.email}</td>
                <td>{client.payload.realm}</td>
                <td>
                    <button className="btn btn-sm btn-danger" data-token={client.payload.client_token} onClick={(event) => {deleteClient(event)}}> X </button>
                </td>
            </tr>
        )
    }

    const UI = () => {
        const tblClientsRows = () => {
            let tblArray = [];
            if(search !== null){
                clients.filter( (client, index) => {
                    console.log(client)
                    if(
                        client.payload.client_token === search ||
                        client.payload.email === search ||
                        client.payload.realm === search
                    ){
                        tblArray.push(rowBuilder(client))
                    }
                })
            }else{
                clients.map( client => {
                    tblArray.push(rowBuilder(client))
                })
            }
            return tblArray;
        };

        return (
            <Fragment>
                <div className="content-header row" id="apiAccessHeader">
                    <div className="content-header-left col-md-9 col-12 mb-2">
                        <div className="row breadcrumbs-top">
                            <div className="col-12">
                                <h2 className="content-header-title float-start mb-0">API-Zugänge</h2>
                                <div className="breadcrumb-wrapper">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a >Verwaltung</a></li>
                                        <li className="breadcrumb-item active">API-Zugänge</li>
                                        <li>
                                            <span className="tourBorderRight" />
                                            <PageTour steps={TourSteps.clients.overview} isButton={false} />
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12 text-end ">
                        <div className="">{( typeof window !== "undefined" )?localStorage.getItem('organization_name'): ''}</div>
                    </div>
                </div>
                <div className="content-body">
                    <section id="tabpanels">
                        <div className="row">
                            <div className="col-12">
                                <div className="tab-content pt-1">
                                    <div className="card">
                                        <div className="card-header border-bottom">
                                            <h4 className="card-title">API-Zugänge verwalten </h4>
                                            <div className="heading-elements">
                                                <div className="heading-elements" >
                                                    <button className="btn btn-primary" id="apiClientAddbtn" onClick={() => {addNewClient()}}> <FontAwesomeIcon icon={faPlus} /></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body py-2 my-25">
                                            <div className="row mb-2">
                                                <div className="col-6">
                                                </div>
                                                <div className="col-6">
                                                    <input className="form-control" type="search" id="apiClientFilter" placeholder="Suche (exakt)" onChange={ (event) => {searchClientId(event)}}/>
                                                </div>
                                            </div>
                                            <div className='table-responsive'>
                                                <table className="datatables-basic table" id="tblClients">
                                                    <thead>
                                                    <tr>
                                                        <th>Aktiv</th>
                                                        <th>Zuletzt benutzt</th>
                                                        <th>ClientID</th>
                                                        <th>Anwendungsname</th>
                                                        <th>E-Mail</th>
                                                        <th>Domäne / Realm</th>
                                                        <th>Aktion</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {tblClientsRows()}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </section>

                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
           {UI()}
        </Fragment>
    )
}

Clients.requireAuth = true;
Clients.requiredScope = ['client.view.any', 'client.view.own'];
export default Clients;
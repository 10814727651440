import React, {Fragment, cloneElement} from 'react';

const hasPermission = ({ roles = [], scopes = [], user }) => {
    /**
     * You will need a GOD or some SUPER ADMIN role as user number 1. This user
     * will have unrestricted access.
     */
    if(!user) return false;
    if(user.roles.includes('god') || user.roles.includes('super_admin')) return true;

    let permissionGranted = false;
    let roles_intersection = roles.filter(r => user.roles.includes(r))
    if(roles_intersection.length > 0) permissionGranted = true;
    let scopes_intersection = scopes.filter(s => user.scopes.includes(s))
    if(scopes_intersection.length > 0) permissionGranted = true;
    return permissionGranted;

};

const PermissionsWrapper = ({
    children,
    scopes = [],
    roles = [],
    user,
    RenderError = () => <></>,
    errorProps = null
}) => {
    /**
     * If a user object is not provided, then return nothing as we do not
     * know the permissions level nor do we want to render special children yet.
     */
    if(!user) return (<Fragment></Fragment>)
    if( !user?.roles || !user?.scopes ) return <></>;

    const permissionGranted = hasPermission({ roles, scopes, user });
    if (!permissionGranted && !errorProps) return <RenderError />;
    if (!permissionGranted && errorProps) return cloneElement(children, { ...errorProps });

    return <>{children}</>;
}

const hasMembership = ({memberships = [], location, organization = null, user}) => {
    /**
     * Allow a realm_admin to get any report they want
     */
    if(!!user && (user.roles.includes('god') || user.roles.includes('realm_admin'))) return true;
    // ignore expired at for now
    // const today = new Date().getTime()
    // const expires = new Date(location?.membership?.expires_at).getTime()
    // if(expires < today)  return false;

    let permissionGranted = false;
    if(memberships.includes(location?.membership?.type)){
        permissionGranted = true
    }
    return permissionGranted;
}
/**
 * Checks for a valid location membership via th required memberships
 * and checks for an expired membership
 */
const MembershipWrapper = ({
    user,
    children,
    memberships = [],
    location,
    RenderError = () => <></>,
    errorProps = null
}) => {
    /**
     * If a user object is not provided, then return nothing as we do not
     * know the permissions level nor do we want to render special children yet.
     */
    if( !location  || !location.membership ||  !location.membership.type || !location.membership.expires_at ) return <></>;
    const permissionGranted = hasMembership({ location, memberships, user });

    if (!permissionGranted && !errorProps) return <RenderError />;
    if (!permissionGranted && errorProps) return cloneElement(children, { ...errorProps });

    return <>{children}</>;
}

export  {hasPermission, PermissionsWrapper, MembershipWrapper, hasMembership}
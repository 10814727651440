import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import {LocationsContext} from "../../../context/Locations/Locations";


const CountSinglePremiseLocationsWidget = (props) => {

    const { locations } = useContext(LocationsContext);
    const locationsChanged = useCompare(locations);
    const [count, setCount] = useState(0)

    useEffect(  () => {
            
        if(locationsChanged === true) resolve()

    }, [locations, locationsChanged]);

    function useCompare (val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const resolve = () => {
        let countLocations = []
        if(!!locations) countLocations = locations.filter(loc => loc.type === 'single_premise')
        setCount(countLocations.length)
    }

    const UI = () => {

        
        return (
            <div className="col-xl-2 col-md-4" id="widgetCountSinglePremiseLocations">
                <div className="card text-center">
                    <div className="card-body">
                        <div className="mx-auto my-1">
                            <i className="fa-solid fa-house fa-4x text-info"></i>
                        </div>
                        <p className="text-muted mb-0 line-ellipsis">Mieteinheiten</p>
                        <h4>{count}</h4>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )

}

export default CountSinglePremiseLocationsWidget;
import React, {Fragment, createContext, useContext, useEffect, useRef, useState} from 'react';
import { useRouter } from "../../components/Shared/Router/Router";
import { AuthContext } from "../Auth/AuthProvider";
import Axios_client from "../../utils/axios_client";

export const AlertsContext = createContext();

const AlertsProvider = ({ children, context }) => {

    const router = useRouter()
    const { user } = useContext(AuthContext);
    const userChanged = useCompare(user);
    const [alerts, setAlerts] = useState([])
    const [updated, setUpdated] = useState(false)

    useEffect(() => {

        if(userChanged && !updated){
            getAlerts()
            setUpdated(true)
        }
       
        
    }, [user, userChanged, alerts])

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const getAlerts = () => {
        if(!user || !user._id) return;
        Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/alerts?user_id=${user._id}`)
        .then( response => {
            setAlerts(response.data)
            setUpdated(true)
        })
        .catch( err => {
            setUpdated(true)
        }) 
    }

    const view = ({hash}) => {
        Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/alerts/view?user_id=${user._id}&hash=${hash}`)
        .then( response => {
            console.log('ALERTS',response.data)
            setAlerts(response.data)
        })
        .catch( err => {
            return
        }) 
    }

    const read = ({hash}) => {
        Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/alerts/read?user_id=${user._id}&hash=${hash}`)
        .then( response => {
            console.log('ALERTS',response.data)
            setAlerts(response.data)
        })
        .catch( err => {
            return
        }) 
    }

    const readAll = () => {
        Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/alerts/read/all?user_id=${user._id}`)
        .then( response => {
            console.log('ALERTS',response.data)
            setAlerts(response.data)
        })
        .catch( err => {
            console.log(err)
        }) 
    }

    const deleteAlert = ({hash}) => {
        Axios_client.backendClient().delete(`${process.env.REACT_APP_API_OPENMETER}/alerts?user_id=${user._id}&hash=${hash}`)
        .then( response => {
            console.log('ALERTS',response.data)
            setAlerts(response.data)
        })
        .catch( err => {
            
        }) 
    }



    const actions = {
        alerts,
        setAlerts,
        getAlerts,
        view,
        read,
        readAll,
        deleteAlert
    }

    return (
        <Fragment>
            <AlertsContext.Provider value={actions}>
                {children}
            </AlertsContext.Provider>
        </Fragment>
    )

}

export default AlertsProvider;
import React, {Fragment, useEffect, useContext, useRef } from 'react';
import {SensorsContext} from "../../../context/Devices/Sensors";
import {LocationsContext} from "../../../context/Locations/Locations";


const CountSensorsWidget = (props) => {

    const { sensors } = useContext(SensorsContext);
    const { locations } = useContext(LocationsContext);
    const locationsChanged = useCompare(locations);
    const sensorsChanged = useCompare(sensors);


    useEffect(  () => {
            
        

    }, [locations, locationsChanged, sensorsChanged, sensors]);

    function useCompare (val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const UI = () => {
        return (
            <div className="col-xl-2 col-md-4" id="widgetCountSensors">
                <div className="card text-center">
                    <div className="card-body">
                        <div className="mx-auto my-1">
                            <i className="fa-solid fa-microchip fa-4x text-secondary"></i>
                        </div>
                        <p className="text-muted mb-0 line-ellipsis">Zähler</p>
                        <h4>{sensors.length}</h4>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )

}

export default CountSensorsWidget;
import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import {useRouter} from "../../../Shared/Router/Router";
import { GatewaysContext } from "../../../../context/Devices/Gateways";
import { SensorsContext } from "../../../../context/Devices/Sensors";

import {PermissionsWrapper, hasPermission} from "../../../../components/Dashboard/reactPermissionsWrapper";
import { AuthContext } from "../../../../context/Auth/AuthProvider";
import { LocationsContext } from '../../../../context/Locations/Locations';

import de from '../datatable.de.json'
const $ = require('jquery');
$.DataTable = require('datatables.net');

const TblSensorsInSight = (props) => {

    const router = useRouter()
    const { user } = useContext(AuthContext);

    const { gateways, gateway } = useContext(GatewaysContext);
    const { sensors, sensorsChanged } = useContext(SensorsContext);

    const { locations, locationsChanged } = useContext(LocationsContext);

    const gatewayChanged = useCompare(gateway)

    useEffect(() => {

        if(gatewayChanged === true ) initializeDT()
        
    }, [ gateway, gatewayChanged, locations, locationsChanged, sensors, sensorsChanged ]);

    function useCompare (val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const initializeDT = () => {        
        if ($.fn.dataTable.isDataTable(`#tblSensorsInSight`)) {
            $(`#tblSensorsInSight`).DataTable().destroy();
            // remove the eventhandler to prevent multiple events
            $(`#tblSensorsInSight tbody`).off('click')
            $(`#tblSensorsInSight tbody`).off('mouseenter')
            $(`#tblSensorsInSight tbody`).off('mouseleave')
            initializeDT()
        } else {

            let insights = [];
            let insight = [];
            if(gateway.serials_insight && gateway.serials_insight.length > 0){
                for(let ins of gateway.serials_insight){
                    insights = insights.concat(ins.serials_insight)
                }
                insight = [...new Set(insights)]
            }

            $(`#tblSensorsInSight`).DataTable({
                language: de,
                paging: true,
                pagingType: "full",
                processing: true,
                searching: true,
                data: insight,
                rowId: '',
                fixedHeader: true,
                pageLength: 50,
                order: [[0,'asc']],
                columns: [
                    {
                        searchable: true,
                        data: null,
                        ordering: true,
                        render: function (data, type, row) {
                            return data;
                        }
                    },
                    {
                        data: null,
                        ordering: true,
                        render: function (data, type, row) {
                            let sensor = sensors.filter(s => s.serial_number == data)[0]
                            if ( !!sensor && !!sensor._id ) {
                                if(sensor?.state?.rssi) return sensor.state.rssi
                                return 'na'
                            } else {
                                return '';
                            }
                        }
                    },
                    {
                        data: null,
                        ordering: true,
                        render: function (data, type, row) {
                            let sensor = sensors.filter(s => s.serial_number == data)[0]
                            if ( !!sensor && !!sensor._id ) {
                                if(sensor?.description) return sensor.description
                                return 'na'
                            } else {
                                return '';
                            }
                        }
                    },
                    {
                        "data": null,
                        "ordering": true,
                        render: function (data, type, row) {
                            let sensor = sensors.filter(s => s.serial_number === data)[0]
                            if ( !!sensor && !!sensor._id &&  hasPermission({user, scopes:['sensor.view.any', 'sensor.view.own']}) ) {
                                return '<div style="text-align:right"><button id="' + data + '" class="btnViewInsightSensor btn btn-primary btn-sm">Anzeigen</button></div>'
                            } else {
                                return '';
                            }
                        }
                    }
                ],
                createdRow: function (row, data, dataIndex) {
                    $(row).attr('data-id', data || 'na');
                    let sensor = sensors.filter(s => s.serial_number == data)[0]
                    if(!!sensor) $(row).addClass('alert-info')
                },
                "fnDrawCallback": function( oSettings ) {}
            });

            $('.dataTables_length select').addClass('form-select');
            $('.dataTables_filter input').addClass('form-control');

            $(`#tblSensorsInSight tbody`).on('mouseenter', 'tr', function () {
                $(this).css('cursor', 'pointer');
                $(this).css('background-color', '#f3f2f7');
            });
            $(`#tblSensorsInSight tbody`).on('mouseleave', 'tr', function () {
                $(this).css('background-color', 'transparent');
            });

            $(`#tblSensorsInSight tbody`).on( 'click', 'tr', function () {});

            $(`#tblSensorsInSight tbody`).on('click', 'button.btnViewInsightSensor', function (e) {
                router.push(`/details/sensor/${e.currentTarget.id}`);
            });
        }
    }

    const UI = () => {

        const isProcessing = () => {
            if(gateways.length === 0 && !gatewayChanged){
                return (
                    <div className="mt-4">
                        <h4 className="text-center"><i className="fa-solid fa-cog fa-spin"></i> WIRD GELADEN ...</h4>
                    </div>
                )
            }
        }

        return (
            <Fragment>
                <div className='table-responsive'>
                    <table id={`tblSensorsInSight`} className="table mb-0 row-border hover order-column w-100" role="grid">
                        <thead>
                        <tr>
                            <th >Seriennummer</th>
                            <th title="Sensor -> Gateway Signal Strength">RSSI (Signalstärk)</th>
                            <th >Description</th>
                            <th ></th>
                        </tr>
                        </thead>
                    </table>
                </div>
                {isProcessing()}
            </Fragment>
        )
    }

    return (
        <Fragment>{UI()}</Fragment>
    )



}

export default TblSensorsInSight;
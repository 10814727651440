import Swal from '@molline/sweetalert2'
import axios from 'axios';
const questionLength = 1000;
const contactForm = {
    showForm: () => {
        const html = `
            <div className="d-flex justify-content-start">
                <h3 className="text-center">Wir sind für Sie da!</h3>
                <p>So erreichen Sie uns...</p>
                <p>
                    <div><i class="fa-solid fa-phone"></i> <span itemprop="telephone"><a id="contact_form_telephone" href="tel:+4971135169599">+49 (0) 711 35 16 95-99</a></span></div>
                    <div><i class="fa-solid fa-clock"></i> Mo. Di. Do. 7.00 Uhr – 15.30 Uhr, Mi. 7.00-13.00 Uhr, Fr. 7.00 – 12.00 Uhr</div>
                    <div><i class="fa-solid fa-at"></i> Oder über unser Kontaktformular:</div>
                </p></br>
                <form>
                    <fieldset class="form-group d-none">
                        <input type="text" id="inputBotCheck" class="form-control square" placeholder="Are you a bot?" />
                    </fieldset>
                    <div className="col-6">
                        <h6>Wie können wir Ihnen helfen?</h6>
                        <fieldset class="form-group">
                            <div class="input-group">
                                <select class="form-control" id="inputHowToHelp" multiple="multiple">
                                    <option selected="">Auswählen *</option>
                                    <option value="EU-Energieefﬁzient-Richtlinie">EU-Energieefﬁzienz-Richtlinie (EED) / Heizkostenverordnung (HKVO)</option>
                                    <option value="Angebot Molliné Connect">Unverbindliches Angebot für Serviceauftrag Molliné Connect</option>
                                    <option value="Miete von Gateway">Miete von Gateway</option>
                                    <option value="Miete von Messgeräten">Miete von Messgeräten</option>
                                    <option value="Kauf von Messgeräten">Kauf von Messgeräten</option>
                                </select>
                            </div>
                        </fieldset>
                    </div>
                    <div className="col-6">
                        <h6>Zu welcher Branche zählen Sie?</h6>
                        <fieldset class="form-group">
                            <div class="input-group">
                                <select class="form-control" id="inputBranch">
                                    <option selected="">Auswählen *</option>
                                    <option value="hausverwaltung">Hausverwaltung</option>
                                    <option value="planer">Planer</option>
                                    <option value="handwerker">Handwerker</option>
                                    <option value="sonstiges">Sonstiges</option>
                                </select>
                            </div>
                        </fieldset>
                    </div>

                    <div className="col-6">
                        <h6>Sind Sie bereits Kunde?</h6>
                        <fieldset class="form-group">
                            <div class="input-group">
                                <select class="form-control" id="inputCustomer">
                                    <option selected="">Auswählen *</option>
                                    <option value="true">Ja</option>
                                    <option value="false">Nein</option>
                                </select>
                            </div>
                        </fieldset>
                    </div>

                    <div className="col-6">
                        <fieldset class="form-group">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <label class="input-group-text" for="inputGreeting">Anrede</label>
                                </div>
                                <select class="form-control" id="greetingGroup">
                                    <option selected="">Auswählen *</option>
                                    <option value="herr">Herr</option>
                                    <option value="frau">Frau</option>
                                    <option value="dr">Dr.</option>
                                    <option value="other">Andern</option>
                                </select>
                            </div>
                        </fieldset>
                    </div>

                    <div className="col-6">
                        <fieldset class="form-group">
                            <input type="text" id="inputName" class="form-control square" placeholder="Name *" />
                        </fieldset>
                    </div>
                    <div className="col-6">
                        <fieldset class="form-group">
                            <input type="text" id="inputFirm" class="form-control square" placeholder="Firma *" />
                        </fieldset>
                    </div>
                    <div className="col-6">
                        <fieldset class="form-group">
                            <input type="text" id="inputAddress" class="form-control square" placeholder="Address *" />
                        </fieldset>
                    </div>
                    <div className="col-6">
                        <fieldset class="form-group">
                            <input type="email" id="inputEmail" class="form-control square" placeholder="E-Mail *" />
                        </fieldset>
                    </div>
                    <div className="col-6">
                        <fieldset class="form-label-group mb-0">
                            <textarea data-length=${questionLength} class="form-control char-textarea" id="inputQuestion" rows="5" placeholder="Haben Sie ein Anliegen? Zusätzliche Angaben (optional)" ></textarea>
                        </fieldset>
                        <small class="counter-value float-right"><span class="char-count" id="qLen">${questionLength}</span> </small>
                    </div>

                </form>
            </div>
        `
        let Swalfrm = Swal.fire({
            width: "60rem",
            title: `Kontakt`,
            allowOutsideClick: true,
            allowEscapeKey: true,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Send',
            cancelButtonText: 'Cancel',
            iconHtml: '<i class="fa-solid fa-2x fa-address-book"></i>',
            customClass: {
                htmlContainer: "contactFrm"
            },
            html: html,
            didOpen: () => {
                let q = Swal.getPopup().querySelector('#inputQuestion');
                q.addEventListener("keydown", () => {
                    Swal.getPopup().querySelector('#qLen').innerHTML = (questionLength - q.value.length)
                })
            },
            preConfirm: (inputValue) => {
                const howtohelp = Swal.getPopup().querySelector('#inputHowToHelp').value;
                const branch = Swal.getPopup().querySelector('#inputBranch').value;
                const customer = Swal.getPopup().querySelector('#inputCustomer').value;
                const greeting = Swal.getPopup().querySelector('#greetingGroup').value;
                const name = Swal.getPopup().querySelector('#inputName').value;
                const firm = Swal.getPopup().querySelector('#inputFirm').value;
                const address = Swal.getPopup().querySelector('#inputAddress').value;
                const email = Swal.getPopup().querySelector('#inputEmail').value;
                const question = Swal.getPopup().querySelector('#inputQuestion').value;
                const botCheck = Swal.getPopup().querySelector('#inputBotCheck').value.substring(0,questionLength);
                if(botCheck.length > 0){
                    return {};
                }
                if ( !howtohelp ||!branch ||!customer ||!greeting ||!name ||!firm ||!address ||!email ||!question ) {
                    Swal.showValidationMessage(`Bitte füllen Sie alle Teile dieses Formulars aus`)
                }
                return { howtohelp,branch,customer,greeting,name,firm,address,email,question }
            },
        })
            .then( async results => {
                if(results.isConfirmed && Object.values(results.value).length > 0){
                    let res = results.value
                    let message = `
                    <div>Interest: ${res.howtohelp}</div>
                    <div>BRANCH: ${res.branch}</div>
                    <div>KUNDEN: ${res.customer}</div>
                    <p>
                        <div>NAME: ${res.greeting} ${res.name}</div>
                        <div>FIRM: ${res.firm}</div>
                        <div>ADDRESS: ${res.address}</div>
                        <div>EMAIL: ${res.email}</div>
                    </p>
                    <p>
                        ${res.question}
                    </p>
                `;
                    axios.post(`${process.env.REACT_APP_API_OPENMETER}/mailer/contactmail`, {
                        receiver: "d.molline@molline.de",//"info@molline.de",
                        sender: res.email,
                        subject: `Kontakt-Anfrage Molline Connect`,
                        message
                    })
                        .then(results => {
                            console.log('RESULTS 198',results)
                            Swal.fire({
                                width: "6rem",
                                title: `Ihre Nachricht wurde gesendet`,
                                allowOutsideClick: true,
                                allowEscapeKey: true,
                                allowEnterKey: false,
                                showConfirmButton: true,
                                showCancelButton: false,
                                confirmButtonText: 'OK',
                                icon: "success",
                            });
                        })
                        .catch(err => {
                            console.log('ERROR 201',err)
                            Swal.fire({
                                width: "60rem",
                                title: `Ihre Nachcht konnte nicht gesendet werden`,
                                allowOutsideClick: true,
                                allowEscapeKey: true,
                                allowEnterKey: false,
                                showConfirmButton: true,
                                showCancelButton: false,
                                confirmButtonText: 'OK',
                                icon: "error",
                            });
                        })
                }
            })
    }



}

export default contactForm;
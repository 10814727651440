import React, {Fragment, useEffect, useContext } from 'react';
import {GatewaysContext} from "../../../../context/Devices/Gateways";


const ZombieModeWidget = (props) => {

    const { gateway, gatewayChanged } = useContext(GatewaysContext);


    useEffect(  () => {
            
    }, [gateway, gatewayChanged]);

    const UI = () => {
        if(gateway?.mode && gateway.mode.mode=== "zombie"){
            return (
                <div className="col-xl-2 col-md-4" id="widgetZombieMode">
                    <div className="card text-center " title="Out of all sensors this gateway can see, this many are registered.">
                        <div className="card-body align-self-center">
                            <div className="mx-auto my-1" style={{height: "75px", display: "inline-flex", alignItems: "center"}}>
                                <i className={`fa-solid fa-biohazard  fa-4x text-white`}></i>
                            </div>
                            <h2 className="mb-0 text-white line-ellipsis ">ZombieMode</h2>
                            <p className="mb-0 text-white"></p>
                        </div>
                    </div>
                </div>
            )
        }else{
            return (<Fragment></Fragment>)
        }

    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )

}

export default ZombieModeWidget;
import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import {AuthContext} from "../../../../context/Auth/AuthProvider";
import {GatewaysContext} from "../../../../context/Devices/Gateways";
import {OrganizationContext} from '../../../../context/Organization/Organization';

import { SocketContext } from '../../../../context/Socket/Sockets';
import OrgCookieCrumb from "../../../../components/Dashboard/orgCookieCrumb";
import Swal from '@molline/sweetalert2';
import {useRouter} from "../../../../components/Shared/Router/Router";
import uniqId from 'uniqid';
import Dropzone from 'react-dropzone';
import CsvParser from 'papaparse';
import {NavLink} from "react-router-dom";

const GatewayCreator = (props) => {

    const [updated, setUpdated] = useState(false);
    const {user} = useContext(AuthContext);
    const {organizations, organization} = useContext(OrganizationContext);
    const organizationsChanged = useCompare(organizations);

    const router = useRouter()
    const {gateways, getAllGateways, manufacturers, getManufacturers, registerGateway} = useContext(GatewaysContext);
    const [location, setLocation] = useState(localStorage.getItem('location'));
    const [gateway, setLocalGateway] = useState(localStorage.getItem('gateway'));
    const [tabActive, setTabActive] = useState('gateway-details-vertical')
    const { socket, socketId } = useContext(SocketContext);

    const DEFAULT_GATEWAY_OPTIONS =  {
        "serial_number": "",
        "uid": "",
        "location_id": "",
        "imei": "",
        "manufacturer_id": "622b5c3bf4da9f00410b22dc",
        "model": "Webdyn Easy",
        "iccid": "",
        "battery": true,
        "power_supply": false,
        "sim_id": "",
        "realm": (user.realm !== undefined)? user.realm: '',
        "organization_id": (location !== null && location.organization !== undefined)?location.organization: (user.organization_id !== undefined)? user.organization_id:'',
        "tags": [],
        "logs": [],
        "comments": [],
        "active": true,
        "sim_type": "vodafone",
        "description": ""
    }

    const [builder, setBuilder] = useState(DEFAULT_GATEWAY_OPTIONS)

    useEffect(() => {
        if(location !== null && typeof location === 'string'){
            let l = JSON.parse(location)
            setLocation(l)
        }
        const getManufacturersFunc = async () => {
            await getManufacturers();
        }
        getManufacturersFunc()
            .catch(err => {
                console.log('Gateway Creator getManufacturers error', err )
            })
        if(!updated){
            document.getElementById('csvTemplateDl').addEventListener('click', () => {
                Swal.fire({
                    icon: 'info',
                    title: 'Gateway.csv - Vorlage',
                    text: "Sie können die Vorlage mit ihrem favorisiertem Tabellenbearbeitungsprogramm öffnen sobald Sie diese heruntergeladen haben. Es ist ein Beispieldatensatz von WebDyn in dieser Datei enthalten, der nicht entfernt werden muss. Die Spaltenbezeichnungen und Reihenfolgen dürfen nicht verändert werden!"
                    //text: 'Once you accept the download of the gateway.csv file, open it in your favorite spreadsheet program. There is a sample Webdyn Gateway included. The header must remain in tact. Leave the first gateway for your reference and add all new gateways after it. It will be ignored.',
                })
            })
            setUpdated(true)
        }

        if(socket !== null ){
            socket.on('gateway', (data) => {
              switch(data.event){
                case 'gateway.created':
                    if(data.payload.io === socketId){
                        Swal.fire({
                            icon: 'info',
                            toast: true,
                            title: 'Gateway wurde erstellt',
                            html: `<p>Your gateway has been created with the following info:</p>` +
                            `<ul>`+
                            `<li>Production Zeitplan: ${data.payload.production_config}</li>` + 
                            `<li>Init Zeitplan: ${data.payload.init_config}</li>` + 
                            `</ul>`
                         })
                    }
                default: 
                    break;
              }
            })
        }

    }, [socket, socketId, tabActive, location, manufacturers, builder, organizationsChanged, organizations, organization]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const register = async(e = undefined) => {
        if(e) e.preventDefault()
        let optional = ['tags', 'location_id', 'logs', 'comments', 'organization_id', 'imei', 'uid', 'iccid', 'sim_id', 'description']
        let err = [];
        Object.keys(builder).forEach( (k) => {
            if(builder[k] === '' && !optional.includes(k)) err.push(`${k}<br/>`)
        })
        err = err && err.length>0?err.join(" "):[];
        if(err.length > 0){
            Swal.fire({
                icon: 'error',
                title: 'Unvöllständig',
                html: '<strong>Fehlende Pflichtfelder:</strong><br/><br/><div style="text-align:left; width:50%; margin-left:25%;">'+err+'</div>',
                okButtonText:"Alles klar"
            })
            return;
        }

        let log = {
            created_at: new Date(),
            author: user._id,
            severity: "info",
            entry: `User ${user._id}: Initial creation`
        }
        let gateway = {...builder}
        gateway.logs.push(log)

        registerGateway(builder)
            .then( res => {
                Swal.fire({
                    icon: 'success',
                    title: 'Erfolg',
                    text: `Gateway ${res._id} wurde erstellt. Eine Standard-Konfiguration wurde bereits an das GAteway gesendet. Sie können diese natürlich noch bearbeiten.`,
                })
            })
            .catch(err => {
                Swal.fire({
                    icon: 'error',
                    title: 'Server-Fehler',
                    footer: err,
                })
            })
    }

    const updateBuilder = () => {
        let form = document.getElementById('gateway-registration');
        let formData = new FormData(form)
        let runner = {...builder}
        for (var entry of formData.entries()) {
            if(entry[0] === 'power'){
                if(entry[1] === 'battery'){runner.battery = true; runner.power = false}
                if(entry[1] === 'power_supply'){runner.battery = false; runner.power = true}
            }else if(entry[0] === 'tags'){
                runner.tags = entry[1].split(',')
            }else{
                if(builder[entry[0]] !== undefined) runner[entry[0]] = entry[1]
            }
        }
        runner.realm = user.realm;
        setBuilder(runner)
    }

    const startOver = (e) => {
        console.log('STARTING OVER')
        e.preventDefault();
        localStorage.removeItem('gateway')
        setBuilder(DEFAULT_GATEWAY_OPTIONS)
        document.getElementById("gateway-registration").reset();
    }

    const upload = (acceptedFiles) => {
        let err = [];
        const accepted = ['text/csv']
        if(acceptedFiles.length > 1){
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Nur eine Datei bitte',
                footer: err
            })
            return;
        }
        if(!accepted.includes(acceptedFiles[0].type)){
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Bitte nur CSV-Dateien',
                footer: err
            })
            return;
        }
        CsvParser.parse(acceptedFiles[0] , {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                let registrationResults = [];
                for(let i = 1; i < results.data.length; i ++){
                    let gateway = results.data[i]
                    gateway.logs = []
                    let tags = []
                    let log = {
                        created_at: new Date(),
                        author: user._id,
                        severity: "info",
                        entry: `User ${user._id}: Initial creation`
                    }
                    gateway.logs.push(log)
                    if(!!!gateway.organization){
                        gateway.organization = user.organization_id
                    }
                    gateway.realm = user.realm;
                    tags = gateway.tags.split(',')
                    gateway.tags = tags
                    registerGateway(gateway)
                        .then( res => {
                            console.log(res)
                            registrationResults.push(res)
                            console.log(i, results.data.length)
                            if(i === results.data.length - 1){
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Erfolg !',
                                    text: `${registrationResults.length} neue Gateways wurden registriert. Für alle Gateways steht eine Production-Konfiguration bereit.`,
                                })
                            }
                        })
                        .catch(err => {
                            console.log('ERROR 215', err)
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: err,
                            })
                        })
                }

            },
        })


    }

    const UI  = () => {
        return (
            <Fragment>

                <div className="content-header row">
                    <div className="content-header-left col-md-9 col-12 mb-2">
                        <div className="row breadcrumbs-top">
                            <div className="col-12">
                                <h2 className="content-header-title float-start mb-0">Gateways Registrieren</h2>
                                <div className="breadcrumb-wrapper">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a >Apps</a>
                                        </li>
                                        <li className="breadcrumb-item"><NavLink to="/apps/gateways"><a >Gateways</a></NavLink>
                                        </li>
                                        <li className="breadcrumb-item active">Gateways Registrieren
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <OrgCookieCrumb />
                </div>
                <div className="content-body">
                    <section className="vertical-wizard">
                        <div className="bs-stepper vertical vertical-wizard-example">
                            <div className="bs-stepper-header">

                                <div className={`step ${(tabActive === 'gateway-details-vertical')? 'active':''}`} data-target="#gateway-details-vertical" role="tab" id="gateway-details-vertical-trigger" onClick={() => setTabActive('gateway-details-vertical')}>
                                    <button type="button" className="step-trigger">
                                        <span className="bs-stepper-box">1</span>
                                        <span className="bs-stepper-label">
                                            <span className="bs-stepper-title">Einzelregistierung</span>
                                            <span className="bs-stepper-subtitle">via Detaileingabe</span>
                                        </span>
                                    </button>
                                </div>
                                <div className={`hidden step ${(tabActive === 'import-info-vertical')? 'active':''}`} data-target="#import-info-vertical" role="tab" id="import-info-vertical-trigger" onClick={() => setTabActive('import-info-vertical')}>
                                    <button type="button" className="step-trigger">
                                        <span className="bs-stepper-box">2</span>
                                        <span className="bs-stepper-label">
                                            <span className="bs-stepper-title">Batch-Import</span>
                                            <span className="bs-stepper-subtitle">via CSV-Liste</span>
                                        </span>
                                    </button>
                                </div>

                            </div>
                            <div className="bs-stepper-content">
                                <form id="gateway-registration">
                                    <div id="gateway-details-vertical" className={`content ${(tabActive === 'gateway-details-vertical')? 'active':''}`} role="tabpanel" aria-labelledby="gateway-details-vertical-trigger">
                                        <div className="content-header">
                                            <h5 className="mb-0">Gateway-Registierung</h5>
                                            <small className="text-muted">Bitte die Details Unten eingeben - Sternchen bedeuten Pflichtfelder</small>
                                        </div>
                                        <div className="row">
                                            <div className="mb-1 col-md-6">
                                                <label className="form-label" for="serial_number">Seriennummer*</label>
                                                <input type="text" name="serial_number" className="form-control" defaultValue={builder.serial_number} onChange={ (e) => updateBuilder(e) }/>
                                            </div>
                                            <div className="mb-1 col-md-6">
                                                <label className="form-label" for="vertical-uid">UID*</label>
                                                <input type="email" name="uid" className="form-control" placeholder="" aria-label="uid" defaultValue={builder.uid} onChange={ (e) => updateBuilder(e) }/>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="mb-1 col-md-6">
                                                <label className="form-label" for="serial_number">SIM Card*</label>
                                                <select className="select2 form-select" name="sim_type" id="select2-basic" defaultValue={"vodafone"} onChange={ (e) => updateBuilder(e) }>
                                                    <option value={"vodafone"}>Vodafone</option>
                                                    <option value={"1nce"}>1nce</option>
                                                </select>
                                            </div>
                                            <div className="mb-1 col-md-6">

                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="mb-1 col-md-6">
                                                <label className="form-label" for="manufacturer_id">Hersteller*</label>
                                                <select className="form-control " name="manufacturer_id" value={builder.manufacturer_id} onChange={ (e) => updateBuilder(e) } >
                                                    {
                                                        manufacturers.map( man => {
                                                            return (
                                                                <option key={uniqId()} value={man._id}>{`${man.name} (${man.short_name})`}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="mb-1 col-md-6">
                                                <label className="form-label" for="model">Modell*</label>
                                                <input type="text" name="model" className="form-control" placeholder="" aria-label="model" defaultValue={builder.model} onChange={ (e) => updateBuilder(e) } readOnly/>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="mb-1 col-md-6">
                                                <label className="form-label" for="organization">Organisation*</label>
                                                <select className="select2 form-select" name="organization_id" id="select2-basic" onChange={ (e) => updateBuilder(e) }>
                                                    <option value={""}>Bitte auswählen</option>
                                                    {
                                                        organizations.map(org => {
                                                            return (
                                                                <Fragment key={`org-${org._id}`}><option value={org._id} selected={organization._id === org._id}>{org.name}</option></Fragment>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="mb-1 col-md-6">
                                                <label className="form-label" for="description">Beschreibung</label>
                                                <input type="text" name="description" className="form-control" defaultValue={builder.description} onChange={ (e) => updateBuilder(e) }/>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="mb-1 col-md-6">
                                                <label className="form-label" for="tags">Schlagwörter</label>
                                                <input type="text" name="tags" className="form-control" defaultValue={builder.tags} onChange={ (e) => updateBuilder(e) }/>
                                                <small className="text-muted">Schlagwörter müssen kommasepariert sein</small>
                                            </div>
                                        </div>


                                        <div className="d-flex justify-content-between mt-2">
                                            <button className="btn btn-outline-primary" onClick={(e) => {startOver(e)}}>
                                                <span className="align-middle d-sm-inline-block d-none">Zurücksetzen</span>
                                                <i className="fas fa-undo align-middle ms-sm-50 ms-mt-2 ms-0"></i>
                                            </button>
                                            <button className="btn btn-primary" type="submit" data-tabid={'gateway-details-vertical'} onClick={(e) => {register(e)}}>
                                                <span className="align-middle d-sm-inline-block d-none">Registieren</span>
                                                <i className="fa-solid fa-arrow-right align-middle ms-sm-50 ms-mt-2 ms-0"></i>
                                            </button>
                                        </div>



                                    </div>
                                </form>

                                <div id="import-info-vertical" className={`content ${(tabActive === 'import-info-vertical')? 'active':''}`} role="tabpanel" aria-labelledby="import-info-vertical-trigger">
                                    <div className="content-header">
                                        <h5 className="mb-0">Batch-Import von mehreren Gateways</h5>
                                        <small className="text-muted">Eine komplette Liste von Gateways via CSV hochladen</small>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h4 className="card-title">CSV-Upload</h4> <a id="csvTemplateDl" className="btn btn-primary" href="/files/gateway.csv" target="_blank" download>'Gateway.csv' - Vorlage herunterladen</a>
                                                </div>
                                                <div className="card-body">
                                                    <p className="card-text">
                                                        Unten eine Datei ablegen oder selektieren. Die Spalten müssen dabei mit der Vorlage exakt identisch sein (Reihenfolge, Name, Anzahl der Spalten). 
                                                        Schlagwörter / Tags sind via Komma zu separieren. Alle neuen Gateways werden automatisch Ihrer Domäne (Realm) und ihrer Organisation zugewiesen, 
                                                        sofern sie keine spezifische Nutzer-/Mieteinheit oder Organisation angegeben haben.<br/>
                                                        <br/>
                                                        Nach dem Import können Sie Ihre Gateways in der <strong className="text-primary"><NavLink to="/apps/gateways">Gateway-Verwaltung</NavLink></strong> editieren.
                                                    </p>
                                                    <div className="dropzone dropzone-area">
                                                        <Dropzone onDrop={acceptedFiles => upload(acceptedFiles)}>
                                                            {({getRootProps, getInputProps}) => (
                                                                <section>
                                                                    <div {...getRootProps()}>
                                                                        <input {...getInputProps()} />
                                                                        <p className="dz-message">CSV hier ablegen oder klicken um eine Datei auszuwählen</p>
                                                                    </div>
                                                                </section>
                                                            )}
                                                        </Dropzone>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Fragment>
        )



    }

    return (
        <Fragment>
           

            {UI()}
        </Fragment>
    )
  

}


GatewayCreator.requiredScope = ['gateway.create.any', 'gateway.create.own']
GatewayCreator.requireAuth = true;  // true -> force login via AuthContext, false -> no login required so no user available either
export default GatewayCreator;
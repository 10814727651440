const lang = {
    currency: {
        symbol: "€",
        label: "EUR"
    },
    lang: {
        language: "Sprache",
        english: "Englisch",
        german: "Deutsch"
    },
    user: {
        profile: "Profil",
        logout: "Ausloggen",
        login: "Einloggen",
        token: "Zugangschlüssel",
        orga: "Organisation"
    },
    mainmenu: {
        dashboards: "Übersicht",
        analytics: "Statistiken",
        apps: "Apps",
        locationManager: "Nutzereinheiten",
        gatewayManager: "Gateways",
        sensorManager: "Zähler",
        organizationManager: 'Organisationen',
        dataManager: "Daten-Transfer",
        filesManager: "Dateien",
        administration: "Verwaltung",
        users: "Benutzer",
        usersList: "Benutzerliste",
        permissions: "Berechtigungen",
        clients: "API-Zugangsverwaltung",
        faq: "FAQ"
    },
    unsecure: {
        PageTitle : "Mein unsicherer Titel"
    },
    dashboard: {
        profileTitle : "Deutsch"
    }
}

module.exports = lang;

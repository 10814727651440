import React, {Fragment, useState, useEffect, useContext} from 'react';
import { AuthContext } from '../../../context/Auth/AuthProvider';
import { LocaleContext } from '../../../context/Locale/Locale';
import { WhiteLabelContext } from '../../../context/WhiteLabels/WhiteLabels';

import { useRouter } from '../../Shared/Router/Router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import MainMenu from "./MainMenu";
import UserMenu from './userMenu';
import {faEllipsis} from "@fortawesome/pro-regular-svg-icons";

const AppHeader = React.memo(({children}) => {

    const router = useRouter()
    const [updated, setUpdated] = useState(false);
    const { user, logout, login, setRedirect, organization, setOrganization} = useContext(AuthContext)
    const {t, locale, localeAvailable, setLang} = useContext(LocaleContext);
    const {config} = useContext(WhiteLabelContext);
    const [logo, setLogo] = useState(process.env.NEXT_PUBLIC_APP_LOGO);
    const [logoAlt, setLogoAlt] = useState(process.env.NEXT_PUBLIC_APP_NAME);

    useEffect(() => { 
        
        if(config !== null){
            //setLogo(`/logos/${config.logo}`)
            //setLogoAlt(config.firm_name)
        }
 
        if(!updated){
            setRedirect(router.route)
            setUpdated(true)
        }
        
    }, [locale, user, config, logo, logoAlt]);

    const UI = () => {
        return (
            <Fragment>

                {/* BEGIN: Header */}
                <nav className="header-navbar navbar-expand-lg navbar navbar-fixed align-items-center navbar-shadow fixed_nav navbar-brand-center navbar-light" data-nav="brand-center">
                    {/*<div className="navbar-header">
                        <ul className="nav navbar-nav flex-row">
                            <li className="nav-item nav-toggle">
                                <a className="nav-link modern-nav-toggle pe-0" data-bs-toggle="collapse" data-bs-target=".header-navbar">
                                    <i className="d-block d-xl-none text-primary toggle-icon font-medium-4" ></i>
                                </a>
                            </li>
                        </ul>
                    </div>*/}
                        <MainMenu />

                    <div className="navbar-header d-xl-block d-none">
                        <ul className="nav navbar-nav">
                            <li className="nav-item">
                                <a  href="/dashboards/analytics">
                                    <span className="brand-logo"><img src="/molline_logo.png" height="28" width="112" priority={true}/></span>
                                    <h2 className="brand-text mb-0"></h2>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="navbar-container d-flex content">
                        <div className="bookmark-wrapper d-flex align-items-center">
                            <ul className="nav navbar-nav d-xl-none">
                                <li className="nav-item">
                                    <button className="navbar-toggler " type="button" data-bs-toggle="collapse" data-bs-target="#mainmenu">
                                        <FontAwesomeIcon icon={faEllipsis} className="text-black" />
                                    </button>
                                </li>
                                {/* small screen molline logo */}
                                <li className="nav-item logo_alignment">
                                    <a  href="/dashboards/analytics">
                                        <span className="brand-logo"><img src="/molline_logo.png" height="28" width="112" priority={true}/></span>
                                        <h2 className="brand-text mb-0"></h2>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <UserMenu user={user}></UserMenu>
                    </div>
                </nav>

                {/* END: Header */}


            </Fragment>
        )
        
        
    }

    return (
        <Fragment>

            {UI()}
           
        </Fragment>
    )
})

export default AppHeader;
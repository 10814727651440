import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {AuthContext} from "../../../context/Auth/AuthProvider";
import {GroupsContext} from "../../../context/Groups/Groups";
import {MgmtUsersContext} from "../../../context/Users/UsersContext";

import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';
import {useRouter} from "../../Shared/Router/Router";

import uniqId from 'uniqid';

import de from  '../tables/datatable.de.json';
import CapitalizeUtils from "../../../utils/capitalizeUtils"
import {hasPermission} from "../reactPermissionsWrapper";
import {OrganizationContext} from "../../../context/Organization/Organization";

const $ = require('jquery');
$.DataTable = require('datatables.net');


const UserGroupDetails = (props) => {
    const router = useRouter()

    const { mgmtUsers } = useContext(MgmtUsersContext)
    const { user } = useContext(AuthContext);
    const {mollineOrganization} = useContext(OrganizationContext)
    const [groupUsers, setGroupUsers] = useState([])

    const propsChanged = useCompare(props)
    const usersChanged = useCompare(mgmtUsers)
    const groupUsersChanged = useCompare(groupUsers)


    useEffect(() => {

        if (propsChanged || usersChanged) {
            parseGroupUsers()
        }

        if (groupUsersChanged) initializeGroupUsersDt()

    }, [propsChanged, usersChanged, groupUsersChanged])

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const parseGroupUsers = () => {
        if (mgmtUsers.length !== 0 && props.group !== undefined && props.group !== null) {
            if (props.group.users.length === 0) return setGroupUsers([])
            const myGroupUsers = []
            mgmtUsers.forEach(user => {
                if (props.group.users.includes(user._id)) myGroupUsers.push(user)
            });
            setGroupUsers(myGroupUsers)
        }
    }

    const refreshDataTables = () => {
        $('#tblGroupUsers').DataTable().destroy();
        $('#tblGroupUsers').off('click', 'button.btnOverflowMenu')
        $('#tblGroupUsers').off('click', 'li.destroyTarget')
        initializeGroupUsersDt();
    }



    const initializeGroupUsersDt = () => {
        if ($.fn.dataTable.isDataTable('#tblGroupUsers')) {
            refreshDataTables()
        } else {
            $('#tblGroupUsers').DataTable({
            "id": '#tblGroupUsers',
            "language": de,
            "data": groupUsers,
            "columns": [
                { "data": "_id",
                   visible: hasPermission({ roles: ["realm_admin", "realm_manager"], user: user}) ? true : false
                },
                {
                    "data": "organization", render: function (data, type, row) {
                        return CapitalizeUtils.capitalize(data.name);
                    }
                },
                {
                    "data": "first_name", render: function (value, type, row) {
                        return CapitalizeUtils.capitalize(value);
                    }
                },
                {
                    "data": "last_name", render: function (value, type, row) {
                        return CapitalizeUtils.capitalize(value);
                    }
                },
                { "data": "emails[0].email" },
                {
                    "data": null,
                    "searchable": false,
                    "ordering": false,
                    "className": "text-end",
                    render: function (data, type, row) {
                        return `<div class="dropdown b-dropdown btn-group" id="${data._id}">
                            <button aria-haspopup="true" type="button" data-bs-toggle="dropdown"  class="btnOverflowMenu btn btn-sm dropdown-toggle btn-secondary dropdown-toggle-split" >
                                <i class="fa-solid fa-bars"></i>
                            </button>
                            <ul role="menu" tabindex="-1" class="dropdown-menu dropdown-menu-right" >
                                <li role="presentation" class="viewTarget">
                                    <a role="menuitem" href="#" target="_self" class="dropdown-item"><i class="text-secondary fa-solid fa-magnifying-glass me-50"></i> Anzeigen</a>
                                </li>
                                <li role="presentation">
                                    <hr role="separator" aria-orientation="horizontal" class="dropdown-divider">
                                </li>
                                <li role="presentation" class="destroyTarget">
                                    <a role="menuitem" id="${data._id}" href="#" target="_self" class="dropdown-item"><i class="text-secondary fa-solid fa-trash-can me-50"></i> Entfernen</a>
                                </li>
                            </ul>
                        </div>`
                    }
                }
            ],
            createdRow: function (row, data, dataIndex) {},
            "fnDrawCallback": function( oSettings ) {
                $('#tblGroupUsers tbody').on('click', 'li.viewTarget', function (e) {
                    e.preventDefault()
                    let link = e.currentTarget;
                    let parent = link.closest('div');
                    router.push(`/details/user/${parent.id}`);
                });
        
                $('#tblGroupUsers').on('click', 'li.destroyTarget', async function (e) {
                    e.preventDefault()
                    let button = e.target
                    let subject_id = button.id;
                    await props.removeUser(subject_id)
        
                });
            }
        });

        
    }

    }

    return (
        <Fragment>
            <div className='table-responsive'>
                <table id="tblGroupUsers" className="table mb-0 row-border hover order-column w-100" role="grid">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th >Kunde</th>
                            <th >Vorname</th>
                            <th >Nachname</th>
                            <th >Email</th>
                            <th></th>
                        </tr>
                    </thead>
                </table>
            </div>
        </Fragment>
    )
}

export default UserGroupDetails;
import React, {Fragment, useContext, useEffect, useState} from 'react';
import {AuthContext} from "../../../../context/Auth/AuthProvider";
import {MgmtUsersContext} from "../../../../context/Users/UsersContext";
import {LocationsContext} from "../../../../context/Locations/Locations";
import OrgCookieCrumb from "../../../../components/Dashboard/orgCookieCrumb";
import de from '../../../../components/Dashboard/tables/datatable.de.json';
import Swal from '@molline/sweetalert2'
import {NavLink} from "react-router-dom";
import {useRouter} from "../../../../components/Shared/Router/Router";
import {OrganizationContext} from '../../../../context/Organization/Organization';


const LocationCreator = (props) => {

    const [updated, setUpdated] = useState(false);
    const {user} = useContext(AuthContext);
    const {location, locations, getAllLocations, locationGroups, createLocation, setLocationGroups, getGroups} = useContext(LocationsContext);
    const [tabActive, setTabActive] = useState('location-create-vertical')
    const { mgmtUsers } = useContext(MgmtUsersContext);
    const { organization, organizations } = useContext(OrganizationContext);

    const router = useRouter()

    const initialForm = {
        name: "",
        street: "",
        house_number: "",
        apartment_number: "",
        apartment_number_two: "",
        apartment_number_three: "",
        postal_code:"" ,
        city: "",
        state: "",
        country: 'de',
        owner: (localStorage.getItem('organization') !== null)? JSON.parse(localStorage.getItem('organization')).name: '',
        realm: user.realm,
        organization_id: (localStorage.getItem('organization') !== null)? JSON.parse(localStorage.getItem('organization'))._id: '',
        active: true,
        type : 'single_premise'

    }
    const [formValues, setFormValues] = useState(initialForm)

    useEffect(() => {


        if(!updated) {
            setUpdated(true)
        }

    }, []);

    const updateForm = (key, value) => {
        setFormValues({...formValues, [key]: value})
    }

    const resetForm = () => {
        setFormValues(initialForm)
       // document.getElementById('org-create-frm').reset()
    }

    const errorToast = (err) => {
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: err,
            showConfirmButton: false,
            customClass: {
                popup: "bg-danger"
            }
        })
    }

    const doYouReallyWantToSave = () => {
        return new Promise(async (resolve) => {
            if (window.sessionStorage && !window.sessionStorage.getItem("yeswecan")) {
                const answer = await Swal.fire({
                    title: 'Sicher?',
                    input: 'checkbox',
                    text: 'Damit werden die Daten gespeichert',
                    inputPlaceholder: 'Nicht mehr fragen',
                    showCancelButton: true,
                    confirmButtonText: 'Ja',
                    cancelButtonText: "Nein",
                })
                if (!answer.isConfirmed)
                    return resolve(false);
                if (answer.value === 1) {
                    window.sessionStorage.setItem("yeswecan", true);
                    return resolve(true);
                }
            }
            return resolve(true);
        })
    }

    const createNewLocation = async (e= undefined) => {
        if(e) e.preventDefault()
        let optional = [ 'name', 'apartment_number', 'apartment_number_two', 'apartment_number_two', 'apartment_number_three', 'type', 'owner']
        let err = [];
        Object.keys(formValues).forEach( (k) => {
            if(formValues[k] === '' && !optional.includes(k)) err.push(`${k}<br/>`)
        })
        err = err && err.length>0?err.join(" "):[];
        if(err.length > 0){
            Swal.fire({
                icon: 'error',
                title: 'Unvöllständig',
                html: '<strong>Fehlende Pflichtfelder:</strong><br/><br/><div style="text-align:left; width:50%; margin-left:25%;">'+err+'</div>',
                okButtonText:"Alles klar"
            })
            return;
        }

        const doSave = await doYouReallyWantToSave()
        if (!doSave) {
            return
        }
        try {
            await createLocation(formValues)
            Swal.fire('Nutzer-/Mieteinheit wurde erstellt!', '', 'success')
            resetForm()
            router.push('apps/locations')
        }catch(e) {
            if(e?.response?.status === 400) {
                Swal.fire('Bitte füllen Sie alle mit * gekennzeichneten Felder aus!', '', 'error')
                return
            }
            console.log('error at creating organization', e);
            errorToast(e)
        }
    }

    const UI  = () => {

        return (
            <Fragment>

                <div className="content-header row">
                    <div className="content-header-left col-md-9 col-12 mb-2">
                        <div className="row breadcrumbs-top">
                            <div className="col-12">
                                <h2 className="content-header-title float-start mb-0">Nutzer-/Mieteinheit Registierung</h2>
                                <div className="breadcrumb-wrapper">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a >Apps</a>
                                        </li>
                                        <li className="breadcrumb-item"><NavLink to="/apps/locations"><a >Nutzereinheiten</a></NavLink>
                                        </li>
                                        <li className="breadcrumb-item active">Nutzer-Mieteinheiten  Registrieren
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <OrgCookieCrumb />
                </div>
                <div className="content-body">
                    <section className="vertical-wizard">
                        <div className="bs-stepper vertical vertical-wizard-example">
                            <div className="bs-stepper-header">

                                <div className={`step ${(tabActive === 'location-create-vertical')? 'active':''}`} data-target="#location-create-vertical" role="tab" id="location-create-vertical-trigger" onClick={() => setTabActive('location-details-vertical')}>
                                    <button type="button" className="step-trigger">
                                        <span className="bs-stepper-box">1</span>
                                        <span className="bs-stepper-label">
                                            <span className="bs-stepper-title">Einzelregistierung</span>
                                            <span className="bs-stepper-subtitle"></span>
                                        </span>
                                    </button>
                                </div>

                            </div>
                            <div className="bs-stepper-content">
                                <form id="location-registration">
                                    <div id="location-create-vertical" className={`content ${(tabActive === 'location-create-vertical')? 'active':''}`} role="tabpanel" aria-labelledby="location-create-vertical-trigger">
                                        <div className="content-header">
                                            <h5 className="mb-0">Nutzer-/Mieteinheit-Registierung</h5>
                                            <small className="text-muted">Bitte die Details Unten eingeben - Sternchen bedeuten Pflichtfelder</small>
                                        </div>

                                        <div className="row">
                                            <div className="mb-1 col-md-6">
                                                <label className="form-label">Name</label>
                                                <input type="text" className="form-control" defaultValue={formValues.name} required onChange={(e) => updateForm('name', e.target.value) }/>
                                            </div>
                                            <div className="mb-1 col-md-6">
                                                <label className="form-label">Straße*</label>
                                                <input type="text"  className="form-control" defaultValue={formValues.street} required onChange={(e) => updateForm('street', e.target.value) }/>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="mb-1 col-md-6">
                                                <label className="form-label">Hausnummer*</label>
                                                <input type="text" className="form-control" defaultValue={formValues.house_number} required onChange={(e) => updateForm('house_number', e.target.value) }/>
                                            </div>
                                            <div className="mb-1 col-md-6">
                                                <label className="form-label">Addresszusatz 1 (Appartment)</label>
                                                <input type="text" className="form-control" defaultValue={formValues.apartment_number} required onChange={(e) => updateForm('apartment_number', e.target.value) }/>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="mb-1 col-md-6">
                                                <label className="form-label">Addresszusatz 2</label>
                                                <input type="text" className="form-control" defaultValue={formValues.apartment_number_two} required onChange={(e) => updateForm('apartment_number_two', e.target.value) }/>
                                            </div>

                                            <div className="mb-1 col-md-6">
                                                <label className="form-label">Addresszusatz 3</label>
                                                <input type="text" className="form-control" defaultValue={formValues.apartment_number_three} required onChange={(e) => updateForm('apartment_number_three', e.target.value) }/>
                                            </div>


                                        </div>

                                        <div className="row">
                                            <div className="mb-1 col-md-6">
                                                <label className="form-label">Postleizahl*</label>
                                                <input type="number" className="form-control" defaultValue={formValues.postal_code} required onChange={(e) => updateForm('postal_code', e.target.value) }/>
                                            </div>
                                            <div className="mb-1 col-md-6">
                                                <label className="form-label">Stadt*</label>
                                                <input type="text" className="form-control" defaultValue={formValues.city} required onChange={(e) => updateForm('city', e.target.value) }/>
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="mb-1 col-md-6">
                                                <label className="form-label">Bundesland*</label>
                                                <input type="text" className="form-control" defaultValue={formValues.state} required onChange={(e) => updateForm('state', e.target.value) }/>
                                            </div>
                                            <div className="mb-1 col-md-6">
                                                <label className="form-label">Land*</label>
                                                <input type="text" className="form-control" defaultValue={formValues.country} required onChange={(e) => updateForm('country', e.target.value) }/>
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="mb-1 col-md-6">
                                                <label className="form-label">Active*</label>
                                                <select className="select2 form-select" name="active" id="location_active"  defaultValue={formValues.active}   onChange={(e) => updateForm('active', e.target.value) }>
                                                    <option value={"true"}>True</option>
                                                    <option value={"false"}>False</option>
                                                </select>
                                            </div>
                                            <div className="mb-1 col-md-6">
                                                <label className="form-label">Type</label>
                                                <select className="select2 form-select" name="type" id="location_type"   defaultValue={formValues.type} onChange={(e) => updateForm('type', e.target.value) }>
                                                    <option value={"single_premise"}>single Premise</option>
                                                    <option value={"multiple_premises"}>Multiple Premises</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="mb-1 col-md-6">
                                                <label className="form-label">Organisation</label>
                                                <select className="select2 form-select"
                                                        name="organization_id"
                                                        id="overviewOrganization"
                                                        onChange={(e) => updateForm('organization_id', e.target.value)}
                                                        disabled={!user || !user.roles || (!user.roles.includes("god"))}>
                                                    {
                                                        organizations.map(org => {
                                                            return (
                                                                <option key={org._id} value={org._id}
                                                                        selected={formValues && formValues.organization_id && formValues.organization_id === org._id}>{org.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>

                                            </div>
                                        </div>


                                        <div className="d-flex justify-content-between mt-2">
                                            <button className="btn btn-outline-primary" onClick={() => {resetForm()}}>
                                                <span className="align-middle d-sm-inline-block d-none">Zurücksetzen</span>
                                                <i className="fas fa-undo align-middle ms-sm-50 ms-mt-2 ms-0"></i>
                                            </button>
                                            <button className="btn btn-primary" disabled={true} type="submit" data-tabid={'location-details-vertical'} onClick={(e) => {createNewLocation(e)}}>
                                                <span className="align-middle d-sm-inline-block d-none">Registieren</span>
                                                <i className="fa-solid fa-arrow-right align-middle ms-sm-50 ms-mt-2 ms-0"></i>
                                            </button>
                                        </div>


                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                </div>
            </Fragment>

        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )

}

LocationCreator.requiredScope = ['location.create.any', 'location.create.own'];
LocationCreator.requireAuth = true;
export default LocationCreator;
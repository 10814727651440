import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import OrgCookieCrumb from "../../../../components/Dashboard/orgCookieCrumb";
import TourSteps from "../../../../TourSteps.json";
import PageTour from "../../../../components/Dashboard/tour/tour";
import {SensorsContext} from "../../../../context/Devices/Sensors";
import {GatewaysContext} from "../../../../context/Devices/Gateways";
import {LocationsContext} from "../../../../context/Locations/Locations";
import SearchLocationsTable from "../../../../components/Dashboard/tables/dataTables/tblSearchLocations";
import {OrganizationContext} from "../../../../context/Organization/Organization";

let searchType =  null;

const SearchPage = () => {

    const { sensor, getSensor, setSensor } = useContext(SensorsContext);
    const { getGateway, gateway, setGateway } = useContext(GatewaysContext);
    const { setSearchLocations, searchLocations, searchLocationsByAddress, searchLocationsChanged} = useContext(LocationsContext);
    const {organizations, organization} = useContext(OrganizationContext);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedType, setSelectedType] = useState('Nutzereinheiten');

    useEffect(() => {

    }, [searchLocationsChanged])


    const searchHandler = (event) => {

        if(searchQuery.length > 0) {

            switch (selectedType) {
                case 'Nutzereinheiten': {
                    searchType = selectedType;

                    setSearchLocations([])
                    searchLocationsByAddress(searchQuery);
                    setSensor({})
                    setGateway({})
                    break;
                }
                case 'Gateways': {
                    searchType = selectedType;

                    getGateway(searchQuery)
                    setSearchLocations([])
                    setSensor({})

                    break;
                }
                case 'Zähler': {
                    searchType = selectedType;

                    getSensor(searchQuery)
                    setSearchLocations([])
                    setGateway({})

                    break;
                }
                default: {
                }
            }
        }
    };

    const typeChangeHandler = (option) => {
        setSelectedType(option);
    };

    const returnOrganisationName = (orgID) => {
        if(orgID !== null || orgID !== undefined) {
            const organisation =  organizations.filter(org => org._id === orgID);
            return organisation[0]?.name;
        }
    }

    const UI = () => {
        return (
            <Fragment>

                <div className="content-header row">
                    <div className="content-header-left col-md-9 col-12 mb-2">
                        <div className="row breadcrumbs-top">
                            <div className="col-12">
                                <h2 className="content-header-title float-start mb-0">Suchfunktion</h2>
                                <div className="breadcrumb-wrapper">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a >Tools</a>
                                        </li>
                                        <li className="breadcrumb-item active"> Suchfunktion
                                        </li>
                                        <li>
                                            <span className="tourBorderRight" />
                                            <PageTour steps={TourSteps.bson_converter.overview}  isButton={false} />
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <OrgCookieCrumb />
                </div>
                <div className="content-body">
                    <section id="tabpanels">
                        <div className="row">
                            <div className="col-12">
                                <div className="tab-content pt-1">
                                    <div className="card" id="searchPageCard">
                                        <div className="card-header border-bottom">
                                            <h4 className="card-title">Suchfunktion (Nutzer-/Mieteinheiten,Gateways,Zähler ) </h4>
                                            <div className="heading-elements">
                                                <div className="heading-elements">
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body py-2 my-25" id="searchPage">
                                            <p>
                                                Sie können dieses Such-Tool verwenden, um nach Nutzer-/Mieteinheiten, Gateways  und Zähler zu suchen.
                                                Die Nutzer-/Mieteinheiten können über folgenden Optionen durchgeführt werden: Straße, Postleizahl oder Stadt.
                                                Die Suche nach Zählern und Gateways erfolgt über Seriennummer bzw. der Seriennummer/UID.
                                            </p>
                                            <br />
                                            <div className="searchInput">
                                                <input type="search"   className="form-control help-video-searchbar" placeholder="Straße,PLZ,Stadt,SERIENNUMMER,UID"

                                                       onKeyDown={(event) => event.key === "Enter" ? searchHandler(event) : ''}
                                                       value={searchQuery}
                                                       onChange={(e) => setSearchQuery(e.target.value)}
                                                />
                                                <div className="btn-group">
                                                    <div className="btn-group">
                                                        <button type="button" className="btn btn-outline-primary dropdown-toggle optionBtn"
                                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                            {selectedType}
                                                        </button>
                                                        <div className="dropdown-menu">
                                                            <a className="dropdown-item"  onClick={() => typeChangeHandler('Nutzereinheiten')}>Nutzereinheiten</a>
                                                            <a className="dropdown-item" onClick={() => typeChangeHandler('Gateways')} >Gateways</a>
                                                            <a className="dropdown-item"  onClick={() => typeChangeHandler('Zähler')} >Zähler</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button className="btn btn-primary searchBtn"
                                                        onClick={(e) => searchHandler(e)}>
                                                    <i className="fas fa-search"></i>
                                                </button>
                                            </div>
                                                {searchType === "Nutzereinheiten" && searchLocations.length > 0 ?
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="card">
                                                                <div className="card-body py-2 my-25">

                                                                    <div className="card-body py-2 my-25">
                                                                        <SearchLocationsTable
                                                                            id="tblSearchLocations"
                                                                        />
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                    :
                                                    <div></div>

                                                }

                                                {searchType === 'Gateways' &&  gateway !== null && typeof gateway === 'object'  ?

                                                    <div className="table-responsive">
                                                        <table className="table mb-0 mt-5">
                                                            <thead>
                                                            <tr>
                                                                <th scope="col" className="text-nowrap">SERIENNUMMER/UID</th>
                                                                <th scope="col" className="text-nowrap">ORGANISATION</th>

                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr>
                                                                <td className="text-nowrap">{gateway.serial_number}</td>
                                                                <td className="text-nowrap">{returnOrganisationName(gateway.organization_id)}</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    :
                                                    <div></div>

                                                }

                                                {searchType === 'Zähler' && sensor !== null && typeof sensor === 'object' && sensor.serial_number ?

                                                        <div className="table-responsive">
                                                            <table className="table mb-0 mt-5">
                                                                <thead>
                                                                <tr>
                                                                    <th scope="col" className="text-nowrap">SERIENNUMMER</th>
                                                                    <th scope="col" className="text-nowrap">TYP</th>
                                                                    <th scope="col" className="text-nowrap">ORGANISATION</th>

                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                <tr>
                                                                    <td className="text-nowrap">{sensor.serial_number}</td>
                                                                    <td className="text-nowrap">{sensor.type}</td>
                                                                    <td className="text-nowrap">{returnOrganisationName(sensor.organization_id)}</td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                    :
                                                    <div></div>
                                                }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Fragment>
        )
    }

    return(
        <Fragment>
            {UI()}
        </Fragment>
    )
}

SearchPage.requiredScope = ['search.view.any'];
SearchPage.requireAuth = true;
export default SearchPage;
import React, {Fragment, useEffect, useContext, useState} from 'react';
import { AuthContext } from "../../../../context/Auth/AuthProvider";
import {SensorsContext} from "../../../../context/Devices/Sensors";
import { LocationsContext } from "../../../../context/Locations/Locations";
import { OrganizationContext } from "../../../../context/Organization/Organization";

import { CSVLink, CSVDownload } from "react-csv";
import { Buffer } from "buffer";
import Swal from "@molline/sweetalert2";
import Axios_client from "../../../../utils/axios_client";
import { PermissionsWrapper, hasPermission, MembershipWrapper, hasMembership } from "../../reactPermissionsWrapper";
import HelpProvider from "../../helpProvider/helpProvider";
import {GroupsContext} from "../../../../context/Groups/Groups";

const ReportYearEndSensorWidget = (props) => {

    const { user } = useContext(AuthContext);
    const { locationsChanged, location, locations, locationChanged } = useContext(LocationsContext);
    const { organization, organizationChanged } = useContext(OrganizationContext);
    const { sensors, sensorsChanged, sanitizedSensor } = useContext(SensorsContext);
    const { groups, groupIcon } = useContext(GroupsContext)
    useEffect(  () => {
            
    }, [ organization, organizationChanged ]);


    const groupsOptions = groups.map(group => (
        `<option key=${group._id}  value=${group._id}>` +
        `<span>${groupIcon({ group: group, size: 2 })}</span>` +
        `${group.name}` +
        `</option>`
    )).join('');

    const handleGroupChange = () => {

        const isReportZippedCheckboxElement = document.getElementById('isReportZippedCheckbox');
        isReportZippedCheckboxElement.disabled = false;

    }

    const endYearReport = () => {
        document.getElementById('report_tab_close').click();
        let years = [];
        years.push(new Date().getFullYear())
        for(let x = 1; x < 3; x++){
            years.push(new Date().getFullYear() - x)
        }

        let html = `
            <p>Wenn Sie auf Erstellen klicken, wird eine CSV-Datei mit allen Zählerständen je Monat des ausgewählten Jahres erstellt und heruntergeladen. 
            Wenn Sie keine Gruppe auswählen, wird der Bericht für alle Nutzer-/Mieteinheiten erstellt. 
            Sie können auch mit einem Klick auf die Option "ZIP-Archiv" für jede Nutzer-/Mieteinheit eine Datei erstellen lassen und diese als ZIP-Archiv herunterladen. <br>
            <small>Falls kein Monates-Endwert vorhanden ist, wird der letzte empfangene Wert des Monats genutzt</small>
            </p>
            
            <select class="form-select" aria-label="Select Year" id="selectInputYear">
                <option>Jahres ...</option>
        `;
        years.map( y => {
            html += `<option key=${y} value=${y} >${y}</option>`
        })
        html += `</select>`

        html += `<br />
                <span> Gruppe: </span>
               <select class="form-select" name="type" id="locationsGroupSelect" > 
                 <option></option>
                 ${groupsOptions}
               </select>
               <div class="form-check form-switch isReportZipped">
                    <label id="customSwitch1" class="form-check-label">Nutzer-/Mieteinheiten Oder Gruppe als ZIP-Archiv?</label>
                    <input type="checkbox" class="form-check-input"   id="isReportZippedCheckbox" />
               </div> 
                    `

        Swal.fire({
            icon: "question",
            width: "35rem",
            title: `Möchten Sie den Zähler Jahresabschlussbericht herunterladen? `,
            allowOutsideClick: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Erstellen',
            background: localStorage.getItem('theme-preference') === 'dark-layout' ? '#161D31' : '#fff',
            cancelButtonText: 'Abbrechen',
            html,
            didOpen: () => {


                const groupSelectElement = document.getElementById('locationsGroupSelect');
                groupSelectElement.addEventListener('change', handleGroupChange);
            },
            preConfirm: () => {
                const year = Swal.getPopup().querySelector('#selectInputYear').value
                const selected_group_id = Swal.getPopup().querySelector('#locationsGroupSelect').value;
                const isReportZipped  = document.getElementById('isReportZippedCheckbox').checked;

                if(year === 'Jahres ...') {
                    Swal.showValidationMessage(`Bitte wählen Sie zuerst ein Jahr aus`)
                }
                return {selected_group_id, isReportZipped, year}
            }
        })
            .then(async (results) => {
                if (!results.isConfirmed || results.isDenied || results.isDismissed) return;

                Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/report/report/endyear/sensor?organization_id=${organization._id}&lang=de&year=${results.value.year}&group_id=${results.value.selected_group_id}&is_zip=${results.value.isReportZipped}`, {})
                    .then(response => {
                        
                    })
                    .catch(err => {
                        
                        console.log(err)
                    })
            })
    }

    const UI = () => {
        
       
        return (
            <PermissionsWrapper user={user} scopes={['sensor.view.any', 'sensor.view.own']} >
                <MembershipWrapper user={user} location={location} memberships={['basic', 'advanced', 'premium']} >
                    <div className="col-xl-4 col-md-6">
                        <div className="card text-center cursor-pointer">
                            <span className="badge rounded-pill bg-success fa-fade  badge-up">Neu!</span>
                            <HelpProvider reportSampleImgPath="/theme2/app-assets/images/reports/end_of_year_report.jpg" title="Jahres-Endbericht für Zähler" descriptionText="Laden Sie hier einen Bericht mit den Jahres-End-Werten von allen Zählern herunter" />
                            <div className="card-body" onClick={() => {endYearReport()}}>
                                <div className="mx-auto my-1">
                                    <i className="fa-solid  fa-file-spreadsheet fa-4x text-success"></i>
                                </div>
                                <p className="text-muted mb-0 line-ellipsis">Jahres-Endbericht für Zähler</p>
                            </div>
                        </div>
                    </div>
                </MembershipWrapper>
            </PermissionsWrapper>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )

}

export default ReportYearEndSensorWidget;
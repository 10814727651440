import React, { useState, Fragment, useEffect, useContext } from 'react';
import { AuthContext } from "../../../../context/Auth/AuthProvider";
import { LocationsContext } from "../../../../context/Locations/Locations";
import {TenantContext} from '../../../../context/Tenant/Tenant';
import {useRouter} from "../../../Shared/Router/Router";
import { PermissionsWrapper, hasPermission, MembershipWrapper, hasMembership } from "../../reactPermissionsWrapper";
import { CSVLink, CSVDownload } from "react-csv";

const CountTenantsWidget = (props) => {

    const router = useRouter()
    const { user } = useContext(AuthContext);
    const { locationsChanged, location, setLocation, locations, locationChanged, capitalize, getLocationAddress, locationIcon } = useContext(LocationsContext);
    const { tenants, tenantsChanged } = useContext(TenantContext);
    const [filteredTenants, setFilteredTenants] = useState([])

    useEffect( () => {

        if(tenantsChanged) csvList();
            
    }, [ tenants, tenantsChanged, ]);

    const csvList = () => {
        let filterTenants = [];
        for(let tenant of tenants){
            let t = {
                ref_id: (!!tenant.token) ? tenant.token : '',
                vor_name: tenant.first_name,
                nach_name: tenant.last_name,
                firm_name: (!!tenant.firm_name) ? tenant.firm_name : '',
                email: tenant.email,
                phone: (!!tenant.phones && tenant.phones.length > 0)? tenant.phones[0]: '',
                einzug: (tenant.in_date)? new Date(tenant.in_date).toLocaleDateString("de-DE"):'',
                auszug: (tenant.out_date)? new Date(tenant.out_date).toLocaleDateString("de-DE"):'',
                beschreibung: tenant.description,
            }
            if(!!tenant.location && !!tenant.location.address){
                t.address = tenant.location.address.street
                t.haus_nummer = tenant.location.address.house_number
                t.mieteinheit = tenant.location.address.street1
                t.stadt = tenant.location.address.city
            }
            filterTenants.push(t)
        }
        setFilteredTenants(filterTenants)
    }

    const countTenants = () => {
        let count = 0
        for(let tenant of tenants){
            let indate = null, outdate = null, today = new Date().getTime();
            if(tenant.in_date) indate = new Date(tenant.in_date).getTime();
            if(tenant.out_date) outdate = new Date(tenant.out_date).getTime();
            if(
                ( indate && indate < today && !outdate ) ||
                ( (indate && indate < today) && (outdate && outdate > today) )
            ){ count ++ }
        }
        return count;
    }

    const UI = () => {
        if(Object.keys(location).length === 0  || !user || locations.length === 0) return <Fragment></Fragment>
        return(
            <PermissionsWrapper user={user} scopes={['location.view.any', 'location.view.own']} >
                <MembershipWrapper user={user} location={location} memberships={['advanced', 'premium']} >
                    <Fragment>
                        
                        <div className="col-xl-2 col-md-4" id="widgetCountTenants">
                            <CSVLink
                                data={filteredTenants}
                                filename={"tenant-report.csv"}
                            >
                            <div className="card text-center " title="Anzahl an Mieter">
                                <span className="badge rounded-pill bg-info fa-fade badge-up" id="funkSensorsDownloadIcon"><i class="fa-solid fa-download"></i></span>
                                <div className="card-body align-self-center">
                                    <div className="mx-auto my-1" style={{display: "inline-flex", alignItems: "center"}}>
                                        <i class="fa-solid fa-people-roof fa-4x text-primary"></i>
                                    </div>
                                    <p className="mb-0 text-muted">Nutzer</p>
                                    <h4 className="mb-0 line-ellipsis ">{countTenants()}</h4>
                                </div>
                            </div>
                            </CSVLink>
                        </div>
                        
                    </Fragment>
                </MembershipWrapper>
            </PermissionsWrapper>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}

export default CountTenantsWidget;
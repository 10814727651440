import React, { useState, useEffect, createContext, Fragment, useContext, useRef } from 'react';

import { AuthContext } from '../../context/Auth/AuthProvider';
import { CookieContext } from '../../context/CookieMaster/CookieMaster';
//import {MgmtUsersContext} from '../../context/Users/UsersContext';
import socketIOClient from 'socket.io-client';

export const SocketContext = createContext();

const SocketProvider = ({ children, context }) => {

    const { user, logout } = useContext(AuthContext);
    const { authCookie, getCookie} = useContext(CookieContext)
    //const { mgmtUsers, setMgmtUsers } = useContext(MgmtUsersContext)

    const [socket, setSocket] = useState(null);
    const [socketId, setSocketId] = useState(null);
    const socketIdChanged = useCompare(socketId)
    const socketChanged = useCompare(socket)
    const userChanged = useCompare(user)
    const authCookieChanged = useCompare(authCookie)

    const [socketUser, setSocketUser] = useState({
        user_id: null,
        socket_id: null,
        status: null
    })
    const socketUserChanged = useCompare(socketUser)

    useEffect(() => {

        if( userChanged === true) getSocket()
        if (socketChanged === true && socket !== null) {
            setSocketId(socket.id)
        }
        if(socketUserChanged && socket !== null){
            let payload = {...socketUser}
            payload.user_id = user._id
            socket.emit('socket_user', {user_id: user._id, payload})
        }
        

    }, [user, userChanged, socketId, socketUser])

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const sleep = (seconds = 1) => {
        return new Promise((resolve, reject) => {
            setTimeout( () => {return resolve()}, seconds * 1000)
        })
    }

    const updateSocketUser = (obj) => {
        let updatedUser = JSON.parse(JSON.stringify(socketUser))
        Object.entries(obj).forEach( (entry,index) => {
            updatedUser[entry[0]] = entry[1]
        })
        setSocketUser(updatedUser)
    }

    const getSocket = () => {
        return new Promise( async (resolve, reject) => {
            /**
             * We sleep for 1 second to give Redis time to be updated with the new User Object.
             */
            await sleep(1)
            let skt = socketIOClient(process.env.REACT_APP_SOCKET_EVENTS, {
                forceNew: true,
                timeout: 3000,
                secure: true,
                withCredentials: true,
                extraHeaders: {
                    "auth": getCookie('auth')
                }
            });

            skt.on('connected', (data) => {
                //console.log('CONNECTED', data)
                setSocket(skt)
                setSocketId(skt.id)
                listen(skt)
                if(!!user && !!user._id){
                    skt.emit('user_connect', {socket_id: skt.id, user_id:user._id})
                    updateSocketUser({socket_id:skt.id, last_connect: new Date().getTime(), status:'connected', last_disconnect: null})
                }
            })
            return resolve()
        })
    }

    const listen = (skt) => {
        /**
         * Force Target or force World to logout
         */
        skt.on('logout', (data) => { 
            if(!!data.payload && !!data.payload.user_id){
                if(data.payload.user_id === user._id) logout();
            } else{
                logout();
            }             
        })


        /**
         * User connected status. If the user is part of the organization
         * and has access to the MGMTUSERS then this will update the MGMTUSER
         * array for each user which it applies to.
         
        skt.on('user', (data) => {
            let mUser = mgmtUsers.filter( u => u._id === data.payload.user_id)[0]
            let mUsers = JSON.parse(JSON.stringify(mgmtUsers))
            switch(data.event){
                case 'user.connect':
                    if(!!mUser){
                        for(let mgUser of mUsers){
                            if(mgUser._id === data.payload.user_id) mgUser.socket = data.payload
                        }
                        setMgmtUsers(mUsers)
                    }
                break;
                case 'user.disconnect':
                    if(!!mUser){
                        for(let mgUser of mUsers){
                            if(mgUser._id === data.payload.user_id) mgUser.socket = data.payload
                        }
                        setMgmtUsers(mUsers)
                    }
                break;
                default: break;
            }
        }) 
*/
    }

    const actions = {
        socket,
        socketId
    }

    return (
        <Fragment>
            <SocketContext.Provider value={actions}>
                {children}
            </SocketContext.Provider>
        </Fragment>
    )

}

export default SocketProvider;
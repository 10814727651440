import React, {Fragment, useState, useEffect, createContext, useContext } from 'react';
import { LocaleContext } from '../Locale/Locale';
import { AuthContext } from '../Auth/AuthProvider';

export const PreferenceContext = createContext();

const PreferenceProvider = ({children}) => {
    
    const [updated, setUpdated] = useState(false);
    const { user } = useContext(AuthContext);
    const {locale, setLang} = useContext(LocaleContext)
    const [preferences, setPreferences] = useState({
        preferred_crypto: 'MATIC',
        currency: {
            'label': 'EUR',
            'symbol': '€'
        },
        locale: 'de'
    })
    
    useEffect( () => {
        if(!updated){
            setUpdated(true)
        }
    }, [preferences])

    const setPreference = (preferencesObject) => {
        console.log('SETTING PREFS', preferencesObject)
        setPreferences(preferencesObject)
        /**
         * Set preferred language
         */
        if(preferencesObject.locale !== locale){
            setLang(preferencesObject.locale)
        }
    }

    const resetPreferences = () => {
        console.log('FAKE RESETTING PREFERENCES')
    }

    const params = {
        preferences,
        setPreference,
        resetPreferences
    }

    return (
        <PreferenceContext.Provider value={params}>
            {children}
        </PreferenceContext.Provider>
    )


}

export default PreferenceProvider;
import React, {Fragment, useEffect, useContext } from 'react';
import {GatewaysContext} from "../../../../context/Devices/Gateways";


const GatewayLastSeenWidget = (props) => {

    const { gateway, gatewayChanged } = useContext(GatewaysContext);


    useEffect(  () => {
            
    }, [gateway, gatewayChanged]);

    const isDelinquent = () => {
        const twoDaysAgo = new Date()
        twoDaysAgo.setDate(twoDaysAgo.getDate() - 2)
        const lastReadDate = new Date(gateway.last_transmission)
        if (lastReadDate < twoDaysAgo) {
            return true
        }
        return false
    }

    const daysSinceLastSent = (dateToParse) => {
        const myDate = new Date(dateToParse);
        const now = new Date();
        const daysLastSent = Math.ceil((now.getTime() - myDate.getTime()) / (1000 * 3600 * 24));
        return (<h5 className={isDelinquent() ? 'text-secondary' : 'text-success'}>vor {daysLastSent} Tag(en)</h5>);
    }

    const dateWithTime = new Intl.DateTimeFormat('de-DE', {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    });

    const parseDate = (dateToParse) => {
        return dateWithTime.format(new Date(dateToParse))
    }

    const UI = () => {
        let icon = isDelinquent() ? <i className="text-warning fa-solid fa-message-lines fa-4x"></i> : <i className="text-success fa-solid fa-message-lines fa-4x"></i>
        return (
            <div className="col-xl-2 col-md-4" id="widgetGatewayLastSeen">
                <div className="card text-center " title="The total number of sensors seen by this gateway (registered & unregistered)">
                    <div className="card-body align-self-center">
                        <div className="mx-auto my-1" style={{ height: "75px",display: "inline-flex", alignItems: "center"}}>
                            {icon}
                        </div>
                        <p className="text-muted mb-0 line-ellipsis">Zuletzt gesendet</p>
                        <h4 className="mb-0">{gateway && gateway.last_transmission ? parseDate(gateway?.last_transmission) : '-'}</h4>
                        {gateway && gateway?.last_transmission && daysSinceLastSent(gateway?.last_transmission)}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )

}

export default GatewayLastSeenWidget;
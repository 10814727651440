

import React, {Fragment, useState, useEffect, useContext} from 'react';

import { LocaleContext } from '../../context/Locale/Locale';
import { useRouter } from '../Shared/Router/Router'


const USPS = ({children}) => {

    const router = useRouter()
    const [updated, setUpdated] = useState(false);
    const {t, locale, localeAvailable, setLang} = useContext(LocaleContext);
//    const {config} = useContext(WhiteLabelContext);



    useEffect(  () => {
        if(!updated){
            setUpdated(true)
        }

    }, [locale]);


    const UI = () => {
        return (
            <Fragment>

                <div id="usps" className="section section-2">
                    <div className="container-fluid">
                        <div className="container">
                            <h2 className="text-center h1 white">So funktioniert MOLLINÉ Connect</h2>
                            <p className="text-center white">Die Welt der Messtechnik wandelt sich hin zu einem digitalen Netz aus Daten. MOLLINÉ Connect als Komplettlösung sorgt dafür, dass Sie Ihren Verbrauch immer im Blick haben, auswerten und optimieren können. Die Kombination aus innovativer Software und weiterleitendem Gateway bildet eine smarte Lösung.</p>
                        </div>

                        <div className="row mb-2">
                            <div className="col-xl-3 col-md-3 col-sm-12 my-1">
                                <div className="card h-100">
                                    <div className="card-header">
                                        <h4 className="card-title"><button type="button" className="btn btn-icon rounded-circle btn-light mr-1"><i className="bx bx-plug"></i></button> Einrichtung</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="card-text pb-1">
                                            <p>Wir haben die neueste, HKVO-konforme Messtechnik in unserem Sortiment.</p>
                                            <a href="#usp1" className="mt-auto btn btn-light dark rounded-pill" data-toggle="modal" data-backdrop="false" data-target="#usp1">Mehr erfahren <i className="bx bx-chevron-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-3 col-sm-12 my-1">
                                <div className="card h-100">
                                    <div className="card-header">
                                        <h4 className="card-title"><button type="button" className="btn btn-icon rounded-circle btn-light mr-1"><i className="bx bx-bar-chart"></i></button> Verwaltung</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="card-text pb-1">
                                            <p>Sobald die Daten auf dem Server verarbeitet wurden, stehen sie Ihnen zur Verfügung.</p>
                                            <a href="#usp2" className="mt-auto btn btn-light dark rounded-pill" data-toggle="modal" data-backdrop="false" data-target="#usp2">Mehr erfahren <i className="bx bx-chevron-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-3 col-sm-12 my-1">
                                <div className="card h-100">
                                    <div className="card-header">
                                        <h4 className="card-title"><button type="button" className="btn btn-icon rounded-circle btn-light mr-1"><i className="bx bx-shield"></i></button> Sicherheit</h4>
                                    </div>

                                    <div className="card-body">
                                        <div className="card-text pb-1">
                                            <p>Die Verbrauchsdaten werden verschlüsselt an den MOLLINÉ Connect-Server übermittelt.</p>
                                            <a href="#usp3" className="my-auto btn btn-light dark rounded-pill" data-toggle="modal" data-backdrop="false" data-target="#usp3">Mehr erfahren <i className="bx bx-chevron-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-3 col-sm-12 my-1">
                                <div className="card h-100">
                                    <div className="card-header">
                                        <h4 className="card-title"><button type="button" className="btn btn-icon rounded-circle btn-light mr-1"><i className="bx bx-wifi"></i></button> Fernablesung</h4>
                                    </div>

                                    <div className="card-body">
                                        <div className="card-text pb-1">
                                            <p>Fernauslesbar mit den Messgeräten und dem MOLLINÉ Easy Funk Gateway.</p>
                                            <a href="#usp4" className="mt-auto btn btn-light dark rounded-pill" data-toggle="modal" data-backdrop="false" data-target="#usp4">Mehr erfahren <i className="bx bx-chevron-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal modal-borderless fade text-left" id="usp1" tabIndex="-1" aria-labelledby="usp1" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title black" id="usp1title">Einrichtung</h4>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <i className="bx bx-x"></i>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <ul className="red">
                                            <li>Neben den Wärme- und Wasserzählern finden Sie auch Messgeräte für die Medien von Druckluft bis Strom in unserem Sortiment. Die Messgeräte, wie auch Heizkostenverteiler und Rauchwarnmelder, können Sie bei uns HKVO-konform bestellen: fernauslesbar, interoperabel und kompatibel mit dem MOLLINÉ Easy Funk Gateway.</li>
                                            <li>Überlassen Sie die Montage der Messtechnik uns oder Ihrem Handwerker des Vertrauens.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal modal-borderless fade text-left" id="usp2" tabIndex="-1" aria-labelledby="usp2" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title black" id="usp2title">Verwaltung</h4>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <i className="bx bx-x"></i>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <ul className="red">
                                            <li>Sie haben die Wahl. Sie können die tagesaktuellen Verbrauchsdaten für ein Energiedatenmanagementsystem nutzen oder Molliné schickt die monatlichen Verbrauchsinformationen automatisiert an Ihre Nutzer.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal modal-borderless fade text-left" id="usp3" tabIndex="-1" aria-labelledby="usp3" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title black" id="usp3title">Sicherheit</h4>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <i className="bx bx-x"></i>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <ul className="red">
                                            <li>Ihre Daten sind gesichert. Weil die Verbrauchsinformationen erst im nachgelagerten Prozess und nicht im Gateway selbst verarbeitet werden, ist das MOLLINÉ Easy Funk Gateway sehr energiesparend.</li>
                                            <li>Bei der Übermittelung werden die Verbrauchsdaten gemäß der Norm EN 13757-4 nach dem AES-128-Verfahren verschlüsselt.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal modal-borderless fade text-left" id="usp4" tabIndex="-1" aria-labelledby="usp4" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title black" id="usp4title">Fernablesung</h4>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <i className="bx bx-x"></i>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <ul className="red">
                                            <li>Neben den Funk-Messgeräten erhalten Sie bei uns das MOLLINÉ Easy Funk Gateway. Einsetzbar in Neu- und Bestandsliegenschaften sowie in privaten und gewerblichen Großimmobilien.</li>
                                            <li>Das MOLLINÉ Easy Funk Gateway wird mit einer Batterie betrieben, was eine autarke Energieversorgung gewährleistet.</li>
                                            <li>Mithilfe des Open-Meter-Systems (OMS) ist es möglich, Funkdaten vieler namhafter Zählerhersteller auszulesen. Ein enormer Vorteil für gemischt ausgestattete Liegenschaften und ein Wechsel zu MOLLINÉ Connect ist damit einfach und problemlos.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Fragment>
        )
    }




    return (
        <Fragment>

            {UI()}

        </Fragment>
    )
}

export default USPS;

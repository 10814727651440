
import React, {Fragment, useState, useEffect, useContext} from 'react';
import { AuthContext } from '../../context/Auth/AuthProvider';
import { LocaleContext } from '../../context/Locale/Locale';
import { useRouter } from '../Shared/Router/Router'
import ContactForm from "./ContactForm";

const Contact = ({children}) => {

    const router = useRouter()
    const [updated, setUpdated] = useState(false);
    const {t, locale, localeAvailable, setLang} = useContext(LocaleContext);
//    const {config} = useContext(WhiteLabelContext);
    const [questionLength, setQuestionLength] = useState(1000);
    const [countConcern, setCountConcern] = useState(questionLength);

    useEffect(  () => {
        if(!updated){
            setUpdated(true)
        }
    }, [locale]);

    const UI = () => {
        return (
            <Fragment>
                <div id="contact" className="section section-7">
                    <div className="container-fluid">
                        <div className="container pt-3 pb-1">
                            <h3 className="text-center h1">Interesse? Kontaktieren Sie uns.</h3>
                            <p className="text-center">MOLLINÉ Connect ist die Lösung, die Sie suchen?<br />
                                Unsere Connect-Experten sind für Sie da.</p>

                            <p className="text-center">
                                <button type="button" className="btn btn-lg btn-primary round my-1" onClick={() => {ContactForm.showForm()}}  >Mit Molliné Kontakt aufnehmen</button>
                            </p>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}

export default Contact;
import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import OrgCookieCrumb from "../../../../components/Dashboard/orgCookieCrumb";
import {OrganizationContext} from "../../../../context/Organization/Organization";
import { GroupsContext } from '../../../../context/Groups/Groups';
import TblLocationGroups from '../../../../components/Dashboard/tables/dataTables/tblLocationGroups';
import { LocationsContext } from '../../../../context/Locations/Locations';

const Billing = () => {

    const {organizations, organization} = useContext(OrganizationContext);
    const { getLocationGroups, locationGroups } = useContext(LocationsContext)
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        setLoaded(true)
        if(!loaded) getLocationGroups()
    }, [locationGroups])

    


    const UI = () => {
        return (
            <Fragment>

                <div className="content-header row">
                    <div className="content-header-left col-md-9 col-12 mb-2">
                        <div className="row breadcrumbs-top">
                            <div className="col-12">
                                <h2 className="content-header-title float-start mb-0">Gruppen-Übersicht</h2>
                                <div className="breadcrumb-wrapper">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a >Tools</a>
                                        </li>
                                        <li className="breadcrumb-item active"> Gruppen-Übersicht
                                        </li>
                                        <li>
                                            <span className="tourBorderRight" />
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <OrgCookieCrumb />
                </div>
                <div className="content-body">
                    <section id="tabpanels">
                        <div className="row">
                            <div className="col-12">
                                <div className="tab-content pt-1">
                                    <div className="card" id="searchPageCard">
                                        <div className="card-header border-bottom">
                                            <h4 className="card-title">Gruppen-Übersicht</h4>
                                            <div className="heading-elements">
                                                <div className="heading-elements">
                                                <span class="badge rounded-pill bg-primary">Nicht bearbeitet</span>
                                                <span class="badge rounded-pill bg-success ms-1">Abrechnungskunde</span>
                                                <span class="badge rounded-pill bg-danger ms-1">Kein Abrechnungskunde</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body py-2 my-25" id="searchPage">
                                            <TblLocationGroups locationGroups={locationGroups} id={"tblLocationGroups"}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Fragment>
        )
    }

    return(
        <Fragment>
            {UI()}
        </Fragment>
    )
}

Billing.requiredScope = ['search.view.any'];
Billing.requireAuth = true;
export default Billing;
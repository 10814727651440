import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import Swal from "@molline/sweetalert2";


const HelpProvider = (props) => {

    const [updated, setUpdated] = useState(false)

    useEffect(() => {
        if(!updated) {
            setUpdated(true)
        }

    }, [])

    const reportHelpHandler = () => {
        if(props.reportSampleImgPath) {
            let html = `<div class="row  g-0">
                            <div class="col-12 "><p class="report_text_center" >${props.descriptionText}</p></div>
                            <br />
                            <img class="report_sampleImg report_sample_img"  style="object-fit: contain" src=${props.reportSampleImgPath}  alt="report sample Image" width="500px"  />
                       </div>`
            Swal.fire({
                icon: "info",
                title: props.title,
                allowOutsideClick: true,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                width: '80rem',
                background: localStorage.getItem('theme-preference') === 'dark-layout' ? '#161D31' : '#fff',
                confirmButtonText: 'Ok',
                html: html,
            })
        }else {
            Swal.fire({
                icon: "info",
                title: props.title,
                allowOutsideClick: true,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                background: localStorage.getItem('theme-preference') === 'dark-layout' ? '#161D31' : '#fff',
                confirmButtonText: 'Ok',
                text: props.descriptionText,
            })
        }

    }

    const UI = () => {
      return(
          <Fragment>
              <i className="fa-regular fa-circle-question cursor-pointer report_info_icon"  title="Brauchen Sie Hilfe?" onClick={() => reportHelpHandler()} ></i>
          </Fragment>

      )
    }
    return (
        <Fragment>{UI()}</Fragment>
    )
}

export default HelpProvider;
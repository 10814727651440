
import React, {Fragment, useState, useEffect, useContext} from 'react';
import { LocaleContext } from '../../context/Locale/Locale';
import { useRouter } from '../Shared/Router/Router'
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";

const params = {
    "realm": "@Digipaper.io",
    "id": "",
    "logo": "",
    "title": "",
    "target": "digipaper",
    "object3d": {
        "scene": {
            "rotation": "0",
            "background": {
                "color": null,
                "image": null,
                "video": null,
                "transparent": true
            }
        },
        "lights":{
            "ambient": {
                "active": true,
                "color": 0xFFFFFF,
                "intensity": 0.3,
                "helper": false,
                "x": 0,
                "y": 30,
                "z": 0
            },
            "front": {
                "active": true,
                "color": 0xFFFFFF,
                "intensity": 1,
                "helper": false,
                "shadow": false,
                "x": 0,
                "y": 20,
                "z": 30
            },
            "back": {
                "active": true,
                "color": 0xFFFFFF,
                "intensity": 1,
                "helper": false,
                "shadow": false,
                "x": 0,
                "y": 20,
                "z": -30
            }
        },
        "indexed": false,
        "allow_sharing": true,
        "allow_embedding": true,
        "password":{
            "required": false,
            "hash": ""
        },
        "publish_from": null,
        "publish_to": null,
        "whitepaper": false,
        "meta_data": {
            "icon": "%PUBLIC_URL%/logo.png",
            "description": "",
            "author": "@Digipaper.io",
            "publisher": "@Digipaper.io",
            "twitter:card": "",
            "twitter:title": "",
            "twitter:description": "",
            "twitter:image": "",
            "twitter:url": "https://read.digipaper.io",
            "og:type": "article",
            "og:title": "",
            "og:description": "",
            "og:locale": "en_UK",
            "og:site_name": "Digipaper.io",
            "og:url": "https://digipaper.io",
            "og:image:width": "1200",
            "og:image:height": "1200",
            "og:image": "%PUBLIC_URL%/digipaper_io.jpg",
            "og:image:secure_url": ""
        },
        "addons": {},
        "config": {
            "type": "flyer",
            "src": ['/pdfs/28-1.pdf', '/pdfs/28-2.pdf'],
            "page_count": 2,
            "height": 297,
            "width": 210,
            "depth": 0.1,
            "rotation": 0,
            "wsegments": 1,
            "hsegments": 1,
            "bund": "links",
            "flip": false,
            "turn": false,
            "spin": false,
            "animate_on_focus": false,
            "xbones": [],
            "ybones": [],
            "model": {
                "indices":"",
                "weights":""
            },
            "material": "matt"
        },
        "social_media": {
            "likes": 0,
            "shares": 0,
            "popularity_score": 0
        }
    }
};

const Hardware = ({children}) => {

    const router = useRouter()
    const [updated, setUpdated] = useState(false);
    const {t, locale, localeAvailable, setLang} = useContext(LocaleContext);
//    const {config} = useContext(WhiteLabelContext);



    useEffect(  () => {
        if(!updated){
            setUpdated(true)
        }

    }, [locale]);

    const UI = () => {
        return (
            <Fragment>
                <div id="hardware" className="section section-3">
                    <div className="container-fluid">
                        <div className="container pt-3 pb-1">
                            <h3 className="text-center h1 white">Komfortabel, modern, sicher.</h3>
                            <p className="text-center white">Mit unserer Funklösung bieten wir Ihnen eine ganzheitliche Lösung zur Fernablesung.
                                Der Zähler wird verknüpft mit einem Clouddienst, sodass Sie Ihren Energieverbrauch effizient optimieren können.</p>
                        </div>

                        <div className="hardware-slider">

                            <Swiper
                                pagination={{
                                    type: "fraction",
                                }}
                                navigation={true}
                                modules={[Autoplay, Pagination, Navigation]}
                                className="molSwiper"
                                autoplay={{
                                    delay: 3500,
                                    disableOnInteraction: false,
                                }}
                            >
                                <SwiperSlide>
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-sm-12">
                                            <p className="text-left h1 white">Benutzerdefinierte  Berichte</p>
                                            <p className="text-left white">Mit unserem neuen <strong>Report Builder</strong> können Sie einen vollständigen Export Ihrer Daten in beliebiger Reihenfolge erstellen. Dieser Bericht wird zu einer auswählbaren Option in Ihrem Berichtsmenü, die Sie jederzeit anzeigen können. Oder noch besser: Planen Sie eine Aufgabe, um den Bericht zu erstellen und Ihnen zuzustellen, wann immer Sie wollen.</p>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-sm-12">
                                            <img className="text-center" src="/homepage/assets/images/products/molline_connect_gateway.jpg" alt="Molline Connect Gateway" />
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-sm-12">
                                            <p className="text-left h1 white">Monatliche  Verbrauchsberichte</p>
                                            <p className="text-left white">Jeder Kunde und Manager erhält jeden Monat einen automatischen Verbrauchsbericht in sein E-Mail-Postfach oder in den Hausbriefkasten.</p>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-sm-12">
                                            <img className="text-center" src="/homepage/assets/images/products/molline_connect_gateway.jpg" alt="Molline Connect Gateway" />
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-sm-12">
                                            <p className="text-left h1 white">Benutzerdefinierte  Berichte</p>
                                            <p className="text-left white">Mit unserem neuen <strong>Report Builder</strong> können Sie einen vollständigen Export Ihrer Daten in beliebiger Reihenfolge erstellen. Dieser Bericht wird zu einer auswählbaren Option in Ihrem Berichtsmenü, die Sie jederzeit anzeigen können. Oder noch besser: Planen Sie eine Aufgabe, um den Bericht zu erstellen und Ihnen zuzustellen, wann immer Sie wollen.</p>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-sm-12">
                                            <img className="text-center" src="/homepage/assets/images/products/molline_connect_gateway.jpg" alt="Molline Connect Gateway" />
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-sm-12">
                                            <p className="text-left h1 white">Mechaniker-Werkzeugkasten</p>
                                            <p className="text-left white">Mit unserer Molline-Connect Mechanic Toolbox können sich Ihre Installateure und Ihr Wartungspersonal täglich einloggen und ihre erforderlichen Wartungsaufgaben einsehen. Weisen Sie Regionen einem Mechaniker zu und lassen Sie sich benachrichtigen, wenn es in dem Gebiet rote Fahnen gibt. Nutzen Sie das Gateway-Positionierungstool, um die Sensorabdeckung effizienter zu gestalten, insbesondere in Ihrer Brieftasche. Das Bewöhner Check-in/out-Tool macht die Benutzerverwaltung für Ihre Wohnungen, Büros und Geschäfte zu einer einfachen Aufgabe.</p>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-sm-12">
                                            <img className="text-center" src="/homepage/assets/images/products/molline_connect_gateway.jpg" alt="Molline Connect Gateway" />
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-sm-12">
                                            <p className="text-left h1 white">NUR Daten-Option</p>
                                            <p className="text-left white">Falls Sie Ihre eigenen Gebäudemanagementsysteme haben, ist das überhaupt kein Problem. Sie brauchen vielleicht nur das Auslesen der Zähler und den Empfang Ihrer Daten. Sobald Ihre Zähler ausgelesen sind, erhalten Sie einen CSV-Bericht mit den aktuellen Daten direkt in Ihren Posteingang, per API oder Webhook. So bleiben Sie mit den täglichen Verbrauchs- und Nutzungsdaten, die Sie selbst überwachen können, auf dem Laufenden. Wir haben sogar eine Backup-Option.</p>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-sm-12">
                                            <img className="text-center" src="/homepage/assets/images/products/molline_connect_gateway.jpg" alt="Molline Connect Gateway" />
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-sm-12">
                                            <p className="text-left h1 white">Erweiterte Systemstatus-Analyse</p>
                                            <p className="text-left white">Molline-Connect überwacht das akzeptierte Verhalten Ihrer Zähler und Gateways, um sicherzustellen, dass sie sich in einem optimalen Zustand befinden. Ihr Dashboard zeigt alle Probleme in Ihrem System an (defekte Zähler, verspätete Meldungen, fehlerhafte Gateways) und kann sogar einen Wartungsbericht nur für Sie erstellen.</p>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-sm-12">
                                            <img className="text-center" src="/homepage/assets/images/products/molline_connect_gateway.jpg" alt="Molline Connect Gateway" />
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-sm-12">
                                            <p className="text-left h1 white">Gerätespezifische Protokollierung und Wartungsberichte</p>
                                            <p className="text-left white">Die Historie jedes Geräts - wer es installiert hat, wer es besucht und wer es ausgetauscht hat - wird nachverfolgt.</p>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-sm-12">
                                            <img className="text-center" src="/homepage/assets/images/products/molline_connect_gateway.jpg" alt="Molline Connect Gateway" />
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-sm-12">
                                            <p className="text-left h1 white">Alle branchenüblichen Sensorhersteller </p>
                                            <p className="text-left white">Molline-Connect kann Daten von über 15 Sensorherstellern empfangen. Das bedeutet, dass ein Wechsel zu Molline-Connect nicht einmal einen Sensortausch erfordert, was den Übergang so einfach und schmerzlos wie möglich macht (Diehl, Techem, Sontex, Innotas, Maddalena, Lansen, Engelmann, Lorenz, Qundis und viele mehr). Neue Zähler werden regelmäßig hinzugefügt!</p>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-sm-12">
                                            <img className="text-center" src="/homepage/assets/images/products/molline_connect_gateway.jpg" alt="Molline Connect Gateway" />
                                        </div>
                                    </div>
                                </SwiperSlide>


                            </Swiper>









                        </div>
                        <a className="btn-next text-center" href="#efficiency"></a>
                    </div>
                </div>

            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}

export default Hardware;

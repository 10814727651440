import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';
import Swal from '@molline/sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import uniqId from 'uniqid';
import { faClose } from '@fortawesome/free-solid-svg-icons';

const TagComponent = (props) => {

    useEffect(() => {
    }, [props.tags, props.isEditing])

    const errorToast = (err) => {
        if (!err) return
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: err?.response ? JSON.stringify(err.response.data) : err,
            showConfirmButton: false,
            customClass: {
                popup: "bg-danger text-white"
            }
        })
    }

    const addTag = async () => {
        Swal.fire({
            title: 'Neue Tags hinzufügen (mit Komma separieren)',
            input: 'text',
            showCancelButton: true,
            confirmButtonText: 'Hinzufügen',
            cancelButtonText: "Abbrechen",
        }).then(async (result) => {
            if (!result.isConfirmed) return;
            if (!result.value.trim()) {
                errorToast("Schlagwörter dürfen nicht leer sein ...");
                return;
            }

            props.addTagFunction(result.value)
        })

    }

    const UI = () => {

        return (
            <Fragment>
                {props.tags !== undefined &&
                    <div className="card-header border-bottom">
                        <div style={{ display: "inline-flex", alignItems: "center" }}>
                            <button disabled={!props.isEditing} onClick={() => addTag()} className="btn btn-primary btn-sm" style={{ width: "18px", height: "18px", padding: "0", borderRadius: "18px", marginRight: "0.5rem" }}>+</button>
                            <h6 style={{ margin: "0", marginRight: "0.5rem" }}>TAGS</h6>
                            {props.tags.map((v, i) => {
                                return (
                                    <span onClick={props.isEditing ? () => props.deleteTagFunction(i) : () => { }} style={{ marginRight: "0.5rem", padding: "5px 9px", textTransform: "uppercase", fontSize: "11px" }} key={uniqId()} className="badge rounded-pill bg-secondary">
                                        <span>{v}</span> {props.isEditing && <FontAwesomeIcon style={{ marginTop: "-1px" }} icon={faClose} />}
                                    </span>
                                )
                            })}
                        </div>
                    </div>
                }
            </Fragment>
        )
    }

    return (
        <Fragment>{UI()}</Fragment>
    )



}

export default TagComponent;

import React, {Fragment, useState, useEffect, useContext} from 'react';
import { AuthContext } from '../../context/Auth/AuthProvider';
import { LocaleContext } from '../../context/Locale/Locale';
import { useRouter } from '../Shared/Router/Router'

import ContactForm from "./ContactForm";

const Timeline = ({children}) => {

    const router = useRouter()
    const [updated, setUpdated] = useState(false);
    const {t, locale, localeAvailable, setLang} = useContext(LocaleContext);
//    const {config} = useContext(WhiteLabelContext);



    useEffect(  () => {
        if(!updated){
            setUpdated(true)
        }

    }, [locale]);


    const UI = () => {
        return (
            <Fragment>
                <div id="timeline" className="section section-6">
                    <video autoPlay muted loop id="bg-timeline">
                        <source src="/homepage/assets/videos/432269528.mp4" type="video/mp4" />
                        Ihr Browser unterstützt leider die Wiedergabe von HTML5 Videos nicht.
                    </video>
                    <div className="container-fluid">
                        <div className="container py-3">
                            <h3 className="text-center h1 white mb-5">Die Heizkostenverordnung (HKVO):<br />Ab wann gilt was?</h3>

                            <div className="row align-items-center hkvo d-flex">
                                <div className="col-2 text-center bottom d-inline-flex justify-content-center align-items-center">
                                    <div className="circle font-weight-bold">1</div>
                                </div>
                                <div className="col-6">
                                    <h5 className="white h4">01.12.2021</h5>
                                    <p className="white">Neuanlagen oder Austausch – nur noch fernablesbare Messgeräte (inkl. HKVE & RWM) installieren.</p>
                                </div>
                            </div>

                            <div className="row timeline">
                                <div className="col-2">
                                    <div className="corner top-right"></div>
                                </div>
                                <div className="col-8">
                                    <hr/>
                                </div>
                                <div className="col-2">
                                    <div className="corner left-bottom"></div>
                                </div>
                            </div>

                            <div className="row align-items-center justify-content-end hkvo d-flex">
                                <div className="col-6 text-right">
                                    <h5 className="white h4">01.01.2022</h5>
                                    <p className="white">Unterjährige Verbrauchsinformation – Nutzer müssen monatlich aktiv informiert werden (wenn Liegenschaft fernablesbar ist).</p>
                                </div>
                                <div className="col-2 text-center full d-inline-flex justify-content-center align-items-center">
                                    <div className="circle font-weight-bold">2</div>
                                </div>
                            </div>

                            <div className="row timeline">
                                <div className="col-2">
                                    <div className="corner right-bottom"></div>
                                </div>
                                <div className="col-8">
                                    <hr/>
                                </div>
                                <div className="col-2">
                                    <div className="corner top-left"></div>
                                </div>
                            </div>

                            <div className="row align-items-center hkvo d-flex">
                                <div className="col-2 text-center full-left d-inline-flex justify-content-center align-items-center">
                                    <div className="circle font-weight-bold">3</div>
                                </div>
                                <div className="col-6">
                                    <h5 className="white h4">01.12.2022</h5>
                                    <p className="white">Neuanlagen – nur noch Messgeräte installieren, die fernablesbar, interoperable und Smart-Meter-Gateway kompatibel sind.</p>
                                </div>
                            </div>

                            <div className="row timeline">
                                <div className="col-2">
                                    <div className="corner top-right"></div>
                                </div>
                                <div className="col-8">
                                    <hr/>
                                </div>
                                <div className="col-2">
                                    <div className="corner left-bottom"></div>
                                </div>
                            </div>

                            <div className="row align-items-center justify-content-end hkvo d-flex">
                                <div className="col-6 text-right">
                                    <h5 className="white h4">01.01.2027</h5>
                                    <p className="white">Neuanlagen und Bestandsliegenschaften – nur noch fernablesbare und interoperable Messgeräte installieren.</p>
                                </div>
                                <div className="col-2 text-center full d-inline-flex justify-content-center align-items-center">
                                    <div className="circle font-weight-bold">4</div>
                                </div>
                            </div>

                            <div className="row timeline">
                                <div className="col-2">
                                    <div className="corner right-bottom"></div>
                                </div>
                                <div className="col-8">
                                    <hr/>
                                </div>
                                <div className="col-2">
                                    <div className="corner top-left"></div>
                                </div>
                            </div>

                            <div className="row align-items-center hkvo d-flex">
                                <div className="col-2 text-center top full-left d-inline-flex justify-content-center align-items-center">
                                    <div className="circle font-weight-bold">5</div>
                                </div>
                                <div className="col-6">
                                    <h5 className="white h4">31.12.2031</h5>
                                    <p className="white">Neuanlagen und Bestandsliegenschaften – nur noch Messgeräte installieren, die fernablesbar, interoperable und Smart-Meter-Gateway kompatibel sind.</p>
                                </div>
                            </div>

                            <p className="text-center py-5">
                                <a type="button" className="btn btn-lg black btn-warning round" onClick={() => {ContactForm.showForm()}}>Jetzt umsteigen</a>
                            </p>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }



    return (
        <Fragment>

            {UI()}

        </Fragment>
    )
}

export default Timeline;

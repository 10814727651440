import React, {Fragment, useEffect, useContext } from 'react';
import { AuthContext } from "../../../../context/Auth/AuthProvider";

import Swal from "@molline/sweetalert2";
import Axios_client from "../../../../utils/axios_client";
import { PermissionsWrapper, hasPermission, MembershipWrapper, hasMembership } from "../../reactPermissionsWrapper";
import HelpProvider from "../../helpProvider/helpProvider";

const UviReportWidget = (props) => {

    const { user } = useContext(AuthContext);
  

    const errorToast = (err) => {
        if (!err) return
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: err?.response ? JSON.stringify(err.response.data) : err,
            showConfirmButton: false,
            customClass: {
                popup: "bg-danger text-white"
            }
        })
    }



    const downloadUviReport = async () => {
        document.getElementById('report_tab_close').click();

        Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/report/report/uvi`)
            .then(response => {
                console.log(response);
                
                const blob = new Blob([response.data]);
                           
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                a.href = url;
                a.download = "uvi_report.csv";
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(err => {
                console.log(err)
                errorToast(err)
            })

    }

    const UI = () => {
       
        return (
            <PermissionsWrapper user={user}>
                    <div className="col-xl-4 col-md-6">
                        <div className="card text-center clickable" >
                            <HelpProvider reportSampleImgPath="/theme2/app-assets/images/reports/sensor_measurement_report.jpg" title="ERAS Jährlich Messbericht" descriptionText="This is an annual sensor measurements report for ERAS import." />
                            <div className="card-body" onClick={() => {downloadUviReport()}}>
                                <div className="mx-auto my-1">
                                    <i className="fa-solid fa-download font-primary fa-4x"></i>
                                </div>
                                <p className="text-muted mb-0 line-ellipsis"
                                    title="">UVI-Report</p>
                            </div>
                        </div>
                    </div>
            </PermissionsWrapper>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )

}

export default UviReportWidget;
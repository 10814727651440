import React, {Fragment, useState, useEffect, useContext, useRef} from 'react';
import {NavLink} from "react-router-dom";
import {erklaerVidoesObjectsArr} from './erklaerVidoesObjectsArr';
import Swal from '@molline/sweetalert2';
import {CookieContext} from "../../../context/CookieMaster/CookieMaster";


const HelpVideos = (props) => {

    const [loading, setLoading] = useState(true);
    const [videos, setVideos] = useState(erklaerVidoesObjectsArr);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedVideo, setSelectedVideo] = useState(null);

    const {setCookie, getCookie, cookieDestroy, generate} = useContext(CookieContext)
    const [cookieConsent, setCookieConsent] = useState(getCookie('CookieConsentErklärVideo'));

    useEffect(() => {

        if(cookieConsent === undefined) {
            cookieConsentDialog().then((result) => {});
        } else {
            setLoading(false);
        }

    }, [loading, cookieConsent]);

    const capitalize = (str) => {
        return `${str.charAt(0).toUpperCase()}${str.slice(1)}`
    }

    const cookieConsentDialog = async () => {
        const { isConfirmed } = await Swal.fire({
            text: 'Um die Erklärvideos zu laden, müssen Sie dem Setzen von Cookies durch Youtube zustimmen',
            showCancelButton: true,
            showCloseButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            confirmButtonText: 'Akzeptieren',
            cancelButtonText: 'Ablehnen',
        });

        if (isConfirmed) {
            setCookie('CookieConsentErklärVideo', 'agreed');
            setCookieConsent('agreed');
            setLoading(false);
        } else {
            setCookieConsent('NotAgreed');

        }
    }

    const handleInputChange = (value) => {
      if(value.length > 0) {
          setSearchQuery(value)
      }else {
          setSearchQuery('');
          setVideos(erklaerVidoesObjectsArr);
      }
    }


    const filterVideosByTags = () => {
        const results = erklaerVidoesObjectsArr.filter((item) => {
            // Check if any tag in the item matches the search query partially
            return item.tags.some((tag) => tag.toLowerCase().includes(searchQuery.toLowerCase()));
        });

        setVideos(results)
    };

/*    const filterVideosByTags = (e) => {
        const queryTags = searchQuery.split(',');
        const results = erklaerVidoesObjectsArr.filter((item) => {
            //  const itemTags = item.tags.split(',');
            return item.some((tag) => tag.includes(searchQuery.toLowerCase()));
        });

        // Update the search results state
        setVideos(results)
    };*/

    const UI = () => {
        return (
            <Fragment>
                <div className="content-header row">
                    <div className="content-header-left col-md-9 col-12 mb-2">
                        <div className="row breadcrumbs-top">
                            <div className="col-12">
                                <h2 className="content-header-title float-start mb-0">Erklärvideos</h2>
                                <div className="breadcrumb-wrapper">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><NavLink className="breadcrumb-navLink" to="/dashboards/analytics"><a>Dashboard</a></NavLink>
                                        </li>
                                        <li className="breadcrumb-item active">Erklärvideos
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <div className="tab-content pt-1">

                                <div className="card">
                                    <div className="card-header border-bottom">
                                        <h4 className="card-title">Erklärvideos </h4>
                                    </div>
                                    <div className="card-body py-2 my-25">
                                        <div className="content-header mt-2">
                                            <h4>MOLLINÉ Connect</h4>
                                            <p className="">
                                                Willkommen auf unserer Plattform! Wir freuen uns, Ihnen eine vielfältige
                                                Sammlung von Erklärvideos präsentieren zu können.
                                                Unsere Videos wurden sorgfältig erstellt, um komplexe Konzepte auf
                                                verständliche Weise zu erklären und Ihnen wertvolles Wissen zu
                                                vermitteln.
                                            </p>
                                        </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="help-video-container">
                                                       <div className="videoSearch">

                                                            <input type="search"  disabled={loading=== true} className="form-control help-video-searchbar" placeholder="Suche"
                                                                   onKeyDown={(event) => event.key === "Enter" ? filterVideosByTags(event) : ''}
                                                                   value={searchQuery} onChange={(e) => handleInputChange(e.target.value)}/>
                                                            <button className="btn btn-primary" disabled={searchQuery === '' || loading === true}   onClick={(e) => filterVideosByTags(e)}><i className="fas fa-search"></i></button>
                                                        </div>

                                                        {loading?
                                                            (
                                                                <div className="text-center ">
                                                                    <div className="spinner-border" style={{width: "5rem", height: "5rem", }} role="status">
                                                                    </div>
                                                                    <div className="">Loading...</div>
                                                                </div>
                                                            )
                                                            :
                                                            (loading === false && cookieConsent == 'agreed' && videos !== undefined && videos.length > 0 ?
                                                                    <span>
                                                                            <div className="media-container">
                                                                                    { videos.map((file, index) => (
                                                                                        <div className="media" key={index} >
                                                                                            {file.type === "video" ?
                                                                                                <div className="video-thumbnail-container" onClick={() => setSelectedVideo(file)}>
                                                                                                    <img  src={`${file.bannerUrl}`}  alt="alternative-text" width="100%" height="200px" />
                                                                                                </div>
                                                                                                :
                                                                                                <></>
                                                                                            }
                                                                                            <div className="video-text-header">
                                                                                                {capitalize(file.title)}

                                                                                                {/*<span id="viewCounter">{file.viewCounter} views </span>*/}
                                                                                            </div>
                                                                                        </div>

                                                                                    ))
                                                                                    }
                                                                            </div>
                                                                                {selectedVideo ?
                                                                                        <div className="popup-media" style={{display: selectedVideo ? 'block' : 'none'}}>
                                                                                            <span onClick={() => setSelectedVideo(null)}>&times;</span>
                                                                                            {
                                                                                                selectedVideo?.type == 'video' ?
                                                                                                    <iframe src={`${selectedVideo?.url}?autoplay=1`} allowFullScreen allow="autoplay"></iframe>
                                                                                                    :
                                                                                                    <></>
                                                                                            }
                                                                                            <div className="video-text-header">
                                                                                                <h3>{capitalize(selectedVideo?.title)}</h3>
                                                                                            </div>


                                                                                        </div>
                                                                                        :
                                                                                        <></>
                                                                                    }
                                                                    </span>
                                                                    :
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            <div className="card">
                                                                                <div className="card-body" style={{"margin": "auto"}}>
                                                                                    {
                                                                                    cookieConsent !== 'agreed' ? <span>Um die Videos zu laden, müssen Sie erst dem Setzen von Cookies durch Youtube <span onClick={cookieConsentDialog} style={{"text-decoration": "underline", "cursor":"pointer"}}>hier</span> zustimmen.</span> :
                                                                                    <span>Scheinbar sind aktuell keine Erklärvideos verfügbar. Bitte versuchen Sie es später erneut.</span>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                            )
                                                        }

                                                    </div>
                                                </div>

                                            </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )


    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )


}


HelpVideos.requiredScope = ['dashboard.view.any', 'dashboard.view.own']
HelpVideos.requireAuth = true;
export default HelpVideos;


import React, {Fragment, useState, useEffect, useContext} from 'react';
import { AuthContext } from '../../context/Auth/AuthProvider';
import { LocaleContext } from '../../context/Locale/Locale';
import { useRouter } from '../Shared/Router/Router'


const Software = ({children}) => {

    const router = useRouter()
    const [updated, setUpdated] = useState(false);
    const {t, locale, localeAvailable, setLang} = useContext(LocaleContext);
//    const {config} = useContext(WhiteLabelContext);



    useEffect(  () => {
        if(!updated){
            setUpdated(true)
        }

    }, [locale]);


    const UI = () => {
        return (
            <Fragment>
                <div id="software" className="section section-5">
                    <div className="container-fluid h-100">
                        <div className="row align-items-center h-100">
                            <div className="col-xl-6 col-lg-6 col-sm-12 text-center">
                                <img className="img-dashboard" src="/homepage/assets/images/products/molline_connect_dashboard.png" alt="Molline Connect Dashboard" />
                            </div>
                            <div className="col-xl-6 col-lg-6 col-sm-12 p-5">
                                <h3 className="text-left h1">Neu. Innovativ.</h3>
                                <p className="text-left">In den letzten Monaten wurde die Heizkostenverordnung (HKVO) an die novellierten Europäischen Energieeffizienz-Richtlinien (EED) angepasst. Mit der Kombination aus Software und dem MOLLINÉ Easy Funk Gateway erhalten Sie mit MOLLINÉ Connect die Lösung für die neuen Herausforderungen. Sie betreffen Verwalter und Eigentümer privater und gewerblicher Immobilien gleichermaßen. So müssen beispielsweise Nutzern monatliche Verbrauchsinformationen aktiv mitgeteilt werden.</p>
                                <p className="text-left">Wir stellen Ihnen hierfür die Verbrauchsdaten von Energie und Wasser zur Verfügung, die Sie benötigen. Entweder für Ihre Abrechnung, die monatliche Verbrauchsinformationen oder tagesaktuell für Ihr Energiedatenmanagement.</p>
                                <a type="button" href="https://www.molline.de/kontakt/" target="_blank" className="btn btn-lg btn-dark round my-1">Jetzt anfragen</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }



    return (
        <Fragment>

            {UI()}

        </Fragment>
    )
}

export default Software;

import React, {Fragment, useEffect, useContext } from 'react';
import {GatewaysContext} from "../../../../context/Devices/Gateways";
import {SensorsContext} from "../../../../context/Devices/Sensors";

const CountKnownSensorsWidget = (props) => {

    const { gateway, gatewayChanged } = useContext(GatewaysContext);
    const { sensors, sensorsChanged } = useContext(SensorsContext);

    useEffect(  () => {
            
    }, [gateway, gatewayChanged, sensors, sensorsChanged]);

    const UI = () => {
        if( !!gateway && gateway?.serials_insight ){
            let insights = [];
            let insight = [];
            if(gateway.serials_insight && gateway.serials_insight.length > 0){
                for(let ins of gateway.serials_insight){
                    insights = insights.concat(ins.serials_insight)
                }
                insight = [...new Set(insights)]
            }
            let count = sensors.filter(sensor => insight.includes(sensor.serial_number))
            return (
                <div className="col-xl-2 col-md-4" id="widgetCountKnownSensors">
                    <div className="card text-center " title="Out of all sensors this gateway can see, this many are registered.">
                        <div className="card-body align-self-center">
                            <div className="mx-auto my-1" style={{height: "75px", display: "inline-flex", alignItems: "center"}}>
                                <i className={`fa-solid fa-microchip fa-4x text-${(count.length > 0)? 'success':'danger'}`}></i>
                            </div>
                            <p className="text-muted mb-0 line-ellipsis">Zähler Bekannte</p>
                            <h2>{ count.length }</h2>
                        </div>
                    </div>
                </div>
            )
        }
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )

}

export default CountKnownSensorsWidget;
import React, {Fragment, useContext, useEffect, useState, useRef} from 'react';
import {AuthContext} from "../../../../context/Auth/AuthProvider";
import OrgCookieCrumb from "../../../../components/Dashboard/orgCookieCrumb";
import Swal from '@molline/sweetalert2';
import {useRouter} from "../../../../components/Shared/Router/Router";
import {PermissionsWrapper, hasPermission} from "../../../../components/Dashboard/reactPermissionsWrapper";
import {OrganizationContext} from '../../../../context/Organization/Organization';
import {GroupsContext} from "../../../../context/Groups/Groups";

import TourSteps from "../../../../TourSteps.json";
import PageTour from "../../../../components/Dashboard/tour/tour";

import de from "../../../../components/Dashboard/tables/datatable.de.json";
const $ = require('jquery')
$.DataTable = require('datatables.net');

const AdminGroups = (props) => {

    const { user } = useContext(AuthContext);
    const { organization } = useContext(OrganizationContext);
    const { groups, createGroup, updateGroup, deleteGroup, groupIcon } = useContext(GroupsContext)

    const router = useRouter()
    const groupsChanged = useCompare(groups)

    useEffect(() => {

        if (groupsChanged === true ) {
            initializeDT()
        }
        
    }, [ user, groups, groupsChanged]);

    function useCompare (val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const initializeDT = () => {
        if ($.fn.dataTable.isDataTable(`#tblGroups`)) {
            $(`#tblGroups`).DataTable().destroy();
            // remove the eventhandler to prevent multiple events 
            $(`#tblGroups tbody`).off('click')
            $(`#tblGroups tbody`).off('mouseenter')
            $(`#tblGroups tbody`).off('mouseleave')
            initializeDT()
        }else {
            $(`#tblGroups`).DataTable({
                language: de,
                processing: true,
                paging: true,
                pagingType: "full",
                pageLength: 50,
                data: groups,
                searching: true,
                orderCellsTop: true,
                rowId: "_id",
                order: [[1,"asc"]],
                columns: [
                    { 
                        "data": "_id",
                        "searchable": true,
                        "visible": false, 
                    },
                    { 
                        "data": "type",
                        "ordering": true,
                        "searchable": true,
                        "visible": true,
                        render: function (type) {
                            return groupIcon({group:{type}, size:1})
                        }
                    },
                    { 
                        "data": "name",
                        "ordering": true,
                        "searchable": true,
                        "visible": true
                    },
                    {
                        "data": "members", 
                        "ordering": true,
                        "searchable": true,
                        "visible": true,
                        render: function (members) {
                            if (!members) return "0";
                            return members?.length;
                        }
                    },
                    {
                        "data": "users",
                        "ordering": true,
                        "searchable": true,
                        "visible": true,
                        render: function (users) {
                            if (!users) return "0";
                            return users?.length;
                        }
                    },
                    {
                        "data": "tags",
                        "ordering": true,
                        "searchable": true,
                        "visible": true,
                        render: function (tags) {
                            if (!tags) return "";
                            return tags;
                        }
                    },
                    {
                        "data": null,
                        "searchable": false,
                        "ordering": false,
                        "className": "text-end",
                        render: function (data, type, row) {
                            return `<div class="dropdown b-dropdown btn-group" id="${data._id}">
                                    <button aria-haspopup="true" type="button" data-bs-toggle="dropdown" class="btnOverflowMenu btn btn-sm dropdown-toggle btn-secondary dropdown-toggle-split" >
                                        <i class="fa-solid fa-bars"></i>
                                    </button>
                                    <ul role="menu" tabindex="-1" class="dropdown-menu dropdown-menu-right" >
                                        ${
                                            (hasPermission({ scopes: ['group.view.any', 'group.view.own'], user: user})) ?
                                            `<li role="presentation" class="viewGroup" >
                                                <a role="menuitem" class="dropdown-item"><i class="text-primary fa-solid fa-magnifying-glass me-25"></i> Details</a>
                                            </li>`:''
                                        }
                                        <hr/>
                                        ${
                                            (hasPermission({   scopes: ['group.delete.any', 'group.delete.any'], user: user})) ?
                                            `
                                            <li role="presentation" class="delGroup">
                                                <a role="menuitem" class="dropdown-item"><i class="text-danger fa-solid fa-trash me-25"></i> Löschen</a>
                                            </li>
                                            ` : ''
                                        }

                                    </ul>
                                </div>`
                        }
                    }
                ],
                createdRow: function (row, data, dataIndex) { },
                "fnDrawCallback": function( oSettings ) {
                    $('.dataTables_length select').addClass('form-select');
                    $('.dataTables_filter input').addClass('form-control');
                    $(`#tblGroups tbody`).on('mouseenter', 'tr', function () {
                        $(this).css('cursor', 'pointer');
                        $(this).css('background-color', '#f3f2f7');
                    });
                    $(`#tblGroups tbody`).on('mouseleave', 'tr', function () {
                        $(this).css('background-color', 'transparent');
                    });
                    $(`#tblGroups tbody`).on('click', 'li.viewGroup', function (e) {
                        let link = e.currentTarget;
                        let parent = link.closest('tr');
                        router.push(`/details/group/${parent.id}`);
                    });
                    $(`#tblGroups tbody`).on('click', 'li.delGroup', function (e) {
                        let link = e.currentTarget;
                        let parent = link.closest('tr');
                        deleteGroup(parent.id).then(()=>{});
                    });
                    $(`#tblGroups tbody tr td`).off('click').on('click', function (e) {
                        const tagName = e.target.tagName;
                        if(tagName === "TD") {
                            let id = e.target.closest('tr').id;
                            router.push(`/details/group/${id}`);
                        }
                    });
                }
            });
            
        }
    }
  
    const UI = () => {
        const isProcessing = () => {
            if (groups.length === 0 && !groupsChanged) {
                return (
                    <div className="mt-4">
                        <h4 className="text-center"><i class="fa-solid fa-cog fa-spin"></i> WIRD GELADEN ...</h4>
                    </div>
                )
            }
        }
        return (
            <Fragment>

                <div className="content-header row">
                    <div className="content-header-left col-md-9 col-12 mb-2">
                        <div className="row breadcrumbs-top">
                            <div className="col-9 ">
                                <h2 className="content-header-title float-start mb-0">Gruppen</h2>
                                <div className="breadcrumb-wrapper">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a>Verwaltung</a></li>
                                        <li className="breadcrumb-item active">Gruppen
                                        </li>
                                        <li>
                                            <span className="tourBorderRight" />
                                            <PageTour steps={TourSteps.Groups.overview} isButton={false} />
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <OrgCookieCrumb />
                </div>

                <div className="content-body">
                    <section id="tabpanels">
                        <div className="row">
                            <div className="col-12">
                                <div class="tab-content pt-1">

                                    <div className="card">
                                        <div  className="card-header border-bottom">
                                            <h4 className="card-title">Gruppen (Nutzer-Mieteinheiten ) verwalten   </h4>
                                            <div className="heading-elements">
                                                <div className="heading-elements">
                                                    <PermissionsWrapper scopes={['organization.create.any']} user={user}>
                                                        <button className='btn btn-primary'
                                                                id="groupCreateBtn"
                                                                onClick={() => createGroup()}>
                                                            <i className="fa-solid fa-plus"> </i>
                                                        </button>
                                                    </PermissionsWrapper>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="card-body py-2 my-25">

                                        <div className="table-responsive">
                                            <table id="tblGroups" className="table mb-0 row-border hover order-column " role="grid">
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th></th>
                                                        <th>Gruppenname</th>
                                                        <th>Zugewiesen</th>
                                                        <th>Verwalter</th>
                                                        <th>Tags</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                            </table>
                                            {isProcessing()}
                                        </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>

            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}
AdminGroups.requiredRole =['realm_manager', 'realm_admin'];
AdminGroups.requireAuth = true;  // true -> force login via AuthContext, false -> no login required so no user available either
export default AdminGroups;
import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import Swal from '@molline/sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import de from '../datatable.de.json'
import {faPlus} from "@fortawesome/free-solid-svg-icons";

import { AuthContext } from '../../../../context/Auth/AuthProvider';
import { useRouter } from "../.././../Shared/Router/Router";
import {PermissionsWrapper, hasPermission } from '../../reactPermissionsWrapper';
import { MgmtUsersContext } from '../../../../context/Users/UsersContext';
import CapitalizeUtils from '../../../../utils/capitalizeUtils'

const $ = require('jquery');
$.DataTable = require('datatables.net');

let dateWithTime = new Intl.DateTimeFormat('de-DE', {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
});

const TblComments = (props) => {
    const commentsChanged = useCompare(props.comments)
    const { user } = useContext(AuthContext);
    const { mgmtUsers, getUser } = useContext(MgmtUsersContext)
    const router = useRouter();

    useEffect(() => {
        if (commentsChanged === true) {
            initializeDT()
        }
    }, [commentsChanged, props.comments, mgmtUsers]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }


    const refreshDataTables = () => {
        $('#tblComments').DataTable().destroy();
        $('#tblComments').off('click', 'button.comment-delete-btn')
        $(`#tblComments tbody`).off('click')
        initializeDT();
    }

    const showUserModal = async (user_id) => {
        try {
            const user = await getUser(user_id)
            if(user !== "{}") Swal.fire(`${CapitalizeUtils.capitalize(user.first_name)} ${CapitalizeUtils.capitalize(user.last_name)}`); 
        } catch (err) {
            console.log('error at getting user', err);
        }
    }

    const initializeDT = () => {
        let table;
        if(props.comments === undefined) { 
            props.comments = []
        }
        if ($.fn.dataTable.isDataTable('#tblComments')) {
            refreshDataTables()
        } else {
            const sortedComments = [...props.comments]
            sortedComments.sort(function(a,b){
                return new Date(b.created_at) - new Date(a.created_at);
              });
            table = $('#tblComments').DataTable({
                force: false,
                order: [],
                data: sortedComments,
                language: de,
                columns: [
                    {
                        "data": null,
                        "searchable": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if (data.created_at !== undefined) {
                                return dateWithTime.format(new Date(data.created_at))
                            } else {
                                return '';
                            }
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if (data.author !== undefined) {
                                return `<span ${hasPermission({user, scopes:['user.view.any']}) ? 'role="button"' : ''} class="author-href" data-user_id="${data.author}">${data.author} 
                                    <i class="fa-light fa-circle-info"></i>
                                </span>`
                            } else {
                                return '';
                            }
                        }
                    },
                    { "data": "comment" },
                    {
                        "data": null,
                        "targets": -1,
                        render: function (data, type, row) {
                            if(hasPermission({user, scopes:['comment.delete.any', 'comment.delete.own']})) {
                                return '<div style="text-align:right"><button id="' + data._id + '" class="btn btn-danger btn-sm comment-delete-btn"><i class="fa-solid fa-close"/></button></div>'
                            }
                            return ''
                        }
                    }
                ],
                options: {
                    "rowId": "_id",
                    drawCallback: function (config) {
                        $('#tblComments tbody tr').off('mouseenter').on('mouseenter', function () {
                            $(this).css('cursor', 'pointer');
                            $(this).css('background-color', '#f3f2f7');
                        });

                        $('#tblComments tbody tr').off('mouseleave').on('mouseleave', function () {
                            $(this).css('background-color', 'transparent');
                        });

                    }
                }
            })
            $('.dataTables_length select').addClass('form-select');
            $('.dataTables_filter input').addClass('form-control');

            $('#tblComments').on('click', 'button.comment-delete-btn', async function (e) {
                e.preventDefault();
                const doSave = await doYouReallyWantToSave()
                if (!doSave) {
                    return
                }
                let comment_id = $(this).attr('id')
                try {
                    await props.deleteComment(comment_id)
                    successToast('Kommentar wurde erfolgreich gelöscht')
                    if (!$.fn.DataTable.isDataTable('#tblComments'))
                        return;
                    $('#tblComments').DataTable().row($(this).parents('tr'))
                        .remove()
                        .draw();
                } catch (e) {
                    console.log('error at deleting comment', e);
                    errorToast('Anmerkung konnte nicht gelöscht werden')
                }
            })
            $('#tblComments tbody').on('click', '.author-href', async function () {
                if(hasPermission({user, scopes:['user.view.any']})) {
                    showUserModal(this.dataset.user_id)
                }
            })
        }
    }

    const doYouReallyWantToSave = () => {
        return new Promise(async (resolve) => {
            if (window.sessionStorage && !window.sessionStorage.getItem("yeswecan")) {
                const answer = await Swal.fire({
                    title: 'Sicher?',
                    input: 'checkbox',
                    text: 'Damit werden die Daten gespeichert',
                    inputPlaceholder: 'Nicht mehr fragen',
                    showCancelButton: true,
                    confirmButtonText: 'Ja',
                    cancelButtonText: "Nein",
                })
                if (!answer.isConfirmed)
                    return resolve(false);
                if (answer.value === 1) {
                    window.sessionStorage.setItem("yeswecan", true);
                    return resolve(true);
                }
            }
            return resolve(true);
        })
    }

    const addComment = () => {
        Swal.fire({
            title: 'Kommentar erstellen',
            width: '60%',
            html:
                '<div class="text-left" style="text-align:left">' +
                '<div class="mb-1">' +
                '<label class="form-label" for="taComment">Kommentar</label>' +
                '<textarea id="taComment" class="form-control border-success" rows="3" maxlength="500"></textarea>' +
                '<div class="float-end"><small><span id="taCommentLen">0</span> / 500</small></div>' +
                '</div>' +
                '</div>',
            showCloseButton: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Speichern',
            cancelButtonText: 'Abbrechen',
            didOpen: () => {
                let q = Swal.getPopup().querySelector('#taComment');
                q.addEventListener("keydown", () => {
                    Swal.getPopup().querySelector('#taCommentLen').innerHTML = (500 - q.value.length)
                })
            },
            preConfirm: () => {
                const comment = Swal.getPopup().querySelector('#taComment').value;
                if (comment.length > 500 || comment.length < 4) return Swal.showValidationMessage(`Minimal 4 und maximal 500 Zeichen!`);
                if (!comment) Swal.showValidationMessage(`Bitte füllen Sie alle Teile dieses Formulars aus`);
                return { comment }
            },
        })
            .then(async results => {
                if (results.dismiss) return;
                try {
                    await props.addComment(results.value.comment)
                    if (!$.fn.DataTable.isDataTable('#tblComments'))
                        return;
                    successToast('Kommentar wurde erfolgreich erstellt')
                } catch (e) {
                    console.log('err', e);
                    errorToast('Kommentar konnte nicht erstellt werden')
                }

            })
    }

    const successToast = (msg) => {
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: msg,
            showConfirmButton: false,
            customClass: {
                popup: "bg-success"
            }
        })
    }

    const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const errorToast = (err) => {
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: err,
            showConfirmButton: false,
            customClass: {
                popup: "bg-danger"
            }
        })
    }

    const UI = () => {

        return (
            <Fragment>
                <div className="card">
                    <div className="card-header border-bottom">
                        <h4 className="card-title">Anmerkungen </h4>
                        <PermissionsWrapper
                            user={user}
                            scopes={['comment.create.own']}
                        >
                            <button className="btn btn-primary" type='button' onClick={() => addComment()}>
                                <FontAwesomeIcon className="" size="1x" icon={faPlus} />
                            </button>
                        </PermissionsWrapper>
                    </div>
                    <div className="card-body py-2 my-25">

                    <div className="table-responsive">
                            <table id="tblComments" className="table mb-0 row-border hover order-column " role="grid">
                                <thead>
                                    <tr>
                                        <th>Datum</th>
                                        <th>Autor</th>
                                        <th>Kommentar</th>
                                        <th></th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>{UI()}</Fragment>
    )



}

export default TblComments;
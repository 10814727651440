import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';
import { AuthContext } from "../../../context/Auth/AuthProvider";
import { LocationsContext } from "../../../context/Locations/Locations";
import { OrganizationContext } from "../../../context/Organization/Organization";
import { SensorsContext } from "../../../context/Devices/Sensors";
import { MgmtUsersContext } from "../../../context/Users/UsersContext";
import { GatewaysContext } from "../../../context/Devices/Gateways";
import { GroupsContext } from "../../../context/Groups/Groups";
import { SocketContext } from "../../../context/Socket/Sockets";
import OrgCookieCrumb from "../../../components/Dashboard/orgCookieCrumb";
import { useRouter } from "../../../components/Shared/Router/Router"


import Swal from '@molline/sweetalert2'
import PageTour from "../../../components/Dashboard/tour/tour";
import TourSteps from "../../../TourSteps.json"

import CountMultiPremiseLocationsWidget from '../../../components/Dashboard/widgets/countLocations_multi_premise';
import CountSinglePremiseLocationsWidget from '../../../components/Dashboard/widgets/countLocations_single_premise';
import CountSensorsWidget from '../../../components/Dashboard/widgets/countSensors';
import SensorsCoverageWidget from '../../../components/Dashboard/widgets/sensors/wmbusSensorCoverage';
import SelectLocationWidget from '../../../components/Dashboard/widgets/locations/selectLocationWidget';
import ConsumptionChartsWidget from '../../../components/Dashboard/widgets/locations/consumptionChartsWidget';
import CountGatewaysWidget from '../../../components/Dashboard/widgets/gateways/countGateways'
import CountEmptyLocationWidget from '../../../components/Dashboard/widgets/locations/countEmptyLocation';

import ReportUviReportWidget from '../../../components/Dashboard/widgets/locations/reportUviReport';
import ReportUviReportPackage from '../../../components/Dashboard/widgets/locations/reportUviReportPackage';
import ReportSensorMeasurementsWidget from '../../../components/Dashboard/widgets/sensors/reportSensorMeasurements';
import {hasPermission, PermissionsWrapper} from "../../../components/Dashboard/reactPermissionsWrapper";
import CountTotalSensorsCoverage from "../../../components/Dashboard/widgets/sensors/countTotalSensorsCoverage";


const statusToast = ({ title, message, timer  }) => {
    let params = {
        toast: true,
        position: 'bottom-end',
        allowOutsideClick: true,
        showConfirmButton: false,
        showCloseButton: true,
        timer: timer!== null ? timer : 7000,
        timerProgressBar: true,
        customClass: {
            popup: "bg-success text-white"
        }
    };
    if (title) {
        params.title = title
    }
    if (message) {
        params.html = message
    }
    if (!title && !message) return;
    return Swal.fire(params)
}


/** 

const LocationQuickDetailsComponent = React.memo(({ locations, setSelectedLocation, selectedLocation }) => {
    const { user } = useContext(AuthContext);
    const router = useRouter()

    if (!locations || locations.length <= 0 || !selectedLocation) return (<Fragment></Fragment>)
    return (
        <Fragment>
            <div className="col-12 dahboard_location_dropdown" id="dahboard_location_dropdown">
                <div className="card mb-1">
                    {locations.length === 1 ?
                        <div className="card-header border-bottom">
                            <h4 className="card-title">{getLocationTypeIcon(selectedLocation)} {getLocationAddress(selectedLocation)}</h4>
                        </div>
                        : locations.length > 1 &&
                        <div className="card-header border-bottom" style={{ padding: 0 }}>
                            <div className="input-group" style={{ border: 0, fontSize: "1.285rem" }}>
                                <span className="input-group-text" style={{ border: 0, fontSize: "1.285rem", padding: "0rem 1.35rem" }}> {getLocationTypeIcon(selectedLocation)} </span>
                                <select className="form-select" name="type" style={{ border: 0, fontSize: "1.285rem", fontWeight: "500", padding: "1.2rem 1rem 1.2rem 0.5rem" }} onChange={(e) => {
                                    const id = e?.target?.value;
                                    if (!id) return;
                                    const loc = locations?.find((l) => l._id === id);
                                    if (loc) {
                                        setSelectedLocation(loc)
                                    }
                                }} >
                                    {
                                        locations.map(loc => {
                                            return (
                                                <option key={uniqId()} selected={selectedLocation?._id === loc?._id} value={loc._id}>{getLocationAddress(loc)}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="dashboardLocationDetail" style={{ display: "flex", flexDirection: "row", flex: 1 }}>
                <div className="dashboardLocationDetailButtons" >
                    <div className="card me-1 mb-1" >
                        <div className="card-body p-1 clickable dashboardLocationDetailbtn"  id="dashboardLocationDetailbtn" data-bs-toggle="tooltip" data-bs-placement="left" title="Liegenschaftsdetails"
                            onClick={(e) => router.push('/details/location/' + selectedLocation?._id)}>
                            <FontAwesomeIcon icon={faMagnifyingGlass} className="text-primary" size="2x" />
                        </div>
                    </div>
                    {(user?.roles?.includes('god') || user?.scopes?.includes('report.generate.consumption')) &&
                        <div className="card me-1 mb-1 consumption_report_dwn_btn" id="consumption_report_dwn_btn">
                            <div className="card-body p-1 clickable" data-bs-toggle="tooltip" data-bs-placement="left" title="Verbrauchsbericht herunterladen"
                                onClick={(e) => downloadConsumptionReport(selectedLocation?._id)}>
                                <FontAwesomeIcon icon={faDownload} className="text-primary" size="2x" />
                            </div>
                        </div>
                    }
                </div>
                <div className="d-flex flex-fill flex-shrink flex-grow row consumption_charts" id="consumption_charts"  style={{ justifyContent: "stretch", alignItems: "flex-start", flexWrap: "wrap" }}>
                    <ConsumptionChart
                        location={selectedLocation}
                        showPlaceholder={true}
                        showLocationAddress={false}
                        height={240}
                    />
                </div>
            </div>
        </Fragment>
    )
})
**/
const DashboardAnalytics = (props) => {
    const router = useRouter()

    const [updated, setUpdated] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [sensorsSafe, setSensorsSafe] = useState([]);

    const { user } = useContext(AuthContext);
    const { locations, location } = useContext(LocationsContext);
    const { organizations, organization } = useContext(OrganizationContext);
    const { mgmtUsers } = useContext(MgmtUsersContext);
    const { socket } = useContext(SocketContext);

    const { sensors } = useContext(SensorsContext);
    const { gateways } = useContext(GatewaysContext);
    const { groups, group } = useContext(GroupsContext);



    const locationsChanged = useCompare(locations);
    const locationChanged = useCompare(location);
    const organizationChanged = useCompare(organization);

    const sensorsChanged = useCompare(sensors);
    const gatewaysChanged = useCompare(gateways);
    const usersChanged = useCompare(mgmtUsers)

    useEffect(() => {
        if(!updated) {
            if (window.sessionStorage && !window.sessionStorage.getItem("welcomeDone")) {
                statusToast({
                    title: "Willkommen bei Molliné-Connect",
                    message: `<p>Die Plattform wird Ihnen ab sofort die <strong>Verbrauchinformationen Ihrer Messageräte</strong> zur Verfügung stellen.<br />
                              <br />
                              Bei Fragen, fehlenden Daten oder Unklarheiten <strong>wenden Sie sich bitte an Ihren zuständigen Verwalter</strong>.</p>`,
                    timer: 5000,
                }).finally(() => window.sessionStorage.setItem("welcomeDone", true));
            }
            setUpdated(true)
        }


    }, [locations, locationsChanged, gateways, gatewaysChanged, sensors, sensorsChanged, organization, organizationChanged, updated])

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const UI = () => {

        return (
            <Fragment>
                <div className="content-header row">
                    <div className="content-header-left col-md-9 col-12 mb-2">
                        <div className="row breadcrumbs-top">
                            <div className="col-9">
                                <h2 className="content-header-title float-start mb-0">Dashboard</h2>
                                <div className="breadcrumb-wrapper">
                                    <ol className="breadcrumb">
                                        {(user?.roles?.includes('tenant')) ?
                                            <li className="breadcrumb-item active">Nutzer</li>
                                            :
                                            <li className="breadcrumb-item active">Analyse
                                            </li>
                                        }
                                        <li>
                                            <span className="tourBorderRight"></span>
                                            <PageTour steps={TourSteps.analytics.overview} isButton={false}/>
                                        </li>

                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <OrgCookieCrumb />
                </div>
                <div className="content-body">
                    <section id="consumption-stats">
                        <div className="row equal-height">
                            <CountMultiPremiseLocationsWidget />
                            <CountSinglePremiseLocationsWidget />
                            <CountEmptyLocationWidget />

                            <CountSensorsWidget />
                            <CountTotalSensorsCoverage />

                            <PermissionsWrapper
                                user={user}
                                scopes={['gateway.view.any', 'gateway.view.own']}>
                                <CountGatewaysWidget />
                            </PermissionsWrapper>



                        </div>
                        <div className="row match-height">
                              <SelectLocationWidget />
                              <ConsumptionChartsWidget />
                        </div>

                        <div className="row match-height">
                            <ReportUviReportWidget />
                            <ReportUviReportPackage />
                            <ReportSensorMeasurementsWidget />
                        </div>

                    </section>
                </div>

            </Fragment>
        )
    }

    return (
        <Fragment>

            {UI()}
        </Fragment>

    )


}

DashboardAnalytics.requiredScope = ['dashboard.view.any', 'dashboard.view.own']
DashboardAnalytics.requireAuth = true;
export default DashboardAnalytics;
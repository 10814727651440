import React, { useState, useEffect, createContext, Fragment, useContext, useRef } from 'react';
import ProgressBar from '../../components/Dashboard/progress/bar_linear'
import { SocketContext } from '../Socket/Sockets';
import { AuthContext } from "../Auth/AuthProvider";

export const ProgressIndicatorContext = createContext();

const ProgressProvider = ({ children, context }) => {

    const {socket, socketId} = useContext(SocketContext)

    const [progressItemList, setProgressItemList] = useState([])
    const [progressCompleteList, setProgressCompleteList] = useState([])
    const [progressErrorList, setProgressErrorList] = useState([])
    const { user } = useContext(AuthContext);


    const [loadingActive, setLoadingActive] = useState(false)
    const progressListChanged = useCompare(progressItemList)
    
    useEffect(() => {

        listen()

    }, [progressItemList, progressCompleteList, progressListChanged, progressErrorList, socketId])

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const listen = () => {
        if(socketId) socket.on('progress', (data) => { 

            //console.log('SOCKET MSG RECVD', data)

            setLoadingActive(true)
            updateProgressItem(data)          
        })
    }

    const updateProgressItem = (progressObj) => {
        let progressList = [...progressItemList]
        let completeList = [...progressCompleteList]
        let errorList = [...progressErrorList]

        let progressItemIndex = progressList.findIndex(l => l._id === progressObj._id)
        if( +progressItemIndex < 0 && progressObj?.progress) progressList.unshift(progressObj) //If progress and does not exist
        if( progressObj?.progress && +progressItemIndex > -1 ) progressList[progressItemIndex] = progressObj //update progress if exists
        if( +progressItemIndex > -1 &&  progressObj?.results ){ //handle removing from progressList and placing into progressCompleteList
            progressList.splice(+progressItemIndex, 1)
            completeList.unshift(progressObj)
            setProgressCompleteList(completeList)
        }

        if( progressObj?.error ){ //handle removing from progressList and placing into progressErrorList
            if(+progressItemIndex > -1) progressList.splice(+progressItemIndex, 1)
            errorList.unshift(progressObj)
            setProgressErrorList(errorList)
        }

        setProgressItemList(progressList)
    }
    
    const actions = {
        updateProgressItem
    }

    const removeItem = (_id) => {
        let completeList = [...progressCompleteList]
        let index = completeList.findIndex(l => l._id === _id)
        completeList.splice(index, 1);
        setProgressCompleteList(completeList)
    }

    const removeErrorItem = (_id) => {
        let errorList = [...progressCompleteList]
        let index = errorList.findIndex(l => l._id === _id)
        errorList.splice(index, 1);
        setProgressErrorList(errorList)
    }

    return (
       
        <Fragment>
            <ProgressIndicatorContext.Provider value={actions}>
                {
                    (!!loadingActive) ? 
                    <Fragment>
                        <div className={`collapse card ${ (!!loadingActive)? 'show':''}` } id="progressContainer">
                            <div className="card-title border-bottom" id="progress-title-bar">
                                <h2 className='float-start'>Downloads </h2><span className="progressClose float-end mb-1" onClick={() => setLoadingActive(false)}><i class="text-secondary text-muted fa-solid fa-rectangle-xmark fa-2x" id="close-lager"></i></span>
                            </div>
                            <div className="card-body" id="progress-items-list">
                                {progressItemList.map( item => {
                                        return (
                                            <Fragment>
                                            <div className="progress_content" key={`progressContent_${item._id}`} id={`progressContent_${item._id}`}  >
                                                <div className="progress_content_body" id={`progressContentBody_${item._id}`}>
                                                    <div className="" >
                                                        <div className=" progress-bar-animated" id={item._id}>
                                                            <ProgressBar 
                                                                id={item._id}
                                                                height="1"
                                                                value={item.progress}
                                                            />
                                                        
                                                        </div>
                                                    </div>
                                                    <div id="currentcurrProgressTitle" className="currProgressTitle">{item.title}</div>
                                                </div>
                                            </div>
                                            <hr />
                                            </Fragment>
                                        )
                                    })
                                }
                                {
                                    progressCompleteList.map(item => {
                                        return (
                                            <Fragment>
                                                <div className='row'>
                                                    <div className="col-1">
                                                        <i className="fa-solid fa-arrow-down-to-line me-50"></i>
                                                    </div>
                                                    <div className="col-9">
                                                        <a  href={item.results} target="_blank" >{item.title}</a>
                                                    </div>
                                                    <div className="col-1">
                                                        <i className="text-danger fa-solid fa-trash" onClick={() => removeItem(item._id)}></i>
                                                    </div>
                                                </div>
                                            
                                            <hr />
                                            </Fragment>
                                        )
                                    })
                                }
                                {
                                    progressErrorList.map(item => {
                                        return (
                                            <Fragment>
                                                <div className='row'>
                                                    <div className="col-1">
                                                        <i className="text-danger fa-solid fa-triangle-exclamation me-50"></i>
                                                    </div>
                                                    <div className="col-9">
                                                        <span className="text-danger">Error: {item.title}: {item.error} </span>
                                                    </div>
                                                    <div className="col-1">
                                                        <i className="text-danger fa-solid fa-trash" onClick={() => removeErrorItem(item._id)}></i>
                                                    </div>
                                                </div>
                                            
                                            <hr />
                                            </Fragment>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </Fragment> 
                    :
                    (!!user && (progressItemList.length > 0 || progressCompleteList.length > 0 || progressErrorList.length > 0))?
                    <Fragment>
                        <div className="progress-collapsed" onClick={() => setLoadingActive(true)} id="progressStatusWidget">
                            <i className="fa-solid fa-arrow-down-to-line fa-4x text-info "></i>
                        </div>
                    </Fragment>
                    : <></>
                }
                {children}
            </ProgressIndicatorContext.Provider>
        </Fragment>
    )

}

export default ProgressProvider;
import React, {Fragment, useEffect, useContext } from 'react';
import { AuthContext } from "../../../../context/Auth/AuthProvider";
import {SensorsContext} from "../../../../context/Devices/Sensors";
import { LocationsContext } from "../../../../context/Locations/Locations";
import { OrganizationContext } from "../../../../context/Organization/Organization";

import { CSVLink, CSVDownload } from "react-csv";
import { Buffer } from "buffer";
import Swal from "@molline/sweetalert2";
import Axios_client from "../../../../utils/axios_client";
import { PermissionsWrapper, hasPermission, MembershipWrapper, hasMembership } from "../../reactPermissionsWrapper";
import HelpProvider from "../../helpProvider/helpProvider";

const ReportSensorsChangedWidget = (props) => {

    const { user } = useContext(AuthContext);
    const { locationsChanged, location, locations, locationChanged } = useContext(LocationsContext);
    const { organization, organizationChanged } = useContext(OrganizationContext);
    const { sensors, sensorsChanged, sanitizedSensor } = useContext(SensorsContext);

    useEffect(  () => {
            
    }, [ organization, organizationChanged ]);


    const replacedSensorsReport = async () => {
        Swal.fire({
            icon: "question",
            title: `Report zu ausgetauschten Zählern generieren`,
            allowOutsideClick: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            background: localStorage.getItem('theme-preference') === 'dark-layout' ? '#161D31' : '#fff',
            showCancelButton: true,
            confirmButtonText: 'Erstellen',
            cancelButtonText: 'Abbrechen',
            text: `Wenn Sie auf "Erstellen" klicken, wird ein CSV-Bericht mit allen ausgetauschten Zählern heruntergeladen.`,
            preConfirm: () => {}
        })
        .then(async (results) => {
            if (!results.isConfirmed || results.isDenied || results.isDismissed) return;
            try {
                const response = await Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/report/sensors/closed?organization_id=${organization._id}`)
                return response.data
            } catch (e) {
                console.log('error at getting report for replaced sensors', e);
                throw new Error(e)
            }
        })
    }

    const UI = () => {
        return (
            <PermissionsWrapper user={user} scopes={['sensor.update.any', "sensor.update.own"]}>
                <div className="col-xl-4 col-md-6">
                    <div className="card text-center clickable" >
                        <HelpProvider reportSampleImgPath="" title="Ausgetauschte Zähler" descriptionText="Laden Sie hier einen Bericht zu allen bisher ausgetauschten Zähler herunter" />
                        <div className="card-body" onClick={() => replacedSensorsReport()}>
                            <span className="icon-report">
                                <i className="fa-solid fa-right-left fa-4x"></i>
                            </span>
                            <p className="text-muted mb-0 line-ellipsis">Ausgetauschte Zähler</p>
                            <h2 className="mb-0">Bericht</h2>
                        </div>
                    </div>
                </div>
            </PermissionsWrapper>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )

}

export default ReportSensorsChangedWidget;
import React, { Fragment, useEffect, useContext, useState } from 'react';
import { GatewaysContext } from "../../../../context/Devices/Gateways";

const CountGatewaysWidget = (props) => {

    const { gateways, gatewaysChanged } = useContext(GatewaysContext);

    const [countGateways, setCountGateways] = useState(0)
    const [countGatewaysDelinquent, setCountGatewaysDelinquent] = useState(0)

    useEffect(() => {
        if (!!gateways && gateways.length !== 0) resolveGateways()
    }, [gateways, gatewaysChanged]);

    const resolveGateways = async () => {

        const countGatewaysDelinquent = () => {
            return new Promise((resolve) => {
                let i = 0, count = 0;
                let today = new Date();
                gateways.map(gateway => {
                    i++
                    let last = new Date(gateway.last_transmission)
                    const hours = Math.floor((Math.abs(today - last) / 1000) / 3600);
                    if (hours > 72 || gateway.last_transmission === undefined) count++;
                    if (i === gateways.length) return resolve(count)
                })
            })
        }

        if (gateways.length > 0) {
            Promise.all([countGatewaysDelinquent()])
                .then(results => {
                    setCountGateways(gateways.length);
                    setCountGatewaysDelinquent(results[0]);
                })
        }
    }

    const UI = () => {
        return (
            <div className="col-xl-2 col-md-4" id="widgetCountGateways">
                <div className="card text-center " title="Anzahl an Gateways, die vor kurzem Kontakt hatten im Verhältnis zu allen Gateways">
                    <div className="card-body align-self-center">
                        <div className="mx-auto my-1" style={{display: "inline-flex", alignItems: "center"}}>
                        <i className="fa-solid fa-router fa-4x"/>
                        </div>
                        <p className="mb-0 text-muted">Gateways</p>
                        <h2 className="mb-0 line-ellipsis ">{`${countGateways - countGatewaysDelinquent}/${countGateways}`}</h2>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )

}

export default CountGatewaysWidget;
import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';
import Swal from '@molline/sweetalert2'
import {useRouter} from "../../../Shared/Router/Router";
import {AuthContext} from "../../../../context/Auth/AuthProvider";
import {GroupsContext} from "../../../../context/Groups/Groups";
import de from "../datatable.de.json";
import capitalizeUtil from '../../../../utils/capitalizeUtils';
import { LocationsContext } from '../../../../context/Locations/Locations';
import { OrganizationContext } from '../../../../context/Organization/Organization';
import Axios_Client from '../../../../utils/axios_client';

const $ = require('jquery')
$.DataTable = require('datatables.net');


const TblLocationGroups = (props) => {

    const propsGroupsChanged = useCompare(props.locationGroups)
    const router = useRouter()
    const { user } = useContext(AuthContext);
    const { getLocationGroups  } = useContext(LocationsContext)
    const { organizations } = useContext(OrganizationContext)

    useEffect(() => {
        if (propsGroupsChanged === true) {
            initializeDT()
        }
    }, [propsGroupsChanged, props]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const errorToast = (err) => {
        if(!err) return
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: err,
            showConfirmButton: false,
            customClass: {
                popup: "bg-danger text-white"
            }
        })
    }

    const successToast = (msg) => {
        if(!msg) return
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: msg,
            showConfirmButton: false,
            customClass: {
                popup: "bg-success text-white"
            }
        })
    }

    const toggleBillingCustomer = async ({ location_id }) => {
        let billingStatus = "billing"
        let multiPremise = props.locationGroups.filter(loc => loc._id === location_id)
        if(multiPremise.length === 0) errorToast('Fehler')
        multiPremise = multiPremise[0]
        if(multiPremise.attributes.includes('billing')) billingStatus = "no_billing"

        let text = '<span>Liegenschaft als Abrechnungskunde markieren</span>'
        if (billingStatus === 'no_billing') text = '<span>Liegenschaft als Nicht-Abrechnungskunden markieren?</span>'

        text += '</br><small>Damit werden alle untergeordneten Mieteinheiten auch aktualisiert</small>'
        const answer = await Swal.fire({
            title: 'Sicher?',
            html: text,
            showCancelButton: true,
            confirmButtonText: 'Ja',
            cancelButtonText: "Nein",
        })
        if (!answer.isConfirmed) return


        try {
            await Axios_Client.backendClient().put(`${process.env.REACT_APP_API_OPENMETER}/location/billing?location_id=${location_id}&billing_status=${billingStatus}`)
            getLocationGroups()
        } catch (err) {
            console.log('error at updating location', err);
            errorToast('Abrechnungs-Status konnte nicht bearbeitet werden')
        }
        successToast('Abrechnungs-Status wurde erfolgreich bearbeitet')
    }

    const initializeDT = () => {
        if ($.fn.dataTable.isDataTable(`#${props.id}`)) {
            $(`#${props.id}`).DataTable().destroy();
            $(`#${props.id} tbody`).off('click')
            $(`#${props.id} tbody`).off('mouseenter')
            $(`#${props.id} tbody`).off('mouseleave')
            initializeDT()
        }else {
            $(`#${props.id}`).DataTable({
                "language": de,
                "processing": true,
                data: props.locationGroups,
                rowId: "_id",
                order: [[1,"asc"]],
                pageLength: 100,
                columns: [
                    { "data": "_id" },
                    {
                        "data": null,
                        "searchable": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            return `${capitalizeUtil.capitalize(data.address.street)} ${data.address.house_number} ${(data.address.street1 !== '') ? ', ME ' + capitalizeUtil.capitalize(data.address.street1) : ''}, ${capitalizeUtil.capitalize(data.address.city)}, ${data.address.post_code}`
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if(data?.group?.name) return data.group.name
                            else return ''
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            const org = organizations.filter(org => org._id === data.organization_id)
                            if(org.length !== 0) return org[0].name
                            return ''
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if (data?.attributes !== undefined && data?.attributes.includes('billing')) {
                                return `<div><i class="text-success font-icon-custom-size fa-solid fa-file-invoice billingCustomer" style="margin-left: 50%"></i></div>`
                            } else if (data?.attributes !== undefined && data?.attributes.includes('no_billing')) {
                                return `<div><i class="text-danger font-icon-custom-size fa-solid fa-file-invoice billingCustomer" style="margin-left: 50%"></i></div>`
                            } else {
                                return `<div><i class="text-primary font-icon-custom-size fa-solid fa-file-invoice billingCustomer" style="margin-left: 50%"></i></div>`
                            }
                        },
                    },
                ],
                createdRow: function (row, data, dataIndex) {
                    $(row).attr('data-location_id', data._id || 'na');
                    //       $(row).addClass('alert-warning');
                },
                "fnDrawCallback": function (oSettings) {
                    

                    $('.dataTables_length select').addClass('form-select');
                    $('.dataTables_filter input').addClass('form-control');

                    $(`#${props.id} tbody tr`).off('mouseenter').on('mouseenter', function () {
                        //$(this).css('cursor', 'pointer');
                        $(this).css('background-color', '#f3f2f7');
                    });

                    $(`#${props.id} tbody tr`).off('mouseleave').on('mouseleave', function () {
                        $(this).css('background-color', '#fff');
                    });

                    $(`#${props.id} tbody tr td`).off('mouseenter').on('mouseenter', function (e) {
                        e.target.closest('td').classList.add('cursor-pointer');
                    });

                    $(`#${props.id} tbody tr td`).off('mouseleave').on('mouseleave', function (e) {
                        e.target.closest('td').classList.remove('cursor-pointer');
                    });

                    $(`#${props.id} tbody`).off('click').on('click', 'i.billingCustomer', function (e) {
                        let link = e.currentTarget;
                        let parent = link.closest('tr');
                        toggleBillingCustomer({ location_id: parent.id})
                    });
                }
            });
        }
        //}
    }

    const UI = () => {
        const isProcessing = () => {
            if (props.locationGroups.length === 0 && !propsGroupsChanged) {
                return (
                    <div className="mt-4">
                        <h4 className="text-center"><i class="fa-solid fa-cog fa-spin"></i> WIRD GELADEN ...</h4>
                    </div>
                )
            }
        }

        return (
            <Fragment>
                <div className="table-responsive">
                    <table id="tblLocationGroups" className="table mb-0 row-border hover order-column " role="grid">
                        <thead>
                            <tr>
                                <th>Liegenschafts-ID</th>
                                <th>Adresse</th>
                                <th>Gruppenname</th>
                                <th>Kunde</th>
                                <th className='text-center'>Abrechnungskunde</th>
                            </tr>
                        </thead>
                    </table>
                    {isProcessing()}
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>{UI()}</Fragment>
    )



}

export default TblLocationGroups;
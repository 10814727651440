import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { useRouter } from "../../../Shared/Router/Router";
import {PermissionsWrapper, hasPermission,} from "../../../../components/Dashboard/reactPermissionsWrapper";

import de from '../datatable.de.json'
import { TenantContext } from '../../../../context/Tenant/Tenant';
import { LocationsContext } from '../../../../context/Locations/Locations';
import { AuthContext } from "../../../../context/Auth/AuthProvider";
import CapitalizeUtils from '../../../../utils/capitalizeUtils';
import {GroupsContext} from "../../../../context/Groups/Groups";
import {OrganizationContext} from "../../../../context/Organization/Organization";


const $ = require('jquery')
$.DataTable = require('datatables.net')


const TenantTable = (props) => {

    const router = useRouter()
    const { user } = useContext(AuthContext);

    const {
        tenants,
        deleteTenant,
        moveOutTenant,
        updateTenant,
        successToast,
        errorToast,
        tenantsChanged,
        formatDate,
        isTenantsDone
    } = useContext(TenantContext);

    const { locations, locationsChanged } = useContext(LocationsContext);
    const { group, groups, groupChanged, groupSelect } = useContext(GroupsContext);
    const {mollineOrganization} = useContext(OrganizationContext)

    useEffect(() => {

        if (tenantsChanged === true  || groupChanged === true ) {
            initializeDT()
        }
        if(!!tenants && !tenantsChanged && !$.fn.dataTable.isDataTable(`#tblTenants`)){
            initializeDT()
        }

    }, [tenants, tenantsChanged, mollineOrganization, locations, groupChanged, locationsChanged]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }
    
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }


    const initializeDT = () => {
        if ($.fn.dataTable.isDataTable(`#tblTenants`)) {
            $(`#tblTenants`).DataTable().destroy();
            $(`#tblTenants tbody`).off('click')
            $(`#tblTenants tbody`).off('mouseenter')
            $(`#tblTenants tbody`).off('mouseleave')
            initializeDT()
        } else {
            $(`#tblTenants`).DataTable({
                language: de,
                paging: true,
                pagingType: "full",
                processing: true,
                searching: true,
                data: tenants,
                orderCellsTop: true,
                fixedHeader: true,
                rowId: "_id",
                pageLength: 100,
                ordering: [[3,'asc']],
                columns: [
                    {
                        "data": null,
                        "searchable": false,
                        "ordering": true,
                        render: function (data, type, row) {
                            let indate = null, outdate = null, today = new Date().getTime();
                            if(data.in_date) indate = new Date(data.in_date).getTime();
                            if(data.out_date) outdate = new Date(data.out_date).getTime();
                            /**
                             * Current Tenants
                             */
                            if( indate && indate < today && !outdate ) return '<i class="fa-solid fa-house-user text-success fa-2x" title="aktuelle(r) Mieter(in)"></i>';
                            if( 
                                (indate && indate < today) && 
                                (outdate && outdate > today) 
                            ) return '<i class="fa-solid fa-house-user text-success fa-2x" title="Aktuelle(r) Mieter(in)"></i>';
                            /**
                             * Future Tenants
                             */
                            if( indate && indate > today && !outdate ) return '<i class="fa-solid fa-house-circle-check text-info fa-2x" title="zukünftiger Mieter(in)"></i>';
                            if( 
                                (indate && indate > today) && 
                                (outdate && outdate > today) 
                            ) return '<i class="fa-solid fa-house-circle-check text-info fa-2x" title="zukünftiger Mieter(in)"></i>';
                            /**
                             * Previous Tenant
                             */
                            return '<i class="fa-solid fa-house-circle-xmark text-danger fa-2x" title="Vormieter(in)"></i>';
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if (!!data.token && data.token.length > 0) {
                                return data.token; // If the user added a foreign key ref, show that key
                            } else {
                                return data._id;
                            }
                        },
                        visible: hasPermission({ roles: ["realm_admin", "realm_manager"], user: user}) ? true : false
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            return CapitalizeUtils.capitalize(data.first_name)
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            return CapitalizeUtils.capitalize(data.last_name)
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if(!!data.email) return data.email
                            return '-'
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if(!!locations && locations.length > 0){
                                let location = locations.filter(loc => loc._id == data.location_id)[0]
                                if(!!location && !!location.address){
                                    return `<span class="tenantAddress">${CapitalizeUtils.capitalize(location.address.street)} ${location.address.house_number}, ME ${location.address.street1}, ${location.address.post_code}, ${CapitalizeUtils.capitalize(location.address.city)}</span>`
                                }else{
                                    return '--'
                                }
                            }else{
                                return '--'
                            }
                            
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if(type === "display") {
                                if (!!data.in_date) {
                                    return formatDate(new Date(data.in_date))
                                }
                                else {
                                    return '--'
                                }
                            }else {
                                if (!!data.in_date) {
                                    const date = new Date(data.in_date)
                                    return date.getTime() / 1000
                                }
                                else {
                                    return 0
                                }
                            }
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if(type === "display") {
                                if (!!data.out_date) {
                                    return formatDate(new Date(data.out_date))
                                }
                                else {
                                    return '--'
                                }
                            }else {
                                if (!!data.out_date) {
                                    const date = new Date(data.out_date)
                                    return date.getTime() / 1000
                                }
                                else {
                                    return 0
                                }
                            }
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if (hasPermission({ scopes: ['tenant.update.own', 'tenant.update.any'], user: user})) {
                                return `
                                    <div class="dropdown b-dropdown btn-group" >
                                        <button aria-haspopup="true" type="button" data-bs-toggle="dropdown" class="btnOverflowMenuTenant btn btn-sm dropdown-toggle btn-secondary dropdown-toggle-split">
                                            <i class="fa-solid fa-bars"></i>
                                        </button>
                                        <ul role="menu" tabIndex="-1" class="dropdown-menu dropdown-menu-right">
                                        ${
                                            hasPermission({user, scopes: ['tenant.update.own', 'tenant.update.any']})?
                                            `<li role="presentation" class="viewTarget">
                                                <a role="menuitem" class="dropdown-item">
                                                    <i class="text-secondary fa-solid fa-pen-to-square"></i>
                                                    Anzeigen
                                                </a>
                                            </li>` : ''
                                        }
                                         ${
                                            (hasPermission({user, scopes: ['tenant.update.own', 'tenant.update.any']}) && !data.out_date)?
                                            `<li role="presentation" class="moveOutTarget">
                                                        <a role="menuitem" class="dropdown-item">
                                                            <i class="text-secondary fa-solid fa-right-from-bracket"></i>
                                                            Auszug
                                                        </a>
                                            </li>` : ''
                                        }
                                       
                                        <li role="presentation">
                                            <hr role="separator" aria-orientation="horizontal" class="dropdown-divider">
                                        </li>
                                        ${
                                            hasPermission({user, scopes: ['tenant.delete.own', 'tenant.delete.any']})?
                                            ` <li role="presentation" class="deleteTarget">
                                                <a role="menuitem" class="dropdown-item">
                                                    <i class="text-secondary fa-solid fa-trash-can"></i> Löschen
                                                </a>
                                            </li>` : ''
                                        } 
                                       

                                        </ul>
                                    </div>
                                `
                            }


                            
                        }
                    }
                ],
                createdRow: function (row, data, dataIndex) {
                    if(!!data.out_date) {
                        $(row).addClass('alert-secondary');
                    }
                }
            });

            $(`#tblTenants tbody tr td`).off('click').on('click', function (e) {
                const tagName = e.target.tagName;
                if(tagName === "TD") {
                    let id = e.target.closest('tr').id;
                    router.push(`/details/tenant/${id}`);
                }
            });

            $('.dataTables_length select').addClass('form-select');
            $('.dataTables_filter input').addClass('form-control');

            $(`#tblTenants tbody`).on('mouseenter', 'tr', function () {
                $(this).css('cursor', 'pointer');
                $(this).css('background-color', '#f3f2f7');
            });
            $(`#tblTenants tbody`).on('mouseleave', 'tr', function () {
                $(this).css('background-color', 'transparent');
            });
            $(`#tblTenants tbody`).on('click', 'tr', function () {
                
            });
            $(`#tblTenants button.btnOverflowMenu`).on( 'click', function (e) {
                e.preventDefault()
                let button = e.target;
                let parent = button.closest('div');
                if(parent.classList.contains('show')) {
                    parent.classList.remove('show')
                }else {
                    parent.classList.add('show')
                }
            });
            $(`#tblTenants tbody`).on( 'click', 'li.viewTarget', function (e) {
                e.preventDefault()
                let link = e.currentTarget;
                let parent = link.closest('tr');
                router.push(`/details/tenant/${parent.id}`);
            });
            $(`#tblTenants tbody`).on( 'click', 'li.moveOutTarget', function (e) {
                e.preventDefault()
                let link = e.currentTarget;
                let parent = link.closest('tr');
                // router.push(`/details/tenant/${parent.id}`);
                moveOutTenant(parent.id)
            });
            $(`#tblTenants tbody`).on( 'click', 'li.deleteTarget', function (e) {
                e.preventDefault()
                let link = e.currentTarget;
                let parent = link.closest('tr');
                // router.push(`/details/tenant/${parent.id}`);
                deleteTenant(parent.id)
            });
            
        }
    }
    
    const UI = () => {
        const isProcessing = () => {
            if (tenants.length === 0 && isTenantsDone) {
                return (
                    <div className="mt-4">
                        <h4 className="text-center">Noch keine Nutzer vorhanden. Neue Nutzer können über das + erstellt werden</h4>
                        <p className='text-center'><a className='fw-bold' href='https://fs.molline.digital/file/Anleitung_Nutzerwechsel_Molliné_Connect.pdf'>Hier können Sie eine Anleitung herunterladen</a></p>
                    </div>
                )
            }else if (tenants.length === 0 && !isTenantsDone){
                return (
                    <div className="mt-4">
                        <h4 className="text-center"><i class="fa-solid fa-cog fa-spin"></i>Wird aktualisiert ...</h4>
                    </div>
                )
            }
        }
        return (
            <Fragment>
                <div className='table-responsive'>
                    <table id="tblTenants" className="table mb-0 row-border hover order-column w-100" role="grid">
                        <thead>
                            <tr>
                                <th></th>
                                <th>ID</th>
                                <th className="">Vorname</th>
                                <th className="">Nachname</th>
                                <th className="">Email</th>
                                <th className="">Mieteinheit <br /> (Straße, Hausnr., Mieteinheit, Plz, Ort) </th>
                                <th className="">Einzug</th>
                                <th className="">Auszug</th>
                                <th className=""></th>
                            </tr>
                        </thead>
                    </table>
                </div>
                {isProcessing()}
            </Fragment>
        )
    }
    
    return (
        <Fragment>{UI()}</Fragment>
    )

}

export default TenantTable;
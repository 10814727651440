import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';

import {PermissionsWrapper, hasPermission } from '../../../../components/Dashboard/reactPermissionsWrapper';
import {AuthContext} from "../../../../context/Auth/AuthProvider";
import OrgCookieCrumb from "../../../../components/Dashboard/orgCookieCrumb";
import Swal from '@molline/sweetalert2';
import {useRouter} from "../../../../components/Shared/Router/Router";
import { OrganizationContext} from "../../../../context/Organization/Organization";

import {NavLink} from "react-router-dom";
import PageTour from "../../../../components/Dashboard/tour/tour";
import TourSteps from "../../../../TourSteps.json";

const OrganizationDetails = (props) => {

    const { user } = useContext(AuthContext);
    const router = useRouter()
    const { organization, organizations, getOrganizations, setOrganization, updateOrganization } = useContext(OrganizationContext);
    const { organization_id } = router.query
    const organizationIdChanged = useCompare(organization_id)
    const organizationsChanged = useCompare(organizations)
    const [initialized, setInitialized] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [editForm, setEditForm] = useState({})
    const [originalOrganization, setOriginalOrganization] = useState({})

    const [currentOrg, setCurrentOrg] = useState({})

    useEffect(() => {
        if (organizationIdChanged || organizationsChanged && !initialized) {
            (async () => {
                await getOrganizations()
                setInitialized(true)
                getOrganization()
            })()
        }

    }, [organization, currentOrg, organizations, organizationsChanged, organizationIdChanged]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const getOrganization = () => {
        const myOrg = organizations.filter(org => org._id === organization_id)
        setCurrentOrg(myOrg[0])
    }

    const updateForm = (key, value, nestedKey) => {
        if(nestedKey) {
            const nested = editForm[nestedKey]
            nested[key] = value
            setEditForm({...editForm, [nestedKey]: nested})
        }else {
            setEditForm({...editForm, [key]: value})
        }
    }

    const doYouReallyWantToSave = () => {
        return new Promise(async (resolve) => {
            if (window.sessionStorage && !window.sessionStorage.getItem("yeswecan")) {
                const answer = await Swal.fire({
                    title: 'Sicher?',
                    input: 'checkbox',
                    text: 'Damit werden die Daten gespeichert',
                    inputPlaceholder: 'Nicht mehr fragen',
                    showCancelButton: true,
                    confirmButtonText: 'Ja',
                    cancelButtonText: "Nein",
                })
                if (!answer.isConfirmed)
                    return resolve(false);
                if (answer.value === 1) {
                    window.sessionStorage.setItem("yeswecan", true);
                    return resolve(true);
                }
            }
            return resolve(true);
        })
    }

    const successToast = (msg) => {
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: msg,
            showConfirmButton: false,
            customClass: {
                popup: "bg-success"
            }
        })
    }

    const errorToast = (err) => {
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: err,
            showConfirmButton: false,
            customClass: {
                popup: "bg-danger"
            }
        })
    }

    const updateMyOganization = async () => {
        const doSave = await doYouReallyWantToSave()
        if (!doSave) {
            return
        }
        try {
            editForm._id = organization_id
            await updateOrganization(editForm)
            setCurrentOrg(editForm)
            successToast('Organisation wurde erfolgreich gespeichert')
            setIsEditing(false)
        } catch (e) {
            console.log('error at updating location', e);
            errorToast(e)
        }
    }


    const UI = () => {
        const RenderConditionalButtons = () => {
            if (isEditing === true) {
                return (
                    <div className="col-12">
                        <button className="btn btn-primary mt-1 me-1 waves-effect waves-float waves-light" onClick={(e) => { e.preventDefault(); updateMyOganization() }}>Speichern</button>
                        <button className="btn btn-muted mt-1 me-1 waves-effect waves-float waves-light" onClick={(e) => {
                            e.preventDefault();
                            setIsEditing(false);
                            setOrganization(originalOrganization)
                        }}>Abbrechen</button>
                    </div>
                )
            } else {
                return (
                    <PermissionsWrapper
                        user={user}
                        scopes={['organization.update.any', 'organization.update.own']}
                    >
                        <div className="col-12">
                            <button id="OrgDetailsUpdateBtn" className="btn btn-secondary mt-1 me-1 waves-effect waves-float waves-light" onClick={(e) => {
                                e.preventDefault();
                                setIsEditing(true);
                                setEditForm(currentOrg)
                                setOriginalOrganization(JSON.parse(JSON.stringify(currentOrg)))
                            }}>Anpassen</button>
                        </div>
                    </PermissionsWrapper>
                )
            }
        }


        if (currentOrg !== null && currentOrg !== undefined && Object.keys(currentOrg).length !== 0) {
            return (
                <Fragment>

                    <div className="content-header row">
                        <div className="content-header-left col-md-9 col-12 mb-2">
                            <div className="row breadcrumbs-top">
                                <div className="col-9">
                                    <h2 className="content-header-title float-start mb-0">{currentOrg?.name}</h2>
                                    <div className="breadcrumb-wrapper">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a >Verwaltung</a></li>
                                            <li className="breadcrumb-item"><NavLink className="breadcrumb-navLink" to="/admin/organizations"><a>Organisationen</a></NavLink></li>
                                            <li className="breadcrumb-item active">Details
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <OrgCookieCrumb />
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header border-bottom">
                                    <h4 className="card-title">Details der Organisation  <PageTour steps={TourSteps.organizations.details}  isButton={false} /></h4>
                                </div>
                                <div className="card-body py-2 my-25" id="organisationDetailDataForm">

                                    <form className='row' id="org-create-frm">
                                        <div className="mb-1 col-md-6">
                                            <label className="form-label">Name*</label>
                                            <input type="text" className="form-control" value={isEditing ? editForm.name : currentOrg?.name} onChange={(e) => updateForm('name', e.target.value)} disabled={!isEditing} />
                                        </div>
                                        <div className="mb-1 col-md-6">
                                            <label className="form-label">Besitzer*</label>
                                            <input type="text" className="form-control" value={isEditing ? editForm.owner : currentOrg?.owner} onChange={(e) => updateForm('owner', e.target.value)} disabled={!isEditing} />
                                        </div>
                                        <div className="mb-1 col-md-6">
                                            <label className="form-label">Straße*</label>
                                            <input type="text" className="form-control" value={isEditing ? editForm?.address?.street : currentOrg?.address?.street} onChange={(e) => updateForm('street', e.target.value, 'address')} disabled={!isEditing} />
                                        </div>
                                        <div className="mb-1 col-md-6">
                                            <label className="form-label">Hausnummer*</label>
                                            <input type="text" className="form-control" value={isEditing ? editForm?.address?.house_number : currentOrg?.address?.house_number} onChange={(e) => updateForm('house_number', e.target.value, 'address')} disabled={!isEditing} />
                                        </div>
                                        <div className="mb-1 col-md-6">
                                            <label className="form-label">Stadt*</label>
                                            <input type="text" className="form-control" value={isEditing ? editForm?.address?.city : currentOrg?.address?.city} onChange={(e) => updateForm('city', e.target.value, 'address')} disabled={!isEditing} />
                                        </div>
                                        <div className="mb-1 col-md-6">
                                            <label className="form-label">Bundesland*</label>
                                            <input type="text" className="form-control" value={isEditing ? editForm?.address?.province : currentOrg?.address?.province} onChange={(e) => updateForm('province', e.target.value, 'address')} disabled={!isEditing} />
                                        </div>
                                        <div className="mb-1 col-md-6">
                                            <label className="form-label">Poszleizahl*</label>
                                            <input type="text" className="form-control" value={isEditing ? editForm?.address?.post_code : currentOrg?.address?.post_code} onChange={(e) => updateForm('post_code', e.target.value, 'address')} disabled={!isEditing} />
                                        </div>
                                        <div className="mb-1 col-md-6">
                                            <label className="form-label">Land*</label>
                                            <input type="text" className="form-control" value={isEditing ? editForm?.address?.country : currentOrg?.address?.country} onChange={(e) => updateForm('country', e.target.value, 'address')} disabled={!isEditing} />
                                        </div>
                                        <div className="mb-1 col-md-6">
                                            <label className="form-label">Email*</label>
                                            <input type="emal" className="form-control" value={isEditing ? editForm?.contact?.email : currentOrg?.contact?.email} onChange={(e) => updateForm('email', e.target.value, 'contact')} disabled={!isEditing} />
                                        </div>
                                        <div className="mb-1 col-md-6">
                                            <label className="form-label">Telefon*</label>
                                            <input type="text" className="form-control" value={isEditing ? editForm?.contact?.phone : currentOrg?.contact?.phone} onChange={(e) => updateForm('phone', e.target.value, 'contact')} disabled={!isEditing} />
                                        </div>
                                        <div className="mb-1 col-md-6">
                                            <label className="form-label">Mobil</label>
                                            <input type="text" className="form-control" value={isEditing ? editForm?.contact?.mobile : currentOrg?.contact?.mobile} onChange={(e) => updateForm('mobile', e.target.value, 'contact')} disabled={!isEditing} />
                                        </div>
                                        <div className="mb-1 col-md-6">
                                            <label className="form-label">Fax</label>
                                            <input type="text" className="form-control" value={isEditing ? editForm?.contact?.fax : currentOrg?.contact?.fax} onChange={(e) => updateForm('fax', e.target.value, 'contact')} disabled={!isEditing} />
                                        </div>
                                        <div className="mb-1 col-md-6">
                                            <label className="form-label">Website</label>
                                            <input type="text" className="form-control" value={isEditing ? editForm?.contact?.url : currentOrg?.contact?.url} onChange={(e) => updateForm('url', e.target.value, 'contact')} disabled={!isEditing} />
                                        </div>
                                        <div className="mb-1 col-md-6">
                                            <label className="form-label">Realm*</label>
                                            <input type="text" className="form-control" value={isEditing ? editForm.realm : currentOrg.realm} onChange={(e) => updateForm('realm', e.target.value)} disabled />
                                        </div>
                                    </form>
                                    <div className='row'>
                                        {RenderConditionalButtons()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Fragment>
            )
        } else {
            return (<Fragment></Fragment>)
        }

    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )

}


OrganizationDetails.requiredScope = ['organization.view.any', 'organization.view.own'];
OrganizationDetails.requireAuth = true;  // true -> force login via AuthContext, false -> no login required so no user available either
export default OrganizationDetails;
import React, {Fragment, useEffect, useContext } from 'react';
import {GatewaysContext} from "../../../../context/Devices/Gateways";
import _ from "underscore";


const GatewayPowerWidget = (props) => {

    const { gateway, gatewayChanged } = useContext(GatewaysContext);


    useEffect(  () => {
            
    }, [gateway, gatewayChanged]);

    const UI = () => {
        
        let powersupply;
        (gateway?.power_supply === true || gateway?.battery === false) ? powersupply = 'Netzteil' : powersupply = 'Akku'

        let icon;
        if (gateway?.supervision?.battery?.capaPrcent < 30) icon = <i class="text-danger fa-4x fa-solid fa-battery-low"></i>;
        else if (gateway?.supervision?.battery?.capaPrcent < 50) icon = <i class="text-warning fa-4x fa-solid fa-battery-half"></i>;
        else if (gateway?.supervision?.battery?.capaPrcent < 80) icon = <i class="text-success fa-4x fa-solid fa-battery-three-quarters"></i>;
        else if (gateway?.supervision?.battery?.capaPrcent < 100) icon = <i class="text-success fa-4x fa-solid fa-battery-full"></i>;
        else if (gateway?.power_supply === true || gateway?.battery === false) icon = <i className="text-success fa-solid fa-plug-circle-bolt fa-4x"></i>

        return (
            <div className="col-xl-2 col-md-4" id="widgetGatewayPower">
                <div className="card text-center " title="Out of all sensors this gateway can see, this many are registered.">
                    <div className="card-body align-self-center">
                        <div className="mx-auto my-1" style={{height: "75px", display: "inline-flex", alignItems: "center"}}>
                            {icon}
                        </div>
                        <p className="text-muted mb-0 line-ellipsis">{powersupply}</p>
                        <h2 className="mb-0">{(powersupply === 'Akku')?  (gateway?.supervision?.battery?.capaPrcent)? gateway.supervision.battery.capaPrcent + '%' : '' : ''}</h2>
                    </div>
                </div>
            </div>
        )

    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )

}

export default GatewayPowerWidget;
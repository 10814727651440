import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import {Link, NavLink} from "react-router-dom";

import {useRouter} from "../../../../components/Shared/Router/Router"
import OrgCookieCrumb from "../../../../components/Dashboard/orgCookieCrumb";
import {TenantContext} from "../../../../context/Tenant/Tenant";
import {LocationsContext} from "../../../../context/Locations/Locations";
import {GroupsContext} from "../../../../context/Groups/Groups";
import {MgmtUsersContext} from "../../../../context/Users/UsersContext";
import {AuthContext} from "../../../../context/Auth/AuthProvider";
import {PermissionsWrapper, hasPermission} from "../../../../components/Dashboard/reactPermissionsWrapper";
import {OrganizationContext} from '../../../../context/Organization/Organization';
import CountTenantsWidget from '../../../../components/Dashboard/widgets/locations/countTenants';

import TenantsTable from '../../../../components/Dashboard/tables/dataTables/tblTenants';
import TenantsUpload from '../../../../components/Dashboard/fileUploads/tenantDropzone';
import TourSteps from "../../../../TourSteps.json";
import PageTour from "../../../../components/Dashboard/tour/tour";

const TenantsOverview = (props) => {

    const {locations, getLocation, groupLocations} = useContext(LocationsContext);
    const {
        createTenant,
        tenants, 
        getTenants, 
        deleteTenant, 
        successToast, 
        errorToast, 
        tenantsChanged,
        doYouReallyWantToSave,
        formatDate,
        exportTenantReports
    } = useContext(TenantContext)
    const [tabActive, setTabActive] = useState('tptenants')
    const { user } = useContext(AuthContext);
    const { mgmtUsers, mgmtUsersChanged } = useContext(MgmtUsersContext);
    const { organization, organizations, organizationChanged } = useContext(OrganizationContext);
    const { group, groups, groupChanged, groupSelect } = useContext(GroupsContext);
    const [countCurrent, setCountCurrent] = useState(0)
    const groupLocationsChanged = useCompare(groupLocations)
    const router = useRouter()


    useEffect(() => {

        if(tenantsChanged === true || groupChanged === true) countCurrentTenants()

    }, [tenants, tenantsChanged, organization, organizationChanged, locations, group, groups, groupChanged, groupLocationsChanged]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const countCurrentTenants = () => {
        let count = 0;
        for(let ten of tenants){
            let indate = null, outdate = null, today = new Date().getTime();
            if(ten.in_date) indate = new Date(ten.in_date).getTime();
            if(ten.out_date) outdate = new Date(ten.out_date).getTime();
            if( indate && indate < today && !outdate ) {count ++; continue;}
            if( 
                (indate && indate < today) && 
                (outdate && outdate > today) 
            ) {count ++; continue;}
        }
        setCountCurrent(count)
    }

    const UI = () => {
        if (!!user) {
            return (
                <Fragment>
                    <div className="content-header row">
                        <div className="content-header-left col-md-9 col-12 mb-2">
                            <div className="row breadcrumbs-top">
                                <div className="col-9">
                                    <h2 className="content-header-title float-start mb-0">Nutzerwechsel</h2>
                                    <div className="breadcrumb-wrapper">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a>Apps</a>
                                            </li>
                                            <li className="breadcrumb-item active">Nutzerwechsel
                                            </li>
                                            <li>
                                                <span className="tourBorderRight"></span>
                                                <PageTour steps={TourSteps.tenants.overview} isButton={false} />
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <OrgCookieCrumb/>
                    </div>

                    <div className="content-body">
                        <section id="tenant_overview">
                            <div className="row">

                                <CountTenantsWidget />

                            </div>
                        </section>

                        <section id="tenant-tabs">
                            <div className="row">
                                <div className="col-12">
                                    <ul className="nav nav-pills mb-2">
                                        <li className="nav-item">
                                            <a className={`nav-link ${(tabActive === 'tptenants') ? 'active' : ''}`} onClick={() => {setTabActive('tptenants')}}>
                                                <i className="fa-solid fa-building"></i>
                                                <span className="fw-bold">Nutzerwechsel</span>
                                            </a>
                                        </li>
{/** 
                                        <li className="nav-item">
                                            <a className={`nav-link ${(tabActive === 'tptenantsImport') ? 'active' : ''}`} onClick={() => {setTabActive('tptenantsImport')}}>
                                                <i className="fa-solid fa-upload"></i>
                                                <span className="fw-bold">Import</span>
                                            </a>
                                        </li>
*/}
                                    </ul>
                                </div>
                            </div>
                        </section>


                        <section id="tabpanels">
                            <div className="row">
                                <div className="col-12">
                                    <div className="tab-content pt-1">
                                        { /* tab tenants */}
                                        <div className={`tab-pane ${(tabActive === 'tptenants') ? 'active' : ''}`}
                                             id="tptenants" role="tabpanel" aria-labelledby="tptenants-tab-fill">
                                            <div className="card">
                                                <div className="card-header d-flex justify-content-between align-items-center border-bottom">
                                                    <h4 className="card-title">Nutzer (Alle) <a href='https://fs.molline.digital/file/Anleitung_Nutzerwechsel_Molliné_Connect.pdf'><i class="fa-regular fa-circle-question" title='Anleitung herunterladen'></i></a></h4>
                                                    <div className="heading-elements" id="tenants_heading_elements">
                                                        {groupSelect()}
                                                        <button className="btn btn-primary btn-small ms-25 me-25" id="tenantMoveinBtn" title="Nutzer einziehen"  onClick={(e) => createTenant(e)}><i className="fa-solid fa-plus"></i>
                                                        </button>
                                                    </div>

                                                </div>
                                                <div className="card-body table-responsive ">
                                                    <TenantsTable />
                                                </div>
                                            </div>
                                        </div>
                                        {/* tab import */}
                                        <div className={`tab-pane ${(tabActive === 'tptenantsImport') ? 'active' : ''}`}
                                             id="tptenants" role="tabpanel" aria-labelledby="tptenantsImport-tab-fill">
                                            <div className="card">
                                                <div className="card-header border-bottom">
                                                    <h4 className="card-title">Import Nutzer {(organization._id)? `-> ${organization._id}`: ""}</h4>
                                                    <div className="col-4">
                                                        
                                                    </div>
                                                    <div className="heading-elements">
                                                    </div>
                                                </div>
                                                <div className="card-body table-responsive ">
                                                    
                                                    <TenantsUpload 
                                                        organization_id={organization._id}
                                                    />

                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </section>
                    </div>

                </Fragment>
            )
        }


    }

    return (
        <Fragment>
            {UI()}
        </Fragment>

    )

}
TenantsOverview.requiredScope = ['tenant.view.own', 'tenant.view.any'];
//TenantsOverview.requiredRole = ['realm_admin', 'realm_manager', 'admin', 'manager','bldg_manager'];
TenantsOverview.requireAuth = true;  // true -> force login via AuthContext, false -> no login required so no user available either
export default TenantsOverview;
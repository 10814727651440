const lang = {
    currency: {
        symbol: "€",
        label: "EUR"
    },
    lang: {
        language: "Language",
        english: "English",
        german: "German"
    },
    user: {
        profile: "Account",
        logout: "Logout",
        login: "Login",
        token: "Show Key",
        orga: "Organization"
    },
    mainmenu: {
        dashboard: "Dashboard",
        dashboards: "Dashboards",
        analytics: "Analytics",
        apps: "Apps",
        locationManager: "Locations",
        gatewayManager: "Gateways",
        sensorManager: "Sensors",
        dataManager: "Data-Transfer",
        administration: "Administration",
        users: "Users",
        usersList: "Users List",
        permissions: "Permissions",
        clients: "Clients",
        faq: "FAQ"
    },
    unsecure: {
        PageTitle : "My Unsecure Title"
    },
    dashboard: {
        profileTitle : "English"
    }
}

module.exports = lang;

import React, {Fragment, useEffect, useContext } from 'react';
import { AuthContext } from "../../../../context/Auth/AuthProvider";

import Swal from "@molline/sweetalert2";
import Axios_client from "../../../../utils/axios_client";
import { PermissionsWrapper, hasPermission, MembershipWrapper, hasMembership } from "../../reactPermissionsWrapper";
import HelpProvider from "../../helpProvider/helpProvider";
import { OrganizationContext } from '../../../../context/Organization/Organization';

const ReportDeadGateways = (props) => {

    const { user } = useContext(AuthContext);
    const { organization, organizationChanged } = useContext(OrganizationContext)

    useEffect(  () => {

    }, [ organization, organizationChanged ]);

    const errorToast = (err) => {
        if (!err) return
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: err?.response ? JSON.stringify(err.response.data) : err,
            showConfirmButton: false,
            customClass: {
                popup: "bg-danger text-white"
            }
        })
    }



    const downloadDeadGatewaysReport = async () => {
        document.getElementById('report_tab_close').click();

        Swal.fire({
            title: 'Bericht zu nicht-empfangenen Gateways',
            showCancelButton: true,
            confirmButtonText: `Herunterladen`,
            cancelButtonText: `Abbrechen`,
            html: `
                <label for="date_span">Tage seit Nicht-Empfangen:</label>
                <input type="number" id="date_span" class="swal2-input" value="14" placeholder="Tage">
                <p></p>
                <input type="checkbox" value="" id="all_orgs">
                <label for="all_orgs">Für alle Kunden herunterladen</label>
            `,
            preConfirm: () => {
                const date_span = Swal.getPopup().querySelector('#date_span').value
                const all_orgs = Swal.getPopup().querySelector('#all_orgs').checked
                return {
                    date_span,
                    all_orgs
                }
            }
          }).then((result) => {
            let org_id
            if(!result.isDismissed) {
                if (!result.value.all_orgs) {
                    org_id = organization._id
                } else {
                    org_id = ''
                }
                Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/report/report/dead/gateways?organization_id=${org_id}&date_span=${result.value.date_span}`)
                    .then(response => {
                    })
                    .catch(err => {
                        console.log(err)
                        errorToast(err)
                    })
            }
          })

    }

    const UI = () => {
       
        return (
            <PermissionsWrapper user={user} scopes={["report.generate.dead"]}>
                    <div className="col-xl-4 col-md-6">
                        <div className="card text-center clickable" >
                            <HelpProvider reportSampleImgPath="/theme2/app-assets/images/reports/sensor_measurement_report.jpg" title="DEAD Gateways" descriptionText="Report zu Gateways, die mehr als x Tage nicht gesendet haben" />
                            <div className="card-body" onClick={() => {downloadDeadGatewaysReport()}}>
                                <div className="mx-auto my-1">
                                    <i className="fa-solid fa-skull font-primary fa-4x"></i>
                                </div>
                                <p className="text-muted mb-0 line-ellipsis"
                                    title="">Dead Gateways Report</p>
                            </div>
                        </div>
                    </div>
            </PermissionsWrapper>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )

}

export default ReportDeadGateways;
import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';
import Swal from '@molline/sweetalert2'
import {useRouter} from "../../../Shared/Router/Router";
import {AuthContext} from "../../../../context/Auth/AuthProvider";

import de from "../datatable.de.json"

import $ from "jquery";
import uniqId from "uniqid";
import { hasPermission} from "../../reactPermissionsWrapper";
import {OrganizationContext} from "../../../../context/Organization/Organization";
$.DataTable = require('datatables.net');

const TblOrganizations = (props) => {

    const organizationsChanged = useCompare(props.organizations)
    const router = useRouter()
    const { user } = useContext(AuthContext);
    const {mollineOrganization} = useContext(OrganizationContext)

    useEffect(() => {
        if (organizationsChanged === true) {
            initializeDT()
        }

    }, [organizationsChanged, props.organizations]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }


    const refreshDataTables = () => {
        $('#tblOrganizations').DataTable().destroy();
        $('#tblOrganizations').off('click', 'button.btnOverflowMenu')
        $('#tblOrganizations').off('click', 'li.destroyTarget')
        initializeDT();
    }

    const initializeDT = () => {
        if (props.organizations.length !== 0) {
            // sort by date descending
            const sortedOrgs = [...props.organizations]
            sortedOrgs.sort(function(a,b){
                return new Date(b.updated_at) - new Date(a.updated_at);
            });
            let table;
            if ($.fn.dataTable.isDataTable('#tblOrganizations')) {
                refreshDataTables()
            } else {
                table = $('#tblOrganizations').DataTable({
                    "language": de,
                    "processing": true,
                    data: sortedOrgs,
                    rowId: "_id",
                    order: [],
                    columns: [
                        { "data": "_id",
                            visible: hasPermission({ roles: ["realm_admin", "realm_manager"], user: user}) ? true : false
                        },
                        { "data": "name" },
                        { "data": "owner" },
                        { "data": "contact.email" },
                        {
                            "data": null,
                            "searchable": false,
                            "ordering": false,
                            "className": "text-end",
                            render: function (data, type, row) {
                                return `<span id="tblOrgDropdownbtns"><div class="dropdown b-dropdown btn-group" id="${data._id}">
                                    <button aria-haspopup="true" type="button" data-bs-toggle="dropdown" class="btnOverflowMenu btn btn-sm dropdown-toggle btn-secondary dropdown-toggle-split" >
                                        <i class="fa-solid fa-bars"></i>
                                    </button>
                                    <ul role="menu" tabindex="-1" class="dropdown-menu dropdown-menu-right" >
                                        <li role="presentation" class="viewTarget">
                                            <a role="menuitem" href="#" target="_self" class="dropdown-item"><i class="text-secondary fa-solid fa-pen-to-square"></i> Anzeigen</a>
                                        </li>
                                        ${(user?.scopes?.includes('organization.delete.any') || user?.scopes?.includes('organization.delete.own') || user?.roles?.includes('god')) ? `
                                            <li role="presentation">
                                                <hr role="separator" aria-orientation="horizontal" class="dropdown-divider">
                                            </li>
                                            <li role="presentation" class="destroyTarget">
                                                <a role="menuitem" href="#" target="_self" class="dropdown-item"><i class="text-secondary fa-solid fa-trash-can"></i> Löschen</a>
                                            </li>
                                        ` : ''}
                                    </ul>
                                </div></span>`
                            }
                        }
                    ],
                    "fnDrawCallback": function( oSettings ) {

                        $('.dataTables_length select').addClass('form-select');
                        $('.dataTables_filter input').addClass('form-control');

                        $('#tblOrganizations tbody').on('click', 'li.viewTarget', function (e) {
                            e.preventDefault()
                            let link = e.currentTarget;
                            let parent = link.closest('div');
                            router.push(`/details/organization/${parent.id}`);
                        });
        
                        $('#tblOrganizations').on('click', 'li.destroyTarget', async function (e) {
                            e.preventDefault()
                            let button = e.target;
                            let organization_id = button.closest('tr').id;
                            await props.deleteOrganization(organization_id)
                        });

                        $('#tblOrganizations tbody tr').off('mouseenter').on('mouseenter', function () {
                            //$(this).css('cursor', 'pointer');
                            $(this).css('background-color', '#f3f2f7');
                        });
        
                        $('#tblOrganizations tbody tr').off('mouseleave').on('mouseleave', function () {
                            $(this).css('background-color', 'transparent');
                        });

                        $('#tblOrganizations tbody tr td').off('mouseenter').on('mouseenter', function (e) {
                            e.target.closest('td').classList.add('cursor-pointer');
                        });

                        $('#tblOrganizations tbody tr td').off('mouseleave').on('mouseleave', function (e) {
                            e.target.closest('td').classList.remove('cursor-pointer');
                        });

                        $('#tblOrganizations tbody tr td').off('click').on('click', function (e) {
                            const tagName = e.target.tagName;
                            if(tagName === "TD") {
                                let id = e.target.closest('tr').id;
                                router.push(`/details/organization/${id}`);
                            }
                        });
                    }
                });

               
            }
        }
    }

    const UI = () => {
        const isProcessing = () => {
            if(props.organizations.length === 0 && !organizationsChanged){
              return (
                <div className="mt-4">
                  <h4 className="text-center"><i class="fa-solid fa-cog fa-spin"></i> WIRD GELADEN ...</h4>
                </div>
              )
            }
        }

        return (
            <Fragment>
                <div className="table-responsive">
                    <table id="tblOrganizations" className="table mb-0 row-border hover order-column " role="grid">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Besitzer</th>
                                <th>Email</th>
                                <th></th>
                            </tr>
                        </thead>
                    </table>
                    {isProcessing()}
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>{UI()}</Fragment>
    )



}

export default TblOrganizations;
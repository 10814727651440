import React, {createContext, Fragment, useContext, useEffect, useRef, useState} from 'react';
import axios from 'axios';
import {AuthContext} from '../Auth/AuthProvider';
import {OrganizationContext} from '../Organization/Organization';
import {GroupsContext} from "../Groups/Groups";

import Swal from '@molline/sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import uniqId from 'uniqid'
import AxiosClient from "../../utils/axios_client";
import {PermissionsWrapper, hasPermission} from "../../components/Dashboard/reactPermissionsWrapper";
const rSwal = withReactContent(Swal)

const capitalize = (str) => {
    if (!str) return str;
    return `${str.charAt(0).toUpperCase()}${str.slice(1)}`
}
const getUserEmailOrFullName = (usr) => {
    if(!usr) return null;
    return usr.emails && usr.emails[0] && usr.emails[0].email ?
        usr.emails[0].email :
        `${capitalize(usr.first_name)} ${capitalize(usr.last_name)}`
}

/**
 * Meant for User management relative to a location or a realm
 * Different than the "user" which is logged in from Auth Context
 */
export const MgmtUsersContext = createContext();

/**
 * Locations are building or addresses which are available for management.
 * These shall be fetched int he background, based upon the user that is logged in.
 */
const MgmtUsersProvider = ({ children, context }) => {

    const { user } = useContext(AuthContext);
    const [mgmtUsers, setMgmtUsers] = useState([]);
    const [mgmtUser, setMgmtUser] = useState({});
    const { organization } = useContext(OrganizationContext);
    const { group, setGroup, getGroups, groups, setGroups } = useContext(GroupsContext);

    const [roles, setRoles] = useState([]);
    const [scopes, setScopes] = useState([]);

    const userChanged = useCompare(user)
    const organizationChanged = useCompare(organization)
    const mgmtUsersChanged = useCompare(mgmtUsers)


    useEffect(() => {
        
        if (userChanged === true || organizationChanged === true) {
            getAllUsers()
            getRoles()
        }

    }, [user, userChanged, roles, scopes, organizationChanged])

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }



    /**
     * Get all users, realtive to this realm & organization
     */
    const getAllUsers = () => {
        return new Promise((resolve, reject) => {
            AxiosClient.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/user/all?organization_id=${organization._id}`)
                .then(response => {
                    //console.log(response.data)
                    if (response.data !== mgmtUsers) setMgmtUsers(response.data)
                    return resolve(response.data)
                })
                .catch(err => {
                    setMgmtUsers([])
                    return resolve()
                })
        })
    }

    /**
     * Send the new updated user to the database
     */
    const updateUser = ({ user_id, payload }) => {
        return new Promise((resolve, reject) => {
            AxiosClient.backendClient().put(`${process.env.REACT_APP_API_OPENMETER}/user`, { _id: user_id, ...payload })
                .then(response => {
                    getAllUsers();
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
    /**
     * Update usrr.organisations arr and Send the new updated user to the database & cache
     */
    const updateUserAccessOrgs = ({ user_id, payload }) => {
        return new Promise((resolve, reject) => {
            AxiosClient.backendClient().put(`${process.env.REACT_APP_API_OPENMETER}/user/organizations`, { _id: user_id, ...payload })
                .then(response => {
                    if(response.status === 200) {
                        getAllUsers();
                        resolve(response.data)
                    }

                })
                .catch(error => {
                    reject(error)
                })
        })
    }



    const getRoles = () => {
        if( !hasPermission({user, scopes:['scope.view.all', 'scope.view.own']}) ) return;
        if( !hasPermission({user, scopes:['role.view.all', 'role.view.own']}) ) return;
        Promise.all([
            AxiosClient.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/roles/role` ),
            AxiosClient.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/roles/scope/`),
        ])
        .then(results => {
            if( !hasPermission({user, scopes:['ultimate.user']}) ) {
                results[0].data = results[0].data.filter(myRole => myRole.name !== 'god')
            }
            setRoles(results[0].data);
            setScopes(results[1].data);
        })
        .catch(err => {
            console.log(err)
        })
    }
    /**
     * Create a new role for an organization and realm
     */
    const createRole = () => {
        Swal.fire({
            title: 'Neue Rolle anlegen',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Erstellen',
            cancelButtonText: 'Abbrechen',
            html:
                '<input class="form-control mb-1" type"text" id="name" placeholder="Name"/>' +
                '<textarea class="form-control mb-1" maxlength="250" rows="5" id="description" placeholder="Beschreibung"/>',
            preConfirm: (inputValue) => {
                const name = Swal.getPopup().querySelector('#name').value
                const description = Swal.getPopup().querySelector('#description').value
                if (!name || !description) {
                    Swal.showValidationMessage(`Bitte das Formular vollständig ausfüllen`)
                }
                return { name, description }
            }
        })
            .then(results => {
                if (results.isConfirmed === true) {
                    AxiosClient.backendClient().post(`${process.env.REACT_APP_API_OPENMETER}/roles/role`, results.value)
                    .then(results => {
                        getRoles();
                        return results
                    }).catch(err => {
                        console.log('CLIENT DELETE ERROR', err)
                    })
                }
            })
    }
    /**
     * Permanently delete a role from the SYSTEM and then remove it 
     * from all entities. Purges role
     */
    const deleteRole = () => {
        let rolesArray = {};
        //Dont list core roles for deletion
        let permRoles = ['realm_admin', 'admin', 'manager', 'authenticated_user', 'anonymous', 'bldg_manager', 'tenant', 'maintenance', 'group_manager', 'group_user']
        roles.map(role => {
            if (!permRoles.includes(role.name)) {
                rolesArray[role.name] = role.name
            }
        })
        Swal.fire({
            title: 'Rolle löschen',
            html: 'Sind Sie sicher? Damit wird die Rolle permanent gelöscht und von allen Nutzern entfernt.<br/><br/>Systemrelevante-Rollen können nicht gelöscht werden.',
            icon: 'error',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Löschen',
            cancelButtonText: 'Abbrechen',
            input: 'select',
            inputOptions: rolesArray,
            inputPlaceholder: 'Rolle auswählen',
            preConfirm: (name) => {
                return { name }
            }
        })
        .then(results => {
            if (results.isConfirmed === true) {
                AxiosClient.backendClient().delete(`${process.env.REACT_APP_API_OPENMETER}/roles/role`, { data: results.value })
                .then(results => {
                    getRoles();
                    getAllUsers();
                }).catch(err => {
                    console.log('Role DELETE ERROR', err)
                })
            }
        })
    }

    const grantRole = ({ user_id, role_name }) => {
        return new Promise((resolve, reject) => {
            AxiosClient.backendClient().post(`${process.env.REACT_APP_API_OPENMETER}/roles/role/grant`, {
                name: role_name,
                entity_id: user_id
            })
            .then(results => {
                //getAllUsers();
                resolve(results)
            })
            .catch(err => {
                reject(err)
            })
        })
    }

    const removeRole = ({user_id, role_name, organization_id}) => {
        return new Promise( (resolve, reject) => {
            AxiosClient.backendClient().delete(`${process.env.REACT_APP_API_OPENMETER}/roles/role/remove`, {
                data: {
                    name: role_name,
                    entity_id: user_id,
                    organization_id: organization_id
                }
            })
            .then(results => {
                //getAllUsers();
                resolve(results)
            })
            .catch(err => {
                reject(err)
            })
            .then(results => {
                getAllUsers();
                resolve(results)
            })
            .catch(err => {
                reject(err)
            })
        })
    }
    /**
     * Removes a permission from a target entity
     */
    const removeScope = ({ scope_name, entity_id }) => {
        return new Promise((resolve, reject) => {

            AxiosClient.backendClient().delete(`${process.env.REACT_APP_API_OPENMETER}/roles/permission`, {
                data: {
                    scope_name,
                    entity_id
                }
            })
            .then(results => {
                resolve(results)
            })
            .catch(err => {
                reject(err)
            })
        })
    }
    /**
     *  Grant a permission to an entity
     */
    const grantScope = ({ scope_name, entity_id }) => {
        return new Promise((resolve, reject) => {
            AxiosClient.backendClient().post(`${process.env.REACT_APP_API_OPENMETER}/roles/permission`, {
                scope_name,
                entity_id
            })
            .then(results => {
                resolve(results)
            })
            .catch(err => {
                reject(err)
            })
        })
    }
    /**
     * Associate a user with a location and also grant the required role to the user
     */
    const addUserToLocation = ({ user_token, target_id, role }) => {
        return new Promise((resolve, reject) => {
            grantRole({ user_token, role_name: role })
                .then(roleGranted => {
                    AxiosClient.backendClient().put(`${process.env.REACT_APP_API_OPENMETER}/location/users`, { user_token, target_id, role })
                    .then(response => {
                        return resolve(response.data)
                    })
                    .catch(error => {
                        console.log('add user to location error', error);
                        return reject(error)
                    })
                })
                .catch(err => {
                    return reject(err)
                })
        })
    }

    const sendVerificationMail = ({user_id, email}) => {
        return new Promise( async (resolve, reject) => {
            axios.post(`${process.env.REACT_APP_API_OPENMETER}/user/email/verify`,{user_id, email})
                .then(response => {
                    return resolve(response.data)
                })
                .catch(err => {
                    return reject(err)
                })
        })
    }

    const deactivateUser = ({ user_id }) => {
        return new Promise(async (resolve, reject) => {
            const _user = mgmtUsers.find((u) => u._id === user_id);
            //console.log("user:", _user)
            Swal.fire({
                iconHtml: "<i class='fa-solid fa-trash'/>",
                customClass: {
                    icon: "border-0"
                },
                title: _user ? 'Nutzer <span class="bg-primary rounded-pill badge">' + getUserEmailOrFullName(_user) + '</span> wirklich löschen?' : 'Nutzer wirklich löschen?',
                text: 'Diese Aktion ist endgültig, sobald der Account gelöscht wurde, ist er nicht mehr reaktivierbar.',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Nutzer löschen',
                cancelButtonText: 'Abbrechen',
            })
                .then(results => {
                    if (results.isConfirmed === true) {
                        AxiosClient.backendClient()
                        .delete(`${process.env.REACT_APP_API_OPENMETER}/user` , { data: { user_id } })
                        .then(response => {
                            getAllUsers();
                            return resolve(response.data)
                        })
                        .catch(err => {
                            return reject(err)
                        })
                    }
                })
        })
    }

    const initializeRoles = () => {
        if (roles.length > 0){
            Swal.fire({
                title: 'Initialisierung bereits erfolgt',
                icon: 'error',
                text: 'Rollen und Bereichsrechte wurden bereits initialisiert und können nicht nochmal initialisiert werden.',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false,
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'Abbrechen',

            })
            return;
        }
        Swal.fire({
            title: 'Build',
            icon: 'info',
            text: 'Berechtigungs-System wird initialisiert - dies sollte ein paar Sekunden brauchen.',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Initialisieren',
            cancelButtonText: 'Abbrechen',
        })
        .then( results => {
            if(results.isConfirmed === true){
                AxiosClient.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/roles/role/initialize?organization_id=${user.organization_id}`)
                    .then(results => {
                        getRoles();
                    }).catch(err => {
                    console.log('CLIENT DELETE ERROR', err)
                })
            }
        })
    }

    const changeRoles = ({ userId, userModel }) => {
        return new Promise((resolve, reject) => {
            if (!roles || roles?.length === 0) {
                return (
                    <button className="btn btn-primary me-1" onClick={() => { initializeRoles() }} > <span className="me-1"><i class="fa fa-wrench" aria-hidden="true"></i></span> Berechtigungen initialisieren</button>
                )
            }

            const _user = userModel ? userModel : mgmtUsers.find(u => u._id === userId);
            const _roles = roles.filter((v, i, a) => a.findIndex(r => r.name == v.name) === i); 

            //unique roles
            if (!_user || !_user.roles) return;
            let userRoles = new Set(_user.roles);
            let newUserRoles = new Set([]);
            let rmUserRoles = new Set([]);

            let html = (
                    <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }} >
                        <div style={{ textAlign: "left", overflow: "hidden", width: "75%" }}>
                            {
                                _roles.map((role, index) => {
                                    if (role.name === "anonymous" || role.name === "authenticated_user" || (role.name === 'god' && !user?.roles?.includes('god'))) return;
                                    if (!user?.roles?.includes('god') && user?.scopes?.includes('role.grant.own') && !user?.scopes.includes('role.grant.any') && (!user?.roles?.includes(role.name) && role.name !== 'tenant' && role.name !== 'bldg_manager')) return
                                    return (
                                            <div class="mb-1" style={{ display: "flex", alignItems: "center" }} key={uniqId()}>
                                                <div class="me-50">
                                                    <div class="form-check form-check">
                                                        <input class="form-check-input" type="checkbox"
                                                            id={role._id + "checkerbox"}
                                                            value={role.name}
                                                            defaultChecked={userRoles.has(role.name)}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    newUserRoles.add(e.target.value);
                                                                    if (rmUserRoles.has(e.target.value)) rmUserRoles.delete(e.target.value);
                                                                } else {
                                                                    if (newUserRoles.has(e.target.value)) newUserRoles.delete(e.target.value);
                                                                    rmUserRoles.add(e.target.value);
                                                                }
                                                            }}
                                                        />
                                                        <span style={{ fontSize: "1em" }} class="badge bg-secondary rounded-pill"><label class="form-check-label" htmlFor={role._id + "checkerbox"}>{role.name}</label></span>
                                                    </div>
                                                </div>
                                                <div><small style={{ whiteSpace: "nowrap" }}>{role.description}</small></div>
                                            </div>
                                    )
                                })
                            }
                        </div>
                    </div>
            )

            rSwal.fire({
                title: `<Fragment>Verwalterrollen ändern für <span className="badge bg-primary rounded-pill">${getUserEmailOrFullName(_user)}</span></Fragment>`,
                iconHtml: '<i class="fa fa-check-circle" aria-hidden="true"></i>',
                customClass: {
                    icon: 'border-0'
                },
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                confirmButtonText: "Speichern",
                cancelButtonText: "Abbrechen",
                showCancelButton: true,
                width: '700px',
                html: html,
            }).then(async (answer) => {
                if (!answer.isConfirmed)
                    return resolve();

                const isForbidden = (r) => {
                    const _r = r.trim().toLocaleLowerCase();
                    return _r === "authenticated_user" || _r === "god" || _r === "anonymous";
                }
                let assignUserRoles = [...newUserRoles].filter(n => n !== isForbidden(n));
                let removeUserRoles = [...rmUserRoles].filter(n => n !== isForbidden(n) && userRoles.has(n));
                let toBeAssigned = assignUserRoles.reduce((p, c, a) => p +
                        `<span className="badge rounded-pill bg-success me-25 mb-25 mt-25" style="font-size:1em">${c}</span>`,
                    '')
                let toBeRemoved = removeUserRoles.reduce((p, c, a) => p +
                        `<span className="badge rounded-pill bg-danger  me-25 mb-25 mt-25" style="font-size:1em">${c}</span>`,
                    '');
                let html = `Damit werden die Verwalterrollen <br/>`;
                if (toBeAssigned.length > 0) html += `${toBeAssigned} hinzugefügt<br/>`
                if (toBeRemoved.length > 0) html += `${toBeRemoved} entfernt<br/>`
                html += `für <span className="badge bg-secondary mt-25" style="font-size:1em">${getUserEmailOrFullName(_user)}</span>`
                if (toBeAssigned.length <= 0 && toBeRemoved.length <= 0)
                    return resolve();
                const youSure = await Swal.fire({
                    title: 'Sicher?',
                    html: html,
                    showCancelButton: true,
                    confirmButtonText: 'Verwalterrollen ändern',
                    cancelButtonText: "Abbrechen",
                })
                if (!youSure.isConfirmed)
                    return resolve();

                let promises = [];

                for (let rmRole of removeUserRoles) {
                    await removeRole({ user_id: _user._id, role_name: rmRole, organization_id: organization._id });
                }
                for (let newRole of assignUserRoles) {
                    await grantRole({ user_id: _user._id, role_name: newRole });
                }
                Swal.fire({
                    toast: true,
                    position: 'bottom-end',
                    timer: 3000,
                    timerProgressBar: true,
                    allowOutsideClick: true,
                    text: `Verwalterrollen für Nutzer ${getUserEmailOrFullName(_user)} geändert.`,
                    showConfirmButton: false,
                    customClass: {
                        popup: "bg-success"
                    }
                })
                resolve("OK");
                getAllUsers();
            }).catch(err => reject(err));
        });
    }

    const verifyPassword = ({user_id, password}) => {
        return new Promise((resolve, reject)=> {
            AxiosClient.backendClient().post(`${process.env.REACT_APP_API_OPENMETER}/user/password/verify`, {user_id, password}).then((result)=>{
                console.log("password verify result:", result)
                resolve(result);
            }).catch((err) => {
                console.error("password verify error")
                resolve(err);
            })
        })
    }

    const changeUserPassword = ({user_id, passwordCurrent, passwordNew}) => {
        return new Promise((resolve, reject)=> {
            AxiosClient.backendClient().post(`${process.env.REACT_APP_API_OPENMETER}/user/password/update`, {user_id, passwordCurrent, passwordNew}).then((result)=>{
                console.log("password verify result:", result)
                return resolve(result);
            }).catch((err) => {
                console.error("password verify error")
                return reject(err);
            })
        })
    }

    const createUser = ({first_name, last_name, email, organization_id, type = 'invitation'}) => {
        return new Promise((resolve, reject)=> {
            AxiosClient.backendClient().post(`${process.env.REACT_APP_API_OPENMETER}/user`, {first_name, last_name, email, organization_id, type})
            .then(res => {
                Swal.fire({
                    title: 'Verwalter erfolgreich angelegt',
                    icon: 'success',
                    html: "Der Verwalter <b>" + res.data._id + "</b> / " + email + " wurde erfolgreich angelegt. Dieser muss sich allerdings noch via E-Mail verifizieren.",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: true,
                })
                getAllUsers()
            })
            .catch(err => {
                let errMsg = '`Leider ist bei der Erstellung etwas schiefgegangen'
                if(!!err?.response?.data) errMsg = err.response.data
                Swal.fire({
                    toast: true,
                    position: 'bottom-end',
                    timer: 3000,
                    title: `Fehler beim Anlegen des Nutzers`,
                    timerProgressBar: true,
                    allowOutsideClick: true,
                    text: errMsg,
                    showConfirmButton: false,
                    customClass: {
                        popup: "bg-danger"
                    }
                })
            })
        })
    }

    const getUser = (user_id) => {
        return new Promise((resolve, reject)=> {
            AxiosClient.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/user/?user_id=${user_id}&organization_id=${organization._id}`).then((result)=>{
                if(hasPermission({user, roles:['realm_admin', 'realm_manager']})) return resolve(result.data);
                if(result.data.organizations.includes(organization._id) || result.data.organization_id === organization._id) return resolve(result.data);
                return resolve({})
            }).catch((err) => {
                return reject(err);
            })
        })
    }

    const logOutTarget = (user_id) => {
        const _user = mgmtUsers.find((u) => u._id === user_id);
        //console.log("user:", _user)
        Swal.fire({
            iconHtml: "<i class='fa-solid fa-crosshairs'/>",
            customClass: {
                icon: "border-0"
            },
            title: 'User Logout erzwingen',
            text: 'Dies wird den Nutzer ausloggen, falls er aktuell die Website nutzt. Dies wird auch Cookies und local Storage löschen. Der Nutzer muss sich danach erneut einloggen.',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Nutzer ausloggen',
            cancelButtonText: 'Abbrechen',
        })
        .then(results => {
            if (results.isConfirmed === true) {
                AxiosClient.backendClient()
                .get(`${process.env.REACT_APP_API_OPENMETER}/user/target/force/logout?user_id=${user_id}`)
                .then(response => {})
                .catch(err => {})
            }
        })
    }

    const errorToast = (err) => {
        if (!err) return
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: err?.response ? JSON.stringify(err.response.data) : err,
            showConfirmButton: false,
            customClass: {
                popup: "bg-danger text-white"
            }
        })
    }

    const doYouReallyWantToSave = () => {
        return new Promise(async (resolve) => {
            if (window.sessionStorage && !window.sessionStorage.getItem("yeswecan")) {
                const answer = await Swal.fire({
                    title: 'Sicher?',
                    input: 'checkbox',
                    text: 'Damit werden die Daten gespeichert',
                    inputPlaceholder: 'Nicht mehr fragen',
                    showCancelButton: true,
                    confirmButtonText: 'Ja',
                    cancelButtonText: "Nein",
                })
                if (!answer.isConfirmed)
                    return resolve(false);
                if (answer.value === 1) {
                    window.sessionStorage.setItem("yeswecan", true);
                    return resolve(true);
                }
            }
            return resolve(true);
        })
    }

    const successToast = (msg) => {
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: msg,
            showConfirmButton: false,
            customClass: {
                popup: "bg-success"
            }
        })
    }

    const resendInvite = async (user_id) => {
        const sure = await doYouReallyWantToSave()
        if(!sure) return
        try {
            await AxiosClient.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/user/invite/resend?user_id=${user_id}`)
            successToast('Email wurde erfolgreich verschickt')
        } catch (err) {
            console.log('error at resending invite email', err);
            errorToast('Fehler beim Senden der Email')
        }
    }

    const actions = {
        mgmtUsers,
        mgmtUser,
        setMgmtUsers,
        setMgmtUser,
        getAllUsers,
        updateUser,
        grantRole,
        removeRole,
        getRoles,
        roles,
        scopes,
        createRole,
        deleteRole,
        removeScope,
        grantScope,
        addUserToLocation,
        sendVerificationMail,
        deactivateUser,
        initializeRoles,
        changeRoles,
        verifyPassword,
        createUser,
        getUser,
        changeUserPassword,
        mgmtUsersChanged,
        logOutTarget,
        updateUserAccessOrgs,
        resendInvite
    }

    return (
        <Fragment>
            <MgmtUsersContext.Provider value={actions}>
                {children}
            </MgmtUsersContext.Provider>
        </Fragment>
    )

}

export default MgmtUsersProvider;
import {Link, NavLink} from 'react-router-dom'
import Cookie from 'js-cookie';
import React, {Fragment, useState, useEffect, useContext } from 'react';
import {AuthContext} from "../../../context/Auth/AuthProvider";
import { useRouter } from '../Router/Router'
import { LocaleContext } from '../../../context/Locale/Locale';
import ContactForm from '../../LandingPages/ContactForm'
import {CookieContext} from "../../../context/CookieMaster/CookieMaster";

const Navbar = ({children}) => {

    const router = useRouter()
    const [updated, setUpdated] = useState(false);
    const { user, logout, login, setRedirect, dencUser} = useContext(AuthContext)
    const { cookieDestroy} = useContext(CookieContext)
    const {t, locale, localeAvailable, setLang} = useContext(LocaleContext);
    const [isUser, setIsUser] = useState();
//    const {config} = useContext(WhiteLabelContext);



    useEffect(  () => {
        if(!updated){
            let decryptedUser = dencUser();
            if(typeof decryptedUser === 'object' && decryptedUser !== null)  {
                setIsUser(decryptedUser);
            }else {
                cookieDestroy('auth');
                cookieDestroy('encuser');
                cookieDestroy('session');
                localStorage.removeItem('encuser')
                localStorage.removeItem('organization')
            }

            setRedirect(router.route)
            setUpdated(true)
        }

    }, [updated, locale, isUser]);

    const goToDahboard = () => {
        localStorage.getItem('encuser') !== null ? router.navigate('dashboards/analytics') : login();
    }

    const UI = () => {
            return (
                <Fragment>
                    <nav className="header-navbar navbar navbar-expand-lg fixed-top bg-white">
                        <div className="container-fluid">
                            <a className="navbar-brand" href="/">
                                <img className="logo mc-icon-home" src={'/homepage/assets/images/logo/molline_logo_pos.svg'} alt="Molliné Logo" />
                            </a>
                            <span className="navbar-text d-flex"  id="header_navbar_buttons">

                              <button type="button" onClick={() => ContactForm.showForm()} className="btn btn-outline-dark round text-uppercase">Kontakt</button>

                                <a href="/faq">
                                     <button type="button" className="btn btn-outline-dark round text-uppercase ml-1 mr-1">FAQ</button>
                                </a>
                                {isUser !== undefined ?
                                    <button type="button" onClick={() => goToDahboard()} className="btn btn-outline-dark round text-uppercase ">Dashboard</button>
                                    :
                                    <button type="button" onClick={() => login()} className="btn btn-outline-dark round text-uppercase">Anmelden</button>
                                }

                        </span>
                        </div>
                    </nav>
                </Fragment>

            )
       /* if(user) {
            return(
                <Fragment>
                    <nav className='primary-nav'>
                        <NavLink to='/home' >Home</NavLink>
                        <NavLink to='/unsecured-sample-page' >unsecure Page</NavLink>
                        <NavLink to='/single-secured-sample-page' >single-secured-sample-page</NavLink>

                        <NavLink to='/dashboard/second-secured' >secure Page</NavLink>
                        <NavLink to='/dashboard/Profile' >Profile Page</NavLink>
                    </nav>
                </Fragment>
                )

        }*/
    }



    return (
        <Fragment>
            {UI()}

        </Fragment>
    )
}

export default Navbar;
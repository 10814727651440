import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useRouter } from '../Shared/Router/Router'
import { LocaleContext } from '../../context/Locale/Locale';
import { WhiteLabelContext } from '../../context/WhiteLabels/WhiteLabels';

const Footer = ({ children }) => {

    const router = useRouter();
    const [firmName, setFirmName] = useState(process.env.NEXT_PUBLIC_APP_NAME)
    const { t, locale, localeAvailable, setLang } = useContext(LocaleContext);
    const { config } = useContext(WhiteLabelContext);

    const [updated, setUpdated] = useState(false);

    useEffect(() => {
        //console.log('config',config);
        if (config !== null) {
            setFirmName(config.meta.title)
        }

        if (!updated) {
            setUpdated(true)
        }
    }, [locale, config, firmName]);

    const UI = () => {
        let date = new Date();
        return (
            <Fragment>

                <footer className="footer footer-sticky footer-light">
                    <p className="clearfix mb-0">
                        <span className="float-md-start d-block d-md-inline-block mt-25">COPYRIGHT &copy; {date.getFullYear()}
                            <a className="ms-25" href="https://molline-connect.de" target="_blank">Molline-Connect</a>
                            <span className="d-none d-sm-inline-block">, All rights Reserved</span>
                        </span>
                    </p>
                </footer>

            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}

export default Footer;
import { useLocation, useNavigate} from 'react-router-dom'
import React, {Fragment, useContext, useEffect, useState} from 'react';
import {AuthContext, setRedirect} from "./AuthProvider";
import Cookie from 'js-cookie';
import Swal from '@molline/sweetalert2';

const RenderUI = React.memo(({authorized, children}) => {
    if(authorized === true){
        return (
            <Fragment>
                {children}
            </Fragment>
        )
    }
    if(authorized === false) {
        return  (
            <Fragment>
                <h1>401 - Sie haben nicht die Befugnis, diese Seite zu öffnen.</h1>
            </Fragment>
        )
    }
    return (<Fragment></Fragment>)
})

const AuthGuard = ({children}) => {
    const [authorized, setAuthorized] = useState();
    const [childrenChecked, setChildrenChecked] = useState(false);
    const { user, initializing, setRedirect, redirectUser, login } = useContext(AuthContext)
    const router = useNavigate();

    useEffect(() => {
        let auth = Cookie.get('auth')
        let encUser = localStorage.getItem('encuser')

        if (!initializing) {
            if (!user) {
                setRedirect(router.pathname)
                redirectUser();
            }else {
                checkAuthorizationOf(children);
            }
        }

        if(!auth && !!encUser){
            localStorage.removeItem('encuser')
            Swal.fire({
                title: `Session ist abgelaufen!`,
                text: `Ihre Session ist abgelaufen. Bitte loggen Sie sich erneut ein :)`,
                icon: 'warning',
                allowOutsideClick: true,
                allowEscapeKey: true,
                allowEnterKey: false,
                showConfirmButton: true,
                showCancelButton: false,
                background: localStorage.getItem('theme-preference') === 'dark-layout' ? '#161D31' : '#fff',
                confirmButtonText: 'Ok',
            })
            .then(() => {
                redirectUser()
            })
            
        }

    }, [initializing, router, user, authorized])


    const checkAuthorizationOf = (children) => {

        const autheval = () => {
            return new Promise(async (resolve) => {
                if(!user) return resolve(false);
                if(user.roles.includes('god'))
                    return resolve(true)

                let child = React.Children.toArray(children.length >= 1 ? children[1] : children);
                let isAuthorized = false;

                if(child[0].type){ 
                    if(child[0].type.requiredRole) {
                        let rolesRequired = child[0].type.requiredRole;
                        for(let i = 0; i < rolesRequired.length; i++){
                            if(user.roles.includes(rolesRequired[i])){
                                isAuthorized = true; 
                                break;
                            }
                        }
                    }
                    if(child[0].type.requiredScope) {
                        let scopeRequired = child[0].type.requiredScope;
                        if(scopeRequired.find((scope)=>user.scopes.includes(scope))){
                            isAuthorized = true;
                        }
                    }
                  
                    resolve(isAuthorized);
                }
                return resolve(false);
            })
        }
        autheval().then( result => {
            setAuthorized(result);
        }).finally(()=>setChildrenChecked(true));

    }

    return (
        <RenderUI
            authorized={authorized}
            children={children}
        ></RenderUI>
    );


}
export default AuthGuard;
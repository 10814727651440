import OrgCookieCrumb from '../../../../components/Dashboard/orgCookieCrumb'
import { useRouter } from "../../../../components/Shared/Router/Router"
import React, {Fragment, useState, useEffect, useContext } from 'react';

import Swal from '@molline/sweetalert2'
import axios from 'axios';
import AxiosClient from "../../../../utils/axios_client"
import { AuthContext } from '../../../../context/Auth/AuthProvider';
import { MgmtUsersContext } from '../../../../context/Users/UsersContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faLock, faRibbon, faBell, faChain, faWrench, faTrash, faBomb } from '@fortawesome/free-solid-svg-icons'
import uniqid from 'uniqid';
import cookies from "js-cookie";
import {PermissionsWrapper, hasPermission} from "../../../../components/Dashboard/reactPermissionsWrapper";
import uniqId from 'uniqid';
import TourSteps from "../../../../TourSteps.json";
import PageTour from "../../../../components/Dashboard/tour/tour";

const $ = require('jquery');
$.DataTable = require('datatables.net');


const Permissions = ({children}) => {

    const [updated, setUpdated] = useState(false);
    const {user} = useContext(AuthContext);

    const { 
        getRoles,
        roles,
        scopes,
        createRole,
        deleteRole,
        removeScope,
        grantScope
    } = useContext(MgmtUsersContext);
    
    const router = useRouter()

    useEffect(() => {
        if(!updated){
            getRoles();
            setUpdated(true)
        }
    }, [roles, scopes]);

    const [scopesFilter, setScopesFilter] = useState(null);
    const [rolesFilter, setRolesFilter] = useState();

    const toggleScopeToRole = (e) => {
        //Get Role
        roles.map((role) => {
            if(role._id === e.target.dataset.role_id){
                //If role has scope then remove it
                if(role.scopes.includes(e.target.dataset.scopename)){
                    //Remove
                    removeScope({
                        scope_name: e.target.dataset.scopename, 
                        entity_id: e.target.dataset.role_id
                    })
                    .then( () => {
                        getRoles();
                    })
                    .catch(err => {
                        console.log('ERROR 64',err)
                    });
                }else{
                    //ADD
                    grantScope({
                        scope_name: e.target.dataset.scopename, 
                        entity_id: e.target.dataset.role_id
                    })
                    .then( () => {
                        getRoles();
                    })
                    .catch(err => {
                        console.log('ERROR 64',err)
                    });
                }
            }
        })
    }
    
    const toggleDropdown = (e) => {
        document.getElementById(e.target.dataset.toggle).classList.toggle('show');
    }

    const purge = () => {
        if(!hasPermission({scopes: ['permission.purge.any','permission.purge.own' ], roles: ['god'], user } )) return;

        let title = 'Orga Bereinigen'
        let icon = 'fa-trash'
        let text = `Sind Sie sicher? Damit wird das Berechtigungssystem für die <strong>KOMPLETTE Organisation</strong> bereinigt 
        und alle Berechtigungen (nicht Rollen) werden entfernt - <strong>danach müssen Sie auf jeden Fall neu initialisieren!</strong>
        Dies passiert NICHT automatisch!`;
        let okButtonText = "Ja"
        let cancleButtonText = "Abbrechen"
        if(user?.roles?.includes('god')){
            title = 'DESTROY'
            icon = 'fa-bomb'
            text = 'Hello God. This will wipe the earth with the Permissions for this Organization - are you sure?'
            okButtonText = 'NUKE EM ALL!'
            cancleButtonText = 'Woopsy'
        }

        Swal.fire({
            title: title,
            iconHtml:`<i class="fa-solid ${icon}"></i>`,
            html: text,
            customClass:{
                icon:"border-0"
            },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: okButtonText, 
            cancelButtonText: cancleButtonText,
            
        })
        .then( results => {
            if(results.isConfirmed === true){
                AxiosClient.backendClient({
                    sessionCookie: cookies.get('session'),
                    userCookie: cookies.get('auth'),
                    app_key: cookies.get('APP_KEY')
                }).delete(`${process.env.REACT_APP_API_OPENMETER}/roles/permission/purge` , {organization_id: user?.organization_id, realm: user?.realm})
                    .then(results => {
                        getRoles();
                    }).catch(err => {
                        console.log('CLIENT DELETE ERROR', err)
                    })
            }
        })  
    }

    const initialize = () => {
        if(!hasPermission({scopes: ['permission.initialize.any', 'permission.initialize.own'], roles: ['god'], user })) return;

        let text = 'Berechtigungs-System wird initialisiert - dies dauert mehrere Minuten.'
        if(user?.roles?.includes('god')){
            text = 'Hello GOD, can\'t believe it is you. You may initialize permissions on a per organization basis. Please choose the organization you would like to initialize, also be sure to be in the right organization.'
        }

        Swal.fire({
            title: 'Initialisieren',
            iconHtml: '<i class="fa-solid fa-wrench"></i>',
            customClass:{
                icon:"border-0"
            },
            text: text,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Initialisieren',
            cancelButtonText: 'Abbrechen',
            
        })
        .then( results => {
            if(results.isConfirmed === true){
                AxiosClient.backendClient({
                    sessionCookie: cookies.get('session'),
                    userCookie: cookies.get('auth'),
                    app_key: cookies.get('APP_KEY')
                }).get(`${process.env.REACT_APP_API_OPENMETER}/roles/role/initialize?organization_id=${user?.organization_id}&realm=${user?.realm}`)
                    .then(results => {
                        Swal.fire({
                            title: 'Berechtigungen werden neu initialisiert',
                            icon: 'success',
                            html: "Die Berechtigungen werden jetzt neu initialisiert. Es dauert mehrere Minuten, bis Änderungen wirksam sind und auf der Website angezeigt werden.",
                            showConfirmButton: true,
                        })
                    }).catch(err => {
                        console.log('error at initialising', err);
                        errorToast('Fehler beim Initialisieren')
                    })
            }
        })  
    }

    const initializeAll = async () =>  {
        Swal.fire({
            title: 'Initialisieren',
            iconHtml: '<i class="fa-solid fa-wrench"></i>',
            customClass:{
                icon:"border-0"
            },
            text: 'Wollen Sie wirklich die Berechtigungen für alle Organisationen neu Initialisieren',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Initialisieren',
            cancelButtonText: 'Abbrechen',
            
        })
        .then( results => {
            if(results.isConfirmed === true){
                AxiosClient.backendClient({
                    sessionCookie: cookies.get('session'),
                    userCookie: cookies.get('auth'),
                    app_key: cookies.get('APP_KEY')
                }).get(`${process.env.REACT_APP_API_OPENMETER}/roles/role/initialize/all`)
                    .then(results => {
                        Swal.fire({
                            title: 'Berechtigungen werden neu initialisiert',
                            icon: 'success',
                            html: "Die Berechtigungen werden jetzt neu initialisiert. Es dauert mehrere Minuten, bis Änderungen wirksam sind und auf der Website angezeigt werden.",
                            showConfirmButton: true,
                        })
                    }).catch(err => {
                        console.log('error at initialising', err);
                        errorToast('Fehler beim Initialisieren')
                    })
            }
        }) 
    }

    const errorToast = (err) => {
        if(!err) return
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: err,
            showConfirmButton: false,
            customClass: {
                popup: "bg-danger text-white"
            }
        })
    }

    const onRoleFilterChange = (e) => {
        e.stopPropagation();
        let role = e.target.name;
        console.log("role filter change", role)
        if(!rolesFilter) setRolesFilter([]);
        if (!e.target?.checked) {
            setRolesFilter((old) => [...old.filter((gt) => gt !== role)]);
            e.target.checked = false;
        } else if (!rolesFilter?.includes(role)) {
            setRolesFilter((old) => [...old, role])
            e.target.checked = true;
        }
    }

    const UI = () => {

        const checkBoxChecked = ({scopename, role_id}) => {
            let role = roles.filter( role => role._id === role_id)[0].name
            return (
                <Fragment>
                    <div className="form-check-primary" key={uniqid()}>
                        <input 
                            type="checkbox" 
                            data-scopename={scopename} 
                            data-role_id={role_id} 
                            className="form-check-input"
                            defaultChecked={true} 
                            onChange={(event) => {toggleScopeToRole(event)}} 
                            disabled={(role === 'god')? true:false}
                        />
                    </div>
                </Fragment>
            )
        }
        const checkBoxUnChecked = ({scopename, role_id}) => {
            return (
                <Fragment>
                    <div className="form-check-primary">
                        <input type="checkbox" data-scopename={scopename} data-role_id={role_id} className="form-check-input" defaultChecked={false} onChange={(event) => {toggleScopeToRole(event)}} />
                    </div>
                </Fragment>
            )
        }

        const showSuperPowerButtons = () => {
            return (
                <Fragment>
                    <PermissionsWrapper scopes={['permission.purge.any', 'permission.purge.own']} roles={['god']} user={user}>
                        <button id="destroyAllPermissions" className="btn btn-primary me-1 mt-50 mb-50" onClick={() => {purge()}} > <span className="me-25"><FontAwesomeIcon icon={user?.roles?.includes('god')? faBomb : faTrash} /></span> {user?.roles?.includes('god')?'Zerstören':'Orga Bereinigen'}</button>
                    </PermissionsWrapper>
                    <PermissionsWrapper scopes={['permission.initialize.any', 'permission.initialize.own']} roles={['god']} user={user}>
                        <button id="initializePermissions" className="btn btn-primary me-1 mt-50 mb-50" onClick={() => {initialize()}} > <span className="me-25"><FontAwesomeIcon icon={faWrench} /></span> Initialisieren</button>
                    </PermissionsWrapper>
                    <PermissionsWrapper scopes={[]} roles={[]} user={user}>
                        <button className="btn btn-danger me-1 mt-50 mb-50"  onClick={() => {initializeAll()}} ><span className="me-50"><FontAwesomeIcon icon={faWrench} /></span>Alles Initialisieren</button>
                    </PermissionsWrapper>
                </Fragment>
            )
        }

        return (
            <Fragment>


                <div className="content-header row">
                    <div className="content-header-left col-md-9 col-12 mb-2" id="permissionPage">
                        <div className="row breadcrumbs-top">
                            <div className="col-12">
                                <h2 className="content-header-title float-start mb-0">Berechtigungen</h2>
                                <div className="breadcrumb-wrapper">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a >Verwaltung</a></li>
                                        <li className="breadcrumb-item active">Berechtigungen
                                        </li>
                                        <li>
                                            <span className="tourBorderRight" />
                                            <PageTour steps={TourSteps.permissions.overview} isButton={false} />
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <OrgCookieCrumb />
                </div>

                <div className="content-body">
                    <div className="row"> 
                        <section id="tblPermissionsSection">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card">

                                                    <div className='card-header  border-bottom d-flex justify-content-between align-items-center'>
                                                        <h4 className="card-title me-1" >Berechtigungsverwaltung </h4>
                                                        <div className="d-flex flex-fill flex-shrink me-50" id="scopeFilter" >
                                                            <input type="text" className="form-control mt-1 mb-1 me-1" style={{ height: "30px" }} id="scopesFilter" name="scopesFilter" placeholder="Scope-Filter ..." onChange={(e) => setScopesFilter(e.target.value)} />
                                                            
                                                        </div>
                                                        <div className="heading-elements d-flex ms-50"  style={{flexDirection:"row", alignItems:"center", flexWrap:"wrap"}} >
                                                            <div className="dropdown b-dropdown d-flex align-items-center me-1" id="rolesFilterbtn" >
                                                                <button aria-haspopup="true" type="button"  data-bs-toggle="dropdown" className="btnOverflowMenu btn btn-primary dropdown-toggle mt-50 mb-50 dropdown-toggle-split" >
                                                                    Rollen-Filter
                                                                </button>
                                                                <ul role="menu" tabindex="-1" className="dropdown-menu dropdown-menu-left" >
                                                                    {roles.map((role) => {
                                                                        return (
                                                                            <li key={uniqId()} >
                                                                                <label className="dropdown-item" for={role?.name}>
                                                                                    <input className="form-check-input input-sm" type="checkbox" id={role?.name} name={role?.name} checked={rolesFilter?.includes(role?.name)} onChange={onRoleFilterChange} />
                                                                                    <span className="ms-50 me-50">{role?.name}</span>
                                                                                </label>
                                                                            </li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            </div>
                                                           <span id="superPowerBtns"> {showSuperPowerButtons()} </span>
                                                            <span>
                                                                  <PermissionsWrapper scopes={['role.create.any']} roles={['god']} user={user}>
                                                                    <button id="createRoleBtn" className="btn btn-secondary me-1 mt-50 mb-50"  onClick={() => {createRole()}} ><span className="me-50"><FontAwesomeIcon icon={faUser} /></span>Neue Rolle</button>
                                                                  </PermissionsWrapper>
                                                                  <PermissionsWrapper scopes={['role.delete.any']} roles={['god']} user={user}>
                                                                    <button id="deleteRoleBtn" className="btn btn-danger mt-50 mb-50"  onClick={() => {deleteRole()}} ><span className="me-50"><FontAwesomeIcon icon={faTrash} /></span>Rolle löschen</button>
                                                                  </PermissionsWrapper>
                                                          </span>


                                                        </div>
                                                    </div>

                                                    <div className="card-body overflowx table-responsive " id="tblPermissionResponsive">
                                                        <table className="datatables-basic table table-hover" id="tblPermissions">
                                                            <thead>
                                                                <tr>
                                                                    <th></th>
                                                                    <th></th>
                                                                    {roles.map( role => {
                                                                        if(rolesFilter && rolesFilter.length > 0 && !rolesFilter.includes(role.name)) return;
                                                                        return (
                                                                            <th key={uniqid()} data-id={role.token}>{role.name}</th>
                                                                        )
                                                                    })}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                scopes.map((scope) => {
                                                                    if (scopesFilter && scope?.name.indexOf(scopesFilter) < 0 && scope?.description.indexOf(scopesFilter) < 0) return null;
                                                                    return (
                                                                        <tr key={uniqid()} className="">
                                                                            <td data-id={scope.token}><span className="badge bg-primary" >{scope.name}</span></td>
                                                                            <td data-id={scope.description} style={{padding:0}}><small>{scope.description}</small></td>
                                                                            {roles.map( role => {
                                                                                if(rolesFilter && rolesFilter.length > 0 && !rolesFilter.includes(role.name)) return;
                                                                                return (
                                                                                    <td
                                                                                        key={uniqid()}
                                                                                    >{ (role.scopes.includes(scope.name))? checkBoxChecked({scopename:scope.name, role_id:role._id}) : checkBoxUnChecked({scopename:scope.name, role_id:role._id}) }</td>
                                                                                )
                                                                            })}
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
           {UI()}
        </Fragment>
    )
}

Permissions.requireAuth = true;
Permissions.requiredScope = ["permission.view.any"]
export default Permissions;
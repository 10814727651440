import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import { SocketContext } from '../../../../context/Socket/Sockets';

import {GatewaysContext} from "../../../../context/Devices/Gateways";
import {SensorsContext} from "../../../../context/Devices/Sensors"
import {OrganizationContext} from '../../../../context/Organization/Organization';

import uniqId from 'uniqid';
import {useRouter} from "../../../../components/Shared/Router/Router";
import Swal from '@molline/sweetalert2'

import {LocationsContext} from '../../../../context/Locations/Locations'
import {AuthContext} from '../../../../context/Auth/AuthProvider'
import {hasPermission, PermissionsWrapper} from "../../../../components/Dashboard/reactPermissionsWrapper";
import TagComponent from "../../../../components/Dashboard/tagsComponent";
import TblComments from "../../../../components/Dashboard/tables/dataTables/tblComments";
import TblSensorsInSight from "../../../../components/Dashboard/tables/dataTables/tblSensorsInSight";
import TblTelegrams from '../../../../components/Dashboard/tables/dataTables/tblTelegrams';

import _ from "underscore";
import {Link, NavLink} from "react-router-dom";
import TourSteps from "../../../../TourSteps.json";
import PageTour from "../../../../components/Dashboard/tour/tour";

import WidgetCountSerialsInsight from '../../../../components/Dashboard/widgets/gateways/countGatewaySerialsInsight'
import WidgetCountKnownSerialsInsight from '../../../../components/Dashboard/widgets/gateways/countKnownGatewaySerialsInsight'
import WidgetZombieMode from '../../../../components/Dashboard/widgets/gateways/zombieMode';
import GatewayLastSeenWidget from '../../../../components/Dashboard/widgets/gateways/gatewayLastSeen';
import GatewayStatusWidget from '../../../../components/Dashboard/widgets/gateways/gatewayStatus';
import GatewayPowerWidget from '../../../../components/Dashboard/widgets/gateways/gatewayPower';

import de from '../../../../components/Dashboard/tables/datatable.de.json'
const $ = require('jquery');
$.DataTable = require('datatables.net');


const GatewayDetails = (props) => {

    const router = useRouter()
    const { user } = useContext(AuthContext);
    const [uid, setUid] = useState(router.query.serial_number);
    const { 
        getGateway, 
        gateway, 
        manufacturers, 
        updateGateway, 
        addComment, 
        deleteComment, 
        activateZombieMode, 
        deleteGateway, 
        configureGateway, 
        gatewaysChanged,
        gatewayChanged,
        doYouReallyWantToSave,
        errorToast,
        successToast,
        viewGatewayConfig,
        viewGatewaySupervision
    } = useContext(GatewaysContext);
    const { sensors } = useContext(SensorsContext);
    const { locations, getAllLocations } = useContext(LocationsContext)
    const sensorsChanged = useCompare(sensors);
    const [gatewayManufacturer, setGatewayManufacturer] = useState('-')
    const [receivedSensors, setReceivedSensors] = useState([])
    const [tabActive, setTabActive] = useState('overview');
    const [isEditing, setIsEditing] = useState(false)

    const [sensorInsight, setSensorInsight] = useState([]);
    const { organization, organizations } = useContext(OrganizationContext);

    const organizationsChanged = useCompare(organizations);
    const gatewayUidChanged = useCompare(uid)
    const [editForm, setEditForm] = useState({})

    const { socket } = useContext(SocketContext);

    useEffect(() => {
        
            if (gatewayUidChanged) {
                getGateway(uid)
            }

            if (manufacturers.length > 0) {
                putManufacturer()
            }

    
/** 
            if(socket !== null ){
                socket.on('gateway', (data) => {
                    let uid = data.payload.uid
                    if(gateway.uid === uid){
                        //playAlert();
                        switch(data.event){
                            case 'gateway.updated':
                                Swal.fire({
                                    icon: 'info',
                                    toast: true,
                                    position: "bottom-end",
                                    showConfirmButton: false,
                                    timer: 10000,
                                    timerProgressBar: true,
                                    title: 'Gateway Updated',
                                    html: `<p>Gateway ${uid} has been updated</p>`,
                                })
                                break;
                            case 'gateway.data':
                                let filename = data.payload.filename
                                Swal.fire({
                                    icon: 'info',
                                    toast: true,
                                    position: "bottom-end",
                                    showConfirmButton: false,
                                    timer: 10000,
                                    timerProgressBar: true,
                                    title: 'Gateway DATA received',
                                    html: `<p>Gateway ${uid} has sent data file ${filename} and is is processing.</p>`,
                                })
                                break;
                            case 'gateway.ack':
                                let ack = data.payload.ack
                                Swal.fire({
                                    icon: (ack.ack.result === 'ok')? "success":"error",
                                    toast: true,
                                    position: "bottom-end",
                                    showConfirmButton: false,
                                    timer: 10000,
                                    timerProgressBar: true,
                                    title: `Gateway Config ${(ack.ack.result === 'ok')? 'install SUCCESS':'install FAILED' }`
                                })
                                break;
                            case 'gateway.supervision':
                                let sup = data.payload.supervision
                                Swal.fire({
                                    icon: 'info',
                                    toast: true,
                                    position: "bottom-end",
                                    showConfirmButton: false,
                                    timer: 10000,
                                    timerProgressBar: true,
                                    title: 'Gateway SUPERVISION',
                                    html: `<p>Gateway ${uid} has sent a new Supervision file.</p>`,
                                })
                                break;
                            case 'gateway.log':
                                let log = data.payload.log
                                Swal.fire({
                                    toast: true,
                                    position: "bottom-end",
                                    showConfirmButton: false,
                                    icon: 'info',
                                    timer: 10000,
                                    timerProgressBar: true,
                                    title: 'Gateway LOG',
                                    html: `<p>Gateway ${uid} has sent a new LOG file.</p>`,
                                })
                                break;
                            default: 
                                break;
                        }
                    }
                })
            }
**/

    }, [uid, gateway, manufacturers, receivedSensors, organizations, organizationsChanged, organization, locations, sensors, tabActive, editForm])

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const capitalize = (str) => {
        if(!str) return str;
        if (str.indexOf("-") > -1)
            return str.split("-").map((w) => `${w.charAt(0).toUpperCase()}${w.slice(1)}`).join("-")
        return `${str.charAt(0).toUpperCase()}${str.slice(1)}`
    }

    const putManufacturer = () => {
        const myManufacturer = manufacturers.find(row => row._id === gateway.manufacturer_id)
        if (myManufacturer !== undefined) setGatewayManufacturer(myManufacturer.name + ` (${myManufacturer.short_name})`)
    }

    const showChangeLocationModal = async (location_data = null) => {
        return new Promise((resolve, reject) => {
            if(!location_data) location_data = locations;
            const changePopup = Swal.fire({
                width: "90%",
                title: `Nutzer suchen`,
                allowOutsideClick: true,
                backdrop: true,
                allowEscapeKey: true,
                allowEnterKey: false,
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: 'Abbrechen',
                html:
                    `<table id="tblSelectLocations" className="table mb-0 row-border hover order-column " role="grid" width="100%">
                        <thead>
                            <tr>
                                <th>Straße</th>
                                <th>Hausnr.</th>
                                <th>ME</th>
                                <th>Stadt</th>
                                <th>PLZ</th>
                                <th>Land</th>
                                <th></th>

                            </tr>
                        </thead>
                    </table>`,
                willOpen: () => {
                    const initializeDT = () => {
                        if ($.fn.dataTable.isDataTable(`#tblSelectLocations`)) {
                            $(`#tblSelectLocations`).DataTable().destroy();
                            // remove the eventhandler to prevent multiple events
                            $(`#tblSelectLocations tbody`).off('click')
                            $(`#tblSelectLocations tbody`).off('mouseenter')
                            $(`#tblSelectLocations tbody`).off('mouseleave')
                            initializeDT()
                        } else {
                            $(`#tblSelectLocations`).DataTable({
                                language: de,
                                paging: true,
                                pagingType: "full",
                                processing: true,
                                searching: true,
                                orderCellsTop: true,
                                fixedHeader: true,
                                pageLength: 100,
                                rowId: "_id",
                                data: location_data,
                                order: [[0, "asc"], [1, "asc"], [2, "asc"]],
                                columns: [
                         
                                    {
                                        "data": "address.street",
                                        render: (data) => capitalize(data)
                                    },
                                    { "data": "address.house_number" },
                                    { "data": "address.street1" },
                                    {
                                        "data": "address.city",
                                        render: (data) => capitalize(data)
                                    },
                                    { "data": "address.post_code" },
                                    {
                                        "data": "address.country",
                                        render: (data) => data.toLocaleUpperCase()
                                    },
                                    {
                                        data: "null", render: function (data, type, row, meta) {
                                            return `<div style="text-align:right"><button class="btn btn-primary btn-small" id="location-select-btn" data-location_id=${row._id}>Auswählen</button></div>`
                                        }
                                    }
                                ]
                            });

                            $('#tblSelectLocations tbody').on('mouseenter', 'tr', function () {
                                $(this).css('cursor', 'pointer');
                                $(this).css('background-color', '#f3f2f7');
                            });

                            $('#tblSelectLocations tbody').on('mouseleave', 'tr', function () {
                                $(this).css('background-color', '#fff');
                            });

                            $('#tblSelectLocations tbody').on('click', 'tr', function () {
                                const button = $(this).find('button').first();
                                if (button && button.get(0)) {
                                    let location_id = button.get(0).dataset.location_id;
                                    resolve(location_id);
                                    changePopup.close();
                                }
                            });
                        }
                    }
                    initializeDT()

                }
            })
        });
    }

    const addGatewayComment = async (comment) => {
        await addComment({comment: comment, _id: gateway._id})
    }

    const deleteGatewayComment = async (comment_id) => {
        await deleteComment({_id: gateway._id, comment_id: comment_id})
    }

    const addGatewayTag = (newTags) => {
        let tags = [...editForm.tags]
        let _newTags = newTags.split(',').map((v) => v.trim());
        for (let newTag of _newTags) {
            tags.push(newTag)
        }
        tags = tags.filter((v, i, a) => a.indexOf(v) === i && v.trim());
        updateForm('tags', tags)
    }

    const deleteGatewayTag =  (index) => {
        let tags = [...editForm.tags];
        tags.splice(index, 1)
        updateForm('tags', tags)
    }

    const updateForm = (key, value, nestedKey) => {
        if(key === 'manufacturer_id') {
            const upd = {...editForm}
            upd.model = ''
            upd.manufacturer_id = value
            setEditForm(upd)
            return
        }
        if (nestedKey) {
            const nested = editForm[nestedKey]
            nested[key] = value
            setEditForm({ ...editForm, [nestedKey]: nested })
        } else {
            setEditForm({ ...editForm, [key]: value })
        }
    }

    const tryChangeLocation = async (e) => {
        e.preventDefault()
        let organization_id = document.getElementById('overviewOrganization').value;

        getAllLocations({organization_id, filter: false})
        .then( async theseLocations => {
            let selectedLocation = await showChangeLocationModal(theseLocations);
            if (selectedLocation && selectedLocation !== "") {
                let gwLocation = locations.filter(l => l._id === selectedLocation)[0]
                updateForm('location_id', gwLocation._id)
            }
        })
    }

    const clearSensorsInsight = async () => {
        let target = JSON.parse(JSON.stringify(gateway))
        target.serials_insight = [];
        updateGateway(target).then(() => { getGateway(gateway.uid) })
    }
    /*
     * Overview Tab / Details Tab
     */
    const OverviewTabContent = () => {
       
        if (!gateway) return (<Fragment></Fragment>);

        const saveGatewayDetails = async () => {
            if(hasPermission({user, scopes:['gateway.update.any', 'gateway.update.own']})) {
                let params = editForm;
                //automatically correct battery flag from supervision file
                if (gateway.supervision && gateway.supervision.msg) {
                    if (gateway.supervision.msg.battery) {
                        params.battery = "true";
                        params.power_supply = "false";
                    }
                }
                const doSave = await doYouReallyWantToSave()
                if (!doSave) {
                    return;
                }
                updateGateway(params).then((newGateway) => {
                    //console.log("updated gateway:", newGateway)
                    if (newGateway) {
                        getGateway(uid)
                    }
                }).catch(err => errorToast(err))
                    .finally(() => {
                        setIsEditing(false)
                    });
            }
        }

        const RenderConditionalButtons = () => {
            if (isEditing === true) {
                return (
                    <div className="col-12">
                        <button className="btn btn-primary mt-1 me-1 waves-effect waves-float waves-light" onClick={(e) => { e.preventDefault(); saveGatewayDetails() }}>Speichern</button>
                        <button className="btn btn-muted mt-1 me-1 waves-effect waves-float waves-light" onClick={(e) => {
                            e.preventDefault();
                            setIsEditing(false);
                        }}>Abbrechen</button>
                    </div>
                )
            } else {
                if (!!user) {
                    return (
                        <PermissionsWrapper
                            user={user}
                            scopes={['gateway.update.any']}
                        >
                            <div className="col-12">
                                <button className="btn btn-secondary mt-1 me-1 waves-effect waves-float waves-light" id="gatewayUpdateBtn" onClick={(e) => {
                                    e.preventDefault();
                                    setEditForm(gateway)
                                    setIsEditing(true);
                                }}>Anpassen</button>
                            </div>
                        </PermissionsWrapper>
                    )
                }
            }
            return (<Fragment></Fragment>);
        }

        return (
            <Fragment>
                <div className="card">
                    <div className="card-header border-bottom">
                        <div style={{ display: "flex", flex: "1 1 auto" }}>
                            <div style={{ flex: 1 }}>
                                <h4 className="card-title">Registrierungsdetails  </h4>
                            </div>
                            
                        </div>
                    </div>
                    {gateway.tags && (gateway.tags.length >= 0 && isEditing || !isEditing && gateway.tags.length > 0) &&
                      <span id="gatewayTags">  <TagComponent tags={isEditing ? editForm.tags : gateway.tags} isEditing={isEditing} addTagFunction={addGatewayTag} deleteTagFunction={deleteGatewayTag} /> </span>
                    }
                    <div className="card-body" id="GatewayOverview">
                        <form className="validate-form mt-2 pt-50" noValidate="noValidate" id="frmGatewayOverview">
                            <div className="row">
                                <div className="col-12 col-sm-6 mb-1">
                                    <label className="form-label" htmlFor="overviewManufacturer">Hersteller</label>
                                    {isEditing === true ?
                                        <select className="form-control " id="overviewManufacturerChange" name="manufacturerChange"  onChange={(e) => { updateForm('manufacturer_id', e.target.value) }}>
                                            {
                                                manufacturers.map(man => {
                                                    return (
                                                        <option key={uniqId()} value={man._id} selected={isEditing ? editForm.manufacturer_id === man._id : gateway?.manufacturer_id === man._id}>{`${man.name} (${man.short_name})`}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        :
                                        <input type="text" className="form-control" id="overviewManufacturer" name="manufacturer" value={gatewayManufacturer ? gatewayManufacturer : 'Unbekannt/Fehler'} disabled />
                                    }
                                </div>
                                <div className="col-12 col-sm-6 mb-1">
                                    <label className="form-label" htmlFor="overviewModel">Modell</label>

                                    <input type="text" className="form-control" id="overviewModel" name="model" onChange={(e) => { updateForm('model', e.target.value) }} value={isEditing ? editForm.model : gateway?.model} disabled={!isEditing} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-6 mb-1">
                                    <label className="form-label" htmlFor="overviewSerialNo">Seriennummer</label>
                                    <input type="text" className="form-control" id="overviewSerialNo" name="serial" value={gateway.serial_number ? gateway.serial_number : '-'} disabled />
                                </div>
                                <div className="col-12 col-sm-6 mb-1">
                                    <label className="form-label" htmlFor="overviewSimType">SIM-Hersteller</label>
                                    <input type="text" className="form-control" id="overviewSimType" name="sim_type" value={gateway.sim_type ? gateway.sim_type : '-'} disabled />
                                </div>
                                <div className="col-12 col-sm-6 mb-1">
                                    <label className="form-label" htmlFor="overviewSimId">SIM-ID</label>
                                    <input type="text" className="form-control" id="overviewSimId" name="sim_id" value={gateway.sim_id ? gateway.sim_id : '-'} disabled />
                                </div>
                                <div className="col-12 col-sm-6 mb-1">
                                    <label className="form-label" htmlFor="overviewUID">UID</label>
                                    <input type="text" className="form-control" id="overviewUID" name="uid" value={gateway.uid ? gateway.uid : '-'} disabled />
                                </div>
                                <div className="col-12 col-sm-6 mb-1">
                                    <label className="form-label" htmlFor="overviewIMEI">IMEI</label>
                                    <input type="text" className="form-control" id="overviewIMEI" name="imei" value={gateway.imei ? gateway.imei : '-'} disabled />
                                </div>
                                <div className="col-12 col-sm-6 mb-1">
                                    <label className="form-label" htmlFor="overviewICCID">ICC-ID</label>
                                    <input type="text" className="form-control" id="overviewICCID" name="iccid" value={gateway.iccid ? gateway.iccid : '-'} disabled />
                                </div>
                                <div className="col-12 col-sm-6 mb-1">
                                    <label className="form-label" htmlFor="overviewLocation">Nutzereinheit</label>
                                    <div className="input-group">
                                        <span className="input-group-text"><i class="fa-solid fa-house"></i></span>
                                        <input type="text" className="form-control" id="overviewLocation" name="location" onClick={(e) => tryChangeLocation(e)} value={isEditing ? editForm.location_id : gateway.location_id} disabled={!isEditing} />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 mb-1">
                                        <label className="form-label">Beschreibung</label>
                                        <input type="text" className="form-control" onChange={(e) => { updateForm('description', e.target.value) }} value={isEditing ? editForm.description : gateway?.description} disabled={!isEditing} />
                                    </div>
                                <div className="col-12 col-sm-6 mb-1">
                                    <label className="form-label" htmlFor="organization">Organisation*</label>
                                    <PermissionsWrapper
                                        user={user}
                                        scopes={['gateway.create.any', 'gateway.update.any']}
                                        errorProps={{ disabled: true }}
                                    >
                                        <select className="select2 form-select" name="organization_id" id="overviewOrganization" onChange={ (e) => updateForm('organization_id', e.target.value) } disabled={!isEditing}>
                                            {
                                                organizations.map(org => {
                                                    let selected = org._id === gateway.organization_id;
                                                    return (
                                                        <option key={org._id} value={org._id} selected={isEditing ? editForm?.organization_id === org._id : gateway.organization_id === org._id}>{org.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </PermissionsWrapper>
                                </div>
                            </div>
                            {RenderConditionalButtons()}
                        </form>
                    </div>
                </div>
            </Fragment>
        )
    }

    const UI = () => {
        let insights = [];
        let insight = [];
        if(gateway.serials_insight && gateway.serials_insight.length > 0){
            for(let ins of gateway.serials_insight){
                insights = insights.concat(ins.serials_insight)
            }
            insight = [...new Set(insights)]
        }
        let known_sensors = sensors.filter( s => insight.includes(s.serial_number))

        return (
            <Fragment>
                <div className="content-header row">
                    <div className="content-header-left col-md-9 col-12 mb-2">
                        <div className="row breadcrumbs-top">
                            <div className="col-12">
                                <h2 className="content-header-title float-start mb-0">Gateway {(gateway !== undefined) ? '(' + gateway?.uid + ')' : ''}</h2>
                                <div className="breadcrumb-wrapper">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a >Apps</a></li>
                                        <li className="breadcrumb-item"><Link className="breadcrumb-navLink" to="/apps/gateways"><a>Gateways</a></Link></li>
                                        <li className="breadcrumb-item active">Details</li>
                                        <li>
                                            <span className="tourBorderRight"></span>
                                            <PageTour steps={TourSteps.gateways.details}  isButton={false} />
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-body">

                    <section id="gatewayData">
                        <div className="row match-height">

                            <GatewayLastSeenWidget />
                            <GatewayStatusWidget />
                            <GatewayPowerWidget />
                            <WidgetZombieMode />
                            <WidgetCountSerialsInsight />
                            <WidgetCountKnownSerialsInsight />

                        </div>
                    </section>

                    <section id="gatewayTabs">
                        <div className="row">
                            <div className="col-12">
                                <ul className="nav nav-pills mb-2">
                                    <li className="nav-item">
                                        <a className={`nav-link ${(tabActive === 'overview') ? 'active' : ''}`} onClick={() => { setTabActive('overview') }}>
                                            <i class="fa-solid fa-magnifying-glass"></i>
                                            <span className="fw-bold">Details</span>
                                        </a>
                                    </li>

                                    <PermissionsWrapper
                                        user={user}
                                        scopes={['sensor.view.any', 'sensor.view.own']}
                                    >
                                        <li className="nav-item" id="gatewaySensorTb">
                                            <a className={`nav-link ${(tabActive === 'sensors') ? 'active' : ''}`} onClick={() => { setTabActive('sensors') }}>
                                                <i class="fa-solid fa-microchip"></i>
                                                <span className="fw-bold">Zähler</span>
                                            </a>
                                        </li>
                                    </PermissionsWrapper>

                                    <PermissionsWrapper
                                        user={user}
                                        scopes={['comment.view.any', 'comment.view.own']}
                                    >
                                        <li className="nav-item" id="gatewayCommentTb">
                                            <a className={`nav-link ${(tabActive === 'comments') ? 'active' : ''}`} onClick={() => { setTabActive('comments') }}>
                                                <i class="fa-solid fa-message-lines"></i>
                                                <span className="fw-bold">Anmerkungen</span>
                                            </a>
                                        </li>
                                    </PermissionsWrapper>

                                    <PermissionsWrapper
                                        user={user}
                                        scopes={['gateway.update.any', 'gateway.delete.any']}
                                    >
                                        <li className="nav-item" id="gatewayAdminTb">
                                            <a className={`nav-link ${(tabActive === 'administration') ? 'active' : ''}`} onClick={() => { setTabActive('administration') }}>
                                                <i class="fa-solid fa-wrench"></i>
                                                <span className="fw-bold">Administration</span>
                                            </a>
                                        </li>
                                    </PermissionsWrapper>

                                    <PermissionsWrapper
                                        user={user}
                                        scopes={['telegram.view.any', 'telegram.view.own']}
                                    >
                                        <li className="nav-item" id="gatewayTelegramTb">
                                            <a className={`nav-link ${(tabActive === 'telegrams') ? 'active' : ''}`} onClick={() => { setTabActive('telegrams') }}>
                                                <i class="fa-solid fa-memo-circle-info"></i>
                                                <span className="fw-bold">Telegramme</span>
                                            </a>
                                        </li>
                                    </PermissionsWrapper>

                                </ul>
                            </div>
                        </div>
                    </section>

                    <section id="tabpanels">
                        <div className="row">
                            <div className="col-12">
                                <div className="tab-content pt-1">
                                    <div className={`tab-pane ${(tabActive === 'overview') ? 'active' : ''}`} id="logs" role="tabpanel" aria-labelledby="home-tab-fill">
                                        {OverviewTabContent()}
                                    </div>

                                    <PermissionsWrapper
                                        user={user}
                                        scopes={['sensor.view.any', 'sensor.view.own']}
                                    >
                                        <div className={`tab-pane ${(tabActive === 'sensors') ? 'active' : ''}`} id="logs" role="tabpanel" aria-labelledby="home-tab-fill">
                                            <div className="card">
                                                <div className="card-header border-bottom">
                                                    <h4 className="card-title">Bekannte Zähler {gateway && gateway.serials_insight && `(${known_sensors.length})`}</h4>
                                                    <PermissionsWrapper 
                                                        scopes={['gateway.update.any']} 
                                                        user={user} 
                                                    >
                                                        <div className="heading-elements">
                                                            <div className="heading-elements">
                                                                <button className="btn btn-danger " onClick={() => { clearSensorsInsight(gateway.uid) }} ><i class="fa-solid fa-broom"></i> Empfangene Zähler leeren</button>
                                                            </div>
                                                        </div>
                                                    </PermissionsWrapper>
                                                
                                                
                                                </div>
                                                <div className="card-body py-2 my-25 table-responsive">
                                                    <TblSensorsInSight />
                                                </div>
                                            </div>
                                        </div>
                                    </PermissionsWrapper>

                                    <PermissionsWrapper
                                        user={user}
                                        scopes={['comment.view.any', 'comment.view.own']}
                                    >
                                        <div className={`tab-pane ${(tabActive === 'comments') ? 'active' : ''}`} id="logs" role="tabpanel" aria-labelledby="home-tab-fill">
                                            <TblComments comments={gateway.comments} addComment={addGatewayComment} deleteComment={deleteGatewayComment} />
                                        </div>
                                    </PermissionsWrapper>

                                    <PermissionsWrapper
                                        user={user}
                                        scopes={['gateway.update.any']}
                                    >
                                        <div className={`tab-pane ${(tabActive === 'administration') ? 'active' : ''}`} id="administration" role="tabpanel" aria-labelledby="home-tab-fill">
                                            <div className="col-12 align-items-stretch">
                                                <div className="card mb-0 full-height" style={{ backgroundColor: 'transparent' }}>
                                                    <div className="card-header border-bottom">
                                                        <h4 className="card-title">Administration</h4>
                                                    </div>
                                                    <div className="card-body py-2 my-25">

                                                        <PermissionsWrapper
                                                            user={user}
                                                            scopes={['gateway.update.any']}
                                                        >
                                                            <div className="row mb-1">
                                                                <div className="col-3"> 
                                                                    <button className="btn btn-info" title="Betrachten Sie die aktuelle Konfiguration des Gateways" id="gatewayViewConfigBtn" onClick={(e) => {viewGatewayConfig(gateway.uid)}}>
                                                                        <i class="fa-solid fa-gear"></i> Aktuelle Konfiguration
                                                                    </button>
                                                                </div>
                                                                <div className="col-9"> 
                                                                    Lassen Sie sich die aktuelle Konfiguration anzeigen.
                                                                </div>
                                                            </div>
                                                        </PermissionsWrapper>

                                                        <PermissionsWrapper
                                                            user={user}
                                                            scopes={['gateway.update.any']}
                                                        >
                                                            <div className="row mb-1">
                                                                <div className="col-3"> 
                                                                    <button className="btn btn-info" title="Betrachten Sie die aktuelle Supervision-Datei des Gateways" id="gatewayViewConfigBtn" onClick={(e) => {viewGatewaySupervision(gateway.uid)}}>
                                                                        <i class="fa-solid fa-gear"></i> Aktuelle Supervision-Datei
                                                                    </button>
                                                                </div>
                                                                <div className="col-9"> 
                                                                    Gateways senden regelmäßig Status-Nachrichten an den Server, genannt "Supervision". Sollte dies für Ihr Gateway verfügbar sein, so können Sie hier den letzten Status ansehen.
                                                                </div>
                                                            </div>
                                                        </PermissionsWrapper>

                                                        <hr />

                                                        <PermissionsWrapper
                                                            user={user}
                                                            scopes={['gateway.update.any']}
                                                        >
                                                            <div className="row mb-1">
                                                                <div className="col-3"> 
                                                                    <button className="btn btn-primary" title="Senden Sie eine neue Konfiguration an das Gateway" id="gatewayConfigBtn" onClick={(e) => {configureGateway({uid:gateway.uid})}}>
                                                                        <i class="fa-solid fa-calendar-lines-pen"></i> Neue Konfiguration senden
                                                                    </button>
                                                                </div>
                                                                <div className="col-9">
                                                                    Hier können Sie eine neue Hör- und Sende-Konfiguration an das Gateway senden. Das Gateway hat bereits eine valide SIM- und FTP-Konfiguration. Dies wird hiervon nicht beeinträchtigt
                                                                    Folgende Möglichkeiten gibt es:
                                                                        <ol>
                                                                            <li>Wenn Sie auf "Konfiguration senden" klicken, ohne vorher die Konfiguration anzupassen, wird das Gateway an einem zufallsgenerierten Zeitpunkt zwischen 08:00 und 18:00 Uhr hören bzw. senden. 
                                                                            </li>
                                                                            <li>
                                                                                Sie können über das Formular selbst einen Zeitraum festlegen
                                                                            </li>
                                                                        </ol>
                                                                </div>
                                                            </div>
                                                        </PermissionsWrapper>

                                                        <PermissionsWrapper
                                                            user={user}
                                                            scopes={['gateway.update.any']}
                                                        >
                                                            <div className="row mb-1">
                                                                <div className="col-3"> 
                                                                    <button className="btn btn-warning" title="Versetzen Sie das Gateway in den Zombie-Modus" id="zombieModeBtn" onClick={() => {activateZombieMode(gateway.uid)}}>
                                                                        <i class="fa-solid fa-biohazard"></i> ZOMBIE-Modus aktivieren
                                                                    </button>
                                                                </div>
                                                                <div className="col-9"> 
                                                                   Wenn Sie den Zombie-Modus aktivieren, wird eine neue Konfiguration an das Gateway gesendet. Damit hört und sendet das Gateway alle 30 Minuten für die nächsten 8 Stunden.
                                                                   Standardmäßig hört/sendet das Gateway alle 24 Stunden. Der Zombie-Modus kann genutzt werden, um eine Gateway-Position zu bewerten und die dabei empfangenen Zähler.
                                                                   Dieser Modus kann frühzeitig beendet werden, indem eine neue Konfiguration an das Gateway gesendet wird.
                                                                </div>
                                                            </div>
                                                        </PermissionsWrapper>
                                                        
                                                        <PermissionsWrapper
                                                            user={user}
                                                            scopes={['gateway.delete.any']}
                                                        >
                                                            <div className="row mb-1">
                                                                <div className="col-3"> 
                                                                    <button className="btn btn-danger " title="Gateway löschen" id="btnDeleteGateway" onClick={(e) => {deleteGateway({uid:gateway.uid})}}>
                                                                        <i class="fa-solid fa-trash"></i> Gateway löschen
                                                                    </button>
                                                                </div>
                                                                <div className="col-9"> 
                                                                    Das Löschen eines Gateways kann nicht rückgängig gemacht werden. Alle Information werden aus dem System gelöscht. Sie können natürlich danach weitere Gateways registrieren.
                                                                    Sollte das Gateway aktuell installierte Zähler empfangen, ist das Löschen keine gute Idee.
                                                                </div>
                                                            </div>
                                                        </PermissionsWrapper>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </PermissionsWrapper>

                                    <PermissionsWrapper
                                        user={user}
                                        scopes={['telegram.view.any', 'telegram.view.own']}
                                    >
                                        <div className={`tab-pane ${(tabActive === 'telegrams') ? 'active' : ''}`} id="logs" role="tabpanel" aria-labelledby="home-tab-fill">
                                            <div className="card">
                                                <div className="card-header border-bottom">
                                                    <h4 className="card-title">Aktuelle Telegramme (maximal 250)</h4>
                                                </div>
                                                <div className="card-body py-2 my-25 table-responsive">
                                                    <TblTelegrams />
                                                </div>
                                            </div>
                                        </div>
                                    </PermissionsWrapper>

                                </div>
                            </div>
                        </div>
                    </section>

                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}

GatewayDetails.requiredScope = ['gateway.view.any', 'gateway.view.own'];
GatewayDetails.requireAuth = true;  // true -> force login via AuthContext, false -> no login required so no user available either
export default GatewayDetails;
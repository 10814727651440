
import React, {Fragment, useState, useEffect, useContext} from 'react';
import { AuthContext } from '../../context/Auth/AuthProvider';
import { LocaleContext } from '../../context/Locale/Locale';
import { useRouter } from '../../components/Shared/Router/Router'


const Hero = ({children}) => {

    const router = useRouter()
    const [updated, setUpdated] = useState(false);
    const {t, locale, localeAvailable, setLang} = useContext(LocaleContext);
//    const {config} = useContext(WhiteLabelContext);



    useEffect( () => {
        if(!updated){
            setUpdated(true)
        }

    }, [locale]);


    const UI = () => {
        return (
            <Fragment>
                <div className="hero">
                    <div className="container-fluid">
                        <img className="connect-logo" src="/homepage/assets/images/logo/molline_connect_logo_neg.svg" />
                        <h1 className="text-center white">Die innovative Lösung<br />für Ihr Energiedatenmanagement.</h1>
                        <p className="text-center h3 white">
                            Die perfekte Kombination aus intelligenter Softwarelösung und Messtechnik der Zukunft.
                        </p>
                        {/*<a className="btn-next text-center" href="#packages"></a>*/}
                        <ul className="text-center">
                            <li className="white">Herstellerunabhängig</li>
                            <li className="white">Garantiert sicherer Wechsel</li>
                            <li className="white">Schneller & einfach dank Fernkonfiguration</li>
                        </ul>
                    </div>
                </div>
            </Fragment>
        )
    }



    return (
        <Fragment>

            {UI()}

        </Fragment>
    )
}

export default Hero;
import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';
import { useRouter } from '../../Shared/Router/Router';
import { AuthContext } from '../../../context/Auth/AuthProvider';
import { AlertsContext } from '../../../context/Alerts/Alerts';
import { SocketContext } from '../../../context/Socket/Sockets';

import useSound from 'use-sound';
import Chime from '../audio/chime.mp3';

const AlertsIcon = (props) => {

    const {
        alerts,
        setAlerts,
        getAlerts,
        view,
        read,
        readAll,
        deleteAlert
    } = useContext(AlertsContext)

    const alertsChanged = useCompare(alerts)

    const [alertsToday, setAlertsToday] = useState([])
    const [alertsYesterday, setAlertsYesterday] = useState([])
    const [alertsOlder, setAlertsOlder] = useState([])
    const [showOnlyUnread, setShowOnlyUnread] = useState(false)
    const showOnlyReadChanged = useCompare(showOnlyUnread)
    const { socket, socketId } = useContext(SocketContext);

    const alertType = {
        sensor: {
            icon: 'fa-solid fa-microchip'
        },
        gateway: {
            icon: 'fa-solid fa-router'
        },
        location: {
            icon: 'fa-solid fa-location-dot'
        },
        system: {
            icon: 'fa-solid fa-gear'
        },
        default: {
            icon: 'fa-solid fa-gear'
        }

    }

    const [volume, setVolume] = useState(0.3);
    const [soundEnabled, setSoundEnabled] = useState(true)
    const [play, { stop, sound }] = useSound(Chime, {volume, interrupt:false, soundEnabled});
    let lastAudioReport = new Date().getTime()

    useEffect(()=>{ 

        document.getElementById('alertsDropdown').addEventListener('click',function (e){
            //console.log('CLICK'); e.stopPropagation()
        }); 
        
        if(alertsChanged === true || showOnlyReadChanged === true){
            init();
        }

        if(socket !== null ){
            socket.on('alert', (data) => {
                if( !data.socket_id || data.socket_id !== socketId) return;
                let now = new Date().getTime();

                //console.log('PLAYING SOCKET ALERT')

                if(now -  lastAudioReport > 60000 ) play() //Stop the nuissance audio. Only play every 60 sec if need be.
                lastAudioReport = now;
                let newAlerts = [...alerts]
                newAlerts.unshift(data.alert)
                setAlerts(newAlerts)
            })
        }

    }, [alerts, showOnlyReadChanged, showOnlyUnread, socketId])

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const init = () => {
        resolveAlertsToday();
        resolveAlertsYesterday();
        resolveAlertsOlder();
    }

    const resolveAlertsToday = () => {
        let today = new Date();
        let day = today.getDate();
        let year = today.getFullYear()
        let validAlerts = alerts.filter(alt => {
            let date = new Date(alt.created_at)
            if(
                date.getDate() === day && date.getFullYear() === year
            ) return alt
        })
        if(!!showOnlyUnread){ validAlerts = validAlerts.filter(a => !a.read_at) }
        setAlertsToday(validAlerts)
    }

    const resolveAlertsYesterday = () => {
        let today = new Date();
        let day = today.getDate() - 1;
        let month = today.getMonth();
        let year = today.getFullYear()
        let validAlerts = alerts.filter(alt => {
            let date = new Date(alt.created_at)
            if(
                date.getDate() === day && date.getFullYear() === year && date.getMonth() === month
            ) return alt
        })
        if(!!showOnlyUnread){ validAlerts = validAlerts.filter(a => !a.read_at) }
        setAlertsYesterday(validAlerts)
    }

    const resolveAlertsOlder = () => {
        let validAlerts = alerts.filter(alt => {
            let alertdate = new Date(alt.created_at)
            let date = new Date();
            let older = date.setDate( date.getDate() - 2)
            if( alertdate < older  ) return alt
        })
        if(!!showOnlyUnread){ validAlerts = validAlerts.filter(a => !a.read_at) }
        setAlertsOlder(validAlerts)
    }

    const alertIcon = (type) => {
        if(!!alertType[type] && !!alertType[type].icon) return alertType[type].icon
        else return alertType.default.icon
    }

    const markRead = (e) => {
        e.preventDefault()
        let hash = e.target.getAttribute("data-hash");
        read({hash})
    }

    const markAllRead = () => {
        try{ readAll() }catch(err){console.log(err)}
    }

    const showOnlyReadAlerts = () => {
        let show = false;
        if(!showOnlyUnread) show = true;
        setShowOnlyUnread(show);
    }

    const UI = () => {
        return(
            <Fragment>
                <a className="nav-link" href="#" data-bs-toggle={(alerts.length > 0)? "dropdown":""}>
                    <i className="fa-regular fa-bell ficon"></i>
                    <span className={`badge rounded-pill bg-danger ${(alertsToday.filter(a => !a.read_at).length > 0)?"":"hidden"} badge-up`}>{ alertsToday.filter(a => !a.read_at).length }</span>
                </a>
                <ul className="dropdown-menu dropdown-menu-media dropdown-menu-end" style={{width: "40rem", height: "50rem" ,overflow: "hidden"}}>
                    
                    <li className="dropdown-menu-header">
                        <div className="dropdown-header d-flex">
                            <h4 className="notification-title mb-0 me-auto">Nachrichten</h4>
                            <div className="float-right">Only show unread</div>
                            <div className={`ms-1 me-2 alert-read-status bg-${(!showOnlyUnread)?"secondary":"success"}`} onClick={() => showOnlyReadAlerts()} />
                        </div>
                    </li>

                    <li className="scrollable-container media-list">
                        <div className="alerts-list">

                            <div className={`muted alert-time-category ${(alertsToday.length === 0)?'hidden':''}`}>TODAY</div>
                            {
                                (alertsToday.length > 0)?
                                alertsToday.map(alert => {
                                    return(
                                        <div className="list-item d-flex align-items-start" key={`${alert.hash}`} >
                                            <div className="row w-100">
                                                <div className="col-1">
                                                    <div className="alert-icon alert-info">
                                                        <i className={`center fa-lg ${alertIcon()}`}></i>
                                                    </div>
                                                </div>
                                                <div className="col-10">
                                                    <div className="alert-title">{alert.title}</div>
                                                    <div className="alert-body">{alert.body}</div>
                                                </div>
                                                <div className="col-1">
                                                    <div className="alert-mark-read float-end">
                                                        <div className={`alert-read-status ${(!alert.read_at)?"bg-danger":"bg-success"}`} data-hash={alert.hash} onClick={(e) => markRead(e)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <Fragment></Fragment>
                            }

                            <div className={`muted alert-time-category ${(alertsYesterday.length === 0)?'hidden':''}`}>YESTERDAY</div>
                            {
                                (alertsYesterday.length > 0)?
                                alertsYesterday.map(alert => {
                                    return(
                                        <div className="list-item d-flex align-items-start" key={`${alert.hash}`} >
                                            <div className="row w-100" style={{width: "100%"}}>
                                                <div className="col-1">
                                                    <div className="alert-icon alert-info">
                                                        <i className="center fa-solid fa-lg fa-microchip"></i>
                                                    </div>
                                                </div>
                                                <div className="col-10">
                                                    <div className="alert-title">{alert.title}</div>
                                                    <div className="alert-body">{alert.body}</div>
                                                </div>
                                                <div className="col-1">
                                                    <div className="alert-mark-read float-end">
                                                        <div className={`alert-read-status ${(!alert.read_at)?"bg-danger":"bg-success"}`} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <Fragment></Fragment>
                            }

                            <div className={`muted alert-time-category ${(alertsOlder.length === 0)?'hidden':''}`}>OLDER</div>
                            {
                                (alertsOlder.length > 0)?
                                alertsOlder.map(alert => {
                                    return(
                                        <div className="list-item d-flex align-items-start" key={`${alert.hash}`} >
                                            <div className="row w-100">
                                                <div className="col-1">
                                                    <div className="alert-icon alert-info">
                                                        <i className="center fa-solid fa-lg fa-microchip"></i>
                                                    </div>
                                                </div>
                                                <div className="col-10">
                                                    <div className="alert-title">{alert.title}</div>
                                                    <div className="alert-body">{alert.body}</div>
                                                </div>
                                                <div className="col-1">
                                                    <div className="alert-mark-read float-end">
                                                        <div className={`alert-read-status ${(!alert.read_at)?"bg-danger":"bg-success"}`} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <Fragment></Fragment>
                            }

                        </div>
                    </li>

                    <li className="alerts-list-footer">
                        <div className="btn btn-sm btn-primary float-end" onClick={() => markAllRead()} >Mark All Read</div>
                    </li>

                </ul>
            </Fragment>
        )
    }

    return (
        <Fragment>{UI()}</Fragment>
    )
}

export default AlertsIcon;
import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import {useRouter} from "../../../Shared/Router/Router";
import { GatewaysContext } from "../../../../context/Devices/Gateways";
import {PermissionsWrapper, hasPermission} from "../../../../components/Dashboard/reactPermissionsWrapper";
import { AuthContext } from "../../../../context/Auth/AuthProvider";
import { LocationsContext } from '../../../../context/Locations/Locations';
import { SensorsContext } from '../../../../context/Devices/Sensors';

import Swal from '@molline/sweetalert2'

import de from '../datatable.de.json'
import capitalizeUtil from "../../../../utils/capitalizeUtils";
const $ = require('jquery');
$.DataTable = require('datatables.net');

const TblGateways = (props) => {

    const router = useRouter()
    const { user } = useContext(AuthContext);
    const { sensors } = useContext(SensorsContext);


    const { gateways, deleteGateway, updateGateway, configureGateway, longtermConfigGateway,sendGatewayToLager, viewGatewayConfig, putGatewayToStorageMode, putGatewayToInstallationMode } = useContext(GatewaysContext);
    const { locations,locationsChanged } = useContext(LocationsContext);

    const gatewaysChanged = useCompare(gateways)

    useEffect(() => {

        if(gatewaysChanged === true || locationsChanged === true) initializeDT()
        
    }, [ gateways, gatewaysChanged, locations, locationsChanged ]);

    function useCompare (val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }


    const initializeDT = () => {
        if ($.fn.dataTable.isDataTable(`#tblGateways`)) {
            $(`#tblGateways`).DataTable().destroy();
            // remove the eventhandler to prevent multiple events
            $(`#tblGateways tbody`).off('click')
            $(`#tblGateways tbody`).off('mouseenter')
            $(`#tblGateways tbody`).off('mouseleave')
            initializeDT()
        } else {
            $(`#tblGateways`).DataTable({
                language: de,
                paging: true,
                pagingType: "full",
                processing: true,
                searching: true,
                data: gateways,
                rowId: "uid",
                fixedHeader: true,
                pageLength: 50,
                order: [[1,'asc']],
                columnDefs: [
                    {
                        target: 0,
                        className: "text-center"
                    },
                    {
                        target: 1,
                        className: "text-center"
                    }
                ],
                columns: [
                    {
                        "data": null,
                        "searchable": false,
                        "ordering": true,
                        render: function ( data, type, row ) {
                            let configString = ""
                            if(data?.config?.current) configString = JSON.stringify(data.config.current)
                            if(data?.config && data?.config?.current && configString.search("ZOMBIE_") !== -1) return `<i class="fa-solid fa-biohazard text-error" title="ZOMBIE CONFIG"></i>`
                            else if(data?.config && data?.config?.current && configString.search("LONGTERM_") !== -1) return `<i class="fa-solid fa-high-definition text-success" title="LONG TERM CONFIG"></i>`
                            else return '<i class="fa-solid fa-standard-definition text-primary" title="STANDARD CONFIC"></i>'
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "ordering": true,
                        render: function ( data, type, row ) {
                            let configString = ""
                            let htmlString = ''
                            if(data?.config?.current) configString = JSON.stringify(data.config.current)
                            if(configString.includes('"apn":"iot.1nce.net"') && configString.includes('"mode":"2G"')) htmlString += `<span class="badge rounded-pill bg-warning text-dark">2G</span>`
                            if(configString.includes('"mode":"NB-IoT"')) htmlString += `<span class="badge rounded-pill bg-warning text-dark" ${htmlString !== '' ? 'style="margin-left: 5px"' : ''}>NB-IoT</span>`
                            if(configString.includes('"mode":"storage"')) htmlString += `<i class="fa-solid fa-warehouse text-primary" title="STORAGE MODE" ${htmlString !== '' ? 'style="margin-left: 5px"' : ''}></i>`
                            return htmlString
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "ordering": true,
                        render: function ( data, type, row ) {
                            if(data.uid !== undefined ){
                                return data.uid;
                            }else if(data.serial_number !== undefined){
                                return data.serial_number;
                            }
                            return '--'
                        }
                    },
                    {
                        "data": null,
                        "searchable": false,
                        "ordering": true,
                        render: function ( data, type, row ) {
                            let date = new Date(data.last_transmission)
                            if(type === "display") {
                                if( !!data.last_transmission ){
                                    var options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
                                    return `${date.toLocaleDateString("de-DE", options)}`;
                                }else{
                                    return '<i class="text-danger fa-solid fa-triangle-exclamation" />';
                                }
                            }else {
                                if( !!data.last_transmission ){
                                    const unix = date.getTime() / 1000
                                    return unix
                                }else{
                                    return 0
                                }
                            }
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "ordering": true,
                        render: function ( data, type, row ) {
                            if(data.power_supply === true || data?.attributes?.includes('datenlogger')){
                                return 'Netzteil';
                            }
                            if( !data.supervision ){
                                return `<span class="text-danger">?%</span> `
                            }
                            if( !!data?.supervision?.battery){
                                if(+data.supervision.battery.capaPrcent < 30 ) return `<span class="text-danger">${+data.supervision.battery.capaPrcent}%</span> `;
                                if(+data.supervision.battery.capaPrcent < 50 ) return `<span class="text-warning">${+data.supervision.battery.capaPrcent}%</span> `;
                                if(+data.supervision.battery.capaPrcent < 101 ) return `<span class="text-success">${+data.supervision.battery.capaPrcent}%</span> `;
                            }else{
                                return '--';
                            }
                        },
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "ordering": true,
                        render: function ( data, type, row ) {
                            if( !!data.rssi ){
                                let rssi = '--'
                                try{rssi = data.rssi}
                                catch(err){return '--'}
                                return rssi;
                            }else{
                                return '--';
                            }
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "ordering": false,
                        render: function ( data, type, row ) {
                            if( !!data.serials_insight ){
                                let sInsight = [], seen = [];
                                for(let tData of data.serials_insight){
                                    sInsight = sInsight.concat(tData.serials_insight)
                                }
                                sInsight = [...new Set(sInsight)]
                                for(let s of sensors){
                                    if(sInsight.includes(s.serial_number)) seen.push(s.serial_number)
                                }
                                return seen.length;
                            }else{
                                return '--';
                            }
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "ordering": false,
                        render: function ( data, type, row ) {
                            if (locations && locations.length > 0 && !!data.location_id) {
                                data.location = locations.filter(loc => loc._id === data.location_id)[0]
                                if (!!data.location) return `<a class="connectLink" data-href="${`/details/location/${data.location._id}`}" href="#" >${capitalizeUtil.capitalize(data.location.address.street)} ${data.location.address.house_number} ${(data.location.address.street1 !== '') ? ', ME ' + data.location.address.street1 : ''}, ${capitalizeUtil.capitalize(data.location.address.city)}, ${data.location.address.post_code}</a>`
                            }
                            return '<i class="text-danger fa-solid fa-triangle-exclamation"></i>';
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if (hasPermission({ scopes: ['gateway.update.own', 'gateway.update.any'], user: user})) {
                                return `
                                <div class="dropdown b-dropdown btn-group" id="${data._id}">
                                    <button aria-haspopup="true" type="button" data-bs-toggle="dropdown" class="btnOverflowMenu btn btn-sm dropdown-toggle btn-secondary dropdown-toggle-split" >
                                        <i class="fa-solid fa-bars"></i>
                                    </button>
                                    <ul role="menu" tabindex="-1" class="dropdown-menu dropdown-menu-right" style="min-width: 200px;">
                                        ${
                                            (hasPermission({ scopes: ['gateway.view.any', 'gateway.view.own'], user: user})) ?
                                            `<li role="presentation" class="viewGateway" data-serial-number="${data.serial_number}" >
                                                <a role="menuitem" class="dropdown-item"><i class="text-secondary fa-solid fa-magnifying-glass me-25"></i> Details</a>
                                            </li>`:''
                                        }
                                        ${
                                            (hasPermission({ scopes: ['gateway.update.any'], user: user})) ?
                                            `<li role="presentation" class="configGateway" data-serial-number="${data.serial_number}" >
                                                <a role="menuitem" class="dropdown-item"><i class="text-info fa-solid fa-upload me-25"></i> Konfiguration senden</a>
                                            </li>`:''
                                        }
                                        ${
                                            (hasPermission({ scopes: ['gateway.update.any'], user: user})) ?
                                            `<li role="presentation" class="longtermConfigGateway" data-serial-number="${data.serial_number}" >
                                                <a role="menuitem" class="dropdown-item"><i class="text-info fa-solid fa-upload me-25"></i> Langzeit-Konfiguration <br> senden</a>
                                            </li>`:''
                                        }
                                        ${
                                            (hasPermission({ scopes: ['gateway.update.any'], user: user})) ?
                                            `<li role="presentation" class="installationConfigGateway" data-serial-number="${data.serial_number}" >
                                                <a role="menuitem" class="dropdown-item"><i class="text-info fa-solid fa-wrench me-25"></i> Installations-Konfiguration <br> senden</a>
                                            </li>`:''
                                        }
                                        ${
                                            (hasPermission({ scopes: ['gateway.update.any'], user: user})) ?
                                            `<li role="presentation" class="storageModeGateway" data-serial-number="${data.serial_number}" >
                                                <a role="menuitem" class="dropdown-item"><i class="text-danger me-25 fa-solid fa-warehouse "></i> Storage-Modus aktivieren</a>
                                            </li>`:''
                                        }
                                        ${
                                            (hasPermission({   scopes: ['gateway.update.any'], user: user})) ?
                                            `
                                            <li role="presentation" class="gatewaySendToLager">
                                                <a role="menuitem" class="dropdown-item"><i class="text-primary me-25 fa-solid fa-warehouse "></i> An Lager senden</a>
                                            </li>
                                            ` : ''
                                        }
                                        ${
                                            (hasPermission({   scopes: ['gateway.update.any'], user: user})) ?
                                            `
                                            <li role="presentation" class="gatewayClearSerials" title="Leert die empfangenen Seriennummern des Gateways" >
                                                <a role="menuitem" class="dropdown-item"><i class="text-danger me-25 fa-solid fa-broom "></i> Seriennummern Leeren</a>
                                            </li>
                                            ` : ''
                                        }
                                        ${
                                            (hasPermission({   scopes: ['gateway.update.any'], user: user})) ?
                                            `
                                            <li role="presentation" class="gatewayViewConfig">
                                                <a role="menuitem" class="dropdown-item"><i class="text-primary me-25 fa-solid fa-gear "></i> Konfiguration ansehen</a>
                                            </li>
                                            ` : ''
                                        }
                                        <hr/>
                                        ${
                                            (hasPermission({   scopes: ['gateway.delete.any', 'gateway.delete.any'], user: user})) ?
                                            `
                                            <li role="presentation" class="delGateway">
                                                <a role="menuitem" class="dropdown-item"><i class="text-danger fa-solid fa-trash me-25"></i> Löschen</a>
                                            </li>
                                            ` : ''
                                        }
                                    </ul>
                                </div>`
                            }
                            return '';
                        }
                    }

                ],
                createdRow: function (row, data, dataIndex) {
                    $(row).attr('data-location_id', data.location_id || 'na');

                    // check if storage mode -> add blue row color
                    let configString = ""
                    if(data?.config?.current) configString = JSON.stringify(data.config.current)
                    if(configString.includes('"mode":"storage"')) {
                        $(row).addClass('alert-primary')
                        return
                    }


                    let date = new Date()
                    let sDate = new Date(data.last_transmission)
                    let hours = Math.abs(date - sDate) / 36e5;
                    if(data.last_transmission === undefined){
                        $(row).addClass('alert-danger');
                    }
                    if(hours > 240){ $(row).addClass('alert-warning'); }    //10 days
                    if(hours > 360){ $(row).addClass('alert-danger'); }     // 15 days
                    else{
                        return '--';
                    }
                },
                "fnDrawCallback": function( oSettings ) {}
            });

            $('.dataTables_length select').addClass('form-select');
            $('.dataTables_filter input').addClass('form-control');

            $(`#tblGateways tbody tr`).off('mouseenter').on('mouseenter', function () {
                $(this).css('cursor', 'pointer');
                $(this).css('background-color', '#f3f2f7');
            });
            $(`#tblGateways tbody tr`).off('mouseleave').on('mouseleave', function () {
                $(this).css('background-color', 'transparent');
            });

            $(`#tblGateways tbody`).on( 'click', 'tr', function () {});

            $(`#tblGateways`).on('error.dt', function (e, settings, techNote, message) {
                alert(message);
            });

            $(`#tblGateways tbody`).on('click', 'tr li.viewGateway', function (e) {
                let link = e.currentTarget;
                let parent = link.closest('tr');
                router.push(`/details/gateway/${parent.id}`);
            });
            $(`#tblGateways tbody`).on('click', ' li.configGateway', function (e) {
                let link = e.currentTarget;
                let parent = link.closest('tr');
                configureGateway({uid:parent.id}).then(()=>{});
            });
            $(`#tblGateways tbody`).on('click', ' li.longtermConfigGateway', function (e) {
                let link = e.currentTarget;
                let parent = link.closest('tr');
                longtermConfigGateway({uid:parent.id}).then(()=>{});
            });
            $(`#tblGateways tbody`).on('click', ' li.storageModeGateway', function (e) {
                let link = e.currentTarget;
                let parent = link.closest('tr');
                putGatewayToStorageMode({uid:parent.id}).then(()=>{});
            });
            $(`#tblGateways tbody`).on('click', ' li.installationConfigGateway', function (e) {
                let link = e.currentTarget;
                let parent = link.closest('tr');
                putGatewayToInstallationMode({uid:parent.id}).then(()=>{});
            });
            $(`#tblGateways tbody`).on('click', ' li.delGateway', function (e) {
                let link = e.currentTarget;
                let parent = link.closest('tr');
                deleteGateway({uid:parent.id}).then(()=>{});
            });
            $(`#tblGateways tbody`).on('click', ' li.gatewaySendToLager', function (e) {
                let link = e.currentTarget;
                let parent = link.closest('tr');
                sendGatewayToLager({uid:parent.id}).then(()=>{});
            });
            $(`#tblGateways tbody`).on('click', 'a.connectLink', function (e) {
                e.preventDefault()
                let link = e.currentTarget.getAttribute("data-href");
                router.push(link);
            });
            $(`#tblGateways tbody`).on('click', ' li.gatewayClearSerials', function (e) {
                let link = e.currentTarget;
                let parent = link.closest('tr');
                let target = gateways.filter( g => g.uid === parent.id)[0]
                if(!!target && !!target.uid){
                    let updatedGateway = JSON.parse(JSON.stringify(target))
                    updatedGateway.serials_insight = [];
                    updateGateway(updatedGateway)
                }
            });
            $(`#tblGateways tbody`).on('click', ' li.gatewayViewConfig', function (e) {
                let link = e.currentTarget;
                let parent = link.closest('tr');
                let target = gateways.filter( g => g.uid === parent.id)[0]
                viewGatewayConfig(parent.id)
                
            });
            $(`#tblGateways tbody tr td`).off('click').on('click', function (e) {
                const tagName = e.target.tagName;
                if(tagName === "TD") {
                    let id = e.target.closest('tr').id;
                    router.push(`/details/gateway/${id}`);
                }
            });
        }
    }

    const UI = () => {

        const isProcessing = () => {
            if(gateways.length === 0 && !gatewaysChanged){
                return (
                    <div className="mt-4">
                        <h4 className="text-center"><i className="fa-solid fa-cog fa-spin"></i> WIRD GELADEN ...</h4>
                    </div>
                )
            }
        }

        return (
            <Fragment>
                <div className='table-responsive'>
                    <table id={`tblGateways`} className="table mb-0 row-border hover order-column w-100" role="grid">
                        <thead>
                        <tr>
                            <th>Config</th>
                            <th>Mode</th>
                            <th>UID</th>
                            <th>Zuletzt gesendet</th>
                            <th>Akku</th>
                            <th>RSSI</th>
                            <th>Zähler</th>
                            <th>Nutzereinheit</th>
                            <th></th>
                        </tr>
                        </thead>
                    </table>
                </div>
                {isProcessing()}
            </Fragment>
        )
    }

    return (
        <Fragment>{UI()}</Fragment>
    )



}

export default TblGateways;

export const erklaerVidoesObjectsArr = [
    {
        type: 'video',
        title: 'MOLLINÉ Connect: Die Online-Plattform für Ihre Verbrauchsdaten',
        description: 'Mit dem Schnellstart erhalten Sie alle Informationen, die  Sie für die Nutzung der Plattform MOLLINÉ Connect benötigen. Das Video vereint alle nachstehenden Kapitel.',
        category: 'molline,kapital,platform,overview',
        tags: ['molline connect', 'plateform', 'registrierung', 'Anmeldung', 'dashboard', 'allgemeine information', 'berichte', 'mesgeräte', 'verwalten von Nutzern', 'tour', 'uvi'],
        bannerUrl: '/app-assets/images/helpVideos/Molliné_Connect_Allgemeine_Zusammenfassung.webp',
        url: 'https://www.youtube-nocookie.com/embed/dsJIMrDOMMw'
    },
    {
        type: 'video',
        title: 'Teil 1: Allgemeine Übersicht über das Dashboard',
        description: 'Mit diesem Video erhalten Sie eine erste Übersicht, welche Funktionen MOLLINÉ Connect bietet',
        category: '',
        tags: ['sprache', 'firma',  'organisation' ,'dark mode', 'theme', ],
        bannerUrl: '/app-assets/images/helpVideos/Molliné_Connect_Allgemeine_Übersicht_über_das_Dashboard.webp',
        url: 'https://www.youtube-nocookie.com/embed/Avmrk4zarqk'
    },
    {
        type: 'video',
        title: 'Teil 2: Übersicht und Download von Berichten',
        description: 'In diesem Video zeigen wir Ihnen, welche Berichte von Verbrauchwerten Sie auf MOLLINÉ Connect finden. Gleichzeitig erklären wir Ihnen welche Berichte Sie für welchen Zweck nutzen können.',
        category: '',
        tags: ['berichte', 'vui', 'vui-paket', 'verbrauchsbericht für nutzereinheiten', 'messbericht', 'sensoren', 'zähler', 'bericht herunterladen', 'jahres endbericht für zähler', 'jahres-Endbericht', 'uVi-Paket für den gesamten Kunden'],
        bannerUrl: '/app-assets/images/helpVideos/Molliné_Connect_Übersicht_und_Download_von_Berichten.webp',
        url: 'https://www.youtube-nocookie.com/embed/UvrCwUoc1HU'
    },
    {
        type: 'video',
        title: 'Teil 3: Die Nutzereinheiten zu Ihren Liegenschaften',
        description: 'Über das Dashboard finden Sie die Rubrik Nutzereinheiten. Hier finden Sie die Nutzereinheiten Ihrer Liegenschaften mit den zugehörigen Nutzern.',
        category: '',
        tags: ['nutzereinheiten', 'mieteinheiten'],
        bannerUrl: '/app-assets/images/helpVideos/Molliné_Connect_Die_Nutzereinheiten_zu_Ihren_Liegenschaften.webp',
        url: 'https://www.youtube-nocookie.com/embed/GyOZzFMpJWg'
    },
    {
        type: 'video',
        title: 'Teil 4: Übersicht und Verwaltung einzelner Nutzer',
        description: 'Über die Rubrik Nutzer sehen die Nutzer der jeweils ausgewählten Liegenschaften. Hier können Sie den Ein- und Auszug sowie die Daten des einzelnen Nutzers verwalten.',
        category: '',
        tags: ['nutzer', 'neuen nutzer einziehen', 'auszug', 'nutzerdetails'],
        bannerUrl: '/app-assets/images/helpVideos/Molliné_Connect_Übersicht_und_Verwaltung_einzelner_Nutzer.webp',
        url: 'https://www.youtube-nocookie.com/embed/rMhVK7OLzUE'
    }



]

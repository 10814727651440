import React, {Fragment, useState, useEffect, useContext } from 'react';
import Hero from '../../components/LandingPages/Hero'
import Packages from "../../components/LandingPages/Packages";
import USPS from "../../components/LandingPages/USPS"
import Hardware from "../../components/LandingPages/Hardware"
import Efficiency from "../../components/LandingPages/Efficiency"
import Software from "../../components/LandingPages/Software"
import Timeline from '../../components/LandingPages/Timeline';
import Contact from "../../components/LandingPages/Contact"

const   Home = (props) => {

    const [updated, setUpdated] = useState(false);

    useEffect(() => {
        if(!updated){
            setUpdated(true)
        }
    }, [updated] );



    const UI = () => {
        return (
            <Fragment>
                <Hero />
                {/*<Packages />*/}
                <USPS />
            {/*    <Hardware />*/}
                <Efficiency />
                <Software />
                <Timeline />
                <Contact />
            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>

    )

}

Home.requireAuth = false;  // true -> force login via AuthContext, false -> no login required so no user available either
export default Home;

import React, {useState, useEffect, createContext, Fragment, useContext, useRef } from 'react';
import Axios_client from "../../utils/axios_client";
import {AuthContext} from '../Auth/AuthProvider';
import {OrganizationContext} from '../Organization/Organization';
import {PermissionsWrapper, hasPermission} from "../../components/Dashboard/reactPermissionsWrapper";
import {GroupsContext} from '../Groups/Groups';
import {LocationsContext} from '../Locations/Locations';

import Swal from '@molline/sweetalert2'

export const GatewaysContext = createContext();

const GatewaysProvider = ({children, context}) => {

    const { user } = useContext(AuthContext);
    const [gateways, setGateways] = useState([]);
    const [gateway, setGateway] = useState({});
    const [insight, setInsight] = useState([]);
    const [manufacturers, setManufacturers] = useState([]);
    const { organization } = useContext(OrganizationContext);
    const { group } = useContext(GroupsContext);

    const userChanged = useCompare(user)
    const gatewaysChanged = useCompare(gateways)
    const gatewayChanged = useCompare(gateway)
    const organizationChanged = useCompare(organization)

    const { locations } = useContext(LocationsContext);
    const locationsChanged = useCompare(locations)

    useEffect(  () => {

        if( userChanged === true || organizationChanged === true || locationsChanged === true ){
            getAllGateways();
            getManufacturers();
        }
        

    }, [user, userChanged, organization, organizationChanged, gateways, gateway, gatewaysChanged, group, locations, locationsChanged]);

    function useCompare (val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const errorToast = (err) => {
        if(!err) return
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: err,
            showConfirmButton: false,
            customClass: {
                popup: "bg-danger text-white"
            }
        })
    }

    const successToast = (msg) => {
        if(!msg) return
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: msg,
            showConfirmButton: false,
            customClass: {
                popup: "bg-success text-white"
            }
        })
    }

    const doYouReallyWantToSave = () => {
        return new Promise(async (resolve) => {
            const answer = await Swal.fire({
                title: 'Sicher?',
                text: 'Damit werden die Daten gespeichert',
                showCancelButton: true,
                confirmButtonText: 'Ja',
                cancelButtonText: "Nein",
            })
            if (!answer.isConfirmed) return resolve(false);
            return resolve(true);
        })
    }

    const getGateway = (uid) => {
        return new Promise( (resolve, reject) => {
            if(!user) return;
            Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/gateway?uid=${uid}`)
            .then( response => {
                setGateway(response.data)
                return resolve(response.data);
            })
            .catch( err => {
                console.log('GATEWAYS FETCHING ERROR 39', err)
                return reject(err)
            })
        })
    }

    const getInsight = (uid) => {
        return new Promise( (resolve, reject) => {
            if(!user) return;
            Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/gateway/insight?uid=${uid}`)
            .then( response => {
                try{response.data = JSON.parse(response.data)}catch(err){}
                return resolve(response.data);
            })
            .catch( err => {
                console.log('INSIGHT FETCHING ERROR 122', err)
                return reject(err)
            })
        })
    } 

    const getAllGateways = () => {
        if(!organization._id){
            setGateways([]);
            return;
        }
        if( user && hasPermission({user, scopes:['gateway.view.any', 'gateway.view.own']}) ){
            Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/gateway/all?organization_id=${organization._id}`)
            .then( async response => {
                let filterArray = []
                if(locations.length === 0 && Object.keys(group).length === 0) filterArray = [...response.data]
                for(const loc of locations){
                    let filtered = response.data.filter(gw => { if(loc.gateways.includes(gw.uid) || !gw.location_id) return gw })
                    filterArray = filterArray.concat(filtered)
                }
                filterArray = [...new Set(filterArray)];

                setGateways(filterArray)
                return filterArray;
            })
            .catch( err => {
                console.log('GATEWAYS FETCHING ERROR 39', err)
                return 'GATEWAYS FETCHING ERROR 111'
            })
        }
    }

    const registerGateway = (params) => {
        return new Promise( (resolve, reject) => {
            if(!hasPermission({user, scopes:['gateway.create.any', 'gateway.craete.own']})) return reject('401')
            Axios_client.backendClient().post(`${process.env.REACT_APP_API_OPENMETER}/gateway`, params)
                .then( response => {
                    setGateway(response.data);
                    getAllGateways();
                    return resolve(response.data);
                })
                .catch( err => {
                    console.log('GATEWAYS FETCHING ERROR 39', err)
                    return reject(err)
                })
        })
    }

    const getManufacturers = () => {
        return new Promise( (resolve, reject) => {
            if(!user || !hasPermission({user, scopes:['manufacturer.view.any','manufacturer.view.own']})) return resolve([]);
            if(manufacturers.length > 0) return;
            Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/manufacturer/`)
            .then( response => {
                setManufacturers(response.data)
                return resolve();
            })
            .catch( err => {
                console.log('GATEWAYS FETCHING ERROR 39', err)
                //     return reject(err)
            })
        })
    }
    /**
     * Not sure this is currently being used, else it is an idea in progress
     */
    const updateGateway = async (updatedGateway) => {
        return new Promise( async (resolve, reject) => {
            if(hasPermission({scopes: ['gateway.update.any', 'gateway.update.own'], user: user})) {
                Axios_client.backendClient().put(`${process.env.REACT_APP_API_OPENMETER}/gateway`, updatedGateway)
                .then(response => {
                    getAllGateways()
                    return resolve(response.data)
                })
                .catch(err => {
                    return reject(err.message)
                })
            }else{return reject('Permission denied. 401')}
        })
    }

    const addComment = async (params) => {
        try {
            const response = await Axios_client.backendClient().put(`${process.env.REACT_APP_API_OPENMETER}/gateway/comment` , params)
            setGateway(response.data)
            getAllGateways();
        } catch (e) {
            console.log('error at creating comment', e);
            throw new Error(e)
        }
    }

    const deleteComment = async (params) => {
        try {
            const response = await Axios_client.backendClient().delete(`${process.env.REACT_APP_API_OPENMETER}/gateway/comment` , { data: params })
            setGateway(response.data);
            getAllGateways();
        } catch (e) {
            console.log('error at deleting comment', e);
            throw new Error(e)
        }
    }

    const activateZombieMode = (uid) => {
        Swal.fire({
            allowOutsideClick: true,
            backdrop: true,
            title: "Zombie Modus",
            html: `<p>Im Zombie-Modus sendet das Gateway für die nächsten 8 Stunden alle 30 Minuten die während diesem Zeitraum erfassten Daten. 
            Dieses Tool sollte primär für Fehlersuche und die Gateway- /Zähler-Installation verwendet werden
                    Nach 8 Stunden wird das Gateway wieder zurück in den Standardmodus versetzt. Der Zombie-Modus kann frühzeitig deaktiviert werden,
                    indem eine neue Konfiguration an das Gateway gesendet wird.
                    <p>Wollen Sie wirklich fortfahren?</p>`,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Zombie-Modus aktivieren",
            
        })
        .then(async result => {
            if(result.isConfirmed){
                Axios_client.backendClient()
                .get(`${process.env.REACT_APP_API_OPENMETER}/gateway/zombiemode?uid=${uid}`)
                .then(response => {
                    Swal.fire({
                        toast: true,
                        allowOutsideClick: true,
                        backdrop: true,
                        title: "Erfolg",
                        html: '<i class="fa-solid fa-biohazard"></i> Zombie-Modus ist aktiviert',
                        position: 'bottom-end',
                        timer: 3000,
                        timerProgressBar: true,
                        showConfirmButton: false,
                        customClass: {
                            popup: "bg-success text-white"
                        }
                    })
                })
                .catch(err => {
                    Swal.fire({
                        toast: true,
                        position: 'bottom-end',
                        timer: 3000,
                        timerProgressBar: true,
                        allowOutsideClick: true,
                        title: "Fehler",
                        text: "Etwas ist schiefgegangen",
                        showConfirmButton: false,
                        customClass: {
                            popup: "bg-danger text-white"
                        }
                    })
                })
            }
        })  
    }
    /**
     * Sends a partial configuration to a gateway. The SIM card and FTP params are not changed.
     * This will only change the listening and sending schedules.
     */
    const configureGateway = async ({uid}) => {
        Swal.fire({
            allowOutsideClick: true,
            backdrop: true,
            width: "60%",
            title: "Dieses Gateway konfigurieren",
            html: `<div class="text-left"><p>Hiermit senden Sie eine neue Konfiguration an das Gateway. In der Standard-Konfiguration
            empfängt/sendet das Gateway an einem zufallsgenerierten Zeitpunkt zwischen 08:00 und 18:00 Uhr. Diese neue Konfiguration ändert ausschließlich
            die Hör- und Sendzeitpunkte sowie den Hör-Zeitraum.
             <span class="text-danger">SIM, FTP etc werden nicht geändert!</span></p>`+
            `<p>Sie können die Konfiguration mit Hilfe der unten stehenden Felder anpassen.</p>` + 

            `<div class="form-group"><label class="text-left">Funk-Empfangs-Schema</label>` +
            `<input type="text" id="cronListen" class="form-control form-control-lg mb-1" placeholder="[{'cron': '0 8 * * *'}]"></input></div>`+

            `<div class="form-group"><label>FTP-Sende-Schema</label>` +
            `<input type="text" id="cronSend" class="form-control form-control-lg mb-1" placeholder="[{'cron': '0 18 * * *'}]"></input></div>`+

            `<div class="form-group"><label>Funk-Hör-Zeitraum</label>` +
            `<input type="text" id="duration" class="form-control form-control-lg" placeholder="1200"></input>`+
            `<small>Bitte Integer in Sekunden eingeben</small></div>` +
            `<div class="mt-1 custom-control-success custom-control custom-checkbox">
                <input class="custom-control-input" type="checkbox" id="optdownload">
                <label class="custom-control-label ms-1" htmlFor="flexCheckDefault"> <b>Diese Konfiguration herunterladen</b></label>
                </div>` ,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Konfiguration senden",
            cancelButtonText: "Abbrechen",
            customClass:{
                icon:"text-justify",
            },
            preConfirm: () => {
                const cronListen = Swal.getPopup().querySelector('#cronListen').value
                const cronSend = Swal.getPopup().querySelector('#cronSend').value
                const duration = Swal.getPopup().querySelector('#duration').value
                const download = Swal.getPopup().querySelector('#optdownload').checked
                return {
                    cronListen,
                    cronSend,
                    duration,
                    download
                }
            } 
        })
        .then(async result => {
            if(result.isConfirmed){
                // await configureGateway({uid, download: result.value.download, cronListen:result.value.cronListen, cronSend:result.value.cronSend, duration:result.value.duration })
                Axios_client.backendClient()
                .post(`${process.env.REACT_APP_API_OPENMETER}/gateway/config`, {
                    download: result.value.download,
                    uid: uid, 
                    cronListen: result.value.cronListen, 
                    cronSend: result.value.cronSend, 
                    duration: result.value.duration
                })
                .then(response => {
                    //console.log(response.data)
                    /**
                    * Will return a base64 string which is a buffer toString() from the bson file.
                    * If the user has selected to download the file then DL, else no need.
                    */
                    if(response?.data?.buffer){
                        const arrayBuffer = new Uint8Array([...window.atob(response.data.buffer)].map(char => char.charCodeAt(0)));
                        const fileLink = document.createElement('a');
                        fileLink.href = window.URL.createObjectURL(new Blob([arrayBuffer]));
                        fileLink.setAttribute('download', `${uid}-cfg.bson`);
                        document.body.appendChild(fileLink);
                        fileLink.click();
                        document.body.removeChild(fileLink);
                    }
                    Swal.fire({
                        toast: true,
                        allowOutsideClick: true,
                        backdrop: true,
                        title: "Erfolg",
                        html: 'Die neue Konfiguration wurde gespeichert.',
                        position: 'bottom-end',
                        timer: 5000,
                        timerProgressBar: true,
                        showConfirmButton: false,
                        customClass: {
                            popup: "bg-success text-white"
                        }
                    })
                })
                .catch(err => {
                    Swal.fire({
                        toast: true,
                        position: 'bottom-end',
                        timer: 3000,
                        timerProgressBar: true,
                        allowOutsideClick: true,
                        title: "Fehler",
                        text: "Etwas ist schiefgelaufen",
                        showConfirmButton: false,
                        customClass: {
                            popup: "bg-danger text-white"
                        }
                    })
                })
            }
        })
    }

    const longtermConfigGateway = async({uid}) => {
        Swal.fire({
            allowOutsideClick: true,
            backdrop: true,
            width: "60%",
            title: "Langzeit-Config senden",
            html: `<p>Wollen Sie wirklich die Langzeit-Konfiguration an das Gateway senden? Damit sendet das Gateway nur noch einmal pro Woche!</p>`,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: `Langzeit-Konfiguration senden`,
            cancelButtonText: "Abbrechen",
            customClass:{
                icon:"text-justify",
            },
            icon: 'question',
            
        }).then(result => {
            if(result.isConfirmed){
                Axios_client.backendClient()
                .get(`${process.env.REACT_APP_API_OPENMETER}/gateway/config/longterm?uid=${uid}`)
                .then(response => {
                    if(response?.data?.buffer){
                        const arrayBuffer = new Uint8Array([...window.atob(response.data.buffer)].map(char => char.charCodeAt(0)));
                        const fileLink = document.createElement('a');
                        fileLink.href = window.URL.createObjectURL(new Blob([arrayBuffer]));
                        fileLink.setAttribute('download', `${uid}-cfg.bson`);
                        document.body.appendChild(fileLink);
                        fileLink.click();
                        document.body.removeChild(fileLink);
                    }
                    successToast(`Gateway ${uid} wurde erfolgreich auf die Langzeit-Konfiguration umgestellt.`)
                    return;
                })
                .catch(err => {
                    errorToast(`Es gab einen Fehler bei der Neu-Konfiguration: ${err}`)
                })
            }
        })
    }

    const putGatewayToStorageMode = async({uid}) => {
        Swal.fire({
            allowOutsideClick: true,
            backdrop: true,
            width: "60%",
            title: "<strong>Storage-Modus</strong> wirklich aktivieren?",
            html: `<p>Wollen Sie wirklich den Storage-Modus aktivieren? Damit hört das Gateway auf, Daten zu senden. Dieser Modus kann <strong class="text-danger">nicht</strong> aus der Ferne deaktiviert werden, sondern nur vor Ort bei dem Gateway.
            <p>Sind Sie sich <strong>ABSOLUT</strong> sicher, dass Sie diesen Modus aktivieren möchten?</p>`,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: `Storage-Modus wirklich aktivieren`,
            cancelButtonText: "Abbrechen",
            customClass:{
                icon:"text-justify",
            },
            icon: 'warning',
            
        }).then(result => {
            if(result.isConfirmed){
                Axios_client.backendClient()
                .get(`${process.env.REACT_APP_API_OPENMETER}/gateway/config/storage?uid=${uid}`)
                .then(response => {
                    Swal.fire({
                        allowOutsideClick: true,
                        backdrop: true,
                        width: "60%",
                        title: "Storage-Modus erfolgreich aktiviert",
                        html: `Dem Gateway ${uid} wurde erfolgreich eine Storage-Konfiguration geschickt. Falls es sich doch um ein falsches Gateway handelt, bitte Dominik Schäfer oder Florian List unverzüglich Bescheid geben`,
                        showConfirmButton: true,
                        confirmButtonText: `Schließen`,
                        customClass:{
                            icon:"text-justify",
                        },
                        icon: 'success',
                        
                    })
                    getAllGateways();
                    return;
                })
                .catch(err => {
                    errorToast(`Es gab einen Fehler bei der Neu-Konfiguration: ${err}`)
                })
            }
        })
    }

    const deleteGateway = ({uid}) => {
        Swal.fire({
            allowOutsideClick: true,
            backdrop: true,
            width: "60%",
            title: "Dieses Gateway löschen",
            html: `<div class="text-left"><p>Hiermit <span class="text-danger">LÖSCHEN</span> Sie das Gateway <b>${uid}</b></p>` +
            `<p>Wenn Sie ein Gateway löschen, wird es aus dem System gelöscht und es empfängt keine Daten mehr.`+
            `Neue Daten von diesem Gateway werden ebenfalls ignoriert. Wenn Sie dies nicht möchten, können Sie das Gateway über die Platform bewegen oder an das Lager senden.</p>`+
            `</div>` ,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: `Gateway ${uid} löschen`,
            cancelButtonText: "Abbrechen",
            customClass:{
                icon:"text-justify",
            },
            icon: 'error',
            
        })
        .then(async result => {
            if(result.isConfirmed){
                Axios_client.backendClient()
                .delete(`${process.env.REACT_APP_API_OPENMETER}/gateway`, { data: {uid}})
                .then(response => {
                    getAllGateways();
                    successToast(`Gateway ${uid} wurde erfolgreich gelöscht.`)
                    return;
                })
                .catch(err => {
                    errorToast(`Es gab einen Fehler beim Löschen: ${err}`)
                })
            }
        })
    }
    /**
     * Return a Gateway to Molline "Lager"
     */
    const sendGatewayToLager = ({uid}) => {
        Swal.fire({
            allowOutsideClick: true,
            backdrop: true,
            width: "60%",
            title: "Gateway an das Lager senden",
            html: `<div class="text-left"><p>Hiermit senden Sie das Gateway <b>${uid}</b> zurück zum ursprünglichen Lagerpunkt.</p>` +
            `<p>Dieses Gateway wird dann von Ihrem Kunden entfernt und wieder Molline zugewiesen. Das Gateway bleibt weiterhin registriert und empfängt weiterhin Daten.`+
            `</div>` ,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: `Gateway ${uid} an das Lager senden`,
            cancelButtonText: "Abbrechen",
            customClass:{
                icon:"text-justify",
            },
            icon: 'warning',
            
        })
        .then(async result => {
            if(result.isConfirmed){
                let target = null;
                let gw = gateways.filter(gwy => gwy.uid === uid)
                if(!gw[0]) return errorToast(`Gatway ${uid} was not found.`)
                target = {...gw[0]}
                target.location_id = '';
                target.organization_id = '61e7be8aa2716b00530187fd';
                target.attributes = [];

                Axios_client.backendClient()
                .put(`${process.env.REACT_APP_API_OPENMETER}/gateway`, target)
                .then(response => {
                    getAllGateways();
                    successToast(`Gateway ${uid} wurde an das Lager geschickt.`)
                    return;
                })
                .catch(err => {
                    errorToast(`Es gab ein Problem beim Senden an das Lager: ${err}`)
                })
            }
        })
    }

    const viewGatewayConfig = (uid) => {
        let gateway = null;
        gateway = gateways.filter(g => g.uid === uid)[0]
        if(!gateway || !gateway.uid){
            try{gateway = getGateway(uid)}catch(err){}
        }
        let config = (gateway?.config)? JSON.stringify(gateway.config, null, 2) : 'Gateway hat noch keine Konfiguration akzeptiert ODER hat diese noch nicht an den Server gesendet.'
        Swal.fire({
            title: 'Aktuelle Gateway Konfiguration',
            icon: 'info',
            width: '60%',
            html:
                '<div class="text-left" style="text-align:left"><pre>'+config+'</pre></div>',
            showCloseButton: true,
            showCancelButton: false,
        })
    }

    const viewGatewaySupervision = (uid) => {
        let gateway = null;
        gateway = gateways.filter(g => g.uid === uid)[0]
        if(!gateway || !gateway.uid){
            try{gateway = getGateway(uid)}catch(err){}
        }
        let supervision = (gateway?.supervision)? JSON.stringify(gateway.supervision, null, 2) : 'Gateway hat noch keine Supervision-Daten an den Server gesendet'
        Swal.fire({
            title: 'Aktuelle Gateway Supervision',
            icon: 'info',
            width: '60%',
            html:
                '<div class="text-left" style="text-align:left"><pre>'+supervision+'</pre></div>',
            showCloseButton: true,
            showCancelButton: false,
        })
    }

    const putGatewayToInstallationMode = async({uid}) => {
        Swal.fire({
            allowOutsideClick: true,
            backdrop: true,
            width: "60%",
            title: "<strong>Installations-Config</strong> wirklich senden?",
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: `Installations-Config senden`,
            cancelButtonText: "Abbrechen",
            customClass:{
                icon:"text-justify",
            },
            icon: 'warning',
            
        }).then(result => {
            if(result.isConfirmed){
                Axios_client.backendClient()
                .get(`${process.env.REACT_APP_API_OPENMETER}/gateway/config/installation?uid=${uid}`)
                .then(response => {
                    Swal.fire({
                        allowOutsideClick: true,
                        backdrop: true,
                        width: "60%",
                        title: "Installations-Config erfolgreich gesendet",
                        showConfirmButton: true,
                        confirmButtonText: `Schließen`,
                        customClass:{
                            icon:"text-justify",
                        },
                        icon: 'success',
                        
                    })
                    getAllGateways();
                    return;
                })
                .catch(err => {
                    errorToast(`Es gab einen Fehler bei der Neu-Konfiguration: ${err}`)
                })
            }
        })
    }

    const actions = {
        gateway,
        gateways,
        setGateway,
        setGateways,
        manufacturers,
        getGateway,
        getAllGateways,
        getManufacturers,
        registerGateway,
        updateGateway,
        addComment,
        deleteComment,
        activateZombieMode,
        configureGateway,
        deleteGateway,
        sendGatewayToLager,
        gatewaysChanged,
        gatewayChanged,
        doYouReallyWantToSave,
        errorToast,
        successToast,
        viewGatewayConfig,
        viewGatewaySupervision,
        getInsight,
        longtermConfigGateway,
        putGatewayToStorageMode,
        putGatewayToInstallationMode
    }

    return (
        <Fragment>
            <GatewaysContext.Provider value={actions}>
                {children}
            </GatewaysContext.Provider>
        </Fragment>
    )

}

export default GatewaysProvider;
import React, { useState, Fragment, useEffect, useContext } from 'react';
import { AuthContext } from "../../../../context/Auth/AuthProvider";
import { LocationsContext } from "../../../../context/Locations/Locations";
import { OrganizationContext } from "../../../../context/Organization/Organization";

import { PermissionsWrapper, hasPermission, MembershipWrapper, hasMembership } from "../../reactPermissionsWrapper";

import Swal from "@molline/sweetalert2";
import Axios_client from "../../../../utils/axios_client";
import HelpProvider from "../../helpProvider/helpProvider";

const ReportUviReportPackage = (props) => {

    const { user } = useContext(AuthContext);
    const { locationsChanged, location, locations } = useContext(LocationsContext);
    const { organization, organizationChanged } = useContext(OrganizationContext);


    useEffect( () => {
        
    }, [ organization, organizationChanged, location ]);



    const buildConsumptionReportsPackage = async() => {
        const today = new Date()
        let year = today.getFullYear();
        let month = today.getMonth()
        /**
         * uVi for the new year are not available until February, so set the request for December of last year
         */
        if (month === 0) {
            month = 11;
            year = year - 1;
        } else {
            month = month - 1
        }

        let years = [];
        years.push(new Date().getFullYear())
        for (let x = 1; x < 2; x++) {
            years.push(new Date().getFullYear() - x)
        }

        let yearHtml = ''
        years.map(y => {
            yearHtml += `<option key=${y} ${(y === year) ? "selected" : ""} value=${y} >${y}</option>`
        })
        const months = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"]
        let monthHtml = ''
        for (const [index, m] of months.entries())
            monthHtml += `<option key=${m} ${(index === month) ? "selected" : ""} value=${index} >${m}</option>`

        let html = `
            <div class="container">
                <p>Für welchen Monat in welchem Jahr möchten sie das Paket mit den Verbrauchsberichten aller Mieteinheiten herunterladen?</p>
                <div class="row">
                    <div class="col-6">
                        <select class="form-select" aria-label="Select Year" id="selectInputMonth">
                            ${monthHtml}
                        </select>
                    </div>
                    <div class="col-6">
                        <select class="form-select" aria-label="Select Year" id="selectInputYear">
                            ${yearHtml}
                        </select>
                    </div>
                </div>
            </div>
        `;


        Swal.fire({
            icon: "question",
            title: `Paket für monatlichen Verbrauchsbericht `,
            allowOutsideClick: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Herunterladen',
            background: localStorage.getItem('theme-preference') === 'dark-layout' ? '#161D31' : '#fff',
            cancelButtonText: 'Abbrechen',
            html,
            preConfirm: () => {
                const inputYear = Swal.getPopup().querySelector('#selectInputYear').value
                const inputMonth = Swal.getPopup().querySelector('#selectInputMonth').value
                if (!inputYear|| !inputMonth) {
                    Swal.showValidationMessage(`Bitte Monat und Jahr auswählen`)
                }
                return { inputYear, inputMonth }
            }
        })
            .then(async (results) => {
                if (results.isConfirmed) {
                    Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/report/report/consumption/package?organization_id=${organization._id}&email=true&email_address=${user.emails[0].email}&month=${results.value.inputMonth}&year=${results.value.inputYear}`)
                        .then(response => {
                            //console.log(response)
                        })
                        .catch(err => {
                            Swal.fire({
                                toast: true,
                                position: 'bottom-end',
                                timer: 3000,
                                title: 'Fehler beim erstellen der Verbraucherberichte',
                                timerProgressBar: true,
                                allowOutsideClick: true,
                                text: `Leider ist bei der Erstellung etwas schiefgegangen. Bitte kontaktieren Sie einen Administrator oder versuchen Sie es später noch einmal.`,
                                showConfirmButton: false,
                                customClass: {
                                    popup: "bg-danger"
                                }
                            })
                        })
                }

            })
    }

    const UI = () => {
        return(
          <PermissionsWrapper user={user} scopes={['location.view.any', 'location.view.own']} >
              { hasMembership({memberships: ['advanced', 'premium'], location: location, user: user}) ?
                  <MembershipWrapper location={location} memberships={['advanced', 'premium']} user={user}>
                      <div className="col-xl-4 col-md-6" id="widgetUviReportsPackg">
                          <div className="card text-center clickable" >
                              <HelpProvider reportSampleImgPath="/theme2/app-assets/images/reports/sample_report.png" title="uVi-Paket für den gesamten Kunden" descriptionText="Laden Sie hier alle uVis für Ihren Kunden herunter" />
                              <div className="card-body" onClick={() => buildConsumptionReportsPackage()}>
                                  <div className="mx-auto my-1">
                                      <i className="fa-solid fa-box-open-full fa-4x text-success"></i>
                                  </div>
                                  <p className="text-muted mb-0 line-ellipsis">uVi-Paket für den gesamten Kunden </p>
                              </div>
                          </div>
                      </div>
                  </MembershipWrapper>
                  :
                  <div className="col-xl-4 col-md-6">
                      <div className="card text-center clickable" >
                          <div className="premium_ribbon premium_ribbon_top_left"><span>Premium</span></div>
                          <HelpProvider reportSampleImgPath="/theme2/app-assets/images/reports/sample_report.png" title="uVi-Paket für den gesamten Kunden" descriptionText="Laden Sie hier alle uVis für Ihren Kunden herunter" />
                          <div className="card-body" >
                              <div className="mx-auto my-1">
                                  <i className="fa-solid fa-box-open-full fa-4x text-success"></i>
                                  </div>
                                  <p className="text-muted mb-0 line-ellipsis">uVi-Paket für den gesamten Kunden </p>
                              </div>
                          </div>
                  </div>
              }

          </PermissionsWrapper>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )

}

export default ReportUviReportPackage;
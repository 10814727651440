import React, {Fragment, useState, useEffect, useContext } from 'react';
import {AuthContext} from "../../../../context/Auth/AuthProvider";
import {NavLink, useNavigate} from "react-router-dom";
import OrgCookieCrumb from "../../../../components/Dashboard/orgCookieCrumb";


const SensorCreator = (props) => {

    const [updated, setUpdated] = useState(false);
    const { user, initializing, setRedirect, login, logout } = useContext(AuthContext)
    const navigate = useNavigate()

    useEffect(() => {
        if(!updated){
            setUpdated(true)
        }
    }, [updated]);

    const handleLogout = () => {
        logout()
        navigate('/')
    }

    const UI = () => {
        return (
            <Fragment>

                <div className="content-header row">
                    <div className="content-header-left col-md-9 col-12 mb-2">
                        <div className="row breadcrumbs-top">
                            <div className="col-12">
                                <h2 className="content-header-title float-start mb-0">Zähler Registrieren</h2>
                                <div className="breadcrumb-wrapper">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a >Apps</a>
                                        </li>
                                        <li className="breadcrumb-item"><NavLink to="/apps/sensors"><a >Zähler</a></NavLink>
                                        </li>
                                        <li className="breadcrumb-item active">Zähler Registrieren
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <OrgCookieCrumb />
                </div>
                <div className="content-body">
                    <h2> Sensor Create Page......</h2>
                </div>
            </Fragment>

        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>

    )

}

SensorCreator.requireAuth = true;  // true -> force login via AuthContext, false -> no login required so no user available either
export default SensorCreator;

import axios from 'axios'
import Cookie from "js-cookie";

const AxiosClient = {

    backendClient: () => {
        let authCookie =  Cookie.get('auth');
        let sessionCookie =  Cookie.get('session');
        let app_key =  Cookie.get('APP_KEY');

        let headers = {}
        if(!!sessionCookie) headers['x-session-id'] = sessionCookie;
        if(!!authCookie) headers.authorization = authCookie;
        if(!!app_key) headers['x-appkey'] = app_key;

        const client = axios.create({
            timeout: 15000,
            headers
        })
        return client;
    },


    frontEndClient: () => {
        let headers = {}
        const client = axios.create({
            timeout: 3000,
            headers
        })
        return client;
    }
}

export default AxiosClient;
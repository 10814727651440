import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { AuthContext } from '../../../../context/Auth/AuthProvider';
import {LocationsContext} from "../../../../context/Locations/Locations";
import {GroupsContext} from "../../../../context/Groups/Groups";
import { useRouter } from "../../../../components/Shared/Router/Router"
import Swal from '@molline/sweetalert2'
import OrgCookieCrumb from "../../../../components/Dashboard/orgCookieCrumb";
import LocationsTable from "../../../../components/Dashboard/tables/dataTables/tblLocations";

import {PermissionsWrapper, hasPermission} from "../../../../components/Dashboard/reactPermissionsWrapper";
import Axios_client from "../../../../utils/axios_client";
import {OrganizationContext} from '../../../../context/Organization/Organization';
import {TenantContext} from '../../../../context/Tenant/Tenant';
import CountEmptyLocationWidget from '../../../../components/Dashboard/widgets/locations/countEmptyLocation';

import PageTour from "../../../../components/Dashboard/tour/tour";
import TourSteps from "../../../../TourSteps.json";

const AppsLocations = (props) => {

    const { user } = useContext(AuthContext);
    const router = useRouter()
    const { locations, groupLocations } = useContext(LocationsContext);
    const { groups, group, groupSelect } = useContext(GroupsContext);

    const [filteredLocations, setFilteredLocations] = useState([]);
    const { organization } = useContext(OrganizationContext);
    const { tenants, isTenantsDone } = useContext(TenantContext);

    const [tabActive, setTabActive] = useState('tplocations')

    const locationsChanged = useCompare(locations)
    const groupLocationsChanged = useCompare(groupLocations)
    const organizationChanged = useCompare(organization)
    const tenantsChanged = useCompare(tenants)

    useEffect(() => {

    }, [tenants, tenantsChanged, isTenantsDone, locations, filteredLocations, locationsChanged, groups, groupLocationsChanged, groupLocations, organizationChanged, organization]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const UI = () => {

        const countLocations = () => {
            if (tabActive === 'tpgroups') {
                return groupLocations.length
            } else {
                return locations.length
            }
        }

        return (
            <Fragment>

                <div className="content-header row">
                    <div className="content-header-left col-md-9 col-12 mb-2">
                        <div className="row breadcrumbs-top">
                            <div className="col-9">
                                <h2 className="content-header-title float-start mb-0">Nutzer-/Mieteinheiten </h2>
                                <div className="breadcrumb-wrapper">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a>Apps</a>
                                        </li>
                                        <li className="breadcrumb-item active">Nutzer-/Mieteinheiten
                                        </li>
                                        <li>
                                            <span className="tourBorderRight" />
                                            <PageTour steps={TourSteps.Locations.overview}  isButton={false} />
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <OrgCookieCrumb />
                </div>

                <div className="content-body">

                    <section id="location-data">
                        <div className="row match-height">

                            <div className="col-xl-2 col-md-4" id="LocCounter">
                                <div className="card text-center">
                                    <div className="card-body">
                                        <div className="mx-auto my-1">
                                            <i className="fa-solid fa-building-user fa-4x text-primary"></i>
                                        </div>
                                        <p className="text-muted mb-0 line-ellipsis">Nutzer-/Mieteinheiten</p>
                                        <h4>{countLocations()}</h4>
                                    </div>
                                </div>
                            </div>
{/** 
                            <PermissionsWrapper
                                user={user}
                                scopes={['location.view.any', 'location.view.own']}
                            >
                                <div className="col-xl-2 col-md-4" id="locUsersCounter">
                                    <div className="card text-center">
                                        <div className="card-body">
                                            <div className="mx-auto my-1">
                                                <i className="fa-solid fa-building-user fa-4x text-success"></i>
                                            </div>
                                            <p className="text-muted mb-0 line-ellipsis">Nutzer</p>
                                            <h4>{countLocationTenants()}</h4>
                                        </div>
                                    </div>
                                </div>
                            </PermissionsWrapper>
        **/}
                            <CountEmptyLocationWidget />

                        </div>
                    </section>

                    <section id="location-tabs">
                        <div className="row">
                            <div className="col-12">
                                <ul className="nav nav-pills mb-2">
                                    <PermissionsWrapper
                                        user={user}
                                        scopes={['location.view.own', 'location.view.any']}
                                    >
                                        <li className="nav-item">
                                            <a className={`nav-link ${(tabActive === 'tplocations') ? 'active' : ''}`} onClick={() => { setTabActive('tplocations') }}>
                                                <i className="fa-solid fa-building"></i>
                                                <span className="fw-bold">Nutzer-/Mieteinheiten</span>
                                            </a>
                                        </li>
                                    </PermissionsWrapper>

                                </ul>
                            </div>
                        </div>
                    </section>

                    <section id="tabpanels">
                        <div className="row">
                            <div className="col-12">
                                <div className="tab-content pt-1">

                                  
                                    <div className={`tab-pane ${(tabActive === 'tplocations') ? 'active' : ''}`} id="tplocations" role="tabpanel" aria-labelledby="tplocations-tab-fill">
                                        <div className="card">
                                            <div className="card-header d-flex justify-content-between align-items-center border-bottom">
                                                <h4 className="card-title">Nutzer-/Mieteinheiten  (Alle)  </h4>
                                                <div className="heading-elements" id="groupSwitch">
                                                    {groupSelect()}
                                                </div>

                                            </div>

                                            <div className="card-body py-2 my-25">
                                                {locations.length > 0 ?
                                                    <LocationsTable id="tblLocations" />
                                                    :
                                                    <span id="tblLocationEmptyMesg">"Scheinbar wurden Sie noch keinen Nutzer-Mieteinheiten  zugewiesen. Bitte kontaktieren Sie ihren Verantwortlichen Manager." </span>

                                                }




                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </section>

                </div>

            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )

}

AppsLocations.requiredScope = ['location.view.any', 'location.view.own'];
AppsLocations.requireAuth = true;  // true -> force login via AuthContext, false -> no login required so no user available either
export default AppsLocations;
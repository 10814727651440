import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import {SocketContext} from "../../../context/Socket/Sockets";
import {AuthContext} from "../../../context/Auth/AuthProvider";
import {PermissionsWrapper, hasPermission} from "../reactPermissionsWrapper";
import $ from 'jquery';

const LiveChatSupport = (props) => {


    const [updated, setUpdated] = useState(false);
    const { user } = useContext(AuthContext);
    const {socket} = useContext(SocketContext)

    const socketChanged = useCompare(socket)

    useEffect(() => {
        if (!updated) {

            setUpdated(true);
        }
    }, [socketChanged])

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const enterChatHandler = (e) => {
        e.preventDefault();

        // NOTE: temporarily html append for chat later socket will take care of it.....
        let chatMesg = document.getElementById('activeChatInput').value;
        if (chatMesg) {
            var html = '<div class="chatContentRight">' + '<p>' + chatMesg + '</p>' + '</div>';
            $('.chat_right:last-child .chat-body').append(html);
            $('#activeChatInput').val('');

            var element = document.getElementById("activeChatBody");
            element.scrollTop = element.scrollHeight;
        }




    }

    const UI = () => {
        if (updated) {
            return (
                <Fragment>
                        <div className="content-body">
                            <div className="chatSupportContainer " id="chatSupportContainer">
                                {/* chat toggle button */}
                                <div className="chat_toggle_btn" data-bs-toggle="collapse" data-bs-target="#activeChatContainer" id="userChatSupportBtn" aria-expanded="false" aria-controls="activeChat"></div>

                                {/* chat window */}
                                <div className="collapse active_chat_container" id="activeChatContainer">
                                    <div className="active-chat-header">
                                        <div className="d-flex align-items-center">
                                            <div className="avatar avatar-border user-profile-toggle m-0 me-1">
                                                <img src="https://www.gravatar.com/avatar/?d=retro" alt="avatar" height="36" width="36"/>
                                                <span className={`avatar-status-${(socket !== null && socket.connected === true) ? 'online' : 'offline'}`}>
                                            </span>
                                            </div>
                                            <h6 className="mb-0" id="liveChatSupportHeader">Molline Live Chat Support</h6>
                                        </div>
                                        <button type="button"  className="btn-close live_chat_close_btn text-reset" data-bs-toggle="collapse" data-bs-target="#activeChatContainer" aria-expanded="false" aria-controls="activeChat"></button>
                                    </div>

                                    <div className="active_chat_body" id="activeChatBody">
                                        <div className="chatDivider" >
                                            <div className="chatDividerText" id="chatDividerText">Dienstag, 21 Februar 2023</div>
                                        </div>
                                        {/* LEFT-SIDE CHAT */}
                                        <div className="chat_left mb-1">
                                            <div className="chat_left_avatar">
                                                <div className="avatar avatar-border user-profile-toggle m-0 me-1">
                                                    <img src="https://www.gravatar.com/avatar/?d=retro" alt="avatar" height="36" width="36"/>
                                                    {/*<span className={`avatar-status-${(socket !== null && socket.connected === true) ? 'online' : 'offline'}`}>*/}
                                                    {/*    </span>*/}
                                                </div>
                                            </div>
                                            <h6>Molline</h6>
                                            <div className="chatContentLeft" id="activeChatContentLeft">
                                              <p>Hallo lieber Besucher! Haben Sie Fragen?
                                                  Schreiben Sie uns gerne, wir melden uns bei Ihnen!
                                                  Live-Chat werktags von 08:00-17:00 Uhr.</p>
                                            </div>
                                            <div className="chatContentLeft" id="activeChatContentLeft">
                                               <p>Scheinbar wurden Sie noch keinen Nutzer-Mieteinheiten  zugewiesen. Bitte kontaktieren Sie ihren Verantwortlichen Manager."</p>
                                            </div>
                                        </div>

                                        {/* RIGHT-SIDE CHAT */}
                                        <div className="chatDivider" >
                                            <div className="chatDividerText" id="chatDividerText">Mittwoch, 22 Februar 2023</div>
                                        </div>
                                        <div className="chat_right mb-1" >
                                            <div className="chat-body">
                                                <div className="chatContentRight" id="activeChatContentRight">
                                                    <p>vielen Dank für Ihre Antwort</p>
                                                </div>
                                                <div className="chatContentRight" id="activeChatContentRight">
                                                    <p>vielen Dank für Ihre Antwort</p>
                                                </div>
                                                <div className="chatContentRight" id="activeChatContentRight">
                                                    <p>vielen Dank für Ihre Antwort</p>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                    <form className="active_chat_send_form chat-app-form" >
                                        <div className="input-group input-group-merge  me-1 form-send-message">
                                        <textarea name="message" id="activeChatInput" cols="20" rows="3" dir="auto" autoCapitalize="off" autoComplete="off" className="active_chat_send_input border-0"
                                                  placeholder="Verfassen Sie Ihre Nachricht…"
                                                  aria-label="Verfassen Sie Ihre Nachricht…">
                                        </textarea>
                                            <button type="button" className="btn chatSendBtn "
                                                    onClick={(e) => {enterChatHandler(e)}}>
                                                <i className="fa-solid fa-paper-plane-top"></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                </Fragment>
            )
        } else {
            <Fragment></Fragment>
        }

    }

    return (
        <Fragment>
            <PermissionsWrapper
                user={user}
                roles={['god']}
            >
                {UI()}
            </PermissionsWrapper>
        </Fragment>
    )
}

export default LiveChatSupport;
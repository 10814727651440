import React, {useState, useEffect, createContext } from 'react';
import Cookie from 'js-cookie';
import CryptoJS from "crypto-js";
import {v1 as uuidv1} from 'uuid';


export const CookieContext = createContext();

const COOKIE_PARAMS = {
    expires: 3, 
    httpOnly: false, 
    secure: (process.env.NODE_ENV === 'production' || process.env.REACT_APP_ENV === 'production' )? true:false, 
    domain: (process.env.NODE_ENV === 'production' || process.env.REACT_APP_ENV === 'production' )? 'molline-connect.de':'localhost',
    path: "/",
};

const CookieProvider = ({children}) => {

    useEffect( () => {

        genKey();
        setSession();
           
    }, [])

    const getCookie = (key) => {
        return Cookie.get(key, COOKIE_PARAMS);
    }

    const setCookie = (key, value) => {
        if(typeof value !== 'string') value = JSON.stringify(value)
        Cookie.set(key, value, COOKIE_PARAMS)
    }

    const cookieCrypt = ({cookieName, cookiePayload}) => {
        let payload = cookiePayload;
        if(typeof cookiePayload !== 'string' && typeof cookiePayload !== 'String'){
            payload = JSON.stringify(cookiePayload);
        }
        let payloadEncrypted = CryptoJS.AES.encrypt( payload, genKey() ).toString();
        Cookie.set(cookieName, payloadEncrypted, COOKIE_PARAMS);
        return;
    }

    const cookieDecrypt = ({cookieName}) => {
        let cookieTarget = Cookie.get(cookieName);
        try{
            let bytes = CryptoJS.AES.decrypt( cookieTarget, genKey() );
            let plaintext = bytes.toString(CryptoJS.enc.Utf8);
            return plaintext;
        }catch(error){
            console.log('COOKIE DECRYPT: Cookie not encrypted or APP_KEY incorrect', error);
            throw new Error('COOKIE DECRYPT: Cookie not encrypted or APP_KEY incorrect')
        }
    }

    const cookieDestroy = (key) => {
        try{
            Cookie.remove( key, COOKIE_PARAMS);
        }catch(error){
            console.log('Nothing to remove or cookie params not correct')
        }
    }

    const generate = (length) => {
        let string = "";
        let stringLength = length !== undefined || null ? length : 64;
        let possible = "ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz0123456789";
        for (let i = 0; i < stringLength; i++){
            string += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return string;
    }
    /**
     * Generate a key to be used for this client encryption. Key should be good until client
     * purposely logs out.
     */
    const genKey = () => {
        let KEY = Cookie.get('APP_KEY')
        if(!KEY){
            KEY = generate(32) //For AES encryption, length must be 32
            Cookie.set('APP_KEY', KEY, COOKIE_PARAMS)
        }
        return KEY;
    }

    /**
     * Set a session cookie to identify this user session.
     */
    const setSession = () => {
        let session_id = Cookie.get('session')  //= uuidv1();
        if (!session_id) {
            session_id = uuidv1();
            Cookie.set('session', session_id, COOKIE_PARAMS)
        }
        return session_id;
    }

    const cookieActions = {
        setCookie,
        getCookie,
        cookieCrypt,
        cookieDecrypt,
        cookieDestroy,
        generate
    }

    return (
        <CookieContext.Provider value={cookieActions}>
            {children}
        </CookieContext.Provider>
    )

}

export default CookieProvider;
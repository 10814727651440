import React, {Fragment, useState, useEffect, useContext} from 'react';
import { LocaleContext } from '../../context/Locale/Locale';
import { useRouter } from '../Shared/Router/Router'


const Efficiency = ({children}) => {

    const router = useRouter()
    const [updated, setUpdated] = useState(false);
    const {t, locale, localeAvailable, setLang} = useContext(LocaleContext);
//    const {config} = useContext(WhiteLabelContext);



    useEffect(  () => {
        if(!updated){
            setUpdated(true)
        }

    }, [locale]);


    const UI = () => {
        return (
            <Fragment>
                <div id="efficiency" className="section section-4">
                    <video autoPlay muted loop id="bg-efficiency">
                        <source src="/homepage/assets/videos/348691753.mp4" type="video/mp4" />
                        Ihr Browser unterstützt leider die Wiedergabe von HTML5 Videos nicht.
                    </video>
                    <div className="container-fluid">
                        <div className="container pt-3 pb-1">
                            <h3 className="text-center h1 white">Mehr Datenmanagement für mehr CO2-Einsparung.</h3>
                            <p className="text-center h3 white bold">Sie verwalten Ihre Liegenschaft über ein Energiedatenmanagementsystem?</p>
                            <p className="text-center white py-2">Wir liefern Ihnen die Verbrauchsdaten nach Wunsch auch tagesaktuell. Durch die Transparenz und der Kontrolle über die Daten können Sie an den richtigen Stellen ansetzen, um die Prozesse für mehr Energieeffizienz zu optimieren. Durch einen geringeren Energieverbrauch lässt sich umso mehr CO2 einsparen.</p>
                            <p className="text-center white">*Gehen Sie mit uns und MOLLINÉ Connect in Richtung Klimaschutz.</p>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }



    return (
        <Fragment>

            {UI()}

        </Fragment>
    )
}

export default Efficiency;

import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { AuthContext } from '../../../../context/Auth/AuthProvider';
import {SensorsContext} from "../../../../context/Devices/Sensors";
import {LocationsContext} from "../../../../context/Locations/Locations";
import SensorTable from '../../../../components/Dashboard/tables/dataTables/tblSensors'
import OrgCookieCrumb from "../../../../components/Dashboard/orgCookieCrumb";
import {GroupsContext} from "../../../../context/Groups/Groups";

import { useRouter } from "../../../../components/Shared/Router/Router";
import { CSVLink, CSVDownload } from "react-csv";

import {PermissionsWrapper, hasPermission} from "../../../../components/Dashboard/reactPermissionsWrapper";
import PageTour from "../../../../components/Dashboard/tour/tour";
import TourSteps from "../../../../TourSteps.json";

import SensorCoverageWidget from '../../../../components/Dashboard/widgets/sensors/wmbusSensorCoverage';
import CountWmbusSensorsWidget from '../../../../components/Dashboard/widgets/sensors/countWmbusSensors';
import CountMbusSensorsWidget from '../../../../components/Dashboard/widgets/sensors/countMbusSensors';
import CountHomelessSensorsWidget from '../../../../components/Dashboard/widgets/sensors/countHomelessSensors';
import CountDefectSensors from "../../../../components/Dashboard/widgets/sensors/countDefectSensors";
import MbusSensorCoverage from "../../../../components/Dashboard/widgets/sensors/mbusSensorCoverage";
import CountSensorsWidget from "../../../../components/Dashboard/widgets/countSensors";

const $ = require('jquery');
$.DataTable = require('datatables.net');

const AppsSensors = (props) => {

    const [updated, setUpdated] = useState(false);
    const { user } = useContext(AuthContext);
    const router = useRouter()
    const { sensors, sensor } = useContext(SensorsContext);
    const { locations } = useContext(LocationsContext);
    const { groupSelect } = useContext(GroupsContext);

    const [funkSensors, setFunkSensors] = useState(0);
    const [nFunkSensors, setnFunkSensors] = useState(0);
    const [countUnassigned, setCountUnassigned] = useState(0);
    const [countDelinquent, setCountDelinquent] = useState(0);
    const [percentCoverage, setPercentCoverage] = useState(0);
    const [homelessSensors, setHomelessSensors] = useState([]);
    const [sensorsNotCovered, setSensorsNotCovered] = useState([]);

    const sensorsChanged = useCompare(sensors)
    const locationsChanged = useCompare(locations)

    const [tabActive, setTabActive] = useState('tpsensors')
    const tabChanged = useCompare(tabActive)


    useEffect(  () => {
        if(sensorsChanged === true) refresh();
        if(tabChanged === true) refresh();
        if(!updated){

            setUpdated(true)
        }
    }, [updated, locationsChanged, locations, user, tabChanged, sensors, sensor, sensorsChanged, funkSensors, nFunkSensors, countUnassigned, tabActive, sensorsNotCovered, countDelinquent]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const refresh = async () => {
        await resolveSensors();
    }

    /**
     * For the CSV reports like the homeless report, we do not need the entire 
     * sensor record to be exposed. Filter unwanted data and return the
     * modified sesnor back.
     * 
     * This may need to be moved to an API call later to keep this off
     * of front end code
     */
    const sanitizedSensor = (ssr) => {
        let modified = { ...ssr }
        delete modified.symetric_key
        delete modified.realm
        delete modified.comments
        delete modified.logs
        delete modified.current_val
        delete modified.manufacturer
        delete modified.hash_id
        delete modified.__v
        delete modified.current
        delete modified.location

        if (modified.state !== undefined && modified.state.date !== undefined) {
            modified.last_transmission = modified.state.date
            modified.value = modified.state.value
            delete modified.state
        }
        if (modified.eras !== undefined) {
            if (modified.eras.number !== undefined) modified.eras_number = modified.eras.number
            if (modified.eras.id !== undefined) modified.eras_id = modified.eras.id
        }

        if (ssr.location_id !== "" && ssr.location !== undefined) {
            let add = ssr.location.address
            modified.location = `${add.street} ${add.house_number}, ${add.street1}, ${add.city}, ${add.post_code}`
        }
        return modified;
    }

    const resolveSensors = () => {
        if(sensors.length === 0) return;
        let funk = 0, nfunk = 0, homeless = [], coveragePercent = 0, notCovered = [];
        let today = new Date()
        for(const sensorItem of sensors){
            (sensorItem.radio === true) ? funk++ : nfunk++;
            if (!sensorItem?.location_id) homeless.push(sanitizedSensor(sensorItem));
            if (!sensorItem?.state?.date) {
                if (!notCovered.includes(sanitizedSensor(sensorItem))) notCovered.push(sanitizedSensor(sensorItem))
            }
            if (!!sensorItem?.state?.date) {
                let last = new Date(sensorItem.state.date)
                const hours = Math.floor((Math.abs(today - last) / 1000) / 3600);
                if (hours > 360 || sensorItem?.state?.status === 'defective') { //15 Days
                    if (!notCovered.includes(sanitizedSensor(sensorItem))) notCovered.push(sanitizedSensor(sensorItem))
                }
            }
        }
        let count = sensors.length - notCovered.length;
        if (count > 0) {
            coveragePercent = ((count / sensors.length) * 100).toFixed(1)
        } else {
            coveragePercent = 0
        }
        if (coveragePercent > 100) coveragePercent = 100;
        if (isNaN(coveragePercent)) coveragePercent = "??";
        setFunkSensors(funk);
        setnFunkSensors(nfunk);
        setHomelessSensors(homeless);
        setSensorsNotCovered(notCovered);
        setPercentCoverage(coveragePercent);
    }

    const UI = () => {

        return (
            <Fragment>

                <div className="content-header row">
                    <div className="content-header-left col-md-9 col-12 mb-2">
                        <div className="row breadcrumbs-top">
                            <div className="col-9 ">
                                <h2 className="content-header-title float-start mb-0">Zähler </h2>
                                <div className="breadcrumb-wrapper">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a>Apps</a></li>
                                        <li className="breadcrumb-item active">Zähler</li>
                                        <li>
                                            <span className="tourBorderRight"></span>
                                            <PageTour steps={TourSteps.sensors.overview} isButton={false} />
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <OrgCookieCrumb />
                </div>


                <div className="content-body">

                    <section id="sensor-data">
                        <div className="row">

                            <CountSensorsWidget />

                            <CountWmbusSensorsWidget />
                            <SensorCoverageWidget />

                            <CountMbusSensorsWidget />
                            <MbusSensorCoverage />

                            <CountHomelessSensorsWidget />
                            <CountDefectSensors />
                        </div>
                    </section>

                    <section id="sensor-tabs">
                        <div className="row">
                            <div className="col-12">
                                <ul className="nav nav-pills mb-2">

                                    <PermissionsWrapper
                                        user={user}
                                        scopes={['sensor.view.any', 'sensor.view.own']}
                                    >
                                        <li className="nav-item">
                                            <a className={`nav-link ${(tabActive === 'tpsensors') ? 'active' : ''}`} onClick={() => { setTabActive('tpsensors') }}>
                                                <i className="fa-solid fa-building"></i>
                                                <span className="fw-bold">Zähler</span>
                                            </a>
                                        </li>
                                    </PermissionsWrapper>

                                </ul>
                            </div>
                        </div>
                    </section>

                    <section id="sensor-tabpanels">
                        <div className="row">
                            <div className="col-12">
                                <div className="tab-content pt-1">

                                    <div className={`tab-pane ${(tabActive === 'tpsensors') ? 'active' : ''}`} id="tpsensors" role="tabpanel" aria-labelledby="tpsensors-tab-fill">
                                        <div className="card">
                                            <div className="card-header border-bottom">
                                                <h4 className="card-title">Zähler </h4>
                                                <div className="heading-elements">
                                                    {groupSelect()}
                                                </div>
                                            </div>
                                            <div className="card-body py-2 my-25" id="sensorTbl">
                                                <SensorTable 
                                                    id="tblSensors"
                                                    sensors={sensors}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>

                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}


        </Fragment>

    )


}

AppsSensors.requireAuth = true;
AppsSensors.requiredScope = ['sensor.view.own', 'sensor.view.any'];
export default AppsSensors;
import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import Swal from '@molline/sweetalert2'
import {SensorsContext} from "../../../../context/Devices/Sensors";
import {AuthContext} from "../../../../context/Auth/AuthProvider";
import {useRouter} from "../../../Shared/Router/Router";
import {PermissionsWrapper, hasPermission} from "../../reactPermissionsWrapper";

import de from '../datatable.de.json';

const $ = require('jquery');
$.DataTable = require('datatables.net');


const TblSensorMeasurements = (props) => {

    const router = useRouter()
    const { sensor, sensorMeasurements, getTelegram } = useContext(SensorsContext);
    const sensorChanged = useCompare(sensor)
    const sensorMeasurementsChanged = useCompare(sensorMeasurements)
    const {user} = useContext(AuthContext);
   
    useEffect(() => {
        if(sensorMeasurementsChanged === true){
            initializeDT()
        }
       
    }, [sensor, sensorMeasurements, sensorChanged, sensorMeasurementsChanged]);

    function useCompare (val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }
  
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const initializeDT = () => {
        let table;
        if(sensorMeasurements){
            if ($.fn.dataTable.isDataTable(`#tblSensorMeasurements`)) {
                $(`#tblSensorMeasurements`).DataTable().destroy();
                // remove the eventhandler to prevent multiple events 
                $(`#tblSensorMeasurements tbody`).off('click')
                $(`#tblSensorMeasurements tbody`).off('mouseenter')
                $(`#tblSensorMeasurements tbody`).off('mouseleave')
                initializeDT()
            }else{
                table = $('#tblSensorMeasurements').DataTable({
                    "language": de,
                    "processing": true,
                    "data": sensorMeasurements,
                    "rowId": "telegram_id",
                    "order": [[ 0, "desc" ]],
                    "pageLength": 25,
                    "columns": [
                        {
                            "data": null,
                            "searchable": true,
                            "ordering": false,
                            "visible": false,
                            render: function ( data, type, row ) {
                                if(!!data?.attributes) return data.attributes
                                return '';
                            }
                        },

                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: function ( data, type, row ) {
                                if(!!data?.date) return data.date
                                return '';
                            }
                        },
                        { 
                            "data": null,  
                            render: function ( data, type, row ) {
                                if(!!data?.value) return data.value;
                                return "";
                            }
                        },
                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: function ( data, type, row ) {
                                if(!!data?.telegram_id) return '<a class="telegramId" id="'+data.telegram_id+'">'+data.telegram_id+'</a>'
                                return '<i >Imported</i>';
                            }
                        },
                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: function ( data, type, row ) {
                                return(
                                    `${hasPermission({user, scopes:['telegram.view.any', 'telegram.view.own']}) ? `<button class="btn btn-sm btn-primary btn-viewtg me-1"><i class="fa-solid fa-magnifying-glass"></i></button>`  : ''}`
                                )
                                
                            }
                        },
                    ],
                    "fnDrawCallback": function( oSettings ) {
                        
                    }
                });

                $('.dataTables_length select').addClass('form-select');
                $('.dataTables_filter input').addClass('form-control');

                $('#tblSensorMeasurements tbody').off('mouseenter').on('mouseenter', 'tr', function () {
                    $(this).css('cursor', 'pointer');
                    $(this).css('background-color', '#f3f2f7');
                });
                $('#tblSensorMeasurements tbody').off('mouseleave').on('mouseleave', 'tr', function () {
                    $(this).css('background-color', 'transparent');
                });

                $('#tblSensorMeasurements tbody').on('click', '.btn-viewtg', function (e) {
                    let button = e.currentTarget;
                    let parent = button.closest('tr');
                    getTelegram(parent.id)
                        .then(telegram => {
                            let t = JSON.stringify(telegram, null, 2)
                            Swal.fire({
                                title: 'Telegram',
                                icon: 'info',
                                width: '90%',
                                html:
                                    '<div class="text-left" style="text-align:left"><pre>' + t + '</pre></div>',
                                showCloseButton: true,
                                showCancelButton: false,
                            })
                        })
                        .catch(err => { })
                });
                
            }
        }
    }

    const UI = () => {

        return (
            <Fragment>

                <div className="btn-toolbar" role="toolbar">
                    <div className="btn-group" role="group">
                        <button className="btn btn-outline-secondary waves-effect" id="btnFilterDaily" data-filter="monthly" onClick={() => {
                            $(`#tblSensorMeasurements`).DataTable()
                            .columns(0)
                            .search($('#btnFilterDaily').text())
                            .draw();
                        }}>Daily</button>

                        <button className="btn btn-outline-secondary waves-effect" id="btnFilterMonthly" onClick={() => {
                            $(`#tblSensorMeasurements`).DataTable()
                            .columns(0)
                            .search($('#btnFilterMonthly').text())
                            .draw();
                        }}>Monthly</button>

                        <button className="btn btn-outline-secondary waves-effect" id="btnFilterYearly" onClick={() => {
                            $(`#tblSensorMeasurements`).DataTable()
                            .columns(0)
                            .search($('#btnFilterYearly').text())
                            .draw();
                        }}>Yearly</button>
                    </div>
                </div>

                <div className='table-responsive'>
                    <table id="tblSensorMeasurements" className="table mb-0 row-border hover order-column w-100" role="grid">
                        <thead>
                        <tr>
                            <th></th>
                            <th >Datum</th>
                            <th >Wert</th>
                            <th >Telegram</th>
                            <th></th>
                            
                        </tr>
                        </thead>

                    </table>
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>{UI()}</Fragment>
    )
}
export default TblSensorMeasurements;
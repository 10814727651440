
import React, {Fragment, useState, useEffect, useContext } from 'react';
import { AuthContext } from '../context/Auth/AuthProvider';


const Error404 = (props) => {

    const [updated, setUpdated] = useState(false);
    const {login} = useContext(AuthContext);


    useEffect(  () => {
        if(!updated){
            setUpdated(true)
        }
    }, );



    const UI = () => {
        return (
            <Fragment>
                <div className="app-content content">
                    <div className="content-wrapper">
                        <div className="content-header row"></div>
                        <div className="content-body">
                            <section className="row flexbox-container">
                                <div className="col-xl-6 col-md-7 col-9">
                                    <div className="card bg-transparent shadow-none">
                                        <div className="card-body text-center bg-transparent">
                                            <h2 className="error-title">Seite nicht gefunden :(</h2>
                                            <p className="pb-3">Wir konnten die von Ihnen gesuchte Seite nicht finden</p>
                                            <img className="img-fluid" src="../../../app-assets/images/pages/404.png" alt="404 error" />
                                            <a href="/" className="btn btn-primary round glow mt-3">ZUR STARTSEITE</a>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>

    )


}


Error404.requireAuth = false;
export default Error404;

import React, {createContext, Fragment, useEffect, useState} from 'react';
import axios from 'axios';

import {useRouter} from "../../components/Shared/Router/Router"

export const CmsContext = createContext();

const CmsProvider = ({children, context}) => {

    const [updated, setUpdated] = useState(false);
    const router = useRouter()

    const [faqs, setFaqs] = useState([]);
    const [articles, setArticles] = useState([]);
    const [categories, setCategories] = useState([]);
    const [articleSelected, setArticleSelected] = useState({});
    const [categorySelected, setCategorySelected] = useState({});

    useEffect( () => {

        if(!updated){
            /**
             * Get faqs and categories
             * Then ONLY show the categories that have faq content
             */
            getFaqs();
            getArticles();
            getCategories();

            setUpdated(true)
        }

    }, [faqs,categories])

    const getArticles = () => {
        ///remote/cms/article
        return new Promise( (resolve, reject) => {
            axios.get(`${process.env.REACT_APP_API_OPENMETER}/cms/article`)
                .then( response => {
                    if(response.data !== articles) setArticles(response.data)
                    resolve();
                })
                .catch(err => {
                    console.log('ARTICLES ERROR',err)
                    reject(err)
                });
        })
    }

    const getFaqs = () => {
        // /remote/cms/faq
        return new Promise( (resolve, reject) => {
            axios.get(`${process.env.REACT_APP_API_OPENMETER}/cms/faq`)
                .then( response => {
                    if(response.data !== faqs) setFaqs(response.data)
                    return resolve(response.data);
                })
                .catch(err => {
                    console.log(err)
                    reject(err)
                });
        })
    }

    const getCategories = () => {
        ///remote/cms/category
        return new Promise( (resolve, reject) => {
            axios.get(`${process.env.REACT_APP_API_OPENMETER}/cms/category` )
                .then( response => {
                    if(response.data !== categories) setCategories(response.data)
                    return resolve(response.data);
                })
                .catch(err => {
                    console.log(err)
                    reject(err)
                });
        })
    }

    const selectCategory = (event) => {
        let id = event.currentTarget.id;
        categories.map( cat => {
            if(cat._id === id){
                setCategorySelected(cat)
            }
        })
    }

    const configActions = {
        selectCategory,
        getFaqs,
        getArticles,
        getCategories,
        faqs,
        categories,
        setCategories,
        setFaqs,
        articles,
        articleSelected,
        setArticleSelected
    }

    return (
        <Fragment>
            <CmsContext.Provider value={configActions}>
                {children}
            </CmsContext.Provider>
        </Fragment>
    )

}

export default CmsProvider;